@charset "UTF-8";
.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.0667em;
}

.fa-xs {
  font-size: .75em;
}

.fa-sm {
  font-size: .875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}

.fa-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: .3em;
}

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: .3em;
}

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  -webkit-filter: none;
  filter: none;
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\f26e";
}

.fa-accessible-icon:before {
  content: "\f368";
}

.fa-accusoft:before {
  content: "\f369";
}

.fa-acquisitions-incorporated:before {
  content: "\f6af";
}

.fa-ad:before {
  content: "\f641";
}

.fa-address-book:before {
  content: "\f2b9";
}

.fa-address-card:before {
  content: "\f2bb";
}

.fa-adjust:before {
  content: "\f042";
}

.fa-adn:before {
  content: "\f170";
}

.fa-adobe:before {
  content: "\f778";
}

.fa-adversal:before {
  content: "\f36a";
}

.fa-affiliatetheme:before {
  content: "\f36b";
}

.fa-air-freshener:before {
  content: "\f5d0";
}

.fa-airbnb:before {
  content: "\f834";
}

.fa-algolia:before {
  content: "\f36c";
}

.fa-align-center:before {
  content: "\f037";
}

.fa-align-justify:before {
  content: "\f039";
}

.fa-align-left:before {
  content: "\f036";
}

.fa-align-right:before {
  content: "\f038";
}

.fa-alipay:before {
  content: "\f642";
}

.fa-allergies:before {
  content: "\f461";
}

.fa-amazon:before {
  content: "\f270";
}

.fa-amazon-pay:before {
  content: "\f42c";
}

.fa-ambulance:before {
  content: "\f0f9";
}

.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}

.fa-amilia:before {
  content: "\f36d";
}

.fa-anchor:before {
  content: "\f13d";
}

.fa-android:before {
  content: "\f17b";
}

.fa-angellist:before {
  content: "\f209";
}

.fa-angle-double-down:before {
  content: "\f103";
}

.fa-angle-double-left:before {
  content: "\f100";
}

.fa-angle-double-right:before {
  content: "\f101";
}

.fa-angle-double-up:before {
  content: "\f102";
}

.fa-angle-down:before {
  content: "\f107";
}

.fa-angle-left:before {
  content: "\f104";
}

.fa-angle-right:before {
  content: "\f105";
}

.fa-angle-up:before {
  content: "\f106";
}

.fa-angry:before {
  content: "\f556";
}

.fa-angrycreative:before {
  content: "\f36e";
}

.fa-angular:before {
  content: "\f420";
}

.fa-ankh:before {
  content: "\f644";
}

.fa-app-store:before {
  content: "\f36f";
}

.fa-app-store-ios:before {
  content: "\f370";
}

.fa-apper:before {
  content: "\f371";
}

.fa-apple:before {
  content: "\f179";
}

.fa-apple-alt:before {
  content: "\f5d1";
}

.fa-apple-pay:before {
  content: "\f415";
}

.fa-archive:before {
  content: "\f187";
}

.fa-archway:before {
  content: "\f557";
}

.fa-arrow-alt-circle-down:before {
  content: "\f358";
}

.fa-arrow-alt-circle-left:before {
  content: "\f359";
}

.fa-arrow-alt-circle-right:before {
  content: "\f35a";
}

.fa-arrow-alt-circle-up:before {
  content: "\f35b";
}

.fa-arrow-circle-down:before {
  content: "\f0ab";
}

.fa-arrow-circle-left:before {
  content: "\f0a8";
}

.fa-arrow-circle-right:before {
  content: "\f0a9";
}

.fa-arrow-circle-up:before {
  content: "\f0aa";
}

.fa-arrow-down:before {
  content: "\f063";
}

.fa-arrow-left:before {
  content: "\f060";
}

.fa-arrow-right:before {
  content: "\f061";
}

.fa-arrow-up:before {
  content: "\f062";
}

.fa-arrows-alt:before {
  content: "\f0b2";
}

.fa-arrows-alt-h:before {
  content: "\f337";
}

.fa-arrows-alt-v:before {
  content: "\f338";
}

.fa-artstation:before {
  content: "\f77a";
}

.fa-assistive-listening-systems:before {
  content: "\f2a2";
}

.fa-asterisk:before {
  content: "\f069";
}

.fa-asymmetrik:before {
  content: "\f372";
}

.fa-at:before {
  content: "\f1fa";
}

.fa-atlas:before {
  content: "\f558";
}

.fa-atlassian:before {
  content: "\f77b";
}

.fa-atom:before {
  content: "\f5d2";
}

.fa-audible:before {
  content: "\f373";
}

.fa-audio-description:before {
  content: "\f29e";
}

.fa-autoprefixer:before {
  content: "\f41c";
}

.fa-avianex:before {
  content: "\f374";
}

.fa-aviato:before {
  content: "\f421";
}

.fa-award:before {
  content: "\f559";
}

.fa-aws:before {
  content: "\f375";
}

.fa-baby:before {
  content: "\f77c";
}

.fa-baby-carriage:before {
  content: "\f77d";
}

.fa-backspace:before {
  content: "\f55a";
}

.fa-backward:before {
  content: "\f04a";
}

.fa-bacon:before {
  content: "\f7e5";
}

.fa-balance-scale:before {
  content: "\f24e";
}

.fa-ban:before {
  content: "\f05e";
}

.fa-band-aid:before {
  content: "\f462";
}

.fa-bandcamp:before {
  content: "\f2d5";
}

.fa-barcode:before {
  content: "\f02a";
}

.fa-bars:before {
  content: "\f0c9";
}

.fa-baseball-ball:before {
  content: "\f433";
}

.fa-basketball-ball:before {
  content: "\f434";
}

.fa-bath:before {
  content: "\f2cd";
}

.fa-battery-empty:before {
  content: "\f244";
}

.fa-battery-full:before {
  content: "\f240";
}

.fa-battery-half:before {
  content: "\f242";
}

.fa-battery-quarter:before {
  content: "\f243";
}

.fa-battery-three-quarters:before {
  content: "\f241";
}

.fa-battle-net:before {
  content: "\f835";
}

.fa-bed:before {
  content: "\f236";
}

.fa-beer:before {
  content: "\f0fc";
}

.fa-behance:before {
  content: "\f1b4";
}

.fa-behance-square:before {
  content: "\f1b5";
}

.fa-bell:before {
  content: "\f0f3";
}

.fa-bell-slash:before {
  content: "\f1f6";
}

.fa-bezier-curve:before {
  content: "\f55b";
}

.fa-bible:before {
  content: "\f647";
}

.fa-bicycle:before {
  content: "\f206";
}

.fa-bimobject:before {
  content: "\f378";
}

.fa-binoculars:before {
  content: "\f1e5";
}

.fa-biohazard:before {
  content: "\f780";
}

.fa-birthday-cake:before {
  content: "\f1fd";
}

.fa-bitbucket:before {
  content: "\f171";
}

.fa-bitcoin:before {
  content: "\f379";
}

.fa-bity:before {
  content: "\f37a";
}

.fa-black-tie:before {
  content: "\f27e";
}

.fa-blackberry:before {
  content: "\f37b";
}

.fa-blender:before {
  content: "\f517";
}

.fa-blender-phone:before {
  content: "\f6b6";
}

.fa-blind:before {
  content: "\f29d";
}

.fa-blog:before {
  content: "\f781";
}

.fa-blogger:before {
  content: "\f37c";
}

.fa-blogger-b:before {
  content: "\f37d";
}

.fa-bluetooth:before {
  content: "\f293";
}

.fa-bluetooth-b:before {
  content: "\f294";
}

.fa-bold:before {
  content: "\f032";
}

.fa-bolt:before {
  content: "\f0e7";
}

.fa-bomb:before {
  content: "\f1e2";
}

.fa-bone:before {
  content: "\f5d7";
}

.fa-bong:before {
  content: "\f55c";
}

.fa-book:before {
  content: "\f02d";
}

.fa-book-dead:before {
  content: "\f6b7";
}

.fa-book-medical:before {
  content: "\f7e6";
}

.fa-book-open:before {
  content: "\f518";
}

.fa-book-reader:before {
  content: "\f5da";
}

.fa-bookmark:before {
  content: "\f02e";
}

.fa-bootstrap:before {
  content: "\f836";
}

.fa-bowling-ball:before {
  content: "\f436";
}

.fa-box:before {
  content: "\f466";
}

.fa-box-open:before {
  content: "\f49e";
}

.fa-boxes:before {
  content: "\f468";
}

.fa-braille:before {
  content: "\f2a1";
}

.fa-brain:before {
  content: "\f5dc";
}

.fa-bread-slice:before {
  content: "\f7ec";
}

.fa-briefcase:before {
  content: "\f0b1";
}

.fa-briefcase-medical:before {
  content: "\f469";
}

.fa-broadcast-tower:before {
  content: "\f519";
}

.fa-broom:before {
  content: "\f51a";
}

.fa-brush:before {
  content: "\f55d";
}

.fa-btc:before {
  content: "\f15a";
}

.fa-buffer:before {
  content: "\f837";
}

.fa-bug:before {
  content: "\f188";
}

.fa-building:before {
  content: "\f1ad";
}

.fa-bullhorn:before {
  content: "\f0a1";
}

.fa-bullseye:before {
  content: "\f140";
}

.fa-burn:before {
  content: "\f46a";
}

.fa-buromobelexperte:before {
  content: "\f37f";
}

.fa-bus:before {
  content: "\f207";
}

.fa-bus-alt:before {
  content: "\f55e";
}

.fa-business-time:before {
  content: "\f64a";
}

.fa-buysellads:before {
  content: "\f20d";
}

.fa-calculator:before {
  content: "\f1ec";
}

.fa-calendar:before {
  content: "\f133";
}

.fa-calendar-alt:before {
  content: "\f073";
}

.fa-calendar-check:before {
  content: "\f274";
}

.fa-calendar-day:before {
  content: "\f783";
}

.fa-calendar-minus:before {
  content: "\f272";
}

.fa-calendar-plus:before {
  content: "\f271";
}

.fa-calendar-times:before {
  content: "\f273";
}

.fa-calendar-week:before {
  content: "\f784";
}

.fa-camera:before {
  content: "\f030";
}

.fa-camera-retro:before {
  content: "\f083";
}

.fa-campground:before {
  content: "\f6bb";
}

.fa-canadian-maple-leaf:before {
  content: "\f785";
}

.fa-candy-cane:before {
  content: "\f786";
}

.fa-cannabis:before {
  content: "\f55f";
}

.fa-capsules:before {
  content: "\f46b";
}

.fa-car:before {
  content: "\f1b9";
}

.fa-car-alt:before {
  content: "\f5de";
}

.fa-car-battery:before {
  content: "\f5df";
}

.fa-car-crash:before {
  content: "\f5e1";
}

.fa-car-side:before {
  content: "\f5e4";
}

.fa-caret-down:before {
  content: "\f0d7";
}

.fa-caret-left:before {
  content: "\f0d9";
}

.fa-caret-right:before {
  content: "\f0da";
}

.fa-caret-square-down:before {
  content: "\f150";
}

.fa-caret-square-left:before {
  content: "\f191";
}

.fa-caret-square-right:before {
  content: "\f152";
}

.fa-caret-square-up:before {
  content: "\f151";
}

.fa-caret-up:before {
  content: "\f0d8";
}

.fa-carrot:before {
  content: "\f787";
}

.fa-cart-arrow-down:before {
  content: "\f218";
}

.fa-cart-plus:before {
  content: "\f217";
}

.fa-cash-register:before {
  content: "\f788";
}

.fa-cat:before {
  content: "\f6be";
}

.fa-cc-amazon-pay:before {
  content: "\f42d";
}

.fa-cc-amex:before {
  content: "\f1f3";
}

.fa-cc-apple-pay:before {
  content: "\f416";
}

.fa-cc-diners-club:before {
  content: "\f24c";
}

.fa-cc-discover:before {
  content: "\f1f2";
}

.fa-cc-jcb:before {
  content: "\f24b";
}

.fa-cc-mastercard:before {
  content: "\f1f1";
}

.fa-cc-paypal:before {
  content: "\f1f4";
}

.fa-cc-stripe:before {
  content: "\f1f5";
}

.fa-cc-visa:before {
  content: "\f1f0";
}

.fa-centercode:before {
  content: "\f380";
}

.fa-centos:before {
  content: "\f789";
}

.fa-certificate:before {
  content: "\f0a3";
}

.fa-chair:before {
  content: "\f6c0";
}

.fa-chalkboard:before {
  content: "\f51b";
}

.fa-chalkboard-teacher:before {
  content: "\f51c";
}

.fa-charging-station:before {
  content: "\f5e7";
}

.fa-chart-area:before {
  content: "\f1fe";
}

.fa-chart-bar:before {
  content: "\f080";
}

.fa-chart-line:before {
  content: "\f201";
}

.fa-chart-pie:before {
  content: "\f200";
}

.fa-check:before {
  content: "\f00c";
}

.fa-check-circle:before {
  content: "\f058";
}

.fa-check-double:before {
  content: "\f560";
}

.fa-check-square:before {
  content: "\f14a";
}

.fa-cheese:before {
  content: "\f7ef";
}

.fa-chess:before {
  content: "\f439";
}

.fa-chess-bishop:before {
  content: "\f43a";
}

.fa-chess-board:before {
  content: "\f43c";
}

.fa-chess-king:before {
  content: "\f43f";
}

.fa-chess-knight:before {
  content: "\f441";
}

.fa-chess-pawn:before {
  content: "\f443";
}

.fa-chess-queen:before {
  content: "\f445";
}

.fa-chess-rook:before {
  content: "\f447";
}

.fa-chevron-circle-down:before {
  content: "\f13a";
}

.fa-chevron-circle-left:before {
  content: "\f137";
}

.fa-chevron-circle-right:before {
  content: "\f138";
}

.fa-chevron-circle-up:before {
  content: "\f139";
}

.fa-chevron-down:before {
  content: "\f078";
}

.fa-chevron-left:before {
  content: "\f053";
}

.fa-chevron-right:before {
  content: "\f054";
}

.fa-chevron-up:before {
  content: "\f077";
}

.fa-child:before {
  content: "\f1ae";
}

.fa-chrome:before {
  content: "\f268";
}

.fa-chromecast:before {
  content: "\f838";
}

.fa-church:before {
  content: "\f51d";
}

.fa-circle:before {
  content: "\f111";
}

.fa-circle-notch:before {
  content: "\f1ce";
}

.fa-city:before {
  content: "\f64f";
}

.fa-clinic-medical:before {
  content: "\f7f2";
}

.fa-clipboard:before {
  content: "\f328";
}

.fa-clipboard-check:before {
  content: "\f46c";
}

.fa-clipboard-list:before {
  content: "\f46d";
}

.fa-clock:before {
  content: "\f017";
}

.fa-clone:before {
  content: "\f24d";
}

.fa-closed-captioning:before {
  content: "\f20a";
}

.fa-cloud:before {
  content: "\f0c2";
}

.fa-cloud-download-alt:before {
  content: "\f381";
}

.fa-cloud-meatball:before {
  content: "\f73b";
}

.fa-cloud-moon:before {
  content: "\f6c3";
}

.fa-cloud-moon-rain:before {
  content: "\f73c";
}

.fa-cloud-rain:before {
  content: "\f73d";
}

.fa-cloud-showers-heavy:before {
  content: "\f740";
}

.fa-cloud-sun:before {
  content: "\f6c4";
}

.fa-cloud-sun-rain:before {
  content: "\f743";
}

.fa-cloud-upload-alt:before {
  content: "\f382";
}

.fa-cloudscale:before {
  content: "\f383";
}

.fa-cloudsmith:before {
  content: "\f384";
}

.fa-cloudversify:before {
  content: "\f385";
}

.fa-cocktail:before {
  content: "\f561";
}

.fa-code:before {
  content: "\f121";
}

.fa-code-branch:before {
  content: "\f126";
}

.fa-codepen:before {
  content: "\f1cb";
}

.fa-codiepie:before {
  content: "\f284";
}

.fa-coffee:before {
  content: "\f0f4";
}

.fa-cog:before {
  content: "\f013";
}

.fa-cogs:before {
  content: "\f085";
}

.fa-coins:before {
  content: "\f51e";
}

.fa-columns:before {
  content: "\f0db";
}

.fa-comment:before {
  content: "\f075";
}

.fa-comment-alt:before {
  content: "\f27a";
}

.fa-comment-dollar:before {
  content: "\f651";
}

.fa-comment-dots:before {
  content: "\f4ad";
}

.fa-comment-medical:before {
  content: "\f7f5";
}

.fa-comment-slash:before {
  content: "\f4b3";
}

.fa-comments:before {
  content: "\f086";
}

.fa-comments-dollar:before {
  content: "\f653";
}

.fa-compact-disc:before {
  content: "\f51f";
}

.fa-compass:before {
  content: "\f14e";
}

.fa-compress:before {
  content: "\f066";
}

.fa-compress-arrows-alt:before {
  content: "\f78c";
}

.fa-concierge-bell:before {
  content: "\f562";
}

.fa-confluence:before {
  content: "\f78d";
}

.fa-connectdevelop:before {
  content: "\f20e";
}

.fa-contao:before {
  content: "\f26d";
}

.fa-cookie:before {
  content: "\f563";
}

.fa-cookie-bite:before {
  content: "\f564";
}

.fa-copy:before {
  content: "\f0c5";
}

.fa-copyright:before {
  content: "\f1f9";
}

.fa-couch:before {
  content: "\f4b8";
}

.fa-cpanel:before {
  content: "\f388";
}

.fa-creative-commons:before {
  content: "\f25e";
}

.fa-creative-commons-by:before {
  content: "\f4e7";
}

.fa-creative-commons-nc:before {
  content: "\f4e8";
}

.fa-creative-commons-nc-eu:before {
  content: "\f4e9";
}

.fa-creative-commons-nc-jp:before {
  content: "\f4ea";
}

.fa-creative-commons-nd:before {
  content: "\f4eb";
}

.fa-creative-commons-pd:before {
  content: "\f4ec";
}

.fa-creative-commons-pd-alt:before {
  content: "\f4ed";
}

.fa-creative-commons-remix:before {
  content: "\f4ee";
}

.fa-creative-commons-sa:before {
  content: "\f4ef";
}

.fa-creative-commons-sampling:before {
  content: "\f4f0";
}

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1";
}

.fa-creative-commons-share:before {
  content: "\f4f2";
}

.fa-creative-commons-zero:before {
  content: "\f4f3";
}

.fa-credit-card:before {
  content: "\f09d";
}

.fa-critical-role:before {
  content: "\f6c9";
}

.fa-crop:before {
  content: "\f125";
}

.fa-crop-alt:before {
  content: "\f565";
}

.fa-cross:before {
  content: "\f654";
}

.fa-crosshairs:before {
  content: "\f05b";
}

.fa-crow:before {
  content: "\f520";
}

.fa-crown:before {
  content: "\f521";
}

.fa-crutch:before {
  content: "\f7f7";
}

.fa-css3:before {
  content: "\f13c";
}

.fa-css3-alt:before {
  content: "\f38b";
}

.fa-cube:before {
  content: "\f1b2";
}

.fa-cubes:before {
  content: "\f1b3";
}

.fa-cut:before {
  content: "\f0c4";
}

.fa-cuttlefish:before {
  content: "\f38c";
}

.fa-d-and-d:before {
  content: "\f38d";
}

.fa-d-and-d-beyond:before {
  content: "\f6ca";
}

.fa-dashcube:before {
  content: "\f210";
}

.fa-database:before {
  content: "\f1c0";
}

.fa-deaf:before {
  content: "\f2a4";
}

.fa-delicious:before {
  content: "\f1a5";
}

.fa-democrat:before {
  content: "\f747";
}

.fa-deploydog:before {
  content: "\f38e";
}

.fa-deskpro:before {
  content: "\f38f";
}

.fa-desktop:before {
  content: "\f108";
}

.fa-dev:before {
  content: "\f6cc";
}

.fa-deviantart:before {
  content: "\f1bd";
}

.fa-dharmachakra:before {
  content: "\f655";
}

.fa-dhl:before {
  content: "\f790";
}

.fa-diagnoses:before {
  content: "\f470";
}

.fa-diaspora:before {
  content: "\f791";
}

.fa-dice:before {
  content: "\f522";
}

.fa-dice-d20:before {
  content: "\f6cf";
}

.fa-dice-d6:before {
  content: "\f6d1";
}

.fa-dice-five:before {
  content: "\f523";
}

.fa-dice-four:before {
  content: "\f524";
}

.fa-dice-one:before {
  content: "\f525";
}

.fa-dice-six:before {
  content: "\f526";
}

.fa-dice-three:before {
  content: "\f527";
}

.fa-dice-two:before {
  content: "\f528";
}

.fa-digg:before {
  content: "\f1a6";
}

.fa-digital-ocean:before {
  content: "\f391";
}

.fa-digital-tachograph:before {
  content: "\f566";
}

.fa-directions:before {
  content: "\f5eb";
}

.fa-discord:before {
  content: "\f392";
}

.fa-discourse:before {
  content: "\f393";
}

.fa-divide:before {
  content: "\f529";
}

.fa-dizzy:before {
  content: "\f567";
}

.fa-dna:before {
  content: "\f471";
}

.fa-dochub:before {
  content: "\f394";
}

.fa-docker:before {
  content: "\f395";
}

.fa-dog:before {
  content: "\f6d3";
}

.fa-dollar-sign:before {
  content: "\f155";
}

.fa-dolly:before {
  content: "\f472";
}

.fa-dolly-flatbed:before {
  content: "\f474";
}

.fa-donate:before {
  content: "\f4b9";
}

.fa-door-closed:before {
  content: "\f52a";
}

.fa-door-open:before {
  content: "\f52b";
}

.fa-dot-circle:before {
  content: "\f192";
}

.fa-dove:before {
  content: "\f4ba";
}

.fa-download:before {
  content: "\f019";
}

.fa-draft2digital:before {
  content: "\f396";
}

.fa-drafting-compass:before {
  content: "\f568";
}

.fa-dragon:before {
  content: "\f6d5";
}

.fa-draw-polygon:before {
  content: "\f5ee";
}

.fa-dribbble:before {
  content: "\f17d";
}

.fa-dribbble-square:before {
  content: "\f397";
}

.fa-dropbox:before {
  content: "\f16b";
}

.fa-drum:before {
  content: "\f569";
}

.fa-drum-steelpan:before {
  content: "\f56a";
}

.fa-drumstick-bite:before {
  content: "\f6d7";
}

.fa-drupal:before {
  content: "\f1a9";
}

.fa-dumbbell:before {
  content: "\f44b";
}

.fa-dumpster:before {
  content: "\f793";
}

.fa-dumpster-fire:before {
  content: "\f794";
}

.fa-dungeon:before {
  content: "\f6d9";
}

.fa-dyalog:before {
  content: "\f399";
}

.fa-earlybirds:before {
  content: "\f39a";
}

.fa-ebay:before {
  content: "\f4f4";
}

.fa-edge:before {
  content: "\f282";
}

.fa-edit:before {
  content: "\f044";
}

.fa-egg:before {
  content: "\f7fb";
}

.fa-eject:before {
  content: "\f052";
}

.fa-elementor:before {
  content: "\f430";
}

.fa-ellipsis-h:before {
  content: "\f141";
}

.fa-ellipsis-v:before {
  content: "\f142";
}

.fa-ello:before {
  content: "\f5f1";
}

.fa-ember:before {
  content: "\f423";
}

.fa-empire:before {
  content: "\f1d1";
}

.fa-envelope:before {
  content: "\f0e0";
}

.fa-envelope-open:before {
  content: "\f2b6";
}

.fa-envelope-open-text:before {
  content: "\f658";
}

.fa-envelope-square:before {
  content: "\f199";
}

.fa-envira:before {
  content: "\f299";
}

.fa-equals:before {
  content: "\f52c";
}

.fa-eraser:before {
  content: "\f12d";
}

.fa-erlang:before {
  content: "\f39d";
}

.fa-ethereum:before {
  content: "\f42e";
}

.fa-ethernet:before {
  content: "\f796";
}

.fa-etsy:before {
  content: "\f2d7";
}

.fa-euro-sign:before {
  content: "\f153";
}

.fa-evernote:before {
  content: "\f839";
}

.fa-exchange-alt:before {
  content: "\f362";
}

.fa-exclamation:before {
  content: "\f12a";
}

.fa-exclamation-circle:before {
  content: "\f06a";
}

.fa-exclamation-triangle:before {
  content: "\f071";
}

.fa-expand:before {
  content: "\f065";
}

.fa-expand-arrows-alt:before {
  content: "\f31e";
}

.fa-expeditedssl:before {
  content: "\f23e";
}

.fa-external-link-alt:before {
  content: "\f35d";
}

.fa-external-link-square-alt:before {
  content: "\f360";
}

.fa-eye:before {
  content: "\f06e";
}

.fa-eye-dropper:before {
  content: "\f1fb";
}

.fa-eye-slash:before {
  content: "\f070";
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-facebook-f:before {
  content: "\f39e";
}

.fa-facebook-messenger:before {
  content: "\f39f";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-fantasy-flight-games:before {
  content: "\f6dc";
}

.fa-fast-backward:before {
  content: "\f049";
}

.fa-fast-forward:before {
  content: "\f050";
}

.fa-fax:before {
  content: "\f1ac";
}

.fa-feather:before {
  content: "\f52d";
}

.fa-feather-alt:before {
  content: "\f56b";
}

.fa-fedex:before {
  content: "\f797";
}

.fa-fedora:before {
  content: "\f798";
}

.fa-female:before {
  content: "\f182";
}

.fa-fighter-jet:before {
  content: "\f0fb";
}

.fa-figma:before {
  content: "\f799";
}

.fa-file:before {
  content: "\f15b";
}

.fa-file-alt:before {
  content: "\f15c";
}

.fa-file-archive:before {
  content: "\f1c6";
}

.fa-file-audio:before {
  content: "\f1c7";
}

.fa-file-code:before {
  content: "\f1c9";
}

.fa-file-contract:before {
  content: "\f56c";
}

.fa-file-csv:before {
  content: "\f6dd";
}

.fa-file-download:before {
  content: "\f56d";
}

.fa-file-excel:before {
  content: "\f1c3";
}

.fa-file-export:before {
  content: "\f56e";
}

.fa-file-image:before {
  content: "\f1c5";
}

.fa-file-import:before {
  content: "\f56f";
}

.fa-file-invoice:before {
  content: "\f570";
}

.fa-file-invoice-dollar:before {
  content: "\f571";
}

.fa-file-medical:before {
  content: "\f477";
}

.fa-file-medical-alt:before {
  content: "\f478";
}

.fa-file-pdf:before {
  content: "\f1c1";
}

.fa-file-powerpoint:before {
  content: "\f1c4";
}

.fa-file-prescription:before {
  content: "\f572";
}

.fa-file-signature:before {
  content: "\f573";
}

.fa-file-upload:before {
  content: "\f574";
}

.fa-file-video:before {
  content: "\f1c8";
}

.fa-file-word:before {
  content: "\f1c2";
}

.fa-fill:before {
  content: "\f575";
}

.fa-fill-drip:before {
  content: "\f576";
}

.fa-film:before {
  content: "\f008";
}

.fa-filter:before {
  content: "\f0b0";
}

.fa-fingerprint:before {
  content: "\f577";
}

.fa-fire:before {
  content: "\f06d";
}

.fa-fire-alt:before {
  content: "\f7e4";
}

.fa-fire-extinguisher:before {
  content: "\f134";
}

.fa-firefox:before {
  content: "\f269";
}

.fa-first-aid:before {
  content: "\f479";
}

.fa-first-order:before {
  content: "\f2b0";
}

.fa-first-order-alt:before {
  content: "\f50a";
}

.fa-firstdraft:before {
  content: "\f3a1";
}

.fa-fish:before {
  content: "\f578";
}

.fa-fist-raised:before {
  content: "\f6de";
}

.fa-flag:before {
  content: "\f024";
}

.fa-flag-checkered:before {
  content: "\f11e";
}

.fa-flag-usa:before {
  content: "\f74d";
}

.fa-flask:before {
  content: "\f0c3";
}

.fa-flickr:before {
  content: "\f16e";
}

.fa-flipboard:before {
  content: "\f44d";
}

.fa-flushed:before {
  content: "\f579";
}

.fa-fly:before {
  content: "\f417";
}

.fa-folder:before {
  content: "\f07b";
}

.fa-folder-minus:before {
  content: "\f65d";
}

.fa-folder-open:before {
  content: "\f07c";
}

.fa-folder-plus:before {
  content: "\f65e";
}

.fa-font:before {
  content: "\f031";
}

.fa-font-awesome:before {
  content: "\f2b4";
}

.fa-font-awesome-alt:before {
  content: "\f35c";
}

.fa-font-awesome-flag:before {
  content: "\f425";
}

.fa-font-awesome-logo-full:before {
  content: "\f4e6";
}

.fa-fonticons:before {
  content: "\f280";
}

.fa-fonticons-fi:before {
  content: "\f3a2";
}

.fa-football-ball:before {
  content: "\f44e";
}

.fa-fort-awesome:before {
  content: "\f286";
}

.fa-fort-awesome-alt:before {
  content: "\f3a3";
}

.fa-forumbee:before {
  content: "\f211";
}

.fa-forward:before {
  content: "\f04e";
}

.fa-foursquare:before {
  content: "\f180";
}

.fa-free-code-camp:before {
  content: "\f2c5";
}

.fa-freebsd:before {
  content: "\f3a4";
}

.fa-frog:before {
  content: "\f52e";
}

.fa-frown:before {
  content: "\f119";
}

.fa-frown-open:before {
  content: "\f57a";
}

.fa-fulcrum:before {
  content: "\f50b";
}

.fa-funnel-dollar:before {
  content: "\f662";
}

.fa-futbol:before {
  content: "\f1e3";
}

.fa-galactic-republic:before {
  content: "\f50c";
}

.fa-galactic-senate:before {
  content: "\f50d";
}

.fa-gamepad:before {
  content: "\f11b";
}

.fa-gas-pump:before {
  content: "\f52f";
}

.fa-gavel:before {
  content: "\f0e3";
}

.fa-gem:before {
  content: "\f3a5";
}

.fa-genderless:before {
  content: "\f22d";
}

.fa-get-pocket:before {
  content: "\f265";
}

.fa-gg:before {
  content: "\f260";
}

.fa-gg-circle:before {
  content: "\f261";
}

.fa-ghost:before {
  content: "\f6e2";
}

.fa-gift:before {
  content: "\f06b";
}

.fa-gifts:before {
  content: "\f79c";
}

.fa-git:before {
  content: "\f1d3";
}

.fa-git-alt:before {
  content: "\f841";
}

.fa-git-square:before {
  content: "\f1d2";
}

.fa-github:before {
  content: "\f09b";
}

.fa-github-alt:before {
  content: "\f113";
}

.fa-github-square:before {
  content: "\f092";
}

.fa-gitkraken:before {
  content: "\f3a6";
}

.fa-gitlab:before {
  content: "\f296";
}

.fa-gitter:before {
  content: "\f426";
}

.fa-glass-cheers:before {
  content: "\f79f";
}

.fa-glass-martini:before {
  content: "\f000";
}

.fa-glass-martini-alt:before {
  content: "\f57b";
}

.fa-glass-whiskey:before {
  content: "\f7a0";
}

.fa-glasses:before {
  content: "\f530";
}

.fa-glide:before {
  content: "\f2a5";
}

.fa-glide-g:before {
  content: "\f2a6";
}

.fa-globe:before {
  content: "\f0ac";
}

.fa-globe-africa:before {
  content: "\f57c";
}

.fa-globe-americas:before {
  content: "\f57d";
}

.fa-globe-asia:before {
  content: "\f57e";
}

.fa-globe-europe:before {
  content: "\f7a2";
}

.fa-gofore:before {
  content: "\f3a7";
}

.fa-golf-ball:before {
  content: "\f450";
}

.fa-goodreads:before {
  content: "\f3a8";
}

.fa-goodreads-g:before {
  content: "\f3a9";
}

.fa-google:before {
  content: "\f1a0";
}

.fa-google-drive:before {
  content: "\f3aa";
}

.fa-google-play:before {
  content: "\f3ab";
}

.fa-google-plus:before {
  content: "\f2b3";
}

.fa-google-plus-g:before {
  content: "\f0d5";
}

.fa-google-plus-square:before {
  content: "\f0d4";
}

.fa-google-wallet:before {
  content: "\f1ee";
}

.fa-gopuram:before {
  content: "\f664";
}

.fa-graduation-cap:before {
  content: "\f19d";
}

.fa-gratipay:before {
  content: "\f184";
}

.fa-grav:before {
  content: "\f2d6";
}

.fa-greater-than:before {
  content: "\f531";
}

.fa-greater-than-equal:before {
  content: "\f532";
}

.fa-grimace:before {
  content: "\f57f";
}

.fa-grin:before {
  content: "\f580";
}

.fa-grin-alt:before {
  content: "\f581";
}

.fa-grin-beam:before {
  content: "\f582";
}

.fa-grin-beam-sweat:before {
  content: "\f583";
}

.fa-grin-hearts:before {
  content: "\f584";
}

.fa-grin-squint:before {
  content: "\f585";
}

.fa-grin-squint-tears:before {
  content: "\f586";
}

.fa-grin-stars:before {
  content: "\f587";
}

.fa-grin-tears:before {
  content: "\f588";
}

.fa-grin-tongue:before {
  content: "\f589";
}

.fa-grin-tongue-squint:before {
  content: "\f58a";
}

.fa-grin-tongue-wink:before {
  content: "\f58b";
}

.fa-grin-wink:before {
  content: "\f58c";
}

.fa-grip-horizontal:before {
  content: "\f58d";
}

.fa-grip-lines:before {
  content: "\f7a4";
}

.fa-grip-lines-vertical:before {
  content: "\f7a5";
}

.fa-grip-vertical:before {
  content: "\f58e";
}

.fa-gripfire:before {
  content: "\f3ac";
}

.fa-grunt:before {
  content: "\f3ad";
}

.fa-guitar:before {
  content: "\f7a6";
}

.fa-gulp:before {
  content: "\f3ae";
}

.fa-h-square:before {
  content: "\f0fd";
}

.fa-hacker-news:before {
  content: "\f1d4";
}

.fa-hacker-news-square:before {
  content: "\f3af";
}

.fa-hackerrank:before {
  content: "\f5f7";
}

.fa-hamburger:before {
  content: "\f805";
}

.fa-hammer:before {
  content: "\f6e3";
}

.fa-hamsa:before {
  content: "\f665";
}

.fa-hand-holding:before {
  content: "\f4bd";
}

.fa-hand-holding-heart:before {
  content: "\f4be";
}

.fa-hand-holding-usd:before {
  content: "\f4c0";
}

.fa-hand-lizard:before {
  content: "\f258";
}

.fa-hand-middle-finger:before {
  content: "\f806";
}

.fa-hand-paper:before {
  content: "\f256";
}

.fa-hand-peace:before {
  content: "\f25b";
}

.fa-hand-point-down:before {
  content: "\f0a7";
}

.fa-hand-point-left:before {
  content: "\f0a5";
}

.fa-hand-point-right:before {
  content: "\f0a4";
}

.fa-hand-point-up:before {
  content: "\f0a6";
}

.fa-hand-pointer:before {
  content: "\f25a";
}

.fa-hand-rock:before {
  content: "\f255";
}

.fa-hand-scissors:before {
  content: "\f257";
}

.fa-hand-spock:before {
  content: "\f259";
}

.fa-hands:before {
  content: "\f4c2";
}

.fa-hands-helping:before {
  content: "\f4c4";
}

.fa-handshake:before {
  content: "\f2b5";
}

.fa-hanukiah:before {
  content: "\f6e6";
}

.fa-hard-hat:before {
  content: "\f807";
}

.fa-hashtag:before {
  content: "\f292";
}

.fa-hat-wizard:before {
  content: "\f6e8";
}

.fa-haykal:before {
  content: "\f666";
}

.fa-hdd:before {
  content: "\f0a0";
}

.fa-heading:before {
  content: "\f1dc";
}

.fa-headphones:before {
  content: "\f025";
}

.fa-headphones-alt:before {
  content: "\f58f";
}

.fa-headset:before {
  content: "\f590";
}

.fa-heart:before {
  content: "\f004";
}

.fa-heart-broken:before {
  content: "\f7a9";
}

.fa-heartbeat:before {
  content: "\f21e";
}

.fa-helicopter:before {
  content: "\f533";
}

.fa-highlighter:before {
  content: "\f591";
}

.fa-hiking:before {
  content: "\f6ec";
}

.fa-hippo:before {
  content: "\f6ed";
}

.fa-hips:before {
  content: "\f452";
}

.fa-hire-a-helper:before {
  content: "\f3b0";
}

.fa-history:before {
  content: "\f1da";
}

.fa-hockey-puck:before {
  content: "\f453";
}

.fa-holly-berry:before {
  content: "\f7aa";
}

.fa-home:before {
  content: "\f015";
}

.fa-hooli:before {
  content: "\f427";
}

.fa-hornbill:before {
  content: "\f592";
}

.fa-horse:before {
  content: "\f6f0";
}

.fa-horse-head:before {
  content: "\f7ab";
}

.fa-hospital:before {
  content: "\f0f8";
}

.fa-hospital-alt:before {
  content: "\f47d";
}

.fa-hospital-symbol:before {
  content: "\f47e";
}

.fa-hot-tub:before {
  content: "\f593";
}

.fa-hotdog:before {
  content: "\f80f";
}

.fa-hotel:before {
  content: "\f594";
}

.fa-hotjar:before {
  content: "\f3b1";
}

.fa-hourglass:before {
  content: "\f254";
}

.fa-hourglass-end:before {
  content: "\f253";
}

.fa-hourglass-half:before {
  content: "\f252";
}

.fa-hourglass-start:before {
  content: "\f251";
}

.fa-house-damage:before {
  content: "\f6f1";
}

.fa-houzz:before {
  content: "\f27c";
}

.fa-hryvnia:before {
  content: "\f6f2";
}

.fa-html5:before {
  content: "\f13b";
}

.fa-hubspot:before {
  content: "\f3b2";
}

.fa-i-cursor:before {
  content: "\f246";
}

.fa-ice-cream:before {
  content: "\f810";
}

.fa-icicles:before {
  content: "\f7ad";
}

.fa-id-badge:before {
  content: "\f2c1";
}

.fa-id-card:before {
  content: "\f2c2";
}

.fa-id-card-alt:before {
  content: "\f47f";
}

.fa-igloo:before {
  content: "\f7ae";
}

.fa-image:before {
  content: "\f03e";
}

.fa-images:before {
  content: "\f302";
}

.fa-imdb:before {
  content: "\f2d8";
}

.fa-inbox:before {
  content: "\f01c";
}

.fa-indent:before {
  content: "\f03c";
}

.fa-industry:before {
  content: "\f275";
}

.fa-infinity:before {
  content: "\f534";
}

.fa-info:before {
  content: "\f129";
}

.fa-info-circle:before {
  content: "\f05a";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-intercom:before {
  content: "\f7af";
}

.fa-internet-explorer:before {
  content: "\f26b";
}

.fa-invision:before {
  content: "\f7b0";
}

.fa-ioxhost:before {
  content: "\f208";
}

.fa-italic:before {
  content: "\f033";
}

.fa-itch-io:before {
  content: "\f83a";
}

.fa-itunes:before {
  content: "\f3b4";
}

.fa-itunes-note:before {
  content: "\f3b5";
}

.fa-java:before {
  content: "\f4e4";
}

.fa-jedi:before {
  content: "\f669";
}

.fa-jedi-order:before {
  content: "\f50e";
}

.fa-jenkins:before {
  content: "\f3b6";
}

.fa-jira:before {
  content: "\f7b1";
}

.fa-joget:before {
  content: "\f3b7";
}

.fa-joint:before {
  content: "\f595";
}

.fa-joomla:before {
  content: "\f1aa";
}

.fa-journal-whills:before {
  content: "\f66a";
}

.fa-js:before {
  content: "\f3b8";
}

.fa-js-square:before {
  content: "\f3b9";
}

.fa-jsfiddle:before {
  content: "\f1cc";
}

.fa-kaaba:before {
  content: "\f66b";
}

.fa-kaggle:before {
  content: "\f5fa";
}

.fa-key:before {
  content: "\f084";
}

.fa-keybase:before {
  content: "\f4f5";
}

.fa-keyboard:before {
  content: "\f11c";
}

.fa-keycdn:before {
  content: "\f3ba";
}

.fa-khanda:before {
  content: "\f66d";
}

.fa-kickstarter:before {
  content: "\f3bb";
}

.fa-kickstarter-k:before {
  content: "\f3bc";
}

.fa-kiss:before {
  content: "\f596";
}

.fa-kiss-beam:before {
  content: "\f597";
}

.fa-kiss-wink-heart:before {
  content: "\f598";
}

.fa-kiwi-bird:before {
  content: "\f535";
}

.fa-korvue:before {
  content: "\f42f";
}

.fa-landmark:before {
  content: "\f66f";
}

.fa-language:before {
  content: "\f1ab";
}

.fa-laptop:before {
  content: "\f109";
}

.fa-laptop-code:before {
  content: "\f5fc";
}

.fa-laptop-medical:before {
  content: "\f812";
}

.fa-laravel:before {
  content: "\f3bd";
}

.fa-lastfm:before {
  content: "\f202";
}

.fa-lastfm-square:before {
  content: "\f203";
}

.fa-laugh:before {
  content: "\f599";
}

.fa-laugh-beam:before {
  content: "\f59a";
}

.fa-laugh-squint:before {
  content: "\f59b";
}

.fa-laugh-wink:before {
  content: "\f59c";
}

.fa-layer-group:before {
  content: "\f5fd";
}

.fa-leaf:before {
  content: "\f06c";
}

.fa-leanpub:before {
  content: "\f212";
}

.fa-lemon:before {
  content: "\f094";
}

.fa-less:before {
  content: "\f41d";
}

.fa-less-than:before {
  content: "\f536";
}

.fa-less-than-equal:before {
  content: "\f537";
}

.fa-level-down-alt:before {
  content: "\f3be";
}

.fa-level-up-alt:before {
  content: "\f3bf";
}

.fa-life-ring:before {
  content: "\f1cd";
}

.fa-lightbulb:before {
  content: "\f0eb";
}

.fa-line:before {
  content: "\f3c0";
}

.fa-link:before {
  content: "\f0c1";
}

.fa-linkedin:before {
  content: "\f08c";
}

.fa-linkedin-in:before {
  content: "\f0e1";
}

.fa-linode:before {
  content: "\f2b8";
}

.fa-linux:before {
  content: "\f17c";
}

.fa-lira-sign:before {
  content: "\f195";
}

.fa-list:before {
  content: "\f03a";
}

.fa-list-alt:before {
  content: "\f022";
}

.fa-list-ol:before {
  content: "\f0cb";
}

.fa-list-ul:before {
  content: "\f0ca";
}

.fa-location-arrow:before {
  content: "\f124";
}

.fa-lock:before {
  content: "\f023";
}

.fa-lock-open:before {
  content: "\f3c1";
}

.fa-long-arrow-alt-down:before {
  content: "\f309";
}

.fa-long-arrow-alt-left:before {
  content: "\f30a";
}

.fa-long-arrow-alt-right:before {
  content: "\f30b";
}

.fa-long-arrow-alt-up:before {
  content: "\f30c";
}

.fa-low-vision:before {
  content: "\f2a8";
}

.fa-luggage-cart:before {
  content: "\f59d";
}

.fa-lyft:before {
  content: "\f3c3";
}

.fa-magento:before {
  content: "\f3c4";
}

.fa-magic:before {
  content: "\f0d0";
}

.fa-magnet:before {
  content: "\f076";
}

.fa-mail-bulk:before {
  content: "\f674";
}

.fa-mailchimp:before {
  content: "\f59e";
}

.fa-male:before {
  content: "\f183";
}

.fa-mandalorian:before {
  content: "\f50f";
}

.fa-map:before {
  content: "\f279";
}

.fa-map-marked:before {
  content: "\f59f";
}

.fa-map-marked-alt:before {
  content: "\f5a0";
}

.fa-map-marker:before {
  content: "\f041";
}

.fa-map-marker-alt:before {
  content: "\f3c5";
}

.fa-map-pin:before {
  content: "\f276";
}

.fa-map-signs:before {
  content: "\f277";
}

.fa-markdown:before {
  content: "\f60f";
}

.fa-marker:before {
  content: "\f5a1";
}

.fa-mars:before {
  content: "\f222";
}

.fa-mars-double:before {
  content: "\f227";
}

.fa-mars-stroke:before {
  content: "\f229";
}

.fa-mars-stroke-h:before {
  content: "\f22b";
}

.fa-mars-stroke-v:before {
  content: "\f22a";
}

.fa-mask:before {
  content: "\f6fa";
}

.fa-mastodon:before {
  content: "\f4f6";
}

.fa-maxcdn:before {
  content: "\f136";
}

.fa-medal:before {
  content: "\f5a2";
}

.fa-medapps:before {
  content: "\f3c6";
}

.fa-medium:before {
  content: "\f23a";
}

.fa-medium-m:before {
  content: "\f3c7";
}

.fa-medkit:before {
  content: "\f0fa";
}

.fa-medrt:before {
  content: "\f3c8";
}

.fa-meetup:before {
  content: "\f2e0";
}

.fa-megaport:before {
  content: "\f5a3";
}

.fa-meh:before {
  content: "\f11a";
}

.fa-meh-blank:before {
  content: "\f5a4";
}

.fa-meh-rolling-eyes:before {
  content: "\f5a5";
}

.fa-memory:before {
  content: "\f538";
}

.fa-mendeley:before {
  content: "\f7b3";
}

.fa-menorah:before {
  content: "\f676";
}

.fa-mercury:before {
  content: "\f223";
}

.fa-meteor:before {
  content: "\f753";
}

.fa-microchip:before {
  content: "\f2db";
}

.fa-microphone:before {
  content: "\f130";
}

.fa-microphone-alt:before {
  content: "\f3c9";
}

.fa-microphone-alt-slash:before {
  content: "\f539";
}

.fa-microphone-slash:before {
  content: "\f131";
}

.fa-microscope:before {
  content: "\f610";
}

.fa-microsoft:before {
  content: "\f3ca";
}

.fa-minus:before {
  content: "\f068";
}

.fa-minus-circle:before {
  content: "\f056";
}

.fa-minus-square:before {
  content: "\f146";
}

.fa-mitten:before {
  content: "\f7b5";
}

.fa-mix:before {
  content: "\f3cb";
}

.fa-mixcloud:before {
  content: "\f289";
}

.fa-mizuni:before {
  content: "\f3cc";
}

.fa-mobile:before {
  content: "\f10b";
}

.fa-mobile-alt:before {
  content: "\f3cd";
}

.fa-modx:before {
  content: "\f285";
}

.fa-monero:before {
  content: "\f3d0";
}

.fa-money-bill:before {
  content: "\f0d6";
}

.fa-money-bill-alt:before {
  content: "\f3d1";
}

.fa-money-bill-wave:before {
  content: "\f53a";
}

.fa-money-bill-wave-alt:before {
  content: "\f53b";
}

.fa-money-check:before {
  content: "\f53c";
}

.fa-money-check-alt:before {
  content: "\f53d";
}

.fa-monument:before {
  content: "\f5a6";
}

.fa-moon:before {
  content: "\f186";
}

.fa-mortar-pestle:before {
  content: "\f5a7";
}

.fa-mosque:before {
  content: "\f678";
}

.fa-motorcycle:before {
  content: "\f21c";
}

.fa-mountain:before {
  content: "\f6fc";
}

.fa-mouse-pointer:before {
  content: "\f245";
}

.fa-mug-hot:before {
  content: "\f7b6";
}

.fa-music:before {
  content: "\f001";
}

.fa-napster:before {
  content: "\f3d2";
}

.fa-neos:before {
  content: "\f612";
}

.fa-network-wired:before {
  content: "\f6ff";
}

.fa-neuter:before {
  content: "\f22c";
}

.fa-newspaper:before {
  content: "\f1ea";
}

.fa-nimblr:before {
  content: "\f5a8";
}

.fa-nintendo-switch:before {
  content: "\f418";
}

.fa-node:before {
  content: "\f419";
}

.fa-node-js:before {
  content: "\f3d3";
}

.fa-not-equal:before {
  content: "\f53e";
}

.fa-notes-medical:before {
  content: "\f481";
}

.fa-npm:before {
  content: "\f3d4";
}

.fa-ns8:before {
  content: "\f3d5";
}

.fa-nutritionix:before {
  content: "\f3d6";
}

.fa-object-group:before {
  content: "\f247";
}

.fa-object-ungroup:before {
  content: "\f248";
}

.fa-odnoklassniki:before {
  content: "\f263";
}

.fa-odnoklassniki-square:before {
  content: "\f264";
}

.fa-oil-can:before {
  content: "\f613";
}

.fa-old-republic:before {
  content: "\f510";
}

.fa-om:before {
  content: "\f679";
}

.fa-opencart:before {
  content: "\f23d";
}

.fa-openid:before {
  content: "\f19b";
}

.fa-opera:before {
  content: "\f26a";
}

.fa-optin-monster:before {
  content: "\f23c";
}

.fa-osi:before {
  content: "\f41a";
}

.fa-otter:before {
  content: "\f700";
}

.fa-outdent:before {
  content: "\f03b";
}

.fa-page4:before {
  content: "\f3d7";
}

.fa-pagelines:before {
  content: "\f18c";
}

.fa-pager:before {
  content: "\f815";
}

.fa-paint-brush:before {
  content: "\f1fc";
}

.fa-paint-roller:before {
  content: "\f5aa";
}

.fa-palette:before {
  content: "\f53f";
}

.fa-palfed:before {
  content: "\f3d8";
}

.fa-pallet:before {
  content: "\f482";
}

.fa-paper-plane:before {
  content: "\f1d8";
}

.fa-paperclip:before {
  content: "\f0c6";
}

.fa-parachute-box:before {
  content: "\f4cd";
}

.fa-paragraph:before {
  content: "\f1dd";
}

.fa-parking:before {
  content: "\f540";
}

.fa-passport:before {
  content: "\f5ab";
}

.fa-pastafarianism:before {
  content: "\f67b";
}

.fa-paste:before {
  content: "\f0ea";
}

.fa-patreon:before {
  content: "\f3d9";
}

.fa-pause:before {
  content: "\f04c";
}

.fa-pause-circle:before {
  content: "\f28b";
}

.fa-paw:before {
  content: "\f1b0";
}

.fa-paypal:before {
  content: "\f1ed";
}

.fa-peace:before {
  content: "\f67c";
}

.fa-pen:before {
  content: "\f304";
}

.fa-pen-alt:before {
  content: "\f305";
}

.fa-pen-fancy:before {
  content: "\f5ac";
}

.fa-pen-nib:before {
  content: "\f5ad";
}

.fa-pen-square:before {
  content: "\f14b";
}

.fa-pencil-alt:before {
  content: "\f303";
}

.fa-pencil-ruler:before {
  content: "\f5ae";
}

.fa-penny-arcade:before {
  content: "\f704";
}

.fa-people-carry:before {
  content: "\f4ce";
}

.fa-pepper-hot:before {
  content: "\f816";
}

.fa-percent:before {
  content: "\f295";
}

.fa-percentage:before {
  content: "\f541";
}

.fa-periscope:before {
  content: "\f3da";
}

.fa-person-booth:before {
  content: "\f756";
}

.fa-phabricator:before {
  content: "\f3db";
}

.fa-phoenix-framework:before {
  content: "\f3dc";
}

.fa-phoenix-squadron:before {
  content: "\f511";
}

.fa-phone:before {
  content: "\f095";
}

.fa-phone-slash:before {
  content: "\f3dd";
}

.fa-phone-square:before {
  content: "\f098";
}

.fa-phone-volume:before {
  content: "\f2a0";
}

.fa-php:before {
  content: "\f457";
}

.fa-pied-piper:before {
  content: "\f2ae";
}

.fa-pied-piper-alt:before {
  content: "\f1a8";
}

.fa-pied-piper-hat:before {
  content: "\f4e5";
}

.fa-pied-piper-pp:before {
  content: "\f1a7";
}

.fa-piggy-bank:before {
  content: "\f4d3";
}

.fa-pills:before {
  content: "\f484";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-pinterest-p:before {
  content: "\f231";
}

.fa-pinterest-square:before {
  content: "\f0d3";
}

.fa-pizza-slice:before {
  content: "\f818";
}

.fa-place-of-worship:before {
  content: "\f67f";
}

.fa-plane:before {
  content: "\f072";
}

.fa-plane-arrival:before {
  content: "\f5af";
}

.fa-plane-departure:before {
  content: "\f5b0";
}

.fa-play:before {
  content: "\f04b";
}

.fa-play-circle:before {
  content: "\f144";
}

.fa-playstation:before {
  content: "\f3df";
}

.fa-plug:before {
  content: "\f1e6";
}

.fa-plus:before {
  content: "\f067";
}

.fa-plus-circle:before {
  content: "\f055";
}

.fa-plus-square:before {
  content: "\f0fe";
}

.fa-podcast:before {
  content: "\f2ce";
}

.fa-poll:before {
  content: "\f681";
}

.fa-poll-h:before {
  content: "\f682";
}

.fa-poo:before {
  content: "\f2fe";
}

.fa-poo-storm:before {
  content: "\f75a";
}

.fa-poop:before {
  content: "\f619";
}

.fa-portrait:before {
  content: "\f3e0";
}

.fa-pound-sign:before {
  content: "\f154";
}

.fa-power-off:before {
  content: "\f011";
}

.fa-pray:before {
  content: "\f683";
}

.fa-praying-hands:before {
  content: "\f684";
}

.fa-prescription:before {
  content: "\f5b1";
}

.fa-prescription-bottle:before {
  content: "\f485";
}

.fa-prescription-bottle-alt:before {
  content: "\f486";
}

.fa-print:before {
  content: "\f02f";
}

.fa-procedures:before {
  content: "\f487";
}

.fa-product-hunt:before {
  content: "\f288";
}

.fa-project-diagram:before {
  content: "\f542";
}

.fa-pushed:before {
  content: "\f3e1";
}

.fa-puzzle-piece:before {
  content: "\f12e";
}

.fa-python:before {
  content: "\f3e2";
}

.fa-qq:before {
  content: "\f1d6";
}

.fa-qrcode:before {
  content: "\f029";
}

.fa-question:before {
  content: "\f128";
}

.fa-question-circle:before {
  content: "\f059";
}

.fa-quidditch:before {
  content: "\f458";
}

.fa-quinscape:before {
  content: "\f459";
}

.fa-quora:before {
  content: "\f2c4";
}

.fa-quote-left:before {
  content: "\f10d";
}

.fa-quote-right:before {
  content: "\f10e";
}

.fa-quran:before {
  content: "\f687";
}

.fa-r-project:before {
  content: "\f4f7";
}

.fa-radiation:before {
  content: "\f7b9";
}

.fa-radiation-alt:before {
  content: "\f7ba";
}

.fa-rainbow:before {
  content: "\f75b";
}

.fa-random:before {
  content: "\f074";
}

.fa-raspberry-pi:before {
  content: "\f7bb";
}

.fa-ravelry:before {
  content: "\f2d9";
}

.fa-react:before {
  content: "\f41b";
}

.fa-reacteurope:before {
  content: "\f75d";
}

.fa-readme:before {
  content: "\f4d5";
}

.fa-rebel:before {
  content: "\f1d0";
}

.fa-receipt:before {
  content: "\f543";
}

.fa-recycle:before {
  content: "\f1b8";
}

.fa-red-river:before {
  content: "\f3e3";
}

.fa-reddit:before {
  content: "\f1a1";
}

.fa-reddit-alien:before {
  content: "\f281";
}

.fa-reddit-square:before {
  content: "\f1a2";
}

.fa-redhat:before {
  content: "\f7bc";
}

.fa-redo:before {
  content: "\f01e";
}

.fa-redo-alt:before {
  content: "\f2f9";
}

.fa-registered:before {
  content: "\f25d";
}

.fa-renren:before {
  content: "\f18b";
}

.fa-reply:before {
  content: "\f3e5";
}

.fa-reply-all:before {
  content: "\f122";
}

.fa-replyd:before {
  content: "\f3e6";
}

.fa-republican:before {
  content: "\f75e";
}

.fa-researchgate:before {
  content: "\f4f8";
}

.fa-resolving:before {
  content: "\f3e7";
}

.fa-restroom:before {
  content: "\f7bd";
}

.fa-retweet:before {
  content: "\f079";
}

.fa-rev:before {
  content: "\f5b2";
}

.fa-ribbon:before {
  content: "\f4d6";
}

.fa-ring:before {
  content: "\f70b";
}

.fa-road:before {
  content: "\f018";
}

.fa-robot:before {
  content: "\f544";
}

.fa-rocket:before {
  content: "\f135";
}

.fa-rocketchat:before {
  content: "\f3e8";
}

.fa-rockrms:before {
  content: "\f3e9";
}

.fa-route:before {
  content: "\f4d7";
}

.fa-rss:before {
  content: "\f09e";
}

.fa-rss-square:before {
  content: "\f143";
}

.fa-ruble-sign:before {
  content: "\f158";
}

.fa-ruler:before {
  content: "\f545";
}

.fa-ruler-combined:before {
  content: "\f546";
}

.fa-ruler-horizontal:before {
  content: "\f547";
}

.fa-ruler-vertical:before {
  content: "\f548";
}

.fa-running:before {
  content: "\f70c";
}

.fa-rupee-sign:before {
  content: "\f156";
}

.fa-sad-cry:before {
  content: "\f5b3";
}

.fa-sad-tear:before {
  content: "\f5b4";
}

.fa-safari:before {
  content: "\f267";
}

.fa-salesforce:before {
  content: "\f83b";
}

.fa-sass:before {
  content: "\f41e";
}

.fa-satellite:before {
  content: "\f7bf";
}

.fa-satellite-dish:before {
  content: "\f7c0";
}

.fa-save:before {
  content: "\f0c7";
}

.fa-schlix:before {
  content: "\f3ea";
}

.fa-school:before {
  content: "\f549";
}

.fa-screwdriver:before {
  content: "\f54a";
}

.fa-scribd:before {
  content: "\f28a";
}

.fa-scroll:before {
  content: "\f70e";
}

.fa-sd-card:before {
  content: "\f7c2";
}

.fa-search:before {
  content: "\f002";
}

.fa-search-dollar:before {
  content: "\f688";
}

.fa-search-location:before {
  content: "\f689";
}

.fa-search-minus:before {
  content: "\f010";
}

.fa-search-plus:before {
  content: "\f00e";
}

.fa-searchengin:before {
  content: "\f3eb";
}

.fa-seedling:before {
  content: "\f4d8";
}

.fa-sellcast:before {
  content: "\f2da";
}

.fa-sellsy:before {
  content: "\f213";
}

.fa-server:before {
  content: "\f233";
}

.fa-servicestack:before {
  content: "\f3ec";
}

.fa-shapes:before {
  content: "\f61f";
}

.fa-share:before {
  content: "\f064";
}

.fa-share-alt:before {
  content: "\f1e0";
}

.fa-share-alt-square:before {
  content: "\f1e1";
}

.fa-share-square:before {
  content: "\f14d";
}

.fa-shekel-sign:before {
  content: "\f20b";
}

.fa-shield-alt:before {
  content: "\f3ed";
}

.fa-ship:before {
  content: "\f21a";
}

.fa-shipping-fast:before {
  content: "\f48b";
}

.fa-shirtsinbulk:before {
  content: "\f214";
}

.fa-shoe-prints:before {
  content: "\f54b";
}

.fa-shopping-bag:before {
  content: "\f290";
}

.fa-shopping-basket:before {
  content: "\f291";
}

.fa-shopping-cart:before {
  content: "\f07a";
}

.fa-shopware:before {
  content: "\f5b5";
}

.fa-shower:before {
  content: "\f2cc";
}

.fa-shuttle-van:before {
  content: "\f5b6";
}

.fa-sign:before {
  content: "\f4d9";
}

.fa-sign-in-alt:before {
  content: "\f2f6";
}

.fa-sign-language:before {
  content: "\f2a7";
}

.fa-sign-out-alt:before {
  content: "\f2f5";
}

.fa-signal:before {
  content: "\f012";
}

.fa-signature:before {
  content: "\f5b7";
}

.fa-sim-card:before {
  content: "\f7c4";
}

.fa-simplybuilt:before {
  content: "\f215";
}

.fa-sistrix:before {
  content: "\f3ee";
}

.fa-sitemap:before {
  content: "\f0e8";
}

.fa-sith:before {
  content: "\f512";
}

.fa-skating:before {
  content: "\f7c5";
}

.fa-sketch:before {
  content: "\f7c6";
}

.fa-skiing:before {
  content: "\f7c9";
}

.fa-skiing-nordic:before {
  content: "\f7ca";
}

.fa-skull:before {
  content: "\f54c";
}

.fa-skull-crossbones:before {
  content: "\f714";
}

.fa-skyatlas:before {
  content: "\f216";
}

.fa-skype:before {
  content: "\f17e";
}

.fa-slack:before {
  content: "\f198";
}

.fa-slack-hash:before {
  content: "\f3ef";
}

.fa-slash:before {
  content: "\f715";
}

.fa-sleigh:before {
  content: "\f7cc";
}

.fa-sliders-h:before {
  content: "\f1de";
}

.fa-slideshare:before {
  content: "\f1e7";
}

.fa-smile:before {
  content: "\f118";
}

.fa-smile-beam:before {
  content: "\f5b8";
}

.fa-smile-wink:before {
  content: "\f4da";
}

.fa-smog:before {
  content: "\f75f";
}

.fa-smoking:before {
  content: "\f48d";
}

.fa-smoking-ban:before {
  content: "\f54d";
}

.fa-sms:before {
  content: "\f7cd";
}

.fa-snapchat:before {
  content: "\f2ab";
}

.fa-snapchat-ghost:before {
  content: "\f2ac";
}

.fa-snapchat-square:before {
  content: "\f2ad";
}

.fa-snowboarding:before {
  content: "\f7ce";
}

.fa-snowflake:before {
  content: "\f2dc";
}

.fa-snowman:before {
  content: "\f7d0";
}

.fa-snowplow:before {
  content: "\f7d2";
}

.fa-socks:before {
  content: "\f696";
}

.fa-solar-panel:before {
  content: "\f5ba";
}

.fa-sort:before {
  content: "\f0dc";
}

.fa-sort-alpha-down:before {
  content: "\f15d";
}

.fa-sort-alpha-up:before {
  content: "\f15e";
}

.fa-sort-amount-down:before {
  content: "\f160";
}

.fa-sort-amount-up:before {
  content: "\f161";
}

.fa-sort-down:before {
  content: "\f0dd";
}

.fa-sort-numeric-down:before {
  content: "\f162";
}

.fa-sort-numeric-up:before {
  content: "\f163";
}

.fa-sort-up:before {
  content: "\f0de";
}

.fa-soundcloud:before {
  content: "\f1be";
}

.fa-sourcetree:before {
  content: "\f7d3";
}

.fa-spa:before {
  content: "\f5bb";
}

.fa-space-shuttle:before {
  content: "\f197";
}

.fa-speakap:before {
  content: "\f3f3";
}

.fa-speaker-deck:before {
  content: "\f83c";
}

.fa-spider:before {
  content: "\f717";
}

.fa-spinner:before {
  content: "\f110";
}

.fa-splotch:before {
  content: "\f5bc";
}

.fa-spotify:before {
  content: "\f1bc";
}

.fa-spray-can:before {
  content: "\f5bd";
}

.fa-square:before {
  content: "\f0c8";
}

.fa-square-full:before {
  content: "\f45c";
}

.fa-square-root-alt:before {
  content: "\f698";
}

.fa-squarespace:before {
  content: "\f5be";
}

.fa-stack-exchange:before {
  content: "\f18d";
}

.fa-stack-overflow:before {
  content: "\f16c";
}

.fa-stackpath:before {
  content: "\f842";
}

.fa-stamp:before {
  content: "\f5bf";
}

.fa-star:before {
  content: "\f005";
}

.fa-star-and-crescent:before {
  content: "\f699";
}

.fa-star-half:before {
  content: "\f089";
}

.fa-star-half-alt:before {
  content: "\f5c0";
}

.fa-star-of-david:before {
  content: "\f69a";
}

.fa-star-of-life:before {
  content: "\f621";
}

.fa-staylinked:before {
  content: "\f3f5";
}

.fa-steam:before {
  content: "\f1b6";
}

.fa-steam-square:before {
  content: "\f1b7";
}

.fa-steam-symbol:before {
  content: "\f3f6";
}

.fa-step-backward:before {
  content: "\f048";
}

.fa-step-forward:before {
  content: "\f051";
}

.fa-stethoscope:before {
  content: "\f0f1";
}

.fa-sticker-mule:before {
  content: "\f3f7";
}

.fa-sticky-note:before {
  content: "\f249";
}

.fa-stop:before {
  content: "\f04d";
}

.fa-stop-circle:before {
  content: "\f28d";
}

.fa-stopwatch:before {
  content: "\f2f2";
}

.fa-store:before {
  content: "\f54e";
}

.fa-store-alt:before {
  content: "\f54f";
}

.fa-strava:before {
  content: "\f428";
}

.fa-stream:before {
  content: "\f550";
}

.fa-street-view:before {
  content: "\f21d";
}

.fa-strikethrough:before {
  content: "\f0cc";
}

.fa-stripe:before {
  content: "\f429";
}

.fa-stripe-s:before {
  content: "\f42a";
}

.fa-stroopwafel:before {
  content: "\f551";
}

.fa-studiovinari:before {
  content: "\f3f8";
}

.fa-stumbleupon:before {
  content: "\f1a4";
}

.fa-stumbleupon-circle:before {
  content: "\f1a3";
}

.fa-subscript:before {
  content: "\f12c";
}

.fa-subway:before {
  content: "\f239";
}

.fa-suitcase:before {
  content: "\f0f2";
}

.fa-suitcase-rolling:before {
  content: "\f5c1";
}

.fa-sun:before {
  content: "\f185";
}

.fa-superpowers:before {
  content: "\f2dd";
}

.fa-superscript:before {
  content: "\f12b";
}

.fa-supple:before {
  content: "\f3f9";
}

.fa-surprise:before {
  content: "\f5c2";
}

.fa-suse:before {
  content: "\f7d6";
}

.fa-swatchbook:before {
  content: "\f5c3";
}

.fa-swimmer:before {
  content: "\f5c4";
}

.fa-swimming-pool:before {
  content: "\f5c5";
}

.fa-symfony:before {
  content: "\f83d";
}

.fa-synagogue:before {
  content: "\f69b";
}

.fa-sync:before {
  content: "\f021";
}

.fa-sync-alt:before {
  content: "\f2f1";
}

.fa-syringe:before {
  content: "\f48e";
}

.fa-table:before {
  content: "\f0ce";
}

.fa-table-tennis:before {
  content: "\f45d";
}

.fa-tablet:before {
  content: "\f10a";
}

.fa-tablet-alt:before {
  content: "\f3fa";
}

.fa-tablets:before {
  content: "\f490";
}

.fa-tachometer-alt:before {
  content: "\f3fd";
}

.fa-tag:before {
  content: "\f02b";
}

.fa-tags:before {
  content: "\f02c";
}

.fa-tape:before {
  content: "\f4db";
}

.fa-tasks:before {
  content: "\f0ae";
}

.fa-taxi:before {
  content: "\f1ba";
}

.fa-teamspeak:before {
  content: "\f4f9";
}

.fa-teeth:before {
  content: "\f62e";
}

.fa-teeth-open:before {
  content: "\f62f";
}

.fa-telegram:before {
  content: "\f2c6";
}

.fa-telegram-plane:before {
  content: "\f3fe";
}

.fa-temperature-high:before {
  content: "\f769";
}

.fa-temperature-low:before {
  content: "\f76b";
}

.fa-tencent-weibo:before {
  content: "\f1d5";
}

.fa-tenge:before {
  content: "\f7d7";
}

.fa-terminal:before {
  content: "\f120";
}

.fa-text-height:before {
  content: "\f034";
}

.fa-text-width:before {
  content: "\f035";
}

.fa-th:before {
  content: "\f00a";
}

.fa-th-large:before {
  content: "\f009";
}

.fa-th-list:before {
  content: "\f00b";
}

.fa-the-red-yeti:before {
  content: "\f69d";
}

.fa-theater-masks:before {
  content: "\f630";
}

.fa-themeco:before {
  content: "\f5c6";
}

.fa-themeisle:before {
  content: "\f2b2";
}

.fa-thermometer:before {
  content: "\f491";
}

.fa-thermometer-empty:before {
  content: "\f2cb";
}

.fa-thermometer-full:before {
  content: "\f2c7";
}

.fa-thermometer-half:before {
  content: "\f2c9";
}

.fa-thermometer-quarter:before {
  content: "\f2ca";
}

.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}

.fa-think-peaks:before {
  content: "\f731";
}

.fa-thumbs-down:before {
  content: "\f165";
}

.fa-thumbs-up:before {
  content: "\f164";
}

.fa-thumbtack:before {
  content: "\f08d";
}

.fa-ticket-alt:before {
  content: "\f3ff";
}

.fa-times:before {
  content: "\f00d";
}

.fa-times-circle:before {
  content: "\f057";
}

.fa-tint:before {
  content: "\f043";
}

.fa-tint-slash:before {
  content: "\f5c7";
}

.fa-tired:before {
  content: "\f5c8";
}

.fa-toggle-off:before {
  content: "\f204";
}

.fa-toggle-on:before {
  content: "\f205";
}

.fa-toilet:before {
  content: "\f7d8";
}

.fa-toilet-paper:before {
  content: "\f71e";
}

.fa-toolbox:before {
  content: "\f552";
}

.fa-tools:before {
  content: "\f7d9";
}

.fa-tooth:before {
  content: "\f5c9";
}

.fa-torah:before {
  content: "\f6a0";
}

.fa-torii-gate:before {
  content: "\f6a1";
}

.fa-tractor:before {
  content: "\f722";
}

.fa-trade-federation:before {
  content: "\f513";
}

.fa-trademark:before {
  content: "\f25c";
}

.fa-traffic-light:before {
  content: "\f637";
}

.fa-train:before {
  content: "\f238";
}

.fa-tram:before {
  content: "\f7da";
}

.fa-transgender:before {
  content: "\f224";
}

.fa-transgender-alt:before {
  content: "\f225";
}

.fa-trash:before {
  content: "\f1f8";
}

.fa-trash-alt:before {
  content: "\f2ed";
}

.fa-trash-restore:before {
  content: "\f829";
}

.fa-trash-restore-alt:before {
  content: "\f82a";
}

.fa-tree:before {
  content: "\f1bb";
}

.fa-trello:before {
  content: "\f181";
}

.fa-tripadvisor:before {
  content: "\f262";
}

.fa-trophy:before {
  content: "\f091";
}

.fa-truck:before {
  content: "\f0d1";
}

.fa-truck-loading:before {
  content: "\f4de";
}

.fa-truck-monster:before {
  content: "\f63b";
}

.fa-truck-moving:before {
  content: "\f4df";
}

.fa-truck-pickup:before {
  content: "\f63c";
}

.fa-tshirt:before {
  content: "\f553";
}

.fa-tty:before {
  content: "\f1e4";
}

.fa-tumblr:before {
  content: "\f173";
}

.fa-tumblr-square:before {
  content: "\f174";
}

.fa-tv:before {
  content: "\f26c";
}

.fa-twitch:before {
  content: "\f1e8";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-typo3:before {
  content: "\f42b";
}

.fa-uber:before {
  content: "\f402";
}

.fa-ubuntu:before {
  content: "\f7df";
}

.fa-uikit:before {
  content: "\f403";
}

.fa-umbrella:before {
  content: "\f0e9";
}

.fa-umbrella-beach:before {
  content: "\f5ca";
}

.fa-underline:before {
  content: "\f0cd";
}

.fa-undo:before {
  content: "\f0e2";
}

.fa-undo-alt:before {
  content: "\f2ea";
}

.fa-uniregistry:before {
  content: "\f404";
}

.fa-universal-access:before {
  content: "\f29a";
}

.fa-university:before {
  content: "\f19c";
}

.fa-unlink:before {
  content: "\f127";
}

.fa-unlock:before {
  content: "\f09c";
}

.fa-unlock-alt:before {
  content: "\f13e";
}

.fa-untappd:before {
  content: "\f405";
}

.fa-upload:before {
  content: "\f093";
}

.fa-ups:before {
  content: "\f7e0";
}

.fa-usb:before {
  content: "\f287";
}

.fa-user:before {
  content: "\f007";
}

.fa-user-alt:before {
  content: "\f406";
}

.fa-user-alt-slash:before {
  content: "\f4fa";
}

.fa-user-astronaut:before {
  content: "\f4fb";
}

.fa-user-check:before {
  content: "\f4fc";
}

.fa-user-circle:before {
  content: "\f2bd";
}

.fa-user-clock:before {
  content: "\f4fd";
}

.fa-user-cog:before {
  content: "\f4fe";
}

.fa-user-edit:before {
  content: "\f4ff";
}

.fa-user-friends:before {
  content: "\f500";
}

.fa-user-graduate:before {
  content: "\f501";
}

.fa-user-injured:before {
  content: "\f728";
}

.fa-user-lock:before {
  content: "\f502";
}

.fa-user-md:before {
  content: "\f0f0";
}

.fa-user-minus:before {
  content: "\f503";
}

.fa-user-ninja:before {
  content: "\f504";
}

.fa-user-nurse:before {
  content: "\f82f";
}

.fa-user-plus:before {
  content: "\f234";
}

.fa-user-secret:before {
  content: "\f21b";
}

.fa-user-shield:before {
  content: "\f505";
}

.fa-user-slash:before {
  content: "\f506";
}

.fa-user-tag:before {
  content: "\f507";
}

.fa-user-tie:before {
  content: "\f508";
}

.fa-user-times:before {
  content: "\f235";
}

.fa-users:before {
  content: "\f0c0";
}

.fa-users-cog:before {
  content: "\f509";
}

.fa-usps:before {
  content: "\f7e1";
}

.fa-ussunnah:before {
  content: "\f407";
}

.fa-utensil-spoon:before {
  content: "\f2e5";
}

.fa-utensils:before {
  content: "\f2e7";
}

.fa-vaadin:before {
  content: "\f408";
}

.fa-vector-square:before {
  content: "\f5cb";
}

.fa-venus:before {
  content: "\f221";
}

.fa-venus-double:before {
  content: "\f226";
}

.fa-venus-mars:before {
  content: "\f228";
}

.fa-viacoin:before {
  content: "\f237";
}

.fa-viadeo:before {
  content: "\f2a9";
}

.fa-viadeo-square:before {
  content: "\f2aa";
}

.fa-vial:before {
  content: "\f492";
}

.fa-vials:before {
  content: "\f493";
}

.fa-viber:before {
  content: "\f409";
}

.fa-video:before {
  content: "\f03d";
}

.fa-video-slash:before {
  content: "\f4e2";
}

.fa-vihara:before {
  content: "\f6a7";
}

.fa-vimeo:before {
  content: "\f40a";
}

.fa-vimeo-square:before {
  content: "\f194";
}

.fa-vimeo-v:before {
  content: "\f27d";
}

.fa-vine:before {
  content: "\f1ca";
}

.fa-vk:before {
  content: "\f189";
}

.fa-vnv:before {
  content: "\f40b";
}

.fa-volleyball-ball:before {
  content: "\f45f";
}

.fa-volume-down:before {
  content: "\f027";
}

.fa-volume-mute:before {
  content: "\f6a9";
}

.fa-volume-off:before {
  content: "\f026";
}

.fa-volume-up:before {
  content: "\f028";
}

.fa-vote-yea:before {
  content: "\f772";
}

.fa-vr-cardboard:before {
  content: "\f729";
}

.fa-vuejs:before {
  content: "\f41f";
}

.fa-walking:before {
  content: "\f554";
}

.fa-wallet:before {
  content: "\f555";
}

.fa-warehouse:before {
  content: "\f494";
}

.fa-water:before {
  content: "\f773";
}

.fa-wave-square:before {
  content: "\f83e";
}

.fa-waze:before {
  content: "\f83f";
}

.fa-weebly:before {
  content: "\f5cc";
}

.fa-weibo:before {
  content: "\f18a";
}

.fa-weight:before {
  content: "\f496";
}

.fa-weight-hanging:before {
  content: "\f5cd";
}

.fa-weixin:before {
  content: "\f1d7";
}

.fa-whatsapp:before {
  content: "\f232";
}

.fa-whatsapp-square:before {
  content: "\f40c";
}

.fa-wheelchair:before {
  content: "\f193";
}

.fa-whmcs:before {
  content: "\f40d";
}

.fa-wifi:before {
  content: "\f1eb";
}

.fa-wikipedia-w:before {
  content: "\f266";
}

.fa-wind:before {
  content: "\f72e";
}

.fa-window-close:before {
  content: "\f410";
}

.fa-window-maximize:before {
  content: "\f2d0";
}

.fa-window-minimize:before {
  content: "\f2d1";
}

.fa-window-restore:before {
  content: "\f2d2";
}

.fa-windows:before {
  content: "\f17a";
}

.fa-wine-bottle:before {
  content: "\f72f";
}

.fa-wine-glass:before {
  content: "\f4e3";
}

.fa-wine-glass-alt:before {
  content: "\f5ce";
}

.fa-wix:before {
  content: "\f5cf";
}

.fa-wizards-of-the-coast:before {
  content: "\f730";
}

.fa-wolf-pack-battalion:before {
  content: "\f514";
}

.fa-won-sign:before {
  content: "\f159";
}

.fa-wordpress:before {
  content: "\f19a";
}

.fa-wordpress-simple:before {
  content: "\f411";
}

.fa-wpbeginner:before {
  content: "\f297";
}

.fa-wpexplorer:before {
  content: "\f2de";
}

.fa-wpforms:before {
  content: "\f298";
}

.fa-wpressr:before {
  content: "\f3e4";
}

.fa-wrench:before {
  content: "\f0ad";
}

.fa-x-ray:before {
  content: "\f497";
}

.fa-xbox:before {
  content: "\f412";
}

.fa-xing:before {
  content: "\f168";
}

.fa-xing-square:before {
  content: "\f169";
}

.fa-y-combinator:before {
  content: "\f23b";
}

.fa-yahoo:before {
  content: "\f19e";
}

.fa-yammer:before {
  content: "\f840";
}

.fa-yandex:before {
  content: "\f413";
}

.fa-yandex-international:before {
  content: "\f414";
}

.fa-yarn:before {
  content: "\f7e3";
}

.fa-yelp:before {
  content: "\f1e9";
}

.fa-yen-sign:before {
  content: "\f157";
}

.fa-yin-yang:before {
  content: "\f6ad";
}

.fa-yoast:before {
  content: "\f2b1";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-youtube-square:before {
  content: "\f431";
}

.fa-zhihu:before {
  content: "\f63f";
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("../fonts/fa-brands-400.eot");
  src: url("../fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.woff") format("woff"), url("../fonts/fa-brands-400.ttf") format("truetype"), url("../fonts/fa-brands-400.svg#fontawesome") format("svg");
}

.fab {
  font-family: 'Font Awesome 5 Brands';
}

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("../fonts/fa-regular-400.eot");
  src: url("../fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.woff") format("woff"), url("../fonts/fa-regular-400.ttf") format("truetype"), url("../fonts/fa-regular-400.svg#fontawesome") format("svg");
}

.far {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("../fonts/fa-solid-900.eot");
  src: url("../fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.woff") format("woff"), url("../fonts/fa-solid-900.ttf") format("truetype"), url("../fonts/fa-solid-900.svg#fontawesome") format("svg");
}

.fa,
.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

/* MaterialDesignIcons.com */
@font-face {
  font-family: "Material Design Icons";
  src: url("../fonts/materialdesignicons-webfont.eot?v=3.2.89");
  src: url("../fonts/materialdesignicons-webfont.eot?#iefix&v=3.2.89") format("embedded-opentype"), url("../fonts/materialdesignicons-webfont.woff2?v=3.2.89") format("woff2"), url("../fonts/materialdesignicons-webfont.woff?v=3.2.89") format("woff"), url("../fonts/materialdesignicons-webfont.ttf?v=3.2.89") format("truetype"), url("../fonts/materialdesignicons-webfont.svg?v=3.2.89#materialdesigniconsregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

.mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mdi-access-point:before {
  content: "\F002";
}

.mdi-access-point-network:before {
  content: "\F003";
}

.mdi-access-point-network-off:before {
  content: "\FBBD";
}

.mdi-account:before {
  content: "\F004";
}

.mdi-account-alert:before {
  content: "\F005";
}

.mdi-account-alert-outline:before {
  content: "\FB2C";
}

.mdi-account-arrow-left:before {
  content: "\FB2D";
}

.mdi-account-arrow-left-outline:before {
  content: "\FB2E";
}

.mdi-account-arrow-right:before {
  content: "\FB2F";
}

.mdi-account-arrow-right-outline:before {
  content: "\FB30";
}

.mdi-account-box:before {
  content: "\F006";
}

.mdi-account-box-multiple:before {
  content: "\F933";
}

.mdi-account-box-outline:before {
  content: "\F007";
}

.mdi-account-card-details:before {
  content: "\F5D2";
}

.mdi-account-check:before {
  content: "\F008";
}

.mdi-account-check-outline:before {
  content: "\FBBE";
}

.mdi-account-child:before {
  content: "\FA88";
}

.mdi-account-child-circle:before {
  content: "\FA89";
}

.mdi-account-circle:before {
  content: "\F009";
}

.mdi-account-circle-outline:before {
  content: "\FB31";
}

.mdi-account-clock:before {
  content: "\FB32";
}

.mdi-account-clock-outline:before {
  content: "\FB33";
}

.mdi-account-convert:before {
  content: "\F00A";
}

.mdi-account-details:before {
  content: "\F631";
}

.mdi-account-edit:before {
  content: "\F6BB";
}

.mdi-account-group:before {
  content: "\F848";
}

.mdi-account-group-outline:before {
  content: "\FB34";
}

.mdi-account-heart:before {
  content: "\F898";
}

.mdi-account-heart-outline:before {
  content: "\FBBF";
}

.mdi-account-key:before {
  content: "\F00B";
}

.mdi-account-key-outline:before {
  content: "\FBC0";
}

.mdi-account-minus:before {
  content: "\F00D";
}

.mdi-account-minus-outline:before {
  content: "\FAEB";
}

.mdi-account-multiple:before {
  content: "\F00E";
}

.mdi-account-multiple-check:before {
  content: "\F8C4";
}

.mdi-account-multiple-minus:before {
  content: "\F5D3";
}

.mdi-account-multiple-minus-outline:before {
  content: "\FBC1";
}

.mdi-account-multiple-outline:before {
  content: "\F00F";
}

.mdi-account-multiple-plus:before {
  content: "\F010";
}

.mdi-account-multiple-plus-outline:before {
  content: "\F7FF";
}

.mdi-account-network:before {
  content: "\F011";
}

.mdi-account-network-outline:before {
  content: "\FBC2";
}

.mdi-account-off:before {
  content: "\F012";
}

.mdi-account-off-outline:before {
  content: "\FBC3";
}

.mdi-account-outline:before {
  content: "\F013";
}

.mdi-account-plus:before {
  content: "\F014";
}

.mdi-account-plus-outline:before {
  content: "\F800";
}

.mdi-account-question:before {
  content: "\FB35";
}

.mdi-account-question-outline:before {
  content: "\FB36";
}

.mdi-account-remove:before {
  content: "\F015";
}

.mdi-account-remove-outline:before {
  content: "\FAEC";
}

.mdi-account-search:before {
  content: "\F016";
}

.mdi-account-search-outline:before {
  content: "\F934";
}

.mdi-account-settings:before {
  content: "\F630";
}

.mdi-account-star:before {
  content: "\F017";
}

.mdi-account-star-outline:before {
  content: "\FBC4";
}

.mdi-account-supervisor:before {
  content: "\FA8A";
}

.mdi-account-supervisor-circle:before {
  content: "\FA8B";
}

.mdi-account-switch:before {
  content: "\F019";
}

.mdi-accusoft:before {
  content: "\F849";
}

.mdi-adjust:before {
  content: "\F01A";
}

.mdi-adobe:before {
  content: "\F935";
}

.mdi-air-conditioner:before {
  content: "\F01B";
}

.mdi-airbag:before {
  content: "\FBC5";
}

.mdi-airballoon:before {
  content: "\F01C";
}

.mdi-airplane:before {
  content: "\F01D";
}

.mdi-airplane-landing:before {
  content: "\F5D4";
}

.mdi-airplane-off:before {
  content: "\F01E";
}

.mdi-airplane-takeoff:before {
  content: "\F5D5";
}

.mdi-airplay:before {
  content: "\F01F";
}

.mdi-airport:before {
  content: "\F84A";
}

.mdi-alarm:before {
  content: "\F020";
}

.mdi-alarm-bell:before {
  content: "\F78D";
}

.mdi-alarm-check:before {
  content: "\F021";
}

.mdi-alarm-light:before {
  content: "\F78E";
}

.mdi-alarm-light-outline:before {
  content: "\FBC6";
}

.mdi-alarm-multiple:before {
  content: "\F022";
}

.mdi-alarm-off:before {
  content: "\F023";
}

.mdi-alarm-plus:before {
  content: "\F024";
}

.mdi-alarm-snooze:before {
  content: "\F68D";
}

.mdi-album:before {
  content: "\F025";
}

.mdi-alert:before {
  content: "\F026";
}

.mdi-alert-box:before {
  content: "\F027";
}

.mdi-alert-circle:before {
  content: "\F028";
}

.mdi-alert-circle-outline:before {
  content: "\F5D6";
}

.mdi-alert-decagram:before {
  content: "\F6BC";
}

.mdi-alert-octagon:before {
  content: "\F029";
}

.mdi-alert-octagram:before {
  content: "\F766";
}

.mdi-alert-outline:before {
  content: "\F02A";
}

.mdi-alien:before {
  content: "\F899";
}

.mdi-all-inclusive:before {
  content: "\F6BD";
}

.mdi-alpha:before {
  content: "\F02B";
}

.mdi-alpha-a:before {
  content: "\41";
}

.mdi-alpha-a-box:before {
  content: "\FAED";
}

.mdi-alpha-a-box-outline:before {
  content: "\FBC7";
}

.mdi-alpha-a-circle:before {
  content: "\FBC8";
}

.mdi-alpha-a-circle-outline:before {
  content: "\FBC9";
}

.mdi-alpha-b:before {
  content: "\42";
}

.mdi-alpha-b-box:before {
  content: "\FAEE";
}

.mdi-alpha-b-box-outline:before {
  content: "\FBCA";
}

.mdi-alpha-b-circle:before {
  content: "\FBCB";
}

.mdi-alpha-b-circle-outline:before {
  content: "\FBCC";
}

.mdi-alpha-c:before {
  content: "\43";
}

.mdi-alpha-c-box:before {
  content: "\FAEF";
}

.mdi-alpha-c-box-outline:before {
  content: "\FBCD";
}

.mdi-alpha-c-circle:before {
  content: "\FBCE";
}

.mdi-alpha-c-circle-outline:before {
  content: "\FBCF";
}

.mdi-alpha-d:before {
  content: "\44";
}

.mdi-alpha-d-box:before {
  content: "\FAF0";
}

.mdi-alpha-d-box-outline:before {
  content: "\FBD0";
}

.mdi-alpha-d-circle:before {
  content: "\FBD1";
}

.mdi-alpha-d-circle-outline:before {
  content: "\FBD2";
}

.mdi-alpha-e:before {
  content: "\45";
}

.mdi-alpha-e-box:before {
  content: "\FAF1";
}

.mdi-alpha-e-box-outline:before {
  content: "\FBD3";
}

.mdi-alpha-e-circle:before {
  content: "\FBD4";
}

.mdi-alpha-e-circle-outline:before {
  content: "\FBD5";
}

.mdi-alpha-f:before {
  content: "\46";
}

.mdi-alpha-f-box:before {
  content: "\FAF2";
}

.mdi-alpha-f-box-outline:before {
  content: "\FBD6";
}

.mdi-alpha-f-circle:before {
  content: "\FBD7";
}

.mdi-alpha-f-circle-outline:before {
  content: "\FBD8";
}

.mdi-alpha-g:before {
  content: "\47";
}

.mdi-alpha-g-box:before {
  content: "\FAF3";
}

.mdi-alpha-g-box-outline:before {
  content: "\FBD9";
}

.mdi-alpha-g-circle:before {
  content: "\FBDA";
}

.mdi-alpha-g-circle-outline:before {
  content: "\FBDB";
}

.mdi-alpha-h:before {
  content: "\48";
}

.mdi-alpha-h-box:before {
  content: "\FAF4";
}

.mdi-alpha-h-box-outline:before {
  content: "\FBDC";
}

.mdi-alpha-h-circle:before {
  content: "\FBDD";
}

.mdi-alpha-h-circle-outline:before {
  content: "\FBDE";
}

.mdi-alpha-i:before {
  content: "\49";
}

.mdi-alpha-i-box:before {
  content: "\FAF5";
}

.mdi-alpha-i-box-outline:before {
  content: "\FBDF";
}

.mdi-alpha-i-circle:before {
  content: "\FBE0";
}

.mdi-alpha-i-circle-outline:before {
  content: "\FBE1";
}

.mdi-alpha-j:before {
  content: "\4A";
}

.mdi-alpha-j-box:before {
  content: "\FAF6";
}

.mdi-alpha-j-box-outline:before {
  content: "\FBE2";
}

.mdi-alpha-j-circle:before {
  content: "\FBE3";
}

.mdi-alpha-j-circle-outline:before {
  content: "\FBE4";
}

.mdi-alpha-k:before {
  content: "\4B";
}

.mdi-alpha-k-box:before {
  content: "\FAF7";
}

.mdi-alpha-k-box-outline:before {
  content: "\FBE5";
}

.mdi-alpha-k-circle:before {
  content: "\FBE6";
}

.mdi-alpha-k-circle-outline:before {
  content: "\FBE7";
}

.mdi-alpha-l:before {
  content: "\4C";
}

.mdi-alpha-l-box:before {
  content: "\FAF8";
}

.mdi-alpha-l-box-outline:before {
  content: "\FBE8";
}

.mdi-alpha-l-circle:before {
  content: "\FBE9";
}

.mdi-alpha-l-circle-outline:before {
  content: "\FBEA";
}

.mdi-alpha-m:before {
  content: "\4D";
}

.mdi-alpha-m-box:before {
  content: "\FAF9";
}

.mdi-alpha-m-box-outline:before {
  content: "\FBEB";
}

.mdi-alpha-m-circle:before {
  content: "\FBEC";
}

.mdi-alpha-m-circle-outline:before {
  content: "\FBED";
}

.mdi-alpha-n:before {
  content: "\4E";
}

.mdi-alpha-n-box:before {
  content: "\FAFA";
}

.mdi-alpha-n-box-outline:before {
  content: "\FBEE";
}

.mdi-alpha-n-circle:before {
  content: "\FBEF";
}

.mdi-alpha-n-circle-outline:before {
  content: "\FBF0";
}

.mdi-alpha-o:before {
  content: "\4F";
}

.mdi-alpha-o-box:before {
  content: "\FAFB";
}

.mdi-alpha-o-box-outline:before {
  content: "\FBF1";
}

.mdi-alpha-o-circle:before {
  content: "\FBF2";
}

.mdi-alpha-o-circle-outline:before {
  content: "\FBF3";
}

.mdi-alpha-p:before {
  content: "\50";
}

.mdi-alpha-p-box:before {
  content: "\FAFC";
}

.mdi-alpha-p-box-outline:before {
  content: "\FBF4";
}

.mdi-alpha-p-circle:before {
  content: "\FBF5";
}

.mdi-alpha-p-circle-outline:before {
  content: "\FBF6";
}

.mdi-alpha-q:before {
  content: "\51";
}

.mdi-alpha-q-box:before {
  content: "\FAFD";
}

.mdi-alpha-q-box-outline:before {
  content: "\FBF7";
}

.mdi-alpha-q-circle:before {
  content: "\FBF8";
}

.mdi-alpha-q-circle-outline:before {
  content: "\FBF9";
}

.mdi-alpha-r:before {
  content: "\52";
}

.mdi-alpha-r-box:before {
  content: "\FAFE";
}

.mdi-alpha-r-box-outline:before {
  content: "\FBFA";
}

.mdi-alpha-r-circle:before {
  content: "\FBFB";
}

.mdi-alpha-r-circle-outline:before {
  content: "\FBFC";
}

.mdi-alpha-s:before {
  content: "\53";
}

.mdi-alpha-s-box:before {
  content: "\FAFF";
}

.mdi-alpha-s-box-outline:before {
  content: "\FBFD";
}

.mdi-alpha-s-circle:before {
  content: "\FBFE";
}

.mdi-alpha-s-circle-outline:before {
  content: "\FBFF";
}

.mdi-alpha-t:before {
  content: "\54";
}

.mdi-alpha-t-box:before {
  content: "\FB00";
}

.mdi-alpha-t-box-outline:before {
  content: "\FC00";
}

.mdi-alpha-t-circle:before {
  content: "\FC01";
}

.mdi-alpha-t-circle-outline:before {
  content: "\FC02";
}

.mdi-alpha-u:before {
  content: "\55";
}

.mdi-alpha-u-box:before {
  content: "\FB01";
}

.mdi-alpha-u-box-outline:before {
  content: "\FC03";
}

.mdi-alpha-u-circle:before {
  content: "\FC04";
}

.mdi-alpha-u-circle-outline:before {
  content: "\FC05";
}

.mdi-alpha-v:before {
  content: "\56";
}

.mdi-alpha-v-box:before {
  content: "\FB02";
}

.mdi-alpha-v-box-outline:before {
  content: "\FC06";
}

.mdi-alpha-v-circle:before {
  content: "\FC07";
}

.mdi-alpha-v-circle-outline:before {
  content: "\FC08";
}

.mdi-alpha-w:before {
  content: "\57";
}

.mdi-alpha-w-box:before {
  content: "\FB03";
}

.mdi-alpha-w-box-outline:before {
  content: "\FC09";
}

.mdi-alpha-w-circle:before {
  content: "\FC0A";
}

.mdi-alpha-w-circle-outline:before {
  content: "\FC0B";
}

.mdi-alpha-x:before {
  content: "\58";
}

.mdi-alpha-x-box:before {
  content: "\FB04";
}

.mdi-alpha-x-box-outline:before {
  content: "\FC0C";
}

.mdi-alpha-x-circle:before {
  content: "\FC0D";
}

.mdi-alpha-x-circle-outline:before {
  content: "\FC0E";
}

.mdi-alpha-y:before {
  content: "\59";
}

.mdi-alpha-y-box:before {
  content: "\FB05";
}

.mdi-alpha-y-box-outline:before {
  content: "\FC0F";
}

.mdi-alpha-y-circle:before {
  content: "\FC10";
}

.mdi-alpha-y-circle-outline:before {
  content: "\FC11";
}

.mdi-alpha-z:before {
  content: "\5A";
}

.mdi-alpha-z-box:before {
  content: "\FB06";
}

.mdi-alpha-z-box-outline:before {
  content: "\FC12";
}

.mdi-alpha-z-circle:before {
  content: "\FC13";
}

.mdi-alpha-z-circle-outline:before {
  content: "\FC14";
}

.mdi-alphabetical:before {
  content: "\F02C";
}

.mdi-altimeter:before {
  content: "\F5D7";
}

.mdi-amazon:before {
  content: "\F02D";
}

.mdi-amazon-alexa:before {
  content: "\F8C5";
}

.mdi-amazon-drive:before {
  content: "\F02E";
}

.mdi-ambulance:before {
  content: "\F02F";
}

.mdi-ampersand:before {
  content: "\FA8C";
}

.mdi-amplifier:before {
  content: "\F030";
}

.mdi-anchor:before {
  content: "\F031";
}

.mdi-android:before {
  content: "\F032";
}

.mdi-android-auto:before {
  content: "\FA8D";
}

.mdi-android-debug-bridge:before {
  content: "\F033";
}

.mdi-android-head:before {
  content: "\F78F";
}

.mdi-android-studio:before {
  content: "\F034";
}

.mdi-angle-acute:before {
  content: "\F936";
}

.mdi-angle-obtuse:before {
  content: "\F937";
}

.mdi-angle-right:before {
  content: "\F938";
}

.mdi-angular:before {
  content: "\F6B1";
}

.mdi-angularjs:before {
  content: "\F6BE";
}

.mdi-animation:before {
  content: "\F5D8";
}

.mdi-animation-outline:before {
  content: "\FA8E";
}

.mdi-animation-play:before {
  content: "\F939";
}

.mdi-animation-play-outline:before {
  content: "\FA8F";
}

.mdi-anvil:before {
  content: "\F89A";
}

.mdi-apple:before {
  content: "\F035";
}

.mdi-apple-finder:before {
  content: "\F036";
}

.mdi-apple-icloud:before {
  content: "\F038";
}

.mdi-apple-ios:before {
  content: "\F037";
}

.mdi-apple-keyboard-caps:before {
  content: "\F632";
}

.mdi-apple-keyboard-command:before {
  content: "\F633";
}

.mdi-apple-keyboard-control:before {
  content: "\F634";
}

.mdi-apple-keyboard-option:before {
  content: "\F635";
}

.mdi-apple-keyboard-shift:before {
  content: "\F636";
}

.mdi-apple-safari:before {
  content: "\F039";
}

.mdi-application:before {
  content: "\F614";
}

.mdi-apps:before {
  content: "\F03B";
}

.mdi-arch:before {
  content: "\F8C6";
}

.mdi-archive:before {
  content: "\F03C";
}

.mdi-arrange-bring-forward:before {
  content: "\F03D";
}

.mdi-arrange-bring-to-front:before {
  content: "\F03E";
}

.mdi-arrange-send-backward:before {
  content: "\F03F";
}

.mdi-arrange-send-to-back:before {
  content: "\F040";
}

.mdi-arrow-all:before {
  content: "\F041";
}

.mdi-arrow-bottom-left:before {
  content: "\F042";
}

.mdi-arrow-bottom-left-bold-outline:before {
  content: "\F9B6";
}

.mdi-arrow-bottom-left-thick:before {
  content: "\F9B7";
}

.mdi-arrow-bottom-right:before {
  content: "\F043";
}

.mdi-arrow-bottom-right-bold-outline:before {
  content: "\F9B8";
}

.mdi-arrow-bottom-right-thick:before {
  content: "\F9B9";
}

.mdi-arrow-collapse:before {
  content: "\F615";
}

.mdi-arrow-collapse-all:before {
  content: "\F044";
}

.mdi-arrow-collapse-down:before {
  content: "\F791";
}

.mdi-arrow-collapse-horizontal:before {
  content: "\F84B";
}

.mdi-arrow-collapse-left:before {
  content: "\F792";
}

.mdi-arrow-collapse-right:before {
  content: "\F793";
}

.mdi-arrow-collapse-up:before {
  content: "\F794";
}

.mdi-arrow-collapse-vertical:before {
  content: "\F84C";
}

.mdi-arrow-decision:before {
  content: "\F9BA";
}

.mdi-arrow-decision-auto:before {
  content: "\F9BB";
}

.mdi-arrow-decision-auto-outline:before {
  content: "\F9BC";
}

.mdi-arrow-decision-outline:before {
  content: "\F9BD";
}

.mdi-arrow-down:before {
  content: "\F045";
}

.mdi-arrow-down-bold:before {
  content: "\F72D";
}

.mdi-arrow-down-bold-box:before {
  content: "\F72E";
}

.mdi-arrow-down-bold-box-outline:before {
  content: "\F72F";
}

.mdi-arrow-down-bold-circle:before {
  content: "\F047";
}

.mdi-arrow-down-bold-circle-outline:before {
  content: "\F048";
}

.mdi-arrow-down-bold-hexagon-outline:before {
  content: "\F049";
}

.mdi-arrow-down-bold-outline:before {
  content: "\F9BE";
}

.mdi-arrow-down-box:before {
  content: "\F6BF";
}

.mdi-arrow-down-drop-circle:before {
  content: "\F04A";
}

.mdi-arrow-down-drop-circle-outline:before {
  content: "\F04B";
}

.mdi-arrow-down-thick:before {
  content: "\F046";
}

.mdi-arrow-expand:before {
  content: "\F616";
}

.mdi-arrow-expand-all:before {
  content: "\F04C";
}

.mdi-arrow-expand-down:before {
  content: "\F795";
}

.mdi-arrow-expand-horizontal:before {
  content: "\F84D";
}

.mdi-arrow-expand-left:before {
  content: "\F796";
}

.mdi-arrow-expand-right:before {
  content: "\F797";
}

.mdi-arrow-expand-up:before {
  content: "\F798";
}

.mdi-arrow-expand-vertical:before {
  content: "\F84E";
}

.mdi-arrow-left:before {
  content: "\F04D";
}

.mdi-arrow-left-bold:before {
  content: "\F730";
}

.mdi-arrow-left-bold-box:before {
  content: "\F731";
}

.mdi-arrow-left-bold-box-outline:before {
  content: "\F732";
}

.mdi-arrow-left-bold-circle:before {
  content: "\F04F";
}

.mdi-arrow-left-bold-circle-outline:before {
  content: "\F050";
}

.mdi-arrow-left-bold-hexagon-outline:before {
  content: "\F051";
}

.mdi-arrow-left-bold-outline:before {
  content: "\F9BF";
}

.mdi-arrow-left-box:before {
  content: "\F6C0";
}

.mdi-arrow-left-drop-circle:before {
  content: "\F052";
}

.mdi-arrow-left-drop-circle-outline:before {
  content: "\F053";
}

.mdi-arrow-left-right-bold-outline:before {
  content: "\F9C0";
}

.mdi-arrow-left-thick:before {
  content: "\F04E";
}

.mdi-arrow-right:before {
  content: "\F054";
}

.mdi-arrow-right-bold:before {
  content: "\F733";
}

.mdi-arrow-right-bold-box:before {
  content: "\F734";
}

.mdi-arrow-right-bold-box-outline:before {
  content: "\F735";
}

.mdi-arrow-right-bold-circle:before {
  content: "\F056";
}

.mdi-arrow-right-bold-circle-outline:before {
  content: "\F057";
}

.mdi-arrow-right-bold-hexagon-outline:before {
  content: "\F058";
}

.mdi-arrow-right-bold-outline:before {
  content: "\F9C1";
}

.mdi-arrow-right-box:before {
  content: "\F6C1";
}

.mdi-arrow-right-drop-circle:before {
  content: "\F059";
}

.mdi-arrow-right-drop-circle-outline:before {
  content: "\F05A";
}

.mdi-arrow-right-thick:before {
  content: "\F055";
}

.mdi-arrow-split-horizontal:before {
  content: "\F93A";
}

.mdi-arrow-split-vertical:before {
  content: "\F93B";
}

.mdi-arrow-top-left:before {
  content: "\F05B";
}

.mdi-arrow-top-left-bold-outline:before {
  content: "\F9C2";
}

.mdi-arrow-top-left-thick:before {
  content: "\F9C3";
}

.mdi-arrow-top-right:before {
  content: "\F05C";
}

.mdi-arrow-top-right-bold-outline:before {
  content: "\F9C4";
}

.mdi-arrow-top-right-thick:before {
  content: "\F9C5";
}

.mdi-arrow-up:before {
  content: "\F05D";
}

.mdi-arrow-up-bold:before {
  content: "\F736";
}

.mdi-arrow-up-bold-box:before {
  content: "\F737";
}

.mdi-arrow-up-bold-box-outline:before {
  content: "\F738";
}

.mdi-arrow-up-bold-circle:before {
  content: "\F05F";
}

.mdi-arrow-up-bold-circle-outline:before {
  content: "\F060";
}

.mdi-arrow-up-bold-hexagon-outline:before {
  content: "\F061";
}

.mdi-arrow-up-bold-outline:before {
  content: "\F9C6";
}

.mdi-arrow-up-box:before {
  content: "\F6C2";
}

.mdi-arrow-up-down-bold-outline:before {
  content: "\F9C7";
}

.mdi-arrow-up-drop-circle:before {
  content: "\F062";
}

.mdi-arrow-up-drop-circle-outline:before {
  content: "\F063";
}

.mdi-arrow-up-thick:before {
  content: "\F05E";
}

.mdi-artist:before {
  content: "\F802";
}

.mdi-artstation:before {
  content: "\FB37";
}

.mdi-aspect-ratio:before {
  content: "\FA23";
}

.mdi-assistant:before {
  content: "\F064";
}

.mdi-asterisk:before {
  content: "\F6C3";
}

.mdi-at:before {
  content: "\F065";
}

.mdi-atlassian:before {
  content: "\F803";
}

.mdi-atom:before {
  content: "\F767";
}

.mdi-attachment:before {
  content: "\F066";
}

.mdi-audio-video:before {
  content: "\F93C";
}

.mdi-audiobook:before {
  content: "\F067";
}

.mdi-augmented-reality:before {
  content: "\F84F";
}

.mdi-auto-fix:before {
  content: "\F068";
}

.mdi-auto-upload:before {
  content: "\F069";
}

.mdi-autorenew:before {
  content: "\F06A";
}

.mdi-av-timer:before {
  content: "\F06B";
}

.mdi-axe:before {
  content: "\F8C7";
}

.mdi-azure:before {
  content: "\F804";
}

.mdi-babel:before {
  content: "\FA24";
}

.mdi-baby:before {
  content: "\F06C";
}

.mdi-baby-buggy:before {
  content: "\F68E";
}

.mdi-backburger:before {
  content: "\F06D";
}

.mdi-backspace:before {
  content: "\F06E";
}

.mdi-backspace-outline:before {
  content: "\FB38";
}

.mdi-backup-restore:before {
  content: "\F06F";
}

.mdi-badminton:before {
  content: "\F850";
}

.mdi-balloon:before {
  content: "\FA25";
}

.mdi-ballot:before {
  content: "\F9C8";
}

.mdi-ballot-outline:before {
  content: "\F9C9";
}

.mdi-ballot-recount:before {
  content: "\FC15";
}

.mdi-ballot-recount-outline:before {
  content: "\FC16";
}

.mdi-bandcamp:before {
  content: "\F674";
}

.mdi-bank:before {
  content: "\F070";
}

.mdi-bank-transfer:before {
  content: "\FA26";
}

.mdi-bank-transfer-in:before {
  content: "\FA27";
}

.mdi-bank-transfer-out:before {
  content: "\FA28";
}

.mdi-barcode:before {
  content: "\F071";
}

.mdi-barcode-scan:before {
  content: "\F072";
}

.mdi-barley:before {
  content: "\F073";
}

.mdi-barley-off:before {
  content: "\FB39";
}

.mdi-barn:before {
  content: "\FB3A";
}

.mdi-barrel:before {
  content: "\F074";
}

.mdi-baseball:before {
  content: "\F851";
}

.mdi-baseball-bat:before {
  content: "\F852";
}

.mdi-basecamp:before {
  content: "\F075";
}

.mdi-basket:before {
  content: "\F076";
}

.mdi-basket-fill:before {
  content: "\F077";
}

.mdi-basket-unfill:before {
  content: "\F078";
}

.mdi-basketball:before {
  content: "\F805";
}

.mdi-basketball-hoop:before {
  content: "\FC17";
}

.mdi-basketball-hoop-outline:before {
  content: "\FC18";
}

.mdi-bat:before {
  content: "\FB3B";
}

.mdi-battery:before {
  content: "\F079";
}

.mdi-battery-10:before {
  content: "\F07A";
}

.mdi-battery-10-bluetooth:before {
  content: "\F93D";
}

.mdi-battery-20:before {
  content: "\F07B";
}

.mdi-battery-20-bluetooth:before {
  content: "\F93E";
}

.mdi-battery-30:before {
  content: "\F07C";
}

.mdi-battery-30-bluetooth:before {
  content: "\F93F";
}

.mdi-battery-40:before {
  content: "\F07D";
}

.mdi-battery-40-bluetooth:before {
  content: "\F940";
}

.mdi-battery-50:before {
  content: "\F07E";
}

.mdi-battery-50-bluetooth:before {
  content: "\F941";
}

.mdi-battery-60:before {
  content: "\F07F";
}

.mdi-battery-60-bluetooth:before {
  content: "\F942";
}

.mdi-battery-70:before {
  content: "\F080";
}

.mdi-battery-70-bluetooth:before {
  content: "\F943";
}

.mdi-battery-80:before {
  content: "\F081";
}

.mdi-battery-80-bluetooth:before {
  content: "\F944";
}

.mdi-battery-90:before {
  content: "\F082";
}

.mdi-battery-90-bluetooth:before {
  content: "\F945";
}

.mdi-battery-alert:before {
  content: "\F083";
}

.mdi-battery-alert-bluetooth:before {
  content: "\F946";
}

.mdi-battery-bluetooth:before {
  content: "\F947";
}

.mdi-battery-bluetooth-variant:before {
  content: "\F948";
}

.mdi-battery-charging:before {
  content: "\F084";
}

.mdi-battery-charging-10:before {
  content: "\F89B";
}

.mdi-battery-charging-100:before {
  content: "\F085";
}

.mdi-battery-charging-20:before {
  content: "\F086";
}

.mdi-battery-charging-30:before {
  content: "\F087";
}

.mdi-battery-charging-40:before {
  content: "\F088";
}

.mdi-battery-charging-50:before {
  content: "\F89C";
}

.mdi-battery-charging-60:before {
  content: "\F089";
}

.mdi-battery-charging-70:before {
  content: "\F89D";
}

.mdi-battery-charging-80:before {
  content: "\F08A";
}

.mdi-battery-charging-90:before {
  content: "\F08B";
}

.mdi-battery-charging-outline:before {
  content: "\F89E";
}

.mdi-battery-charging-wireless:before {
  content: "\F806";
}

.mdi-battery-charging-wireless-10:before {
  content: "\F807";
}

.mdi-battery-charging-wireless-20:before {
  content: "\F808";
}

.mdi-battery-charging-wireless-30:before {
  content: "\F809";
}

.mdi-battery-charging-wireless-40:before {
  content: "\F80A";
}

.mdi-battery-charging-wireless-50:before {
  content: "\F80B";
}

.mdi-battery-charging-wireless-60:before {
  content: "\F80C";
}

.mdi-battery-charging-wireless-70:before {
  content: "\F80D";
}

.mdi-battery-charging-wireless-80:before {
  content: "\F80E";
}

.mdi-battery-charging-wireless-90:before {
  content: "\F80F";
}

.mdi-battery-charging-wireless-alert:before {
  content: "\F810";
}

.mdi-battery-charging-wireless-outline:before {
  content: "\F811";
}

.mdi-battery-minus:before {
  content: "\F08C";
}

.mdi-battery-negative:before {
  content: "\F08D";
}

.mdi-battery-outline:before {
  content: "\F08E";
}

.mdi-battery-plus:before {
  content: "\F08F";
}

.mdi-battery-positive:before {
  content: "\F090";
}

.mdi-battery-unknown:before {
  content: "\F091";
}

.mdi-battery-unknown-bluetooth:before {
  content: "\F949";
}

.mdi-battlenet:before {
  content: "\FB3C";
}

.mdi-beach:before {
  content: "\F092";
}

.mdi-beaker:before {
  content: "\F68F";
}

.mdi-beats:before {
  content: "\F097";
}

.mdi-bed-empty:before {
  content: "\F89F";
}

.mdi-beer:before {
  content: "\F098";
}

.mdi-behance:before {
  content: "\F099";
}

.mdi-bell:before {
  content: "\F09A";
}

.mdi-bell-off:before {
  content: "\F09B";
}

.mdi-bell-off-outline:before {
  content: "\FA90";
}

.mdi-bell-outline:before {
  content: "\F09C";
}

.mdi-bell-plus:before {
  content: "\F09D";
}

.mdi-bell-plus-outline:before {
  content: "\FA91";
}

.mdi-bell-ring:before {
  content: "\F09E";
}

.mdi-bell-ring-outline:before {
  content: "\F09F";
}

.mdi-bell-sleep:before {
  content: "\F0A0";
}

.mdi-bell-sleep-outline:before {
  content: "\FA92";
}

.mdi-beta:before {
  content: "\F0A1";
}

.mdi-betamax:before {
  content: "\F9CA";
}

.mdi-bible:before {
  content: "\F0A2";
}

.mdi-bike:before {
  content: "\F0A3";
}

.mdi-billiards:before {
  content: "\FB3D";
}

.mdi-billiards-rack:before {
  content: "\FB3E";
}

.mdi-bing:before {
  content: "\F0A4";
}

.mdi-binoculars:before {
  content: "\F0A5";
}

.mdi-bio:before {
  content: "\F0A6";
}

.mdi-biohazard:before {
  content: "\F0A7";
}

.mdi-bitbucket:before {
  content: "\F0A8";
}

.mdi-bitcoin:before {
  content: "\F812";
}

.mdi-black-mesa:before {
  content: "\F0A9";
}

.mdi-blackberry:before {
  content: "\F0AA";
}

.mdi-blender:before {
  content: "\F0AB";
}

.mdi-blinds:before {
  content: "\F0AC";
}

.mdi-block-helper:before {
  content: "\F0AD";
}

.mdi-blogger:before {
  content: "\F0AE";
}

.mdi-bluetooth:before {
  content: "\F0AF";
}

.mdi-bluetooth-audio:before {
  content: "\F0B0";
}

.mdi-bluetooth-connect:before {
  content: "\F0B1";
}

.mdi-bluetooth-off:before {
  content: "\F0B2";
}

.mdi-bluetooth-settings:before {
  content: "\F0B3";
}

.mdi-bluetooth-transfer:before {
  content: "\F0B4";
}

.mdi-blur:before {
  content: "\F0B5";
}

.mdi-blur-linear:before {
  content: "\F0B6";
}

.mdi-blur-off:before {
  content: "\F0B7";
}

.mdi-blur-radial:before {
  content: "\F0B8";
}

.mdi-bomb:before {
  content: "\F690";
}

.mdi-bomb-off:before {
  content: "\F6C4";
}

.mdi-bone:before {
  content: "\F0B9";
}

.mdi-book:before {
  content: "\F0BA";
}

.mdi-book-lock:before {
  content: "\F799";
}

.mdi-book-lock-open:before {
  content: "\F79A";
}

.mdi-book-minus:before {
  content: "\F5D9";
}

.mdi-book-multiple:before {
  content: "\F0BB";
}

.mdi-book-multiple-minus:before {
  content: "\FA93";
}

.mdi-book-multiple-plus:before {
  content: "\FA94";
}

.mdi-book-multiple-remove:before {
  content: "\FA95";
}

.mdi-book-multiple-variant:before {
  content: "\F0BC";
}

.mdi-book-open:before {
  content: "\F0BD";
}

.mdi-book-open-outline:before {
  content: "\FB3F";
}

.mdi-book-open-page-variant:before {
  content: "\F5DA";
}

.mdi-book-open-variant:before {
  content: "\F0BE";
}

.mdi-book-outline:before {
  content: "\FB40";
}

.mdi-book-plus:before {
  content: "\F5DB";
}

.mdi-book-remove:before {
  content: "\FA96";
}

.mdi-book-variant:before {
  content: "\F0BF";
}

.mdi-bookmark:before {
  content: "\F0C0";
}

.mdi-bookmark-check:before {
  content: "\F0C1";
}

.mdi-bookmark-minus:before {
  content: "\F9CB";
}

.mdi-bookmark-minus-outline:before {
  content: "\F9CC";
}

.mdi-bookmark-music:before {
  content: "\F0C2";
}

.mdi-bookmark-off:before {
  content: "\F9CD";
}

.mdi-bookmark-off-outline:before {
  content: "\F9CE";
}

.mdi-bookmark-outline:before {
  content: "\F0C3";
}

.mdi-bookmark-plus:before {
  content: "\F0C5";
}

.mdi-bookmark-plus-outline:before {
  content: "\F0C4";
}

.mdi-bookmark-remove:before {
  content: "\F0C6";
}

.mdi-boombox:before {
  content: "\F5DC";
}

.mdi-bootstrap:before {
  content: "\F6C5";
}

.mdi-border-all:before {
  content: "\F0C7";
}

.mdi-border-all-variant:before {
  content: "\F8A0";
}

.mdi-border-bottom:before {
  content: "\F0C8";
}

.mdi-border-bottom-variant:before {
  content: "\F8A1";
}

.mdi-border-color:before {
  content: "\F0C9";
}

.mdi-border-horizontal:before {
  content: "\F0CA";
}

.mdi-border-inside:before {
  content: "\F0CB";
}

.mdi-border-left:before {
  content: "\F0CC";
}

.mdi-border-left-variant:before {
  content: "\F8A2";
}

.mdi-border-none:before {
  content: "\F0CD";
}

.mdi-border-none-variant:before {
  content: "\F8A3";
}

.mdi-border-outside:before {
  content: "\F0CE";
}

.mdi-border-right:before {
  content: "\F0CF";
}

.mdi-border-right-variant:before {
  content: "\F8A4";
}

.mdi-border-style:before {
  content: "\F0D0";
}

.mdi-border-top:before {
  content: "\F0D1";
}

.mdi-border-top-variant:before {
  content: "\F8A5";
}

.mdi-border-vertical:before {
  content: "\F0D2";
}

.mdi-bottle-wine:before {
  content: "\F853";
}

.mdi-bow-tie:before {
  content: "\F677";
}

.mdi-bowl:before {
  content: "\F617";
}

.mdi-bowling:before {
  content: "\F0D3";
}

.mdi-box:before {
  content: "\F0D4";
}

.mdi-box-cutter:before {
  content: "\F0D5";
}

.mdi-box-shadow:before {
  content: "\F637";
}

.mdi-boxing-glove:before {
  content: "\FB41";
}

.mdi-braille:before {
  content: "\F9CF";
}

.mdi-brain:before {
  content: "\F9D0";
}

.mdi-bridge:before {
  content: "\F618";
}

.mdi-briefcase:before {
  content: "\F0D6";
}

.mdi-briefcase-check:before {
  content: "\F0D7";
}

.mdi-briefcase-download:before {
  content: "\F0D8";
}

.mdi-briefcase-download-outline:before {
  content: "\FC19";
}

.mdi-briefcase-edit:before {
  content: "\FA97";
}

.mdi-briefcase-edit-outline:before {
  content: "\FC1A";
}

.mdi-briefcase-minus:before {
  content: "\FA29";
}

.mdi-briefcase-minus-outline:before {
  content: "\FC1B";
}

.mdi-briefcase-outline:before {
  content: "\F813";
}

.mdi-briefcase-plus:before {
  content: "\FA2A";
}

.mdi-briefcase-plus-outline:before {
  content: "\FC1C";
}

.mdi-briefcase-remove:before {
  content: "\FA2B";
}

.mdi-briefcase-remove-outline:before {
  content: "\FC1D";
}

.mdi-briefcase-search:before {
  content: "\FA2C";
}

.mdi-briefcase-search-outline:before {
  content: "\FC1E";
}

.mdi-briefcase-upload:before {
  content: "\F0D9";
}

.mdi-briefcase-upload-outline:before {
  content: "\FC1F";
}

.mdi-brightness-1:before {
  content: "\F0DA";
}

.mdi-brightness-2:before {
  content: "\F0DB";
}

.mdi-brightness-3:before {
  content: "\F0DC";
}

.mdi-brightness-4:before {
  content: "\F0DD";
}

.mdi-brightness-5:before {
  content: "\F0DE";
}

.mdi-brightness-6:before {
  content: "\F0DF";
}

.mdi-brightness-7:before {
  content: "\F0E0";
}

.mdi-brightness-auto:before {
  content: "\F0E1";
}

.mdi-broom:before {
  content: "\F0E2";
}

.mdi-brush:before {
  content: "\F0E3";
}

.mdi-buddhism:before {
  content: "\F94A";
}

.mdi-buffer:before {
  content: "\F619";
}

.mdi-bug:before {
  content: "\F0E4";
}

.mdi-bug-check:before {
  content: "\FA2D";
}

.mdi-bug-check-outline:before {
  content: "\FA2E";
}

.mdi-bug-outline:before {
  content: "\FA2F";
}

.mdi-bulldozer:before {
  content: "\FB07";
}

.mdi-bulletin-board:before {
  content: "\F0E5";
}

.mdi-bullhorn:before {
  content: "\F0E6";
}

.mdi-bullhorn-outline:before {
  content: "\FB08";
}

.mdi-bullseye:before {
  content: "\F5DD";
}

.mdi-bullseye-arrow:before {
  content: "\F8C8";
}

.mdi-bus:before {
  content: "\F0E7";
}

.mdi-bus-alert:before {
  content: "\FA98";
}

.mdi-bus-articulated-end:before {
  content: "\F79B";
}

.mdi-bus-articulated-front:before {
  content: "\F79C";
}

.mdi-bus-clock:before {
  content: "\F8C9";
}

.mdi-bus-double-decker:before {
  content: "\F79D";
}

.mdi-bus-school:before {
  content: "\F79E";
}

.mdi-bus-side:before {
  content: "\F79F";
}

.mdi-cached:before {
  content: "\F0E8";
}

.mdi-cake:before {
  content: "\F0E9";
}

.mdi-cake-layered:before {
  content: "\F0EA";
}

.mdi-cake-variant:before {
  content: "\F0EB";
}

.mdi-calculator:before {
  content: "\F0EC";
}

.mdi-calculator-variant:before {
  content: "\FA99";
}

.mdi-calendar:before {
  content: "\F0ED";
}

.mdi-calendar-alert:before {
  content: "\FA30";
}

.mdi-calendar-blank:before {
  content: "\F0EE";
}

.mdi-calendar-blank-outline:before {
  content: "\FB42";
}

.mdi-calendar-check:before {
  content: "\F0EF";
}

.mdi-calendar-check-outline:before {
  content: "\FC20";
}

.mdi-calendar-clock:before {
  content: "\F0F0";
}

.mdi-calendar-edit:before {
  content: "\F8A6";
}

.mdi-calendar-export:before {
  content: "\FB09";
}

.mdi-calendar-heart:before {
  content: "\F9D1";
}

.mdi-calendar-import:before {
  content: "\FB0A";
}

.mdi-calendar-multiple:before {
  content: "\F0F1";
}

.mdi-calendar-multiple-check:before {
  content: "\F0F2";
}

.mdi-calendar-multiselect:before {
  content: "\FA31";
}

.mdi-calendar-outline:before {
  content: "\FB43";
}

.mdi-calendar-plus:before {
  content: "\F0F3";
}

.mdi-calendar-question:before {
  content: "\F691";
}

.mdi-calendar-range:before {
  content: "\F678";
}

.mdi-calendar-range-outline:before {
  content: "\FB44";
}

.mdi-calendar-remove:before {
  content: "\F0F4";
}

.mdi-calendar-remove-outline:before {
  content: "\FC21";
}

.mdi-calendar-search:before {
  content: "\F94B";
}

.mdi-calendar-star:before {
  content: "\F9D2";
}

.mdi-calendar-text:before {
  content: "\F0F5";
}

.mdi-calendar-text-outline:before {
  content: "\FC22";
}

.mdi-calendar-today:before {
  content: "\F0F6";
}

.mdi-calendar-week:before {
  content: "\FA32";
}

.mdi-calendar-week-begin:before {
  content: "\FA33";
}

.mdi-call-made:before {
  content: "\F0F7";
}

.mdi-call-merge:before {
  content: "\F0F8";
}

.mdi-call-missed:before {
  content: "\F0F9";
}

.mdi-call-received:before {
  content: "\F0FA";
}

.mdi-call-split:before {
  content: "\F0FB";
}

.mdi-camcorder:before {
  content: "\F0FC";
}

.mdi-camcorder-box:before {
  content: "\F0FD";
}

.mdi-camcorder-box-off:before {
  content: "\F0FE";
}

.mdi-camcorder-off:before {
  content: "\F0FF";
}

.mdi-camera:before {
  content: "\F100";
}

.mdi-camera-account:before {
  content: "\F8CA";
}

.mdi-camera-burst:before {
  content: "\F692";
}

.mdi-camera-control:before {
  content: "\FB45";
}

.mdi-camera-enhance:before {
  content: "\F101";
}

.mdi-camera-enhance-outline:before {
  content: "\FB46";
}

.mdi-camera-front:before {
  content: "\F102";
}

.mdi-camera-front-variant:before {
  content: "\F103";
}

.mdi-camera-gopro:before {
  content: "\F7A0";
}

.mdi-camera-image:before {
  content: "\F8CB";
}

.mdi-camera-iris:before {
  content: "\F104";
}

.mdi-camera-metering-center:before {
  content: "\F7A1";
}

.mdi-camera-metering-matrix:before {
  content: "\F7A2";
}

.mdi-camera-metering-partial:before {
  content: "\F7A3";
}

.mdi-camera-metering-spot:before {
  content: "\F7A4";
}

.mdi-camera-off:before {
  content: "\F5DF";
}

.mdi-camera-party-mode:before {
  content: "\F105";
}

.mdi-camera-rear:before {
  content: "\F106";
}

.mdi-camera-rear-variant:before {
  content: "\F107";
}

.mdi-camera-switch:before {
  content: "\F108";
}

.mdi-camera-timer:before {
  content: "\F109";
}

.mdi-cancel:before {
  content: "\F739";
}

.mdi-candle:before {
  content: "\F5E2";
}

.mdi-candycane:before {
  content: "\F10A";
}

.mdi-cannabis:before {
  content: "\F7A5";
}

.mdi-caps-lock:before {
  content: "\FA9A";
}

.mdi-car:before {
  content: "\F10B";
}

.mdi-car-battery:before {
  content: "\F10C";
}

.mdi-car-brake-abs:before {
  content: "\FC23";
}

.mdi-car-brake-parking:before {
  content: "\FC24";
}

.mdi-car-connected:before {
  content: "\F10D";
}

.mdi-car-convertible:before {
  content: "\F7A6";
}

.mdi-car-door:before {
  content: "\FB47";
}

.mdi-car-electric:before {
  content: "\FB48";
}

.mdi-car-esp:before {
  content: "\FC25";
}

.mdi-car-estate:before {
  content: "\F7A7";
}

.mdi-car-hatchback:before {
  content: "\F7A8";
}

.mdi-car-key:before {
  content: "\FB49";
}

.mdi-car-light-dimmed:before {
  content: "\FC26";
}

.mdi-car-light-fog:before {
  content: "\FC27";
}

.mdi-car-light-high:before {
  content: "\FC28";
}

.mdi-car-limousine:before {
  content: "\F8CC";
}

.mdi-car-multiple:before {
  content: "\FB4A";
}

.mdi-car-pickup:before {
  content: "\F7A9";
}

.mdi-car-side:before {
  content: "\F7AA";
}

.mdi-car-sports:before {
  content: "\F7AB";
}

.mdi-car-tire-alert:before {
  content: "\FC29";
}

.mdi-car-wash:before {
  content: "\F10E";
}

.mdi-caravan:before {
  content: "\F7AC";
}

.mdi-card:before {
  content: "\FB4B";
}

.mdi-card-bulleted:before {
  content: "\FB4C";
}

.mdi-card-bulleted-off:before {
  content: "\FB4D";
}

.mdi-card-bulleted-off-outline:before {
  content: "\FB4E";
}

.mdi-card-bulleted-outline:before {
  content: "\FB4F";
}

.mdi-card-bulleted-settings:before {
  content: "\FB50";
}

.mdi-card-bulleted-settings-outline:before {
  content: "\FB51";
}

.mdi-card-outline:before {
  content: "\FB52";
}

.mdi-card-text:before {
  content: "\FB53";
}

.mdi-card-text-outline:before {
  content: "\FB54";
}

.mdi-cards:before {
  content: "\F638";
}

.mdi-cards-club:before {
  content: "\F8CD";
}

.mdi-cards-diamond:before {
  content: "\F8CE";
}

.mdi-cards-heart:before {
  content: "\F8CF";
}

.mdi-cards-outline:before {
  content: "\F639";
}

.mdi-cards-playing-outline:before {
  content: "\F63A";
}

.mdi-cards-spade:before {
  content: "\F8D0";
}

.mdi-cards-variant:before {
  content: "\F6C6";
}

.mdi-carrot:before {
  content: "\F10F";
}

.mdi-cart:before {
  content: "\F110";
}

.mdi-cart-arrow-right:before {
  content: "\FC2A";
}

.mdi-cart-off:before {
  content: "\F66B";
}

.mdi-cart-outline:before {
  content: "\F111";
}

.mdi-cart-plus:before {
  content: "\F112";
}

.mdi-case-sensitive-alt:before {
  content: "\F113";
}

.mdi-cash:before {
  content: "\F114";
}

.mdi-cash-100:before {
  content: "\F115";
}

.mdi-cash-multiple:before {
  content: "\F116";
}

.mdi-cash-refund:before {
  content: "\FA9B";
}

.mdi-cash-usd:before {
  content: "\F117";
}

.mdi-cassette:before {
  content: "\F9D3";
}

.mdi-cast:before {
  content: "\F118";
}

.mdi-cast-connected:before {
  content: "\F119";
}

.mdi-cast-off:before {
  content: "\F789";
}

.mdi-castle:before {
  content: "\F11A";
}

.mdi-cat:before {
  content: "\F11B";
}

.mdi-cctv:before {
  content: "\F7AD";
}

.mdi-ceiling-light:before {
  content: "\F768";
}

.mdi-cellphone:before {
  content: "\F11C";
}

.mdi-cellphone-android:before {
  content: "\F11D";
}

.mdi-cellphone-arrow-down:before {
  content: "\F9D4";
}

.mdi-cellphone-basic:before {
  content: "\F11E";
}

.mdi-cellphone-dock:before {
  content: "\F11F";
}

.mdi-cellphone-erase:before {
  content: "\F94C";
}

.mdi-cellphone-iphone:before {
  content: "\F120";
}

.mdi-cellphone-key:before {
  content: "\F94D";
}

.mdi-cellphone-link:before {
  content: "\F121";
}

.mdi-cellphone-link-off:before {
  content: "\F122";
}

.mdi-cellphone-lock:before {
  content: "\F94E";
}

.mdi-cellphone-message:before {
  content: "\F8D2";
}

.mdi-cellphone-off:before {
  content: "\F94F";
}

.mdi-cellphone-screenshot:before {
  content: "\FA34";
}

.mdi-cellphone-settings:before {
  content: "\F123";
}

.mdi-cellphone-settings-variant:before {
  content: "\F950";
}

.mdi-cellphone-sound:before {
  content: "\F951";
}

.mdi-cellphone-text:before {
  content: "\F8D1";
}

.mdi-cellphone-wireless:before {
  content: "\F814";
}

.mdi-certificate:before {
  content: "\F124";
}

.mdi-chair-school:before {
  content: "\F125";
}

.mdi-charity:before {
  content: "\FC2B";
}

.mdi-chart-arc:before {
  content: "\F126";
}

.mdi-chart-areaspline:before {
  content: "\F127";
}

.mdi-chart-bar:before {
  content: "\F128";
}

.mdi-chart-bar-stacked:before {
  content: "\F769";
}

.mdi-chart-bell-curve:before {
  content: "\FC2C";
}

.mdi-chart-bubble:before {
  content: "\F5E3";
}

.mdi-chart-donut:before {
  content: "\F7AE";
}

.mdi-chart-donut-variant:before {
  content: "\F7AF";
}

.mdi-chart-gantt:before {
  content: "\F66C";
}

.mdi-chart-histogram:before {
  content: "\F129";
}

.mdi-chart-line:before {
  content: "\F12A";
}

.mdi-chart-line-stacked:before {
  content: "\F76A";
}

.mdi-chart-line-variant:before {
  content: "\F7B0";
}

.mdi-chart-multiline:before {
  content: "\F8D3";
}

.mdi-chart-pie:before {
  content: "\F12B";
}

.mdi-chart-scatterplot-hexbin:before {
  content: "\F66D";
}

.mdi-chart-timeline:before {
  content: "\F66E";
}

.mdi-chat:before {
  content: "\FB55";
}

.mdi-chat-alert:before {
  content: "\FB56";
}

.mdi-chat-processing:before {
  content: "\FB57";
}

.mdi-check:before {
  content: "\F12C";
}

.mdi-check-all:before {
  content: "\F12D";
}

.mdi-check-box-multiple-outline:before {
  content: "\FC2D";
}

.mdi-check-box-outline:before {
  content: "\FC2E";
}

.mdi-check-circle:before {
  content: "\F5E0";
}

.mdi-check-circle-outline:before {
  content: "\F5E1";
}

.mdi-check-decagram:before {
  content: "\F790";
}

.mdi-check-network:before {
  content: "\FC2F";
}

.mdi-check-network-outline:before {
  content: "\FC30";
}

.mdi-check-outline:before {
  content: "\F854";
}

.mdi-checkbook:before {
  content: "\FA9C";
}

.mdi-checkbox-blank:before {
  content: "\F12E";
}

.mdi-checkbox-blank-circle:before {
  content: "\F12F";
}

.mdi-checkbox-blank-circle-outline:before {
  content: "\F130";
}

.mdi-checkbox-blank-outline:before {
  content: "\F131";
}

.mdi-checkbox-intermediate:before {
  content: "\F855";
}

.mdi-checkbox-marked:before {
  content: "\F132";
}

.mdi-checkbox-marked-circle:before {
  content: "\F133";
}

.mdi-checkbox-marked-circle-outline:before {
  content: "\F134";
}

.mdi-checkbox-marked-outline:before {
  content: "\F135";
}

.mdi-checkbox-multiple-blank:before {
  content: "\F136";
}

.mdi-checkbox-multiple-blank-circle:before {
  content: "\F63B";
}

.mdi-checkbox-multiple-blank-circle-outline:before {
  content: "\F63C";
}

.mdi-checkbox-multiple-blank-outline:before {
  content: "\F137";
}

.mdi-checkbox-multiple-marked:before {
  content: "\F138";
}

.mdi-checkbox-multiple-marked-circle:before {
  content: "\F63D";
}

.mdi-checkbox-multiple-marked-circle-outline:before {
  content: "\F63E";
}

.mdi-checkbox-multiple-marked-outline:before {
  content: "\F139";
}

.mdi-checkerboard:before {
  content: "\F13A";
}

.mdi-chef-hat:before {
  content: "\FB58";
}

.mdi-chemical-weapon:before {
  content: "\F13B";
}

.mdi-chess-bishop:before {
  content: "\F85B";
}

.mdi-chess-king:before {
  content: "\F856";
}

.mdi-chess-knight:before {
  content: "\F857";
}

.mdi-chess-pawn:before {
  content: "\F858";
}

.mdi-chess-queen:before {
  content: "\F859";
}

.mdi-chess-rook:before {
  content: "\F85A";
}

.mdi-chevron-double-down:before {
  content: "\F13C";
}

.mdi-chevron-double-left:before {
  content: "\F13D";
}

.mdi-chevron-double-right:before {
  content: "\F13E";
}

.mdi-chevron-double-up:before {
  content: "\F13F";
}

.mdi-chevron-down:before {
  content: "\F140";
}

.mdi-chevron-down-box:before {
  content: "\F9D5";
}

.mdi-chevron-down-box-outline:before {
  content: "\F9D6";
}

.mdi-chevron-down-circle:before {
  content: "\FB0B";
}

.mdi-chevron-down-circle-outline:before {
  content: "\FB0C";
}

.mdi-chevron-left:before {
  content: "\F141";
}

.mdi-chevron-left-box:before {
  content: "\F9D7";
}

.mdi-chevron-left-box-outline:before {
  content: "\F9D8";
}

.mdi-chevron-left-circle:before {
  content: "\FB0D";
}

.mdi-chevron-left-circle-outline:before {
  content: "\FB0E";
}

.mdi-chevron-right:before {
  content: "\F142";
}

.mdi-chevron-right-box:before {
  content: "\F9D9";
}

.mdi-chevron-right-box-outline:before {
  content: "\F9DA";
}

.mdi-chevron-right-circle:before {
  content: "\FB0F";
}

.mdi-chevron-right-circle-outline:before {
  content: "\FB10";
}

.mdi-chevron-up:before {
  content: "\F143";
}

.mdi-chevron-up-box:before {
  content: "\F9DB";
}

.mdi-chevron-up-box-outline:before {
  content: "\F9DC";
}

.mdi-chevron-up-circle:before {
  content: "\FB11";
}

.mdi-chevron-up-circle-outline:before {
  content: "\FB12";
}

.mdi-chili-hot:before {
  content: "\F7B1";
}

.mdi-chili-medium:before {
  content: "\F7B2";
}

.mdi-chili-mild:before {
  content: "\F7B3";
}

.mdi-chip:before {
  content: "\F61A";
}

.mdi-christianity:before {
  content: "\F952";
}

.mdi-church:before {
  content: "\F144";
}

.mdi-circle:before {
  content: "\F764";
}

.mdi-circle-edit-outline:before {
  content: "\F8D4";
}

.mdi-circle-medium:before {
  content: "\F9DD";
}

.mdi-circle-outline:before {
  content: "\F765";
}

.mdi-circle-slice-1:before {
  content: "\FA9D";
}

.mdi-circle-slice-2:before {
  content: "\FA9E";
}

.mdi-circle-slice-3:before {
  content: "\FA9F";
}

.mdi-circle-slice-4:before {
  content: "\FAA0";
}

.mdi-circle-slice-5:before {
  content: "\FAA1";
}

.mdi-circle-slice-6:before {
  content: "\FAA2";
}

.mdi-circle-slice-7:before {
  content: "\FAA3";
}

.mdi-circle-slice-8:before {
  content: "\FAA4";
}

.mdi-circle-small:before {
  content: "\F9DE";
}

.mdi-cisco-webex:before {
  content: "\F145";
}

.mdi-city:before {
  content: "\F146";
}

.mdi-city-variant:before {
  content: "\FA35";
}

.mdi-city-variant-outline:before {
  content: "\FA36";
}

.mdi-clipboard:before {
  content: "\F147";
}

.mdi-clipboard-account:before {
  content: "\F148";
}

.mdi-clipboard-account-outline:before {
  content: "\FC31";
}

.mdi-clipboard-alert:before {
  content: "\F149";
}

.mdi-clipboard-arrow-down:before {
  content: "\F14A";
}

.mdi-clipboard-arrow-down-outline:before {
  content: "\FC32";
}

.mdi-clipboard-arrow-left:before {
  content: "\F14B";
}

.mdi-clipboard-arrow-up:before {
  content: "\FC33";
}

.mdi-clipboard-arrow-up-outline:before {
  content: "\FC34";
}

.mdi-clipboard-check:before {
  content: "\F14C";
}

.mdi-clipboard-check-outline:before {
  content: "\F8A7";
}

.mdi-clipboard-flow:before {
  content: "\F6C7";
}

.mdi-clipboard-outline:before {
  content: "\F14D";
}

.mdi-clipboard-play:before {
  content: "\FC35";
}

.mdi-clipboard-play-outline:before {
  content: "\FC36";
}

.mdi-clipboard-plus:before {
  content: "\F750";
}

.mdi-clipboard-pulse:before {
  content: "\F85C";
}

.mdi-clipboard-pulse-outline:before {
  content: "\F85D";
}

.mdi-clipboard-text:before {
  content: "\F14E";
}

.mdi-clipboard-text-outline:before {
  content: "\FA37";
}

.mdi-clipboard-text-play:before {
  content: "\FC37";
}

.mdi-clipboard-text-play-outline:before {
  content: "\FC38";
}

.mdi-clippy:before {
  content: "\F14F";
}

.mdi-clock:before {
  content: "\F953";
}

.mdi-clock-alert:before {
  content: "\F954";
}

.mdi-clock-alert-outline:before {
  content: "\F5CE";
}

.mdi-clock-end:before {
  content: "\F151";
}

.mdi-clock-fast:before {
  content: "\F152";
}

.mdi-clock-in:before {
  content: "\F153";
}

.mdi-clock-out:before {
  content: "\F154";
}

.mdi-clock-outline:before {
  content: "\F150";
}

.mdi-clock-start:before {
  content: "\F155";
}

.mdi-close:before {
  content: "\F156";
}

.mdi-close-box:before {
  content: "\F157";
}

.mdi-close-box-multiple:before {
  content: "\FC39";
}

.mdi-close-box-multiple-outline:before {
  content: "\FC3A";
}

.mdi-close-box-outline:before {
  content: "\F158";
}

.mdi-close-circle:before {
  content: "\F159";
}

.mdi-close-circle-outline:before {
  content: "\F15A";
}

.mdi-close-network:before {
  content: "\F15B";
}

.mdi-close-network-outline:before {
  content: "\FC3B";
}

.mdi-close-octagon:before {
  content: "\F15C";
}

.mdi-close-octagon-outline:before {
  content: "\F15D";
}

.mdi-close-outline:before {
  content: "\F6C8";
}

.mdi-closed-caption:before {
  content: "\F15E";
}

.mdi-cloud:before {
  content: "\F15F";
}

.mdi-cloud-alert:before {
  content: "\F9DF";
}

.mdi-cloud-braces:before {
  content: "\F7B4";
}

.mdi-cloud-check:before {
  content: "\F160";
}

.mdi-cloud-circle:before {
  content: "\F161";
}

.mdi-cloud-download:before {
  content: "\F162";
}

.mdi-cloud-download-outline:before {
  content: "\FB59";
}

.mdi-cloud-off-outline:before {
  content: "\F164";
}

.mdi-cloud-outline:before {
  content: "\F163";
}

.mdi-cloud-print:before {
  content: "\F165";
}

.mdi-cloud-print-outline:before {
  content: "\F166";
}

.mdi-cloud-question:before {
  content: "\FA38";
}

.mdi-cloud-search:before {
  content: "\F955";
}

.mdi-cloud-search-outline:before {
  content: "\F956";
}

.mdi-cloud-sync:before {
  content: "\F63F";
}

.mdi-cloud-tags:before {
  content: "\F7B5";
}

.mdi-cloud-upload:before {
  content: "\F167";
}

.mdi-cloud-upload-outline:before {
  content: "\FB5A";
}

.mdi-clover:before {
  content: "\F815";
}

.mdi-code-array:before {
  content: "\F168";
}

.mdi-code-braces:before {
  content: "\F169";
}

.mdi-code-brackets:before {
  content: "\F16A";
}

.mdi-code-equal:before {
  content: "\F16B";
}

.mdi-code-greater-than:before {
  content: "\F16C";
}

.mdi-code-greater-than-or-equal:before {
  content: "\F16D";
}

.mdi-code-less-than:before {
  content: "\F16E";
}

.mdi-code-less-than-or-equal:before {
  content: "\F16F";
}

.mdi-code-not-equal:before {
  content: "\F170";
}

.mdi-code-not-equal-variant:before {
  content: "\F171";
}

.mdi-code-parentheses:before {
  content: "\F172";
}

.mdi-code-string:before {
  content: "\F173";
}

.mdi-code-tags:before {
  content: "\F174";
}

.mdi-code-tags-check:before {
  content: "\F693";
}

.mdi-codepen:before {
  content: "\F175";
}

.mdi-coffee:before {
  content: "\F176";
}

.mdi-coffee-outline:before {
  content: "\F6C9";
}

.mdi-coffee-to-go:before {
  content: "\F177";
}

.mdi-coffin:before {
  content: "\FB5B";
}

.mdi-cogs:before {
  content: "\F8D5";
}

.mdi-coin:before {
  content: "\F178";
}

.mdi-coins:before {
  content: "\F694";
}

.mdi-collage:before {
  content: "\F640";
}

.mdi-collapse-all:before {
  content: "\FAA5";
}

.mdi-collapse-all-outline:before {
  content: "\FAA6";
}

.mdi-color-helper:before {
  content: "\F179";
}

.mdi-comment:before {
  content: "\F17A";
}

.mdi-comment-account:before {
  content: "\F17B";
}

.mdi-comment-account-outline:before {
  content: "\F17C";
}

.mdi-comment-alert:before {
  content: "\F17D";
}

.mdi-comment-alert-outline:before {
  content: "\F17E";
}

.mdi-comment-arrow-left:before {
  content: "\F9E0";
}

.mdi-comment-arrow-left-outline:before {
  content: "\F9E1";
}

.mdi-comment-arrow-right:before {
  content: "\F9E2";
}

.mdi-comment-arrow-right-outline:before {
  content: "\F9E3";
}

.mdi-comment-check:before {
  content: "\F17F";
}

.mdi-comment-check-outline:before {
  content: "\F180";
}

.mdi-comment-eye:before {
  content: "\FA39";
}

.mdi-comment-eye-outline:before {
  content: "\FA3A";
}

.mdi-comment-multiple:before {
  content: "\F85E";
}

.mdi-comment-multiple-outline:before {
  content: "\F181";
}

.mdi-comment-outline:before {
  content: "\F182";
}

.mdi-comment-plus:before {
  content: "\F9E4";
}

.mdi-comment-plus-outline:before {
  content: "\F183";
}

.mdi-comment-processing:before {
  content: "\F184";
}

.mdi-comment-processing-outline:before {
  content: "\F185";
}

.mdi-comment-question:before {
  content: "\F816";
}

.mdi-comment-question-outline:before {
  content: "\F186";
}

.mdi-comment-remove:before {
  content: "\F5DE";
}

.mdi-comment-remove-outline:before {
  content: "\F187";
}

.mdi-comment-search:before {
  content: "\FA3B";
}

.mdi-comment-search-outline:before {
  content: "\FA3C";
}

.mdi-comment-text:before {
  content: "\F188";
}

.mdi-comment-text-multiple:before {
  content: "\F85F";
}

.mdi-comment-text-multiple-outline:before {
  content: "\F860";
}

.mdi-comment-text-outline:before {
  content: "\F189";
}

.mdi-compare:before {
  content: "\F18A";
}

.mdi-compass:before {
  content: "\F18B";
}

.mdi-compass-off:before {
  content: "\FB5C";
}

.mdi-compass-off-outline:before {
  content: "\FB5D";
}

.mdi-compass-outline:before {
  content: "\F18C";
}

.mdi-console:before {
  content: "\F18D";
}

.mdi-console-line:before {
  content: "\F7B6";
}

.mdi-console-network:before {
  content: "\F8A8";
}

.mdi-console-network-outline:before {
  content: "\FC3C";
}

.mdi-contact-mail:before {
  content: "\F18E";
}

.mdi-contacts:before {
  content: "\F6CA";
}

.mdi-contain:before {
  content: "\FA3D";
}

.mdi-contain-end:before {
  content: "\FA3E";
}

.mdi-contain-start:before {
  content: "\FA3F";
}

.mdi-content-copy:before {
  content: "\F18F";
}

.mdi-content-cut:before {
  content: "\F190";
}

.mdi-content-duplicate:before {
  content: "\F191";
}

.mdi-content-paste:before {
  content: "\F192";
}

.mdi-content-save:before {
  content: "\F193";
}

.mdi-content-save-all:before {
  content: "\F194";
}

.mdi-content-save-outline:before {
  content: "\F817";
}

.mdi-content-save-settings:before {
  content: "\F61B";
}

.mdi-content-save-settings-outline:before {
  content: "\FB13";
}

.mdi-contrast:before {
  content: "\F195";
}

.mdi-contrast-box:before {
  content: "\F196";
}

.mdi-contrast-circle:before {
  content: "\F197";
}

.mdi-controller-classic:before {
  content: "\FB5E";
}

.mdi-controller-classic-outline:before {
  content: "\FB5F";
}

.mdi-cookie:before {
  content: "\F198";
}

.mdi-copyright:before {
  content: "\F5E6";
}

.mdi-cordova:before {
  content: "\F957";
}

.mdi-corn:before {
  content: "\F7B7";
}

.mdi-counter:before {
  content: "\F199";
}

.mdi-cow:before {
  content: "\F19A";
}

.mdi-crane:before {
  content: "\F861";
}

.mdi-creation:before {
  content: "\F1C9";
}

.mdi-credit-card:before {
  content: "\F19B";
}

.mdi-credit-card-multiple:before {
  content: "\F19C";
}

.mdi-credit-card-off:before {
  content: "\F5E4";
}

.mdi-credit-card-plus:before {
  content: "\F675";
}

.mdi-credit-card-refund:before {
  content: "\FAA7";
}

.mdi-credit-card-scan:before {
  content: "\F19D";
}

.mdi-credit-card-settings:before {
  content: "\F8D6";
}

.mdi-crop:before {
  content: "\F19E";
}

.mdi-crop-free:before {
  content: "\F19F";
}

.mdi-crop-landscape:before {
  content: "\F1A0";
}

.mdi-crop-portrait:before {
  content: "\F1A1";
}

.mdi-crop-rotate:before {
  content: "\F695";
}

.mdi-crop-square:before {
  content: "\F1A2";
}

.mdi-crosshairs:before {
  content: "\F1A3";
}

.mdi-crosshairs-gps:before {
  content: "\F1A4";
}

.mdi-crown:before {
  content: "\F1A5";
}

.mdi-cryengine:before {
  content: "\F958";
}

.mdi-crystal-ball:before {
  content: "\FB14";
}

.mdi-cube:before {
  content: "\F1A6";
}

.mdi-cube-outline:before {
  content: "\F1A7";
}

.mdi-cube-scan:before {
  content: "\FB60";
}

.mdi-cube-send:before {
  content: "\F1A8";
}

.mdi-cube-unfolded:before {
  content: "\F1A9";
}

.mdi-cup:before {
  content: "\F1AA";
}

.mdi-cup-off:before {
  content: "\F5E5";
}

.mdi-cup-water:before {
  content: "\F1AB";
}

.mdi-cupcake:before {
  content: "\F959";
}

.mdi-curling:before {
  content: "\F862";
}

.mdi-currency-bdt:before {
  content: "\F863";
}

.mdi-currency-brl:before {
  content: "\FB61";
}

.mdi-currency-btc:before {
  content: "\F1AC";
}

.mdi-currency-chf:before {
  content: "\F7B8";
}

.mdi-currency-cny:before {
  content: "\F7B9";
}

.mdi-currency-eth:before {
  content: "\F7BA";
}

.mdi-currency-eur:before {
  content: "\F1AD";
}

.mdi-currency-gbp:before {
  content: "\F1AE";
}

.mdi-currency-ils:before {
  content: "\FC3D";
}

.mdi-currency-inr:before {
  content: "\F1AF";
}

.mdi-currency-jpy:before {
  content: "\F7BB";
}

.mdi-currency-krw:before {
  content: "\F7BC";
}

.mdi-currency-kzt:before {
  content: "\F864";
}

.mdi-currency-ngn:before {
  content: "\F1B0";
}

.mdi-currency-php:before {
  content: "\F9E5";
}

.mdi-currency-rub:before {
  content: "\F1B1";
}

.mdi-currency-sign:before {
  content: "\F7BD";
}

.mdi-currency-try:before {
  content: "\F1B2";
}

.mdi-currency-twd:before {
  content: "\F7BE";
}

.mdi-currency-usd:before {
  content: "\F1B3";
}

.mdi-currency-usd-off:before {
  content: "\F679";
}

.mdi-current-ac:before {
  content: "\F95A";
}

.mdi-current-dc:before {
  content: "\F95B";
}

.mdi-cursor-default:before {
  content: "\F1B4";
}

.mdi-cursor-default-outline:before {
  content: "\F1B5";
}

.mdi-cursor-move:before {
  content: "\F1B6";
}

.mdi-cursor-pointer:before {
  content: "\F1B7";
}

.mdi-cursor-text:before {
  content: "\F5E7";
}

.mdi-database:before {
  content: "\F1B8";
}

.mdi-database-check:before {
  content: "\FAA8";
}

.mdi-database-edit:before {
  content: "\FB62";
}

.mdi-database-export:before {
  content: "\F95D";
}

.mdi-database-import:before {
  content: "\F95C";
}

.mdi-database-lock:before {
  content: "\FAA9";
}

.mdi-database-minus:before {
  content: "\F1B9";
}

.mdi-database-plus:before {
  content: "\F1BA";
}

.mdi-database-search:before {
  content: "\F865";
}

.mdi-death-star:before {
  content: "\F8D7";
}

.mdi-death-star-variant:before {
  content: "\F8D8";
}

.mdi-deathly-hallows:before {
  content: "\FB63";
}

.mdi-debian:before {
  content: "\F8D9";
}

.mdi-debug-step-into:before {
  content: "\F1BB";
}

.mdi-debug-step-out:before {
  content: "\F1BC";
}

.mdi-debug-step-over:before {
  content: "\F1BD";
}

.mdi-decagram:before {
  content: "\F76B";
}

.mdi-decagram-outline:before {
  content: "\F76C";
}

.mdi-decimal-decrease:before {
  content: "\F1BE";
}

.mdi-decimal-increase:before {
  content: "\F1BF";
}

.mdi-delete:before {
  content: "\F1C0";
}

.mdi-delete-circle:before {
  content: "\F682";
}

.mdi-delete-circle-outline:before {
  content: "\FB64";
}

.mdi-delete-empty:before {
  content: "\F6CB";
}

.mdi-delete-forever:before {
  content: "\F5E8";
}

.mdi-delete-forever-outline:before {
  content: "\FB65";
}

.mdi-delete-outline:before {
  content: "\F9E6";
}

.mdi-delete-restore:before {
  content: "\F818";
}

.mdi-delete-sweep:before {
  content: "\F5E9";
}

.mdi-delete-sweep-outline:before {
  content: "\FC3E";
}

.mdi-delete-variant:before {
  content: "\F1C1";
}

.mdi-delta:before {
  content: "\F1C2";
}

.mdi-desk-lamp:before {
  content: "\F95E";
}

.mdi-deskphone:before {
  content: "\F1C3";
}

.mdi-desktop-classic:before {
  content: "\F7BF";
}

.mdi-desktop-mac:before {
  content: "\F1C4";
}

.mdi-desktop-mac-dashboard:before {
  content: "\F9E7";
}

.mdi-desktop-tower:before {
  content: "\F1C5";
}

.mdi-desktop-tower-monitor:before {
  content: "\FAAA";
}

.mdi-details:before {
  content: "\F1C6";
}

.mdi-developer-board:before {
  content: "\F696";
}

.mdi-deviantart:before {
  content: "\F1C7";
}

.mdi-dialpad:before {
  content: "\F61C";
}

.mdi-diameter:before {
  content: "\FC3F";
}

.mdi-diameter-outline:before {
  content: "\FC40";
}

.mdi-diameter-variant:before {
  content: "\FC41";
}

.mdi-diamond:before {
  content: "\FB66";
}

.mdi-diamond-outline:before {
  content: "\FB67";
}

.mdi-diamond-stone:before {
  content: "\F1C8";
}

.mdi-dice-1:before {
  content: "\F1CA";
}

.mdi-dice-2:before {
  content: "\F1CB";
}

.mdi-dice-3:before {
  content: "\F1CC";
}

.mdi-dice-4:before {
  content: "\F1CD";
}

.mdi-dice-5:before {
  content: "\F1CE";
}

.mdi-dice-6:before {
  content: "\F1CF";
}

.mdi-dice-d10:before {
  content: "\F76E";
}

.mdi-dice-d12:before {
  content: "\F866";
}

.mdi-dice-d20:before {
  content: "\F5EA";
}

.mdi-dice-d4:before {
  content: "\F5EB";
}

.mdi-dice-d6:before {
  content: "\F5EC";
}

.mdi-dice-d8:before {
  content: "\F5ED";
}

.mdi-dice-multiple:before {
  content: "\F76D";
}

.mdi-dictionary:before {
  content: "\F61D";
}

.mdi-dip-switch:before {
  content: "\F7C0";
}

.mdi-directions:before {
  content: "\F1D0";
}

.mdi-directions-fork:before {
  content: "\F641";
}

.mdi-disc:before {
  content: "\F5EE";
}

.mdi-disc-alert:before {
  content: "\F1D1";
}

.mdi-disc-player:before {
  content: "\F95F";
}

.mdi-discord:before {
  content: "\F66F";
}

.mdi-dishwasher:before {
  content: "\FAAB";
}

.mdi-disqus:before {
  content: "\F1D2";
}

.mdi-disqus-outline:before {
  content: "\F1D3";
}

.mdi-division:before {
  content: "\F1D4";
}

.mdi-division-box:before {
  content: "\F1D5";
}

.mdi-dlna:before {
  content: "\FA40";
}

.mdi-dna:before {
  content: "\F683";
}

.mdi-dns:before {
  content: "\F1D6";
}

.mdi-dns-outline:before {
  content: "\FB68";
}

.mdi-do-not-disturb:before {
  content: "\F697";
}

.mdi-do-not-disturb-off:before {
  content: "\F698";
}

.mdi-docker:before {
  content: "\F867";
}

.mdi-doctor:before {
  content: "\FA41";
}

.mdi-dog:before {
  content: "\FA42";
}

.mdi-dog-service:before {
  content: "\FAAC";
}

.mdi-dog-side:before {
  content: "\FA43";
}

.mdi-dolby:before {
  content: "\F6B2";
}

.mdi-domain:before {
  content: "\F1D7";
}

.mdi-donkey:before {
  content: "\F7C1";
}

.mdi-door:before {
  content: "\F819";
}

.mdi-door-closed:before {
  content: "\F81A";
}

.mdi-door-open:before {
  content: "\F81B";
}

.mdi-doorbell-video:before {
  content: "\F868";
}

.mdi-dot-net:before {
  content: "\FAAD";
}

.mdi-dots-horizontal:before {
  content: "\F1D8";
}

.mdi-dots-horizontal-circle:before {
  content: "\F7C2";
}

.mdi-dots-horizontal-circle-outline:before {
  content: "\FB69";
}

.mdi-dots-vertical:before {
  content: "\F1D9";
}

.mdi-dots-vertical-circle:before {
  content: "\F7C3";
}

.mdi-dots-vertical-circle-outline:before {
  content: "\FB6A";
}

.mdi-douban:before {
  content: "\F699";
}

.mdi-download:before {
  content: "\F1DA";
}

.mdi-download-multiple:before {
  content: "\F9E8";
}

.mdi-download-network:before {
  content: "\F6F3";
}

.mdi-download-network-outline:before {
  content: "\FC42";
}

.mdi-download-outline:before {
  content: "\FB6B";
}

.mdi-drag:before {
  content: "\F1DB";
}

.mdi-drag-horizontal:before {
  content: "\F1DC";
}

.mdi-drag-variant:before {
  content: "\FB6C";
}

.mdi-drag-vertical:before {
  content: "\F1DD";
}

.mdi-drawing:before {
  content: "\F1DE";
}

.mdi-drawing-box:before {
  content: "\F1DF";
}

.mdi-dribbble:before {
  content: "\F1E0";
}

.mdi-dribbble-box:before {
  content: "\F1E1";
}

.mdi-drone:before {
  content: "\F1E2";
}

.mdi-dropbox:before {
  content: "\F1E3";
}

.mdi-drupal:before {
  content: "\F1E4";
}

.mdi-duck:before {
  content: "\F1E5";
}

.mdi-dumbbell:before {
  content: "\F1E6";
}

.mdi-dump-truck:before {
  content: "\FC43";
}

.mdi-ear-hearing:before {
  content: "\F7C4";
}

.mdi-ear-hearing-off:before {
  content: "\FA44";
}

.mdi-earth:before {
  content: "\F1E7";
}

.mdi-earth-box:before {
  content: "\F6CC";
}

.mdi-earth-box-off:before {
  content: "\F6CD";
}

.mdi-earth-off:before {
  content: "\F1E8";
}

.mdi-edge:before {
  content: "\F1E9";
}

.mdi-egg:before {
  content: "\FAAE";
}

.mdi-egg-easter:before {
  content: "\FAAF";
}

.mdi-eight-track:before {
  content: "\F9E9";
}

.mdi-eject:before {
  content: "\F1EA";
}

.mdi-eject-outline:before {
  content: "\FB6D";
}

.mdi-elephant:before {
  content: "\F7C5";
}

.mdi-elevation-decline:before {
  content: "\F1EB";
}

.mdi-elevation-rise:before {
  content: "\F1EC";
}

.mdi-elevator:before {
  content: "\F1ED";
}

.mdi-email:before {
  content: "\F1EE";
}

.mdi-email-alert:before {
  content: "\F6CE";
}

.mdi-email-check:before {
  content: "\FAB0";
}

.mdi-email-check-outline:before {
  content: "\FAB1";
}

.mdi-email-lock:before {
  content: "\F1F1";
}

.mdi-email-mark-as-unread:before {
  content: "\FB6E";
}

.mdi-email-open:before {
  content: "\F1EF";
}

.mdi-email-open-outline:before {
  content: "\F5EF";
}

.mdi-email-outline:before {
  content: "\F1F0";
}

.mdi-email-plus:before {
  content: "\F9EA";
}

.mdi-email-plus-outline:before {
  content: "\F9EB";
}

.mdi-email-search:before {
  content: "\F960";
}

.mdi-email-search-outline:before {
  content: "\F961";
}

.mdi-email-variant:before {
  content: "\F5F0";
}

.mdi-ember:before {
  content: "\FB15";
}

.mdi-emby:before {
  content: "\F6B3";
}

.mdi-emoticon:before {
  content: "\FC44";
}

.mdi-emoticon-angry:before {
  content: "\FC45";
}

.mdi-emoticon-angry-outline:before {
  content: "\FC46";
}

.mdi-emoticon-cool:before {
  content: "\FC47";
}

.mdi-emoticon-cool-outline:before {
  content: "\F1F3";
}

.mdi-emoticon-cry:before {
  content: "\FC48";
}

.mdi-emoticon-cry-outline:before {
  content: "\FC49";
}

.mdi-emoticon-dead:before {
  content: "\FC4A";
}

.mdi-emoticon-dead-outline:before {
  content: "\F69A";
}

.mdi-emoticon-devil:before {
  content: "\FC4B";
}

.mdi-emoticon-devil-outline:before {
  content: "\F1F4";
}

.mdi-emoticon-excited:before {
  content: "\FC4C";
}

.mdi-emoticon-excited-outline:before {
  content: "\F69B";
}

.mdi-emoticon-happy:before {
  content: "\FC4D";
}

.mdi-emoticon-happy-outline:before {
  content: "\F1F5";
}

.mdi-emoticon-kiss:before {
  content: "\FC4E";
}

.mdi-emoticon-kiss-outline:before {
  content: "\FC4F";
}

.mdi-emoticon-neutral:before {
  content: "\FC50";
}

.mdi-emoticon-neutral-outline:before {
  content: "\F1F6";
}

.mdi-emoticon-outline:before {
  content: "\F1F2";
}

.mdi-emoticon-poop:before {
  content: "\F1F7";
}

.mdi-emoticon-poop-outline:before {
  content: "\FC51";
}

.mdi-emoticon-sad:before {
  content: "\FC52";
}

.mdi-emoticon-sad-outline:before {
  content: "\F1F8";
}

.mdi-emoticon-tongue:before {
  content: "\F1F9";
}

.mdi-emoticon-tongue-outline:before {
  content: "\FC53";
}

.mdi-emoticon-wink:before {
  content: "\FC54";
}

.mdi-emoticon-wink-outline:before {
  content: "\FC55";
}

.mdi-engine:before {
  content: "\F1FA";
}

.mdi-engine-off:before {
  content: "\FA45";
}

.mdi-engine-off-outline:before {
  content: "\FA46";
}

.mdi-engine-outline:before {
  content: "\F1FB";
}

.mdi-equal:before {
  content: "\F1FC";
}

.mdi-equal-box:before {
  content: "\F1FD";
}

.mdi-eraser:before {
  content: "\F1FE";
}

.mdi-eraser-variant:before {
  content: "\F642";
}

.mdi-escalator:before {
  content: "\F1FF";
}

.mdi-eslint:before {
  content: "\FC56";
}

.mdi-et:before {
  content: "\FAB2";
}

.mdi-ethereum:before {
  content: "\F869";
}

.mdi-ethernet:before {
  content: "\F200";
}

.mdi-ethernet-cable:before {
  content: "\F201";
}

.mdi-ethernet-cable-off:before {
  content: "\F202";
}

.mdi-etsy:before {
  content: "\F203";
}

.mdi-ev-station:before {
  content: "\F5F1";
}

.mdi-eventbrite:before {
  content: "\F7C6";
}

.mdi-evernote:before {
  content: "\F204";
}

.mdi-exclamation:before {
  content: "\F205";
}

.mdi-exit-run:before {
  content: "\FA47";
}

.mdi-exit-to-app:before {
  content: "\F206";
}

.mdi-expand-all:before {
  content: "\FAB3";
}

.mdi-expand-all-outline:before {
  content: "\FAB4";
}

.mdi-exponent:before {
  content: "\F962";
}

.mdi-exponent-box:before {
  content: "\F963";
}

.mdi-export:before {
  content: "\F207";
}

.mdi-export-variant:before {
  content: "\FB6F";
}

.mdi-eye:before {
  content: "\F208";
}

.mdi-eye-circle:before {
  content: "\FB70";
}

.mdi-eye-circle-outline:before {
  content: "\FB71";
}

.mdi-eye-off:before {
  content: "\F209";
}

.mdi-eye-off-outline:before {
  content: "\F6D0";
}

.mdi-eye-outline:before {
  content: "\F6CF";
}

.mdi-eye-plus:before {
  content: "\F86A";
}

.mdi-eye-plus-outline:before {
  content: "\F86B";
}

.mdi-eye-settings:before {
  content: "\F86C";
}

.mdi-eye-settings-outline:before {
  content: "\F86D";
}

.mdi-eyedropper:before {
  content: "\F20A";
}

.mdi-eyedropper-variant:before {
  content: "\F20B";
}

.mdi-face:before {
  content: "\F643";
}

.mdi-face-outline:before {
  content: "\FB72";
}

.mdi-face-profile:before {
  content: "\F644";
}

.mdi-face-recognition:before {
  content: "\FC57";
}

.mdi-facebook:before {
  content: "\F20C";
}

.mdi-facebook-box:before {
  content: "\F20D";
}

.mdi-facebook-messenger:before {
  content: "\F20E";
}

.mdi-facebook-workplace:before {
  content: "\FB16";
}

.mdi-factory:before {
  content: "\F20F";
}

.mdi-fan:before {
  content: "\F210";
}

.mdi-fan-off:before {
  content: "\F81C";
}

.mdi-fast-forward:before {
  content: "\F211";
}

.mdi-fast-forward-outline:before {
  content: "\F6D1";
}

.mdi-fax:before {
  content: "\F212";
}

.mdi-feather:before {
  content: "\F6D2";
}

.mdi-feature-search:before {
  content: "\FA48";
}

.mdi-feature-search-outline:before {
  content: "\FA49";
}

.mdi-fedora:before {
  content: "\F8DA";
}

.mdi-ferry:before {
  content: "\F213";
}

.mdi-file:before {
  content: "\F214";
}

.mdi-file-account:before {
  content: "\F73A";
}

.mdi-file-alert:before {
  content: "\FA4A";
}

.mdi-file-alert-outline:before {
  content: "\FA4B";
}

.mdi-file-cabinet:before {
  content: "\FAB5";
}

.mdi-file-chart:before {
  content: "\F215";
}

.mdi-file-check:before {
  content: "\F216";
}

.mdi-file-cloud:before {
  content: "\F217";
}

.mdi-file-compare:before {
  content: "\F8A9";
}

.mdi-file-delimited:before {
  content: "\F218";
}

.mdi-file-document:before {
  content: "\F219";
}

.mdi-file-document-box:before {
  content: "\F21A";
}

.mdi-file-document-box-multiple:before {
  content: "\FAB6";
}

.mdi-file-document-box-multiple-outline:before {
  content: "\FAB7";
}

.mdi-file-document-box-outline:before {
  content: "\F9EC";
}

.mdi-file-document-outline:before {
  content: "\F9ED";
}

.mdi-file-download:before {
  content: "\F964";
}

.mdi-file-download-outline:before {
  content: "\F965";
}

.mdi-file-excel:before {
  content: "\F21B";
}

.mdi-file-excel-box:before {
  content: "\F21C";
}

.mdi-file-export:before {
  content: "\F21D";
}

.mdi-file-find:before {
  content: "\F21E";
}

.mdi-file-find-outline:before {
  content: "\FB73";
}

.mdi-file-hidden:before {
  content: "\F613";
}

.mdi-file-image:before {
  content: "\F21F";
}

.mdi-file-import:before {
  content: "\F220";
}

.mdi-file-lock:before {
  content: "\F221";
}

.mdi-file-move:before {
  content: "\FAB8";
}

.mdi-file-multiple:before {
  content: "\F222";
}

.mdi-file-music:before {
  content: "\F223";
}

.mdi-file-outline:before {
  content: "\F224";
}

.mdi-file-pdf:before {
  content: "\F225";
}

.mdi-file-pdf-box:before {
  content: "\F226";
}

.mdi-file-percent:before {
  content: "\F81D";
}

.mdi-file-plus:before {
  content: "\F751";
}

.mdi-file-powerpoint:before {
  content: "\F227";
}

.mdi-file-powerpoint-box:before {
  content: "\F228";
}

.mdi-file-presentation-box:before {
  content: "\F229";
}

.mdi-file-question:before {
  content: "\F86E";
}

.mdi-file-remove:before {
  content: "\FB74";
}

.mdi-file-replace:before {
  content: "\FB17";
}

.mdi-file-replace-outline:before {
  content: "\FB18";
}

.mdi-file-restore:before {
  content: "\F670";
}

.mdi-file-search:before {
  content: "\FC58";
}

.mdi-file-search-outline:before {
  content: "\FC59";
}

.mdi-file-send:before {
  content: "\F22A";
}

.mdi-file-table:before {
  content: "\FC5A";
}

.mdi-file-table-outline:before {
  content: "\FC5B";
}

.mdi-file-tree:before {
  content: "\F645";
}

.mdi-file-undo:before {
  content: "\F8DB";
}

.mdi-file-upload:before {
  content: "\FA4C";
}

.mdi-file-upload-outline:before {
  content: "\FA4D";
}

.mdi-file-video:before {
  content: "\F22B";
}

.mdi-file-word:before {
  content: "\F22C";
}

.mdi-file-word-box:before {
  content: "\F22D";
}

.mdi-file-xml:before {
  content: "\F22E";
}

.mdi-film:before {
  content: "\F22F";
}

.mdi-filmstrip:before {
  content: "\F230";
}

.mdi-filmstrip-off:before {
  content: "\F231";
}

.mdi-filter:before {
  content: "\F232";
}

.mdi-filter-outline:before {
  content: "\F233";
}

.mdi-filter-remove:before {
  content: "\F234";
}

.mdi-filter-remove-outline:before {
  content: "\F235";
}

.mdi-filter-variant:before {
  content: "\F236";
}

.mdi-finance:before {
  content: "\F81E";
}

.mdi-find-replace:before {
  content: "\F6D3";
}

.mdi-fingerprint:before {
  content: "\F237";
}

.mdi-fire:before {
  content: "\F238";
}

.mdi-fire-truck:before {
  content: "\F8AA";
}

.mdi-firebase:before {
  content: "\F966";
}

.mdi-firefox:before {
  content: "\F239";
}

.mdi-fish:before {
  content: "\F23A";
}

.mdi-flag:before {
  content: "\F23B";
}

.mdi-flag-checkered:before {
  content: "\F23C";
}

.mdi-flag-minus:before {
  content: "\FB75";
}

.mdi-flag-outline:before {
  content: "\F23D";
}

.mdi-flag-plus:before {
  content: "\FB76";
}

.mdi-flag-remove:before {
  content: "\FB77";
}

.mdi-flag-triangle:before {
  content: "\F23F";
}

.mdi-flag-variant:before {
  content: "\F240";
}

.mdi-flag-variant-outline:before {
  content: "\F23E";
}

.mdi-flash:before {
  content: "\F241";
}

.mdi-flash-auto:before {
  content: "\F242";
}

.mdi-flash-circle:before {
  content: "\F81F";
}

.mdi-flash-off:before {
  content: "\F243";
}

.mdi-flash-outline:before {
  content: "\F6D4";
}

.mdi-flash-red-eye:before {
  content: "\F67A";
}

.mdi-flashlight:before {
  content: "\F244";
}

.mdi-flashlight-off:before {
  content: "\F245";
}

.mdi-flask:before {
  content: "\F093";
}

.mdi-flask-empty:before {
  content: "\F094";
}

.mdi-flask-empty-outline:before {
  content: "\F095";
}

.mdi-flask-outline:before {
  content: "\F096";
}

.mdi-flattr:before {
  content: "\F246";
}

.mdi-flip-to-back:before {
  content: "\F247";
}

.mdi-flip-to-front:before {
  content: "\F248";
}

.mdi-floor-lamp:before {
  content: "\F8DC";
}

.mdi-floor-plan:before {
  content: "\F820";
}

.mdi-floppy:before {
  content: "\F249";
}

.mdi-floppy-variant:before {
  content: "\F9EE";
}

.mdi-flower:before {
  content: "\F24A";
}

.mdi-flower-outline:before {
  content: "\F9EF";
}

.mdi-flower-tulip:before {
  content: "\F9F0";
}

.mdi-flower-tulip-outline:before {
  content: "\F9F1";
}

.mdi-folder:before {
  content: "\F24B";
}

.mdi-folder-account:before {
  content: "\F24C";
}

.mdi-folder-account-outline:before {
  content: "\FB78";
}

.mdi-folder-clock:before {
  content: "\FAB9";
}

.mdi-folder-clock-outline:before {
  content: "\FABA";
}

.mdi-folder-download:before {
  content: "\F24D";
}

.mdi-folder-edit:before {
  content: "\F8DD";
}

.mdi-folder-google-drive:before {
  content: "\F24E";
}

.mdi-folder-image:before {
  content: "\F24F";
}

.mdi-folder-key:before {
  content: "\F8AB";
}

.mdi-folder-key-network:before {
  content: "\F8AC";
}

.mdi-folder-key-network-outline:before {
  content: "\FC5C";
}

.mdi-folder-lock:before {
  content: "\F250";
}

.mdi-folder-lock-open:before {
  content: "\F251";
}

.mdi-folder-move:before {
  content: "\F252";
}

.mdi-folder-multiple:before {
  content: "\F253";
}

.mdi-folder-multiple-image:before {
  content: "\F254";
}

.mdi-folder-multiple-outline:before {
  content: "\F255";
}

.mdi-folder-network:before {
  content: "\F86F";
}

.mdi-folder-network-outline:before {
  content: "\FC5D";
}

.mdi-folder-open:before {
  content: "\F76F";
}

.mdi-folder-outline:before {
  content: "\F256";
}

.mdi-folder-plus:before {
  content: "\F257";
}

.mdi-folder-plus-outline:before {
  content: "\FB79";
}

.mdi-folder-remove:before {
  content: "\F258";
}

.mdi-folder-remove-outline:before {
  content: "\FB7A";
}

.mdi-folder-search:before {
  content: "\F967";
}

.mdi-folder-search-outline:before {
  content: "\F968";
}

.mdi-folder-star:before {
  content: "\F69C";
}

.mdi-folder-star-outline:before {
  content: "\FB7B";
}

.mdi-folder-text:before {
  content: "\FC5E";
}

.mdi-folder-text-outline:before {
  content: "\FC5F";
}

.mdi-folder-upload:before {
  content: "\F259";
}

.mdi-font-awesome:before {
  content: "\F03A";
}

.mdi-food:before {
  content: "\F25A";
}

.mdi-food-apple:before {
  content: "\F25B";
}

.mdi-food-apple-outline:before {
  content: "\FC60";
}

.mdi-food-croissant:before {
  content: "\F7C7";
}

.mdi-food-fork-drink:before {
  content: "\F5F2";
}

.mdi-food-off:before {
  content: "\F5F3";
}

.mdi-food-variant:before {
  content: "\F25C";
}

.mdi-football:before {
  content: "\F25D";
}

.mdi-football-australian:before {
  content: "\F25E";
}

.mdi-football-helmet:before {
  content: "\F25F";
}

.mdi-forklift:before {
  content: "\F7C8";
}

.mdi-format-align-bottom:before {
  content: "\F752";
}

.mdi-format-align-center:before {
  content: "\F260";
}

.mdi-format-align-justify:before {
  content: "\F261";
}

.mdi-format-align-left:before {
  content: "\F262";
}

.mdi-format-align-middle:before {
  content: "\F753";
}

.mdi-format-align-right:before {
  content: "\F263";
}

.mdi-format-align-top:before {
  content: "\F754";
}

.mdi-format-annotation-minus:before {
  content: "\FABB";
}

.mdi-format-annotation-plus:before {
  content: "\F646";
}

.mdi-format-bold:before {
  content: "\F264";
}

.mdi-format-clear:before {
  content: "\F265";
}

.mdi-format-color-fill:before {
  content: "\F266";
}

.mdi-format-color-text:before {
  content: "\F69D";
}

.mdi-format-columns:before {
  content: "\F8DE";
}

.mdi-format-float-center:before {
  content: "\F267";
}

.mdi-format-float-left:before {
  content: "\F268";
}

.mdi-format-float-none:before {
  content: "\F269";
}

.mdi-format-float-right:before {
  content: "\F26A";
}

.mdi-format-font:before {
  content: "\F6D5";
}

.mdi-format-font-size-decrease:before {
  content: "\F9F2";
}

.mdi-format-font-size-increase:before {
  content: "\F9F3";
}

.mdi-format-header-1:before {
  content: "\F26B";
}

.mdi-format-header-2:before {
  content: "\F26C";
}

.mdi-format-header-3:before {
  content: "\F26D";
}

.mdi-format-header-4:before {
  content: "\F26E";
}

.mdi-format-header-5:before {
  content: "\F26F";
}

.mdi-format-header-6:before {
  content: "\F270";
}

.mdi-format-header-decrease:before {
  content: "\F271";
}

.mdi-format-header-equal:before {
  content: "\F272";
}

.mdi-format-header-increase:before {
  content: "\F273";
}

.mdi-format-header-pound:before {
  content: "\F274";
}

.mdi-format-horizontal-align-center:before {
  content: "\F61E";
}

.mdi-format-horizontal-align-left:before {
  content: "\F61F";
}

.mdi-format-horizontal-align-right:before {
  content: "\F620";
}

.mdi-format-indent-decrease:before {
  content: "\F275";
}

.mdi-format-indent-increase:before {
  content: "\F276";
}

.mdi-format-italic:before {
  content: "\F277";
}

.mdi-format-letter-case:before {
  content: "\FB19";
}

.mdi-format-letter-case-lower:before {
  content: "\FB1A";
}

.mdi-format-letter-case-upper:before {
  content: "\FB1B";
}

.mdi-format-line-spacing:before {
  content: "\F278";
}

.mdi-format-line-style:before {
  content: "\F5C8";
}

.mdi-format-line-weight:before {
  content: "\F5C9";
}

.mdi-format-list-bulleted:before {
  content: "\F279";
}

.mdi-format-list-bulleted-type:before {
  content: "\F27A";
}

.mdi-format-list-checkbox:before {
  content: "\F969";
}

.mdi-format-list-checks:before {
  content: "\F755";
}

.mdi-format-list-numbered:before {
  content: "\F27B";
}

.mdi-format-page-break:before {
  content: "\F6D6";
}

.mdi-format-paint:before {
  content: "\F27C";
}

.mdi-format-paragraph:before {
  content: "\F27D";
}

.mdi-format-pilcrow:before {
  content: "\F6D7";
}

.mdi-format-quote-close:before {
  content: "\F27E";
}

.mdi-format-quote-open:before {
  content: "\F756";
}

.mdi-format-rotate-90:before {
  content: "\F6A9";
}

.mdi-format-section:before {
  content: "\F69E";
}

.mdi-format-size:before {
  content: "\F27F";
}

.mdi-format-strikethrough:before {
  content: "\F280";
}

.mdi-format-strikethrough-variant:before {
  content: "\F281";
}

.mdi-format-subscript:before {
  content: "\F282";
}

.mdi-format-superscript:before {
  content: "\F283";
}

.mdi-format-text:before {
  content: "\F284";
}

.mdi-format-textdirection-l-to-r:before {
  content: "\F285";
}

.mdi-format-textdirection-r-to-l:before {
  content: "\F286";
}

.mdi-format-title:before {
  content: "\F5F4";
}

.mdi-format-underline:before {
  content: "\F287";
}

.mdi-format-vertical-align-bottom:before {
  content: "\F621";
}

.mdi-format-vertical-align-center:before {
  content: "\F622";
}

.mdi-format-vertical-align-top:before {
  content: "\F623";
}

.mdi-format-wrap-inline:before {
  content: "\F288";
}

.mdi-format-wrap-square:before {
  content: "\F289";
}

.mdi-format-wrap-tight:before {
  content: "\F28A";
}

.mdi-format-wrap-top-bottom:before {
  content: "\F28B";
}

.mdi-forum:before {
  content: "\F28C";
}

.mdi-forum-outline:before {
  content: "\F821";
}

.mdi-forward:before {
  content: "\F28D";
}

.mdi-fountain:before {
  content: "\F96A";
}

.mdi-foursquare:before {
  content: "\F28E";
}

.mdi-freebsd:before {
  content: "\F8DF";
}

.mdi-fridge:before {
  content: "\F290";
}

.mdi-fridge-bottom:before {
  content: "\F292";
}

.mdi-fridge-outline:before {
  content: "\F28F";
}

.mdi-fridge-top:before {
  content: "\F291";
}

.mdi-fuel:before {
  content: "\F7C9";
}

.mdi-fullscreen:before {
  content: "\F293";
}

.mdi-fullscreen-exit:before {
  content: "\F294";
}

.mdi-function:before {
  content: "\F295";
}

.mdi-function-variant:before {
  content: "\F870";
}

.mdi-fuse:before {
  content: "\FC61";
}

.mdi-fuse-blade:before {
  content: "\FC62";
}

.mdi-gamepad:before {
  content: "\F296";
}

.mdi-gamepad-variant:before {
  content: "\F297";
}

.mdi-garage:before {
  content: "\F6D8";
}

.mdi-garage-alert:before {
  content: "\F871";
}

.mdi-garage-open:before {
  content: "\F6D9";
}

.mdi-gas-cylinder:before {
  content: "\F647";
}

.mdi-gas-station:before {
  content: "\F298";
}

.mdi-gate:before {
  content: "\F299";
}

.mdi-gate-and:before {
  content: "\F8E0";
}

.mdi-gate-nand:before {
  content: "\F8E1";
}

.mdi-gate-nor:before {
  content: "\F8E2";
}

.mdi-gate-not:before {
  content: "\F8E3";
}

.mdi-gate-or:before {
  content: "\F8E4";
}

.mdi-gate-xnor:before {
  content: "\F8E5";
}

.mdi-gate-xor:before {
  content: "\F8E6";
}

.mdi-gauge:before {
  content: "\F29A";
}

.mdi-gauge-empty:before {
  content: "\F872";
}

.mdi-gauge-full:before {
  content: "\F873";
}

.mdi-gauge-low:before {
  content: "\F874";
}

.mdi-gavel:before {
  content: "\F29B";
}

.mdi-gender-female:before {
  content: "\F29C";
}

.mdi-gender-male:before {
  content: "\F29D";
}

.mdi-gender-male-female:before {
  content: "\F29E";
}

.mdi-gender-transgender:before {
  content: "\F29F";
}

.mdi-gentoo:before {
  content: "\F8E7";
}

.mdi-gesture:before {
  content: "\F7CA";
}

.mdi-gesture-double-tap:before {
  content: "\F73B";
}

.mdi-gesture-pinch:before {
  content: "\FABC";
}

.mdi-gesture-spread:before {
  content: "\FABD";
}

.mdi-gesture-swipe-down:before {
  content: "\F73C";
}

.mdi-gesture-swipe-horizontal:before {
  content: "\FABE";
}

.mdi-gesture-swipe-left:before {
  content: "\F73D";
}

.mdi-gesture-swipe-right:before {
  content: "\F73E";
}

.mdi-gesture-swipe-up:before {
  content: "\F73F";
}

.mdi-gesture-swipe-vertical:before {
  content: "\FABF";
}

.mdi-gesture-tap:before {
  content: "\F740";
}

.mdi-gesture-two-double-tap:before {
  content: "\F741";
}

.mdi-gesture-two-tap:before {
  content: "\F742";
}

.mdi-ghost:before {
  content: "\F2A0";
}

.mdi-ghost-off:before {
  content: "\F9F4";
}

.mdi-gift:before {
  content: "\F2A1";
}

.mdi-git:before {
  content: "\F2A2";
}

.mdi-github-box:before {
  content: "\F2A3";
}

.mdi-github-circle:before {
  content: "\F2A4";
}

.mdi-github-face:before {
  content: "\F6DA";
}

.mdi-gitlab:before {
  content: "\FB7C";
}

.mdi-glass-cocktail:before {
  content: "\F356";
}

.mdi-glass-flute:before {
  content: "\F2A5";
}

.mdi-glass-mug:before {
  content: "\F2A6";
}

.mdi-glass-stange:before {
  content: "\F2A7";
}

.mdi-glass-tulip:before {
  content: "\F2A8";
}

.mdi-glass-wine:before {
  content: "\F875";
}

.mdi-glassdoor:before {
  content: "\F2A9";
}

.mdi-glasses:before {
  content: "\F2AA";
}

.mdi-globe-model:before {
  content: "\F8E8";
}

.mdi-gmail:before {
  content: "\F2AB";
}

.mdi-gnome:before {
  content: "\F2AC";
}

.mdi-gog:before {
  content: "\FB7D";
}

.mdi-golf:before {
  content: "\F822";
}

.mdi-gondola:before {
  content: "\F685";
}

.mdi-google:before {
  content: "\F2AD";
}

.mdi-google-adwords:before {
  content: "\FC63";
}

.mdi-google-allo:before {
  content: "\F801";
}

.mdi-google-analytics:before {
  content: "\F7CB";
}

.mdi-google-assistant:before {
  content: "\F7CC";
}

.mdi-google-cardboard:before {
  content: "\F2AE";
}

.mdi-google-chrome:before {
  content: "\F2AF";
}

.mdi-google-circles:before {
  content: "\F2B0";
}

.mdi-google-circles-communities:before {
  content: "\F2B1";
}

.mdi-google-circles-extended:before {
  content: "\F2B2";
}

.mdi-google-circles-group:before {
  content: "\F2B3";
}

.mdi-google-controller:before {
  content: "\F2B4";
}

.mdi-google-controller-off:before {
  content: "\F2B5";
}

.mdi-google-drive:before {
  content: "\F2B6";
}

.mdi-google-earth:before {
  content: "\F2B7";
}

.mdi-google-fit:before {
  content: "\F96B";
}

.mdi-google-glass:before {
  content: "\F2B8";
}

.mdi-google-hangouts:before {
  content: "\F2C9";
}

.mdi-google-home:before {
  content: "\F823";
}

.mdi-google-keep:before {
  content: "\F6DB";
}

.mdi-google-lens:before {
  content: "\F9F5";
}

.mdi-google-maps:before {
  content: "\F5F5";
}

.mdi-google-nearby:before {
  content: "\F2B9";
}

.mdi-google-pages:before {
  content: "\F2BA";
}

.mdi-google-photos:before {
  content: "\F6DC";
}

.mdi-google-physical-web:before {
  content: "\F2BB";
}

.mdi-google-play:before {
  content: "\F2BC";
}

.mdi-google-plus:before {
  content: "\F2BD";
}

.mdi-google-plus-box:before {
  content: "\F2BE";
}

.mdi-google-spreadsheet:before {
  content: "\F9F6";
}

.mdi-google-street-view:before {
  content: "\FC64";
}

.mdi-google-translate:before {
  content: "\F2BF";
}

.mdi-google-wallet:before {
  content: "\F2C0";
}

.mdi-gpu:before {
  content: "\F8AD";
}

.mdi-gradient:before {
  content: "\F69F";
}

.mdi-graphql:before {
  content: "\F876";
}

.mdi-grave-stone:before {
  content: "\FB7E";
}

.mdi-grease-pencil:before {
  content: "\F648";
}

.mdi-greater-than:before {
  content: "\F96C";
}

.mdi-greater-than-or-equal:before {
  content: "\F96D";
}

.mdi-grid:before {
  content: "\F2C1";
}

.mdi-grid-large:before {
  content: "\F757";
}

.mdi-grid-off:before {
  content: "\F2C2";
}

.mdi-group:before {
  content: "\F2C3";
}

.mdi-guitar-acoustic:before {
  content: "\F770";
}

.mdi-guitar-electric:before {
  content: "\F2C4";
}

.mdi-guitar-pick:before {
  content: "\F2C5";
}

.mdi-guitar-pick-outline:before {
  content: "\F2C6";
}

.mdi-guy-fawkes-mask:before {
  content: "\F824";
}

.mdi-hackernews:before {
  content: "\F624";
}

.mdi-hail:before {
  content: "\FAC0";
}

.mdi-halloween:before {
  content: "\FB7F";
}

.mdi-hamburger:before {
  content: "\F684";
}

.mdi-hammer:before {
  content: "\F8E9";
}

.mdi-hand:before {
  content: "\FA4E";
}

.mdi-hand-okay:before {
  content: "\FA4F";
}

.mdi-hand-peace:before {
  content: "\FA50";
}

.mdi-hand-peace-variant:before {
  content: "\FA51";
}

.mdi-hand-pointing-down:before {
  content: "\FA52";
}

.mdi-hand-pointing-left:before {
  content: "\FA53";
}

.mdi-hand-pointing-right:before {
  content: "\F2C7";
}

.mdi-hand-pointing-up:before {
  content: "\FA54";
}

.mdi-hanger:before {
  content: "\F2C8";
}

.mdi-hard-hat:before {
  content: "\F96E";
}

.mdi-harddisk:before {
  content: "\F2CA";
}

.mdi-hat-fedora:before {
  content: "\FB80";
}

.mdi-hazard-lights:before {
  content: "\FC65";
}

.mdi-headphones:before {
  content: "\F2CB";
}

.mdi-headphones-bluetooth:before {
  content: "\F96F";
}

.mdi-headphones-box:before {
  content: "\F2CC";
}

.mdi-headphones-off:before {
  content: "\F7CD";
}

.mdi-headphones-settings:before {
  content: "\F2CD";
}

.mdi-headset:before {
  content: "\F2CE";
}

.mdi-headset-dock:before {
  content: "\F2CF";
}

.mdi-headset-off:before {
  content: "\F2D0";
}

.mdi-heart:before {
  content: "\F2D1";
}

.mdi-heart-box:before {
  content: "\F2D2";
}

.mdi-heart-box-outline:before {
  content: "\F2D3";
}

.mdi-heart-broken:before {
  content: "\F2D4";
}

.mdi-heart-circle:before {
  content: "\F970";
}

.mdi-heart-circle-outline:before {
  content: "\F971";
}

.mdi-heart-half:before {
  content: "\F6DE";
}

.mdi-heart-half-full:before {
  content: "\F6DD";
}

.mdi-heart-half-outline:before {
  content: "\F6DF";
}

.mdi-heart-multiple:before {
  content: "\FA55";
}

.mdi-heart-multiple-outline:before {
  content: "\FA56";
}

.mdi-heart-off:before {
  content: "\F758";
}

.mdi-heart-outline:before {
  content: "\F2D5";
}

.mdi-heart-pulse:before {
  content: "\F5F6";
}

.mdi-helicopter:before {
  content: "\FAC1";
}

.mdi-help:before {
  content: "\F2D6";
}

.mdi-help-box:before {
  content: "\F78A";
}

.mdi-help-circle:before {
  content: "\F2D7";
}

.mdi-help-circle-outline:before {
  content: "\F625";
}

.mdi-help-network:before {
  content: "\F6F4";
}

.mdi-help-network-outline:before {
  content: "\FC66";
}

.mdi-help-rhombus:before {
  content: "\FB81";
}

.mdi-help-rhombus-outline:before {
  content: "\FB82";
}

.mdi-hexagon:before {
  content: "\F2D8";
}

.mdi-hexagon-multiple:before {
  content: "\F6E0";
}

.mdi-hexagon-outline:before {
  content: "\F2D9";
}

.mdi-hexagon-slice-1:before {
  content: "\FAC2";
}

.mdi-hexagon-slice-2:before {
  content: "\FAC3";
}

.mdi-hexagon-slice-3:before {
  content: "\FAC4";
}

.mdi-hexagon-slice-4:before {
  content: "\FAC5";
}

.mdi-hexagon-slice-5:before {
  content: "\FAC6";
}

.mdi-hexagon-slice-6:before {
  content: "\FAC7";
}

.mdi-hexagram:before {
  content: "\FAC8";
}

.mdi-hexagram-outline:before {
  content: "\FAC9";
}

.mdi-high-definition:before {
  content: "\F7CE";
}

.mdi-high-definition-box:before {
  content: "\F877";
}

.mdi-highway:before {
  content: "\F5F7";
}

.mdi-hinduism:before {
  content: "\F972";
}

.mdi-history:before {
  content: "\F2DA";
}

.mdi-hockey-puck:before {
  content: "\F878";
}

.mdi-hockey-sticks:before {
  content: "\F879";
}

.mdi-hololens:before {
  content: "\F2DB";
}

.mdi-home:before {
  content: "\F2DC";
}

.mdi-home-account:before {
  content: "\F825";
}

.mdi-home-alert:before {
  content: "\F87A";
}

.mdi-home-assistant:before {
  content: "\F7CF";
}

.mdi-home-automation:before {
  content: "\F7D0";
}

.mdi-home-circle:before {
  content: "\F7D1";
}

.mdi-home-currency-usd:before {
  content: "\F8AE";
}

.mdi-home-heart:before {
  content: "\F826";
}

.mdi-home-lock:before {
  content: "\F8EA";
}

.mdi-home-lock-open:before {
  content: "\F8EB";
}

.mdi-home-map-marker:before {
  content: "\F5F8";
}

.mdi-home-minus:before {
  content: "\F973";
}

.mdi-home-modern:before {
  content: "\F2DD";
}

.mdi-home-outline:before {
  content: "\F6A0";
}

.mdi-home-plus:before {
  content: "\F974";
}

.mdi-home-variant:before {
  content: "\F2DE";
}

.mdi-home-variant-outline:before {
  content: "\FB83";
}

.mdi-hook:before {
  content: "\F6E1";
}

.mdi-hook-off:before {
  content: "\F6E2";
}

.mdi-hops:before {
  content: "\F2DF";
}

.mdi-horseshoe:before {
  content: "\FA57";
}

.mdi-hospital:before {
  content: "\F2E0";
}

.mdi-hospital-building:before {
  content: "\F2E1";
}

.mdi-hospital-marker:before {
  content: "\F2E2";
}

.mdi-hot-tub:before {
  content: "\F827";
}

.mdi-hotel:before {
  content: "\F2E3";
}

.mdi-houzz:before {
  content: "\F2E4";
}

.mdi-houzz-box:before {
  content: "\F2E5";
}

.mdi-hulu:before {
  content: "\F828";
}

.mdi-human:before {
  content: "\F2E6";
}

.mdi-human-child:before {
  content: "\F2E7";
}

.mdi-human-female:before {
  content: "\F649";
}

.mdi-human-female-boy:before {
  content: "\FA58";
}

.mdi-human-female-female:before {
  content: "\FA59";
}

.mdi-human-female-girl:before {
  content: "\FA5A";
}

.mdi-human-greeting:before {
  content: "\F64A";
}

.mdi-human-handsdown:before {
  content: "\F64B";
}

.mdi-human-handsup:before {
  content: "\F64C";
}

.mdi-human-male:before {
  content: "\F64D";
}

.mdi-human-male-boy:before {
  content: "\FA5B";
}

.mdi-human-male-female:before {
  content: "\F2E8";
}

.mdi-human-male-girl:before {
  content: "\FA5C";
}

.mdi-human-male-male:before {
  content: "\FA5D";
}

.mdi-human-pregnant:before {
  content: "\F5CF";
}

.mdi-humble-bundle:before {
  content: "\F743";
}

.mdi-ice-cream:before {
  content: "\F829";
}

.mdi-iframe:before {
  content: "\FC67";
}

.mdi-iframe-outline:before {
  content: "\FC68";
}

.mdi-image:before {
  content: "\F2E9";
}

.mdi-image-album:before {
  content: "\F2EA";
}

.mdi-image-area:before {
  content: "\F2EB";
}

.mdi-image-area-close:before {
  content: "\F2EC";
}

.mdi-image-broken:before {
  content: "\F2ED";
}

.mdi-image-broken-variant:before {
  content: "\F2EE";
}

.mdi-image-filter:before {
  content: "\F2EF";
}

.mdi-image-filter-black-white:before {
  content: "\F2F0";
}

.mdi-image-filter-center-focus:before {
  content: "\F2F1";
}

.mdi-image-filter-center-focus-weak:before {
  content: "\F2F2";
}

.mdi-image-filter-drama:before {
  content: "\F2F3";
}

.mdi-image-filter-frames:before {
  content: "\F2F4";
}

.mdi-image-filter-hdr:before {
  content: "\F2F5";
}

.mdi-image-filter-none:before {
  content: "\F2F6";
}

.mdi-image-filter-tilt-shift:before {
  content: "\F2F7";
}

.mdi-image-filter-vintage:before {
  content: "\F2F8";
}

.mdi-image-move:before {
  content: "\F9F7";
}

.mdi-image-multiple:before {
  content: "\F2F9";
}

.mdi-image-off:before {
  content: "\F82A";
}

.mdi-image-outline:before {
  content: "\F975";
}

.mdi-image-plus:before {
  content: "\F87B";
}

.mdi-image-search:before {
  content: "\F976";
}

.mdi-image-search-outline:before {
  content: "\F977";
}

.mdi-image-size-select-actual:before {
  content: "\FC69";
}

.mdi-image-size-select-large:before {
  content: "\FC6A";
}

.mdi-image-size-select-small:before {
  content: "\FC6B";
}

.mdi-import:before {
  content: "\F2FA";
}

.mdi-inbox:before {
  content: "\F686";
}

.mdi-inbox-arrow-down:before {
  content: "\F2FB";
}

.mdi-inbox-arrow-up:before {
  content: "\F3D1";
}

.mdi-inbox-multiple:before {
  content: "\F8AF";
}

.mdi-inbox-multiple-outline:before {
  content: "\FB84";
}

.mdi-incognito:before {
  content: "\F5F9";
}

.mdi-infinity:before {
  content: "\F6E3";
}

.mdi-information:before {
  content: "\F2FC";
}

.mdi-information-outline:before {
  content: "\F2FD";
}

.mdi-information-variant:before {
  content: "\F64E";
}

.mdi-instagram:before {
  content: "\F2FE";
}

.mdi-instapaper:before {
  content: "\F2FF";
}

.mdi-internet-explorer:before {
  content: "\F300";
}

.mdi-invert-colors:before {
  content: "\F301";
}

.mdi-ip:before {
  content: "\FA5E";
}

.mdi-ip-network:before {
  content: "\FA5F";
}

.mdi-ip-network-outline:before {
  content: "\FC6C";
}

.mdi-ipod:before {
  content: "\FC6D";
}

.mdi-islam:before {
  content: "\F978";
}

.mdi-itunes:before {
  content: "\F676";
}

.mdi-jeepney:before {
  content: "\F302";
}

.mdi-jira:before {
  content: "\F303";
}

.mdi-jquery:before {
  content: "\F87C";
}

.mdi-jsfiddle:before {
  content: "\F304";
}

.mdi-json:before {
  content: "\F626";
}

.mdi-judaism:before {
  content: "\F979";
}

.mdi-karate:before {
  content: "\F82B";
}

.mdi-keg:before {
  content: "\F305";
}

.mdi-kettle:before {
  content: "\F5FA";
}

.mdi-key:before {
  content: "\F306";
}

.mdi-key-change:before {
  content: "\F307";
}

.mdi-key-minus:before {
  content: "\F308";
}

.mdi-key-plus:before {
  content: "\F309";
}

.mdi-key-remove:before {
  content: "\F30A";
}

.mdi-key-variant:before {
  content: "\F30B";
}

.mdi-keyboard:before {
  content: "\F30C";
}

.mdi-keyboard-backspace:before {
  content: "\F30D";
}

.mdi-keyboard-caps:before {
  content: "\F30E";
}

.mdi-keyboard-close:before {
  content: "\F30F";
}

.mdi-keyboard-off:before {
  content: "\F310";
}

.mdi-keyboard-outline:before {
  content: "\F97A";
}

.mdi-keyboard-return:before {
  content: "\F311";
}

.mdi-keyboard-settings:before {
  content: "\F9F8";
}

.mdi-keyboard-settings-outline:before {
  content: "\F9F9";
}

.mdi-keyboard-tab:before {
  content: "\F312";
}

.mdi-keyboard-variant:before {
  content: "\F313";
}

.mdi-kickstarter:before {
  content: "\F744";
}

.mdi-knife:before {
  content: "\F9FA";
}

.mdi-knife-military:before {
  content: "\F9FB";
}

.mdi-kodi:before {
  content: "\F314";
}

.mdi-label:before {
  content: "\F315";
}

.mdi-label-off:before {
  content: "\FACA";
}

.mdi-label-off-outline:before {
  content: "\FACB";
}

.mdi-label-outline:before {
  content: "\F316";
}

.mdi-label-variant:before {
  content: "\FACC";
}

.mdi-label-variant-outline:before {
  content: "\FACD";
}

.mdi-ladybug:before {
  content: "\F82C";
}

.mdi-lambda:before {
  content: "\F627";
}

.mdi-lamp:before {
  content: "\F6B4";
}

.mdi-lan:before {
  content: "\F317";
}

.mdi-lan-connect:before {
  content: "\F318";
}

.mdi-lan-disconnect:before {
  content: "\F319";
}

.mdi-lan-pending:before {
  content: "\F31A";
}

.mdi-language-c:before {
  content: "\F671";
}

.mdi-language-cpp:before {
  content: "\F672";
}

.mdi-language-csharp:before {
  content: "\F31B";
}

.mdi-language-css3:before {
  content: "\F31C";
}

.mdi-language-go:before {
  content: "\F7D2";
}

.mdi-language-haskell:before {
  content: "\FC6E";
}

.mdi-language-html5:before {
  content: "\F31D";
}

.mdi-language-java:before {
  content: "\FB1C";
}

.mdi-language-javascript:before {
  content: "\F31E";
}

.mdi-language-lua:before {
  content: "\F8B0";
}

.mdi-language-php:before {
  content: "\F31F";
}

.mdi-language-python:before {
  content: "\F320";
}

.mdi-language-python-text:before {
  content: "\F321";
}

.mdi-language-r:before {
  content: "\F7D3";
}

.mdi-language-ruby-on-rails:before {
  content: "\FACE";
}

.mdi-language-swift:before {
  content: "\F6E4";
}

.mdi-language-typescript:before {
  content: "\F6E5";
}

.mdi-laptop:before {
  content: "\F322";
}

.mdi-laptop-chromebook:before {
  content: "\F323";
}

.mdi-laptop-mac:before {
  content: "\F324";
}

.mdi-laptop-off:before {
  content: "\F6E6";
}

.mdi-laptop-windows:before {
  content: "\F325";
}

.mdi-laravel:before {
  content: "\FACF";
}

.mdi-lastfm:before {
  content: "\F326";
}

.mdi-lastpass:before {
  content: "\F446";
}

.mdi-launch:before {
  content: "\F327";
}

.mdi-lava-lamp:before {
  content: "\F7D4";
}

.mdi-layers:before {
  content: "\F328";
}

.mdi-layers-off:before {
  content: "\F329";
}

.mdi-layers-off-outline:before {
  content: "\F9FC";
}

.mdi-layers-outline:before {
  content: "\F9FD";
}

.mdi-lead-pencil:before {
  content: "\F64F";
}

.mdi-leaf:before {
  content: "\F32A";
}

.mdi-leaf-maple:before {
  content: "\FC6F";
}

.mdi-led-off:before {
  content: "\F32B";
}

.mdi-led-on:before {
  content: "\F32C";
}

.mdi-led-outline:before {
  content: "\F32D";
}

.mdi-led-strip:before {
  content: "\F7D5";
}

.mdi-led-variant-off:before {
  content: "\F32E";
}

.mdi-led-variant-on:before {
  content: "\F32F";
}

.mdi-led-variant-outline:before {
  content: "\F330";
}

.mdi-less-than:before {
  content: "\F97B";
}

.mdi-less-than-or-equal:before {
  content: "\F97C";
}

.mdi-library:before {
  content: "\F331";
}

.mdi-library-books:before {
  content: "\F332";
}

.mdi-library-music:before {
  content: "\F333";
}

.mdi-library-plus:before {
  content: "\F334";
}

.mdi-library-shelves:before {
  content: "\FB85";
}

.mdi-lifebuoy:before {
  content: "\F87D";
}

.mdi-light-switch:before {
  content: "\F97D";
}

.mdi-lightbulb:before {
  content: "\F335";
}

.mdi-lightbulb-on:before {
  content: "\F6E7";
}

.mdi-lightbulb-on-outline:before {
  content: "\F6E8";
}

.mdi-lightbulb-outline:before {
  content: "\F336";
}

.mdi-lighthouse:before {
  content: "\F9FE";
}

.mdi-lighthouse-on:before {
  content: "\F9FF";
}

.mdi-link:before {
  content: "\F337";
}

.mdi-link-off:before {
  content: "\F338";
}

.mdi-link-plus:before {
  content: "\FC70";
}

.mdi-link-variant:before {
  content: "\F339";
}

.mdi-link-variant-off:before {
  content: "\F33A";
}

.mdi-linkedin:before {
  content: "\F33B";
}

.mdi-linkedin-box:before {
  content: "\F33C";
}

.mdi-linux:before {
  content: "\F33D";
}

.mdi-linux-mint:before {
  content: "\F8EC";
}

.mdi-litecoin:before {
  content: "\FA60";
}

.mdi-loading:before {
  content: "\F771";
}

.mdi-lock:before {
  content: "\F33E";
}

.mdi-lock-alert:before {
  content: "\F8ED";
}

.mdi-lock-clock:before {
  content: "\F97E";
}

.mdi-lock-open:before {
  content: "\F33F";
}

.mdi-lock-open-outline:before {
  content: "\F340";
}

.mdi-lock-outline:before {
  content: "\F341";
}

.mdi-lock-pattern:before {
  content: "\F6E9";
}

.mdi-lock-plus:before {
  content: "\F5FB";
}

.mdi-lock-question:before {
  content: "\F8EE";
}

.mdi-lock-reset:before {
  content: "\F772";
}

.mdi-lock-smart:before {
  content: "\F8B1";
}

.mdi-locker:before {
  content: "\F7D6";
}

.mdi-locker-multiple:before {
  content: "\F7D7";
}

.mdi-login:before {
  content: "\F342";
}

.mdi-login-variant:before {
  content: "\F5FC";
}

.mdi-logout:before {
  content: "\F343";
}

.mdi-logout-variant:before {
  content: "\F5FD";
}

.mdi-looks:before {
  content: "\F344";
}

.mdi-loop:before {
  content: "\F6EA";
}

.mdi-loupe:before {
  content: "\F345";
}

.mdi-lumx:before {
  content: "\F346";
}

.mdi-lyft:before {
  content: "\FB1D";
}

.mdi-magnet:before {
  content: "\F347";
}

.mdi-magnet-on:before {
  content: "\F348";
}

.mdi-magnify:before {
  content: "\F349";
}

.mdi-magnify-close:before {
  content: "\F97F";
}

.mdi-magnify-minus:before {
  content: "\F34A";
}

.mdi-magnify-minus-cursor:before {
  content: "\FA61";
}

.mdi-magnify-minus-outline:before {
  content: "\F6EB";
}

.mdi-magnify-plus:before {
  content: "\F34B";
}

.mdi-magnify-plus-cursor:before {
  content: "\FA62";
}

.mdi-magnify-plus-outline:before {
  content: "\F6EC";
}

.mdi-mail-ru:before {
  content: "\F34C";
}

.mdi-mailbox:before {
  content: "\F6ED";
}

.mdi-map:before {
  content: "\F34D";
}

.mdi-map-legend:before {
  content: "\FA00";
}

.mdi-map-marker:before {
  content: "\F34E";
}

.mdi-map-marker-check:before {
  content: "\FC71";
}

.mdi-map-marker-circle:before {
  content: "\F34F";
}

.mdi-map-marker-distance:before {
  content: "\F8EF";
}

.mdi-map-marker-minus:before {
  content: "\F650";
}

.mdi-map-marker-multiple:before {
  content: "\F350";
}

.mdi-map-marker-off:before {
  content: "\F351";
}

.mdi-map-marker-outline:before {
  content: "\F7D8";
}

.mdi-map-marker-plus:before {
  content: "\F651";
}

.mdi-map-marker-radius:before {
  content: "\F352";
}

.mdi-map-minus:before {
  content: "\F980";
}

.mdi-map-outline:before {
  content: "\F981";
}

.mdi-map-plus:before {
  content: "\F982";
}

.mdi-map-search:before {
  content: "\F983";
}

.mdi-map-search-outline:before {
  content: "\F984";
}

.mdi-mapbox:before {
  content: "\FB86";
}

.mdi-margin:before {
  content: "\F353";
}

.mdi-markdown:before {
  content: "\F354";
}

.mdi-marker:before {
  content: "\F652";
}

.mdi-marker-check:before {
  content: "\F355";
}

.mdi-mastodon:before {
  content: "\FAD0";
}

.mdi-mastodon-variant:before {
  content: "\FAD1";
}

.mdi-material-design:before {
  content: "\F985";
}

.mdi-material-ui:before {
  content: "\F357";
}

.mdi-math-compass:before {
  content: "\F358";
}

.mdi-math-cos:before {
  content: "\FC72";
}

.mdi-math-sin:before {
  content: "\FC73";
}

.mdi-math-tan:before {
  content: "\FC74";
}

.mdi-matrix:before {
  content: "\F628";
}

.mdi-maxcdn:before {
  content: "\F359";
}

.mdi-medal:before {
  content: "\F986";
}

.mdi-medical-bag:before {
  content: "\F6EE";
}

.mdi-medium:before {
  content: "\F35A";
}

.mdi-meetup:before {
  content: "\FAD2";
}

.mdi-memory:before {
  content: "\F35B";
}

.mdi-menu:before {
  content: "\F35C";
}

.mdi-menu-down:before {
  content: "\F35D";
}

.mdi-menu-down-outline:before {
  content: "\F6B5";
}

.mdi-menu-left:before {
  content: "\F35E";
}

.mdi-menu-left-outline:before {
  content: "\FA01";
}

.mdi-menu-open:before {
  content: "\FB87";
}

.mdi-menu-right:before {
  content: "\F35F";
}

.mdi-menu-right-outline:before {
  content: "\FA02";
}

.mdi-menu-swap:before {
  content: "\FA63";
}

.mdi-menu-swap-outline:before {
  content: "\FA64";
}

.mdi-menu-up:before {
  content: "\F360";
}

.mdi-menu-up-outline:before {
  content: "\F6B6";
}

.mdi-message:before {
  content: "\F361";
}

.mdi-message-alert:before {
  content: "\F362";
}

.mdi-message-alert-outline:before {
  content: "\FA03";
}

.mdi-message-bulleted:before {
  content: "\F6A1";
}

.mdi-message-bulleted-off:before {
  content: "\F6A2";
}

.mdi-message-draw:before {
  content: "\F363";
}

.mdi-message-image:before {
  content: "\F364";
}

.mdi-message-outline:before {
  content: "\F365";
}

.mdi-message-plus:before {
  content: "\F653";
}

.mdi-message-processing:before {
  content: "\F366";
}

.mdi-message-reply:before {
  content: "\F367";
}

.mdi-message-reply-text:before {
  content: "\F368";
}

.mdi-message-settings:before {
  content: "\F6EF";
}

.mdi-message-settings-variant:before {
  content: "\F6F0";
}

.mdi-message-text:before {
  content: "\F369";
}

.mdi-message-text-outline:before {
  content: "\F36A";
}

.mdi-message-video:before {
  content: "\F36B";
}

.mdi-meteor:before {
  content: "\F629";
}

.mdi-metronome:before {
  content: "\F7D9";
}

.mdi-metronome-tick:before {
  content: "\F7DA";
}

.mdi-micro-sd:before {
  content: "\F7DB";
}

.mdi-microphone:before {
  content: "\F36C";
}

.mdi-microphone-minus:before {
  content: "\F8B2";
}

.mdi-microphone-off:before {
  content: "\F36D";
}

.mdi-microphone-outline:before {
  content: "\F36E";
}

.mdi-microphone-plus:before {
  content: "\F8B3";
}

.mdi-microphone-settings:before {
  content: "\F36F";
}

.mdi-microphone-variant:before {
  content: "\F370";
}

.mdi-microphone-variant-off:before {
  content: "\F371";
}

.mdi-microscope:before {
  content: "\F654";
}

.mdi-microsoft:before {
  content: "\F372";
}

.mdi-microsoft-dynamics:before {
  content: "\F987";
}

.mdi-microwave:before {
  content: "\FC75";
}

.mdi-midi:before {
  content: "\F8F0";
}

.mdi-midi-port:before {
  content: "\F8F1";
}

.mdi-minecraft:before {
  content: "\F373";
}

.mdi-mini-sd:before {
  content: "\FA04";
}

.mdi-minidisc:before {
  content: "\FA05";
}

.mdi-minus:before {
  content: "\F374";
}

.mdi-minus-box:before {
  content: "\F375";
}

.mdi-minus-box-outline:before {
  content: "\F6F1";
}

.mdi-minus-circle:before {
  content: "\F376";
}

.mdi-minus-circle-outline:before {
  content: "\F377";
}

.mdi-minus-network:before {
  content: "\F378";
}

.mdi-minus-network-outline:before {
  content: "\FC76";
}

.mdi-mixcloud:before {
  content: "\F62A";
}

.mdi-mixed-reality:before {
  content: "\F87E";
}

.mdi-mixer:before {
  content: "\F7DC";
}

.mdi-molecule:before {
  content: "\FB88";
}

.mdi-monitor:before {
  content: "\F379";
}

.mdi-monitor-cellphone:before {
  content: "\F988";
}

.mdi-monitor-cellphone-star:before {
  content: "\F989";
}

.mdi-monitor-dashboard:before {
  content: "\FA06";
}

.mdi-monitor-multiple:before {
  content: "\F37A";
}

.mdi-more:before {
  content: "\F37B";
}

.mdi-motorbike:before {
  content: "\F37C";
}

.mdi-mouse:before {
  content: "\F37D";
}

.mdi-mouse-bluetooth:before {
  content: "\F98A";
}

.mdi-mouse-off:before {
  content: "\F37E";
}

.mdi-mouse-variant:before {
  content: "\F37F";
}

.mdi-mouse-variant-off:before {
  content: "\F380";
}

.mdi-move-resize:before {
  content: "\F655";
}

.mdi-move-resize-variant:before {
  content: "\F656";
}

.mdi-movie:before {
  content: "\F381";
}

.mdi-movie-roll:before {
  content: "\F7DD";
}

.mdi-muffin:before {
  content: "\F98B";
}

.mdi-multiplication:before {
  content: "\F382";
}

.mdi-multiplication-box:before {
  content: "\F383";
}

.mdi-mushroom:before {
  content: "\F7DE";
}

.mdi-mushroom-outline:before {
  content: "\F7DF";
}

.mdi-music:before {
  content: "\F759";
}

.mdi-music-box:before {
  content: "\F384";
}

.mdi-music-box-outline:before {
  content: "\F385";
}

.mdi-music-circle:before {
  content: "\F386";
}

.mdi-music-circle-outline:before {
  content: "\FAD3";
}

.mdi-music-note:before {
  content: "\F387";
}

.mdi-music-note-bluetooth:before {
  content: "\F5FE";
}

.mdi-music-note-bluetooth-off:before {
  content: "\F5FF";
}

.mdi-music-note-eighth:before {
  content: "\F388";
}

.mdi-music-note-half:before {
  content: "\F389";
}

.mdi-music-note-off:before {
  content: "\F38A";
}

.mdi-music-note-quarter:before {
  content: "\F38B";
}

.mdi-music-note-sixteenth:before {
  content: "\F38C";
}

.mdi-music-note-whole:before {
  content: "\F38D";
}

.mdi-music-off:before {
  content: "\F75A";
}

.mdi-nas:before {
  content: "\F8F2";
}

.mdi-nativescript:before {
  content: "\F87F";
}

.mdi-nature:before {
  content: "\F38E";
}

.mdi-nature-people:before {
  content: "\F38F";
}

.mdi-navigation:before {
  content: "\F390";
}

.mdi-near-me:before {
  content: "\F5CD";
}

.mdi-needle:before {
  content: "\F391";
}

.mdi-netflix:before {
  content: "\F745";
}

.mdi-network:before {
  content: "\F6F2";
}

.mdi-network-off:before {
  content: "\FC77";
}

.mdi-network-off-outline:before {
  content: "\FC78";
}

.mdi-network-outline:before {
  content: "\FC79";
}

.mdi-network-strength-1:before {
  content: "\F8F3";
}

.mdi-network-strength-1-alert:before {
  content: "\F8F4";
}

.mdi-network-strength-2:before {
  content: "\F8F5";
}

.mdi-network-strength-2-alert:before {
  content: "\F8F6";
}

.mdi-network-strength-3:before {
  content: "\F8F7";
}

.mdi-network-strength-3-alert:before {
  content: "\F8F8";
}

.mdi-network-strength-4:before {
  content: "\F8F9";
}

.mdi-network-strength-4-alert:before {
  content: "\F8FA";
}

.mdi-network-strength-off:before {
  content: "\F8FB";
}

.mdi-network-strength-off-outline:before {
  content: "\F8FC";
}

.mdi-network-strength-outline:before {
  content: "\F8FD";
}

.mdi-new-box:before {
  content: "\F394";
}

.mdi-newspaper:before {
  content: "\F395";
}

.mdi-nfc:before {
  content: "\F396";
}

.mdi-nfc-tap:before {
  content: "\F397";
}

.mdi-nfc-variant:before {
  content: "\F398";
}

.mdi-ninja:before {
  content: "\F773";
}

.mdi-nintendo-switch:before {
  content: "\F7E0";
}

.mdi-nodejs:before {
  content: "\F399";
}

.mdi-not-equal:before {
  content: "\F98C";
}

.mdi-not-equal-variant:before {
  content: "\F98D";
}

.mdi-note:before {
  content: "\F39A";
}

.mdi-note-multiple:before {
  content: "\F6B7";
}

.mdi-note-multiple-outline:before {
  content: "\F6B8";
}

.mdi-note-outline:before {
  content: "\F39B";
}

.mdi-note-plus:before {
  content: "\F39C";
}

.mdi-note-plus-outline:before {
  content: "\F39D";
}

.mdi-note-text:before {
  content: "\F39E";
}

.mdi-notebook:before {
  content: "\F82D";
}

.mdi-notification-clear-all:before {
  content: "\F39F";
}

.mdi-npm:before {
  content: "\F6F6";
}

.mdi-npm-variant:before {
  content: "\F98E";
}

.mdi-npm-variant-outline:before {
  content: "\F98F";
}

.mdi-nuke:before {
  content: "\F6A3";
}

.mdi-null:before {
  content: "\F7E1";
}

.mdi-numeric:before {
  content: "\F3A0";
}

.mdi-numeric-0:before {
  content: "\30";
}

.mdi-numeric-0-box:before {
  content: "\F3A1";
}

.mdi-numeric-0-box-multiple-outline:before {
  content: "\F3A2";
}

.mdi-numeric-0-box-outline:before {
  content: "\F3A3";
}

.mdi-numeric-0-circle:before {
  content: "\FC7A";
}

.mdi-numeric-0-circle-outline:before {
  content: "\FC7B";
}

.mdi-numeric-1:before {
  content: "\31";
}

.mdi-numeric-1-box:before {
  content: "\F3A4";
}

.mdi-numeric-1-box-multiple-outline:before {
  content: "\F3A5";
}

.mdi-numeric-1-box-outline:before {
  content: "\F3A6";
}

.mdi-numeric-1-circle:before {
  content: "\FC7C";
}

.mdi-numeric-1-circle-outline:before {
  content: "\FC7D";
}

.mdi-numeric-2:before {
  content: "\32";
}

.mdi-numeric-2-box:before {
  content: "\F3A7";
}

.mdi-numeric-2-box-multiple-outline:before {
  content: "\F3A8";
}

.mdi-numeric-2-box-outline:before {
  content: "\F3A9";
}

.mdi-numeric-2-circle:before {
  content: "\FC7E";
}

.mdi-numeric-2-circle-outline:before {
  content: "\FC7F";
}

.mdi-numeric-3:before {
  content: "\33";
}

.mdi-numeric-3-box:before {
  content: "\F3AA";
}

.mdi-numeric-3-box-multiple-outline:before {
  content: "\F3AB";
}

.mdi-numeric-3-box-outline:before {
  content: "\F3AC";
}

.mdi-numeric-3-circle:before {
  content: "\FC80";
}

.mdi-numeric-3-circle-outline:before {
  content: "\FC81";
}

.mdi-numeric-4:before {
  content: "\34";
}

.mdi-numeric-4-box:before {
  content: "\F3AD";
}

.mdi-numeric-4-box-multiple-outline:before {
  content: "\F3AE";
}

.mdi-numeric-4-box-outline:before {
  content: "\F3AF";
}

.mdi-numeric-4-circle:before {
  content: "\FC82";
}

.mdi-numeric-4-circle-outline:before {
  content: "\FC83";
}

.mdi-numeric-5:before {
  content: "\35";
}

.mdi-numeric-5-box:before {
  content: "\F3B0";
}

.mdi-numeric-5-box-multiple-outline:before {
  content: "\F3B1";
}

.mdi-numeric-5-box-outline:before {
  content: "\F3B2";
}

.mdi-numeric-5-circle:before {
  content: "\FC84";
}

.mdi-numeric-5-circle-outline:before {
  content: "\FC85";
}

.mdi-numeric-6:before {
  content: "\36";
}

.mdi-numeric-6-box:before {
  content: "\F3B3";
}

.mdi-numeric-6-box-multiple-outline:before {
  content: "\F3B4";
}

.mdi-numeric-6-box-outline:before {
  content: "\F3B5";
}

.mdi-numeric-6-circle:before {
  content: "\FC86";
}

.mdi-numeric-6-circle-outline:before {
  content: "\FC87";
}

.mdi-numeric-7:before {
  content: "\37";
}

.mdi-numeric-7-box:before {
  content: "\F3B6";
}

.mdi-numeric-7-box-multiple-outline:before {
  content: "\F3B7";
}

.mdi-numeric-7-box-outline:before {
  content: "\F3B8";
}

.mdi-numeric-7-circle:before {
  content: "\FC88";
}

.mdi-numeric-7-circle-outline:before {
  content: "\FC89";
}

.mdi-numeric-8:before {
  content: "\38";
}

.mdi-numeric-8-box:before {
  content: "\F3B9";
}

.mdi-numeric-8-box-multiple-outline:before {
  content: "\F3BA";
}

.mdi-numeric-8-box-outline:before {
  content: "\F3BB";
}

.mdi-numeric-8-circle:before {
  content: "\FC8A";
}

.mdi-numeric-8-circle-outline:before {
  content: "\FC8B";
}

.mdi-numeric-9:before {
  content: "\39";
}

.mdi-numeric-9-box:before {
  content: "\F3BC";
}

.mdi-numeric-9-box-multiple-outline:before {
  content: "\F3BD";
}

.mdi-numeric-9-box-outline:before {
  content: "\F3BE";
}

.mdi-numeric-9-circle:before {
  content: "\FC8C";
}

.mdi-numeric-9-circle-outline:before {
  content: "\FC8D";
}

.mdi-numeric-9-plus-box:before {
  content: "\F3BF";
}

.mdi-numeric-9-plus-box-multiple-outline:before {
  content: "\F3C0";
}

.mdi-numeric-9-plus-box-outline:before {
  content: "\F3C1";
}

.mdi-numeric-9-plus-circle:before {
  content: "\FC8E";
}

.mdi-numeric-9-plus-circle-outline:before {
  content: "\FC8F";
}

.mdi-nut:before {
  content: "\F6F7";
}

.mdi-nutrition:before {
  content: "\F3C2";
}

.mdi-oar:before {
  content: "\F67B";
}

.mdi-octagon:before {
  content: "\F3C3";
}

.mdi-octagon-outline:before {
  content: "\F3C4";
}

.mdi-octagram:before {
  content: "\F6F8";
}

.mdi-octagram-outline:before {
  content: "\F774";
}

.mdi-odnoklassniki:before {
  content: "\F3C5";
}

.mdi-office:before {
  content: "\F3C6";
}

.mdi-office-building:before {
  content: "\F990";
}

.mdi-oil:before {
  content: "\F3C7";
}

.mdi-oil-temperature:before {
  content: "\F3C8";
}

.mdi-omega:before {
  content: "\F3C9";
}

.mdi-one-up:before {
  content: "\FB89";
}

.mdi-onedrive:before {
  content: "\F3CA";
}

.mdi-onenote:before {
  content: "\F746";
}

.mdi-onepassword:before {
  content: "\F880";
}

.mdi-opacity:before {
  content: "\F5CC";
}

.mdi-open-in-app:before {
  content: "\F3CB";
}

.mdi-open-in-new:before {
  content: "\F3CC";
}

.mdi-open-source-initiative:before {
  content: "\FB8A";
}

.mdi-openid:before {
  content: "\F3CD";
}

.mdi-opera:before {
  content: "\F3CE";
}

.mdi-orbit:before {
  content: "\F018";
}

.mdi-origin:before {
  content: "\FB2B";
}

.mdi-ornament:before {
  content: "\F3CF";
}

.mdi-ornament-variant:before {
  content: "\F3D0";
}

.mdi-owl:before {
  content: "\F3D2";
}

.mdi-pac-man:before {
  content: "\FB8B";
}

.mdi-package:before {
  content: "\F3D3";
}

.mdi-package-down:before {
  content: "\F3D4";
}

.mdi-package-up:before {
  content: "\F3D5";
}

.mdi-package-variant:before {
  content: "\F3D6";
}

.mdi-package-variant-closed:before {
  content: "\F3D7";
}

.mdi-page-first:before {
  content: "\F600";
}

.mdi-page-last:before {
  content: "\F601";
}

.mdi-page-layout-body:before {
  content: "\F6F9";
}

.mdi-page-layout-footer:before {
  content: "\F6FA";
}

.mdi-page-layout-header:before {
  content: "\F6FB";
}

.mdi-page-layout-sidebar-left:before {
  content: "\F6FC";
}

.mdi-page-layout-sidebar-right:before {
  content: "\F6FD";
}

.mdi-page-next:before {
  content: "\FB8C";
}

.mdi-page-next-outline:before {
  content: "\FB8D";
}

.mdi-page-previous:before {
  content: "\FB8E";
}

.mdi-page-previous-outline:before {
  content: "\FB8F";
}

.mdi-palette:before {
  content: "\F3D8";
}

.mdi-palette-advanced:before {
  content: "\F3D9";
}

.mdi-palette-swatch:before {
  content: "\F8B4";
}

.mdi-pan:before {
  content: "\FB90";
}

.mdi-pan-bottom-left:before {
  content: "\FB91";
}

.mdi-pan-bottom-right:before {
  content: "\FB92";
}

.mdi-pan-down:before {
  content: "\FB93";
}

.mdi-pan-horizontal:before {
  content: "\FB94";
}

.mdi-pan-left:before {
  content: "\FB95";
}

.mdi-pan-right:before {
  content: "\FB96";
}

.mdi-pan-top-left:before {
  content: "\FB97";
}

.mdi-pan-top-right:before {
  content: "\FB98";
}

.mdi-pan-up:before {
  content: "\FB99";
}

.mdi-pan-vertical:before {
  content: "\FB9A";
}

.mdi-panda:before {
  content: "\F3DA";
}

.mdi-pandora:before {
  content: "\F3DB";
}

.mdi-panorama:before {
  content: "\F3DC";
}

.mdi-panorama-fisheye:before {
  content: "\F3DD";
}

.mdi-panorama-horizontal:before {
  content: "\F3DE";
}

.mdi-panorama-vertical:before {
  content: "\F3DF";
}

.mdi-panorama-wide-angle:before {
  content: "\F3E0";
}

.mdi-paper-cut-vertical:before {
  content: "\F3E1";
}

.mdi-paperclip:before {
  content: "\F3E2";
}

.mdi-parachute:before {
  content: "\FC90";
}

.mdi-parachute-outline:before {
  content: "\FC91";
}

.mdi-parking:before {
  content: "\F3E3";
}

.mdi-passport:before {
  content: "\F7E2";
}

.mdi-patreon:before {
  content: "\F881";
}

.mdi-pause:before {
  content: "\F3E4";
}

.mdi-pause-circle:before {
  content: "\F3E5";
}

.mdi-pause-circle-outline:before {
  content: "\F3E6";
}

.mdi-pause-octagon:before {
  content: "\F3E7";
}

.mdi-pause-octagon-outline:before {
  content: "\F3E8";
}

.mdi-paw:before {
  content: "\F3E9";
}

.mdi-paw-off:before {
  content: "\F657";
}

.mdi-paypal:before {
  content: "\F882";
}

.mdi-peace:before {
  content: "\F883";
}

.mdi-pen:before {
  content: "\F3EA";
}

.mdi-pencil:before {
  content: "\F3EB";
}

.mdi-pencil-box:before {
  content: "\F3EC";
}

.mdi-pencil-box-outline:before {
  content: "\F3ED";
}

.mdi-pencil-circle:before {
  content: "\F6FE";
}

.mdi-pencil-circle-outline:before {
  content: "\F775";
}

.mdi-pencil-lock:before {
  content: "\F3EE";
}

.mdi-pencil-off:before {
  content: "\F3EF";
}

.mdi-pencil-outline:before {
  content: "\FC92";
}

.mdi-pentagon:before {
  content: "\F6FF";
}

.mdi-pentagon-outline:before {
  content: "\F700";
}

.mdi-percent:before {
  content: "\F3F0";
}

.mdi-periodic-table:before {
  content: "\F8B5";
}

.mdi-periodic-table-co2:before {
  content: "\F7E3";
}

.mdi-periscope:before {
  content: "\F747";
}

.mdi-pharmacy:before {
  content: "\F3F1";
}

.mdi-phone:before {
  content: "\F3F2";
}

.mdi-phone-bluetooth:before {
  content: "\F3F3";
}

.mdi-phone-classic:before {
  content: "\F602";
}

.mdi-phone-forward:before {
  content: "\F3F4";
}

.mdi-phone-hangup:before {
  content: "\F3F5";
}

.mdi-phone-in-talk:before {
  content: "\F3F6";
}

.mdi-phone-incoming:before {
  content: "\F3F7";
}

.mdi-phone-lock:before {
  content: "\F3F8";
}

.mdi-phone-log:before {
  content: "\F3F9";
}

.mdi-phone-minus:before {
  content: "\F658";
}

.mdi-phone-missed:before {
  content: "\F3FA";
}

.mdi-phone-outgoing:before {
  content: "\F3FB";
}

.mdi-phone-paused:before {
  content: "\F3FC";
}

.mdi-phone-plus:before {
  content: "\F659";
}

.mdi-phone-return:before {
  content: "\F82E";
}

.mdi-phone-rotate-landscape:before {
  content: "\F884";
}

.mdi-phone-rotate-portrait:before {
  content: "\F885";
}

.mdi-phone-settings:before {
  content: "\F3FD";
}

.mdi-phone-voip:before {
  content: "\F3FE";
}

.mdi-pi:before {
  content: "\F3FF";
}

.mdi-pi-box:before {
  content: "\F400";
}

.mdi-piano:before {
  content: "\F67C";
}

.mdi-pickaxe:before {
  content: "\F8B6";
}

.mdi-pier:before {
  content: "\F886";
}

.mdi-pier-crane:before {
  content: "\F887";
}

.mdi-pig:before {
  content: "\F401";
}

.mdi-pill:before {
  content: "\F402";
}

.mdi-pillar:before {
  content: "\F701";
}

.mdi-pin:before {
  content: "\F403";
}

.mdi-pin-off:before {
  content: "\F404";
}

.mdi-pin-off-outline:before {
  content: "\F92F";
}

.mdi-pin-outline:before {
  content: "\F930";
}

.mdi-pine-tree:before {
  content: "\F405";
}

.mdi-pine-tree-box:before {
  content: "\F406";
}

.mdi-pinterest:before {
  content: "\F407";
}

.mdi-pinterest-box:before {
  content: "\F408";
}

.mdi-pinwheel:before {
  content: "\FAD4";
}

.mdi-pinwheel-outline:before {
  content: "\FAD5";
}

.mdi-pipe:before {
  content: "\F7E4";
}

.mdi-pipe-disconnected:before {
  content: "\F7E5";
}

.mdi-pipe-leak:before {
  content: "\F888";
}

.mdi-pirate:before {
  content: "\FA07";
}

.mdi-pistol:before {
  content: "\F702";
}

.mdi-piston:before {
  content: "\F889";
}

.mdi-pizza:before {
  content: "\F409";
}

.mdi-play:before {
  content: "\F40A";
}

.mdi-play-box-outline:before {
  content: "\F40B";
}

.mdi-play-circle:before {
  content: "\F40C";
}

.mdi-play-circle-outline:before {
  content: "\F40D";
}

.mdi-play-network:before {
  content: "\F88A";
}

.mdi-play-network-outline:before {
  content: "\FC93";
}

.mdi-play-pause:before {
  content: "\F40E";
}

.mdi-play-protected-content:before {
  content: "\F40F";
}

.mdi-play-speed:before {
  content: "\F8FE";
}

.mdi-playlist-check:before {
  content: "\F5C7";
}

.mdi-playlist-edit:before {
  content: "\F8FF";
}

.mdi-playlist-minus:before {
  content: "\F410";
}

.mdi-playlist-music:before {
  content: "\FC94";
}

.mdi-playlist-music-outline:before {
  content: "\FC95";
}

.mdi-playlist-play:before {
  content: "\F411";
}

.mdi-playlist-plus:before {
  content: "\F412";
}

.mdi-playlist-remove:before {
  content: "\F413";
}

.mdi-playstation:before {
  content: "\F414";
}

.mdi-plex:before {
  content: "\F6B9";
}

.mdi-plus:before {
  content: "\F415";
}

.mdi-plus-box:before {
  content: "\F416";
}

.mdi-plus-box-outline:before {
  content: "\F703";
}

.mdi-plus-circle:before {
  content: "\F417";
}

.mdi-plus-circle-multiple-outline:before {
  content: "\F418";
}

.mdi-plus-circle-outline:before {
  content: "\F419";
}

.mdi-plus-minus:before {
  content: "\F991";
}

.mdi-plus-minus-box:before {
  content: "\F992";
}

.mdi-plus-network:before {
  content: "\F41A";
}

.mdi-plus-network-outline:before {
  content: "\FC96";
}

.mdi-plus-one:before {
  content: "\F41B";
}

.mdi-plus-outline:before {
  content: "\F704";
}

.mdi-pocket:before {
  content: "\F41C";
}

.mdi-podcast:before {
  content: "\F993";
}

.mdi-pokeball:before {
  content: "\F41D";
}

.mdi-pokemon-go:before {
  content: "\FA08";
}

.mdi-poker-chip:before {
  content: "\F82F";
}

.mdi-polaroid:before {
  content: "\F41E";
}

.mdi-poll:before {
  content: "\F41F";
}

.mdi-poll-box:before {
  content: "\F420";
}

.mdi-polymer:before {
  content: "\F421";
}

.mdi-pool:before {
  content: "\F606";
}

.mdi-popcorn:before {
  content: "\F422";
}

.mdi-postage-stamp:before {
  content: "\FC97";
}

.mdi-pot:before {
  content: "\F65A";
}

.mdi-pot-mix:before {
  content: "\F65B";
}

.mdi-pound:before {
  content: "\F423";
}

.mdi-pound-box:before {
  content: "\F424";
}

.mdi-power:before {
  content: "\F425";
}

.mdi-power-cycle:before {
  content: "\F900";
}

.mdi-power-off:before {
  content: "\F901";
}

.mdi-power-on:before {
  content: "\F902";
}

.mdi-power-plug:before {
  content: "\F6A4";
}

.mdi-power-plug-off:before {
  content: "\F6A5";
}

.mdi-power-settings:before {
  content: "\F426";
}

.mdi-power-sleep:before {
  content: "\F903";
}

.mdi-power-socket:before {
  content: "\F427";
}

.mdi-power-socket-au:before {
  content: "\F904";
}

.mdi-power-socket-eu:before {
  content: "\F7E6";
}

.mdi-power-socket-uk:before {
  content: "\F7E7";
}

.mdi-power-socket-us:before {
  content: "\F7E8";
}

.mdi-power-standby:before {
  content: "\F905";
}

.mdi-powershell:before {
  content: "\FA09";
}

.mdi-prescription:before {
  content: "\F705";
}

.mdi-presentation:before {
  content: "\F428";
}

.mdi-presentation-play:before {
  content: "\F429";
}

.mdi-printer:before {
  content: "\F42A";
}

.mdi-printer-3d:before {
  content: "\F42B";
}

.mdi-printer-alert:before {
  content: "\F42C";
}

.mdi-printer-settings:before {
  content: "\F706";
}

.mdi-printer-wireless:before {
  content: "\FA0A";
}

.mdi-priority-high:before {
  content: "\F603";
}

.mdi-priority-low:before {
  content: "\F604";
}

.mdi-professional-hexagon:before {
  content: "\F42D";
}

.mdi-progress-alert:before {
  content: "\FC98";
}

.mdi-progress-check:before {
  content: "\F994";
}

.mdi-progress-clock:before {
  content: "\F995";
}

.mdi-progress-download:before {
  content: "\F996";
}

.mdi-progress-upload:before {
  content: "\F997";
}

.mdi-progress-wrench:before {
  content: "\FC99";
}

.mdi-projector:before {
  content: "\F42E";
}

.mdi-projector-screen:before {
  content: "\F42F";
}

.mdi-publish:before {
  content: "\F6A6";
}

.mdi-pulse:before {
  content: "\F430";
}

.mdi-pumpkin:before {
  content: "\FB9B";
}

.mdi-puzzle:before {
  content: "\F431";
}

.mdi-puzzle-outline:before {
  content: "\FA65";
}

.mdi-qi:before {
  content: "\F998";
}

.mdi-qqchat:before {
  content: "\F605";
}

.mdi-qrcode:before {
  content: "\F432";
}

.mdi-qrcode-edit:before {
  content: "\F8B7";
}

.mdi-qrcode-scan:before {
  content: "\F433";
}

.mdi-quadcopter:before {
  content: "\F434";
}

.mdi-quality-high:before {
  content: "\F435";
}

.mdi-quality-low:before {
  content: "\FA0B";
}

.mdi-quality-medium:before {
  content: "\FA0C";
}

.mdi-quicktime:before {
  content: "\F436";
}

.mdi-rabbit:before {
  content: "\F906";
}

.mdi-radar:before {
  content: "\F437";
}

.mdi-radiator:before {
  content: "\F438";
}

.mdi-radiator-disabled:before {
  content: "\FAD6";
}

.mdi-radiator-off:before {
  content: "\FAD7";
}

.mdi-radio:before {
  content: "\F439";
}

.mdi-radio-am:before {
  content: "\FC9A";
}

.mdi-radio-fm:before {
  content: "\FC9B";
}

.mdi-radio-handheld:before {
  content: "\F43A";
}

.mdi-radio-tower:before {
  content: "\F43B";
}

.mdi-radioactive:before {
  content: "\F43C";
}

.mdi-radiobox-blank:before {
  content: "\F43D";
}

.mdi-radiobox-marked:before {
  content: "\F43E";
}

.mdi-radius:before {
  content: "\FC9C";
}

.mdi-radius-outline:before {
  content: "\FC9D";
}

.mdi-raspberrypi:before {
  content: "\F43F";
}

.mdi-ray-end:before {
  content: "\F440";
}

.mdi-ray-end-arrow:before {
  content: "\F441";
}

.mdi-ray-start:before {
  content: "\F442";
}

.mdi-ray-start-arrow:before {
  content: "\F443";
}

.mdi-ray-start-end:before {
  content: "\F444";
}

.mdi-ray-vertex:before {
  content: "\F445";
}

.mdi-react:before {
  content: "\F707";
}

.mdi-read:before {
  content: "\F447";
}

.mdi-receipt:before {
  content: "\F449";
}

.mdi-record:before {
  content: "\F44A";
}

.mdi-record-player:before {
  content: "\F999";
}

.mdi-record-rec:before {
  content: "\F44B";
}

.mdi-recycle:before {
  content: "\F44C";
}

.mdi-reddit:before {
  content: "\F44D";
}

.mdi-redo:before {
  content: "\F44E";
}

.mdi-redo-variant:before {
  content: "\F44F";
}

.mdi-reflect-horizontal:before {
  content: "\FA0D";
}

.mdi-reflect-vertical:before {
  content: "\FA0E";
}

.mdi-refresh:before {
  content: "\F450";
}

.mdi-regex:before {
  content: "\F451";
}

.mdi-registered-trademark:before {
  content: "\FA66";
}

.mdi-relative-scale:before {
  content: "\F452";
}

.mdi-reload:before {
  content: "\F453";
}

.mdi-reminder:before {
  content: "\F88B";
}

.mdi-remote:before {
  content: "\F454";
}

.mdi-remote-desktop:before {
  content: "\F8B8";
}

.mdi-rename-box:before {
  content: "\F455";
}

.mdi-reorder-horizontal:before {
  content: "\F687";
}

.mdi-reorder-vertical:before {
  content: "\F688";
}

.mdi-repeat:before {
  content: "\F456";
}

.mdi-repeat-off:before {
  content: "\F457";
}

.mdi-repeat-once:before {
  content: "\F458";
}

.mdi-replay:before {
  content: "\F459";
}

.mdi-reply:before {
  content: "\F45A";
}

.mdi-reply-all:before {
  content: "\F45B";
}

.mdi-reproduction:before {
  content: "\F45C";
}

.mdi-resistor:before {
  content: "\FB1F";
}

.mdi-resistor-nodes:before {
  content: "\FB20";
}

.mdi-resize:before {
  content: "\FA67";
}

.mdi-resize-bottom-right:before {
  content: "\F45D";
}

.mdi-responsive:before {
  content: "\F45E";
}

.mdi-restart:before {
  content: "\F708";
}

.mdi-restore:before {
  content: "\F99A";
}

.mdi-restore-clock:before {
  content: "\F6A7";
}

.mdi-rewind:before {
  content: "\F45F";
}

.mdi-rewind-outline:before {
  content: "\F709";
}

.mdi-rhombus:before {
  content: "\F70A";
}

.mdi-rhombus-medium:before {
  content: "\FA0F";
}

.mdi-rhombus-outline:before {
  content: "\F70B";
}

.mdi-rhombus-split:before {
  content: "\FA10";
}

.mdi-ribbon:before {
  content: "\F460";
}

.mdi-rice:before {
  content: "\F7E9";
}

.mdi-ring:before {
  content: "\F7EA";
}

.mdi-road:before {
  content: "\F461";
}

.mdi-road-variant:before {
  content: "\F462";
}

.mdi-robot:before {
  content: "\F6A8";
}

.mdi-robot-industrial:before {
  content: "\FB21";
}

.mdi-robot-vacuum:before {
  content: "\F70C";
}

.mdi-robot-vacuum-variant:before {
  content: "\F907";
}

.mdi-rocket:before {
  content: "\F463";
}

.mdi-rollupjs:before {
  content: "\FB9C";
}

.mdi-room-service:before {
  content: "\F88C";
}

.mdi-rotate-3d:before {
  content: "\F464";
}

.mdi-rotate-left:before {
  content: "\F465";
}

.mdi-rotate-left-variant:before {
  content: "\F466";
}

.mdi-rotate-right:before {
  content: "\F467";
}

.mdi-rotate-right-variant:before {
  content: "\F468";
}

.mdi-rounded-corner:before {
  content: "\F607";
}

.mdi-router-wireless:before {
  content: "\F469";
}

.mdi-router-wireless-settings:before {
  content: "\FA68";
}

.mdi-routes:before {
  content: "\F46A";
}

.mdi-rowing:before {
  content: "\F608";
}

.mdi-rss:before {
  content: "\F46B";
}

.mdi-rss-box:before {
  content: "\F46C";
}

.mdi-ruler:before {
  content: "\F46D";
}

.mdi-ruler-square:before {
  content: "\FC9E";
}

.mdi-run:before {
  content: "\F70D";
}

.mdi-run-fast:before {
  content: "\F46E";
}

.mdi-safe:before {
  content: "\FA69";
}

.mdi-sale:before {
  content: "\F46F";
}

.mdi-salesforce:before {
  content: "\F88D";
}

.mdi-sass:before {
  content: "\F7EB";
}

.mdi-satellite:before {
  content: "\F470";
}

.mdi-satellite-uplink:before {
  content: "\F908";
}

.mdi-satellite-variant:before {
  content: "\F471";
}

.mdi-sausage:before {
  content: "\F8B9";
}

.mdi-saxophone:before {
  content: "\F609";
}

.mdi-scale:before {
  content: "\F472";
}

.mdi-scale-balance:before {
  content: "\F5D1";
}

.mdi-scale-bathroom:before {
  content: "\F473";
}

.mdi-scanner:before {
  content: "\F6AA";
}

.mdi-scanner-off:before {
  content: "\F909";
}

.mdi-school:before {
  content: "\F474";
}

.mdi-scissors-cutting:before {
  content: "\FA6A";
}

.mdi-screen-rotation:before {
  content: "\F475";
}

.mdi-screen-rotation-lock:before {
  content: "\F476";
}

.mdi-screwdriver:before {
  content: "\F477";
}

.mdi-script:before {
  content: "\FB9D";
}

.mdi-script-outline:before {
  content: "\F478";
}

.mdi-script-text:before {
  content: "\FB9E";
}

.mdi-script-text-outline:before {
  content: "\FB9F";
}

.mdi-sd:before {
  content: "\F479";
}

.mdi-seal:before {
  content: "\F47A";
}

.mdi-search-web:before {
  content: "\F70E";
}

.mdi-seat:before {
  content: "\FC9F";
}

.mdi-seat-flat:before {
  content: "\F47B";
}

.mdi-seat-flat-angled:before {
  content: "\F47C";
}

.mdi-seat-individual-suite:before {
  content: "\F47D";
}

.mdi-seat-legroom-extra:before {
  content: "\F47E";
}

.mdi-seat-legroom-normal:before {
  content: "\F47F";
}

.mdi-seat-legroom-reduced:before {
  content: "\F480";
}

.mdi-seat-outline:before {
  content: "\FCA0";
}

.mdi-seat-recline-extra:before {
  content: "\F481";
}

.mdi-seat-recline-normal:before {
  content: "\F482";
}

.mdi-seatbelt:before {
  content: "\FCA1";
}

.mdi-security:before {
  content: "\F483";
}

.mdi-security-network:before {
  content: "\F484";
}

.mdi-select:before {
  content: "\F485";
}

.mdi-select-all:before {
  content: "\F486";
}

.mdi-select-compare:before {
  content: "\FAD8";
}

.mdi-select-drag:before {
  content: "\FA6B";
}

.mdi-select-inverse:before {
  content: "\F487";
}

.mdi-select-off:before {
  content: "\F488";
}

.mdi-selection:before {
  content: "\F489";
}

.mdi-selection-drag:before {
  content: "\FA6C";
}

.mdi-selection-off:before {
  content: "\F776";
}

.mdi-send:before {
  content: "\F48A";
}

.mdi-send-lock:before {
  content: "\F7EC";
}

.mdi-serial-port:before {
  content: "\F65C";
}

.mdi-server:before {
  content: "\F48B";
}

.mdi-server-minus:before {
  content: "\F48C";
}

.mdi-server-network:before {
  content: "\F48D";
}

.mdi-server-network-off:before {
  content: "\F48E";
}

.mdi-server-off:before {
  content: "\F48F";
}

.mdi-server-plus:before {
  content: "\F490";
}

.mdi-server-remove:before {
  content: "\F491";
}

.mdi-server-security:before {
  content: "\F492";
}

.mdi-set-all:before {
  content: "\F777";
}

.mdi-set-center:before {
  content: "\F778";
}

.mdi-set-center-right:before {
  content: "\F779";
}

.mdi-set-left:before {
  content: "\F77A";
}

.mdi-set-left-center:before {
  content: "\F77B";
}

.mdi-set-left-right:before {
  content: "\F77C";
}

.mdi-set-none:before {
  content: "\F77D";
}

.mdi-set-right:before {
  content: "\F77E";
}

.mdi-set-top-box:before {
  content: "\F99E";
}

.mdi-settings:before {
  content: "\F493";
}

.mdi-settings-box:before {
  content: "\F494";
}

.mdi-settings-helper:before {
  content: "\FA6D";
}

.mdi-settings-outline:before {
  content: "\F8BA";
}

.mdi-shape:before {
  content: "\F830";
}

.mdi-shape-circle-plus:before {
  content: "\F65D";
}

.mdi-shape-outline:before {
  content: "\F831";
}

.mdi-shape-plus:before {
  content: "\F495";
}

.mdi-shape-polygon-plus:before {
  content: "\F65E";
}

.mdi-shape-rectangle-plus:before {
  content: "\F65F";
}

.mdi-shape-square-plus:before {
  content: "\F660";
}

.mdi-share:before {
  content: "\F496";
}

.mdi-share-outline:before {
  content: "\F931";
}

.mdi-share-variant:before {
  content: "\F497";
}

.mdi-sheep:before {
  content: "\FCA2";
}

.mdi-shield:before {
  content: "\F498";
}

.mdi-shield-account:before {
  content: "\F88E";
}

.mdi-shield-account-outline:before {
  content: "\FA11";
}

.mdi-shield-airplane:before {
  content: "\F6BA";
}

.mdi-shield-airplane-outline:before {
  content: "\FCA3";
}

.mdi-shield-check:before {
  content: "\F565";
}

.mdi-shield-check-outline:before {
  content: "\FCA4";
}

.mdi-shield-cross:before {
  content: "\FCA5";
}

.mdi-shield-cross-outline:before {
  content: "\FCA6";
}

.mdi-shield-half-full:before {
  content: "\F77F";
}

.mdi-shield-home:before {
  content: "\F689";
}

.mdi-shield-home-outline:before {
  content: "\FCA7";
}

.mdi-shield-key:before {
  content: "\FBA0";
}

.mdi-shield-key-outline:before {
  content: "\FBA1";
}

.mdi-shield-lock:before {
  content: "\F99C";
}

.mdi-shield-lock-outline:before {
  content: "\FCA8";
}

.mdi-shield-off:before {
  content: "\F99D";
}

.mdi-shield-off-outline:before {
  content: "\F99B";
}

.mdi-shield-outline:before {
  content: "\F499";
}

.mdi-shield-plus:before {
  content: "\FAD9";
}

.mdi-shield-plus-outline:before {
  content: "\FADA";
}

.mdi-shield-remove:before {
  content: "\FADB";
}

.mdi-shield-remove-outline:before {
  content: "\FADC";
}

.mdi-ship-wheel:before {
  content: "\F832";
}

.mdi-shoe-formal:before {
  content: "\FB22";
}

.mdi-shoe-heel:before {
  content: "\FB23";
}

.mdi-shopify:before {
  content: "\FADD";
}

.mdi-shopping:before {
  content: "\F49A";
}

.mdi-shopping-music:before {
  content: "\F49B";
}

.mdi-shovel:before {
  content: "\F70F";
}

.mdi-shovel-off:before {
  content: "\F710";
}

.mdi-shower:before {
  content: "\F99F";
}

.mdi-shower-head:before {
  content: "\F9A0";
}

.mdi-shredder:before {
  content: "\F49C";
}

.mdi-shuffle:before {
  content: "\F49D";
}

.mdi-shuffle-disabled:before {
  content: "\F49E";
}

.mdi-shuffle-variant:before {
  content: "\F49F";
}

.mdi-sigma:before {
  content: "\F4A0";
}

.mdi-sigma-lower:before {
  content: "\F62B";
}

.mdi-sign-caution:before {
  content: "\F4A1";
}

.mdi-sign-direction:before {
  content: "\F780";
}

.mdi-sign-text:before {
  content: "\F781";
}

.mdi-signal:before {
  content: "\F4A2";
}

.mdi-signal-2g:before {
  content: "\F711";
}

.mdi-signal-3g:before {
  content: "\F712";
}

.mdi-signal-4g:before {
  content: "\F713";
}

.mdi-signal-5g:before {
  content: "\FA6E";
}

.mdi-signal-cellular-1:before {
  content: "\F8BB";
}

.mdi-signal-cellular-2:before {
  content: "\F8BC";
}

.mdi-signal-cellular-3:before {
  content: "\F8BD";
}

.mdi-signal-cellular-outline:before {
  content: "\F8BE";
}

.mdi-signal-hspa:before {
  content: "\F714";
}

.mdi-signal-hspa-plus:before {
  content: "\F715";
}

.mdi-signal-off:before {
  content: "\F782";
}

.mdi-signal-variant:before {
  content: "\F60A";
}

.mdi-silo:before {
  content: "\FB24";
}

.mdi-silverware:before {
  content: "\F4A3";
}

.mdi-silverware-fork:before {
  content: "\F4A4";
}

.mdi-silverware-fork-knife:before {
  content: "\FA6F";
}

.mdi-silverware-spoon:before {
  content: "\F4A5";
}

.mdi-silverware-variant:before {
  content: "\F4A6";
}

.mdi-sim:before {
  content: "\F4A7";
}

.mdi-sim-alert:before {
  content: "\F4A8";
}

.mdi-sim-off:before {
  content: "\F4A9";
}

.mdi-sina-weibo:before {
  content: "\FADE";
}

.mdi-sitemap:before {
  content: "\F4AA";
}

.mdi-skip-backward:before {
  content: "\F4AB";
}

.mdi-skip-forward:before {
  content: "\F4AC";
}

.mdi-skip-next:before {
  content: "\F4AD";
}

.mdi-skip-next-circle:before {
  content: "\F661";
}

.mdi-skip-next-circle-outline:before {
  content: "\F662";
}

.mdi-skip-previous:before {
  content: "\F4AE";
}

.mdi-skip-previous-circle:before {
  content: "\F663";
}

.mdi-skip-previous-circle-outline:before {
  content: "\F664";
}

.mdi-skull:before {
  content: "\F68B";
}

.mdi-skull-crossbones:before {
  content: "\FBA2";
}

.mdi-skull-crossbones-outline:before {
  content: "\FBA3";
}

.mdi-skull-outline:before {
  content: "\FBA4";
}

.mdi-skype:before {
  content: "\F4AF";
}

.mdi-skype-business:before {
  content: "\F4B0";
}

.mdi-slack:before {
  content: "\F4B1";
}

.mdi-slackware:before {
  content: "\F90A";
}

.mdi-sleep:before {
  content: "\F4B2";
}

.mdi-sleep-off:before {
  content: "\F4B3";
}

.mdi-smog:before {
  content: "\FA70";
}

.mdi-smoke-detector:before {
  content: "\F392";
}

.mdi-smoking:before {
  content: "\F4B4";
}

.mdi-smoking-off:before {
  content: "\F4B5";
}

.mdi-snapchat:before {
  content: "\F4B6";
}

.mdi-snowflake:before {
  content: "\F716";
}

.mdi-snowman:before {
  content: "\F4B7";
}

.mdi-soccer:before {
  content: "\F4B8";
}

.mdi-soccer-field:before {
  content: "\F833";
}

.mdi-sofa:before {
  content: "\F4B9";
}

.mdi-solar-power:before {
  content: "\FA71";
}

.mdi-solid:before {
  content: "\F68C";
}

.mdi-sort:before {
  content: "\F4BA";
}

.mdi-sort-alphabetical:before {
  content: "\F4BB";
}

.mdi-sort-ascending:before {
  content: "\F4BC";
}

.mdi-sort-descending:before {
  content: "\F4BD";
}

.mdi-sort-numeric:before {
  content: "\F4BE";
}

.mdi-sort-variant:before {
  content: "\F4BF";
}

.mdi-sort-variant-lock:before {
  content: "\FCA9";
}

.mdi-sort-variant-lock-open:before {
  content: "\FCAA";
}

.mdi-soundcloud:before {
  content: "\F4C0";
}

.mdi-source-branch:before {
  content: "\F62C";
}

.mdi-source-commit:before {
  content: "\F717";
}

.mdi-source-commit-end:before {
  content: "\F718";
}

.mdi-source-commit-end-local:before {
  content: "\F719";
}

.mdi-source-commit-local:before {
  content: "\F71A";
}

.mdi-source-commit-next-local:before {
  content: "\F71B";
}

.mdi-source-commit-start:before {
  content: "\F71C";
}

.mdi-source-commit-start-next-local:before {
  content: "\F71D";
}

.mdi-source-fork:before {
  content: "\F4C1";
}

.mdi-source-merge:before {
  content: "\F62D";
}

.mdi-source-pull:before {
  content: "\F4C2";
}

.mdi-source-repository:before {
  content: "\FCAB";
}

.mdi-source-repository-multiple:before {
  content: "\FCAC";
}

.mdi-soy-sauce:before {
  content: "\F7ED";
}

.mdi-spa:before {
  content: "\FCAD";
}

.mdi-spa-outline:before {
  content: "\FCAE";
}

.mdi-space-invaders:before {
  content: "\FBA5";
}

.mdi-speaker:before {
  content: "\F4C3";
}

.mdi-speaker-bluetooth:before {
  content: "\F9A1";
}

.mdi-speaker-off:before {
  content: "\F4C4";
}

.mdi-speaker-wireless:before {
  content: "\F71E";
}

.mdi-speedometer:before {
  content: "\F4C5";
}

.mdi-spellcheck:before {
  content: "\F4C6";
}

.mdi-spider-web:before {
  content: "\FBA6";
}

.mdi-spotify:before {
  content: "\F4C7";
}

.mdi-spotlight:before {
  content: "\F4C8";
}

.mdi-spotlight-beam:before {
  content: "\F4C9";
}

.mdi-spray:before {
  content: "\F665";
}

.mdi-spray-bottle:before {
  content: "\FADF";
}

.mdi-square:before {
  content: "\F763";
}

.mdi-square-edit-outline:before {
  content: "\F90B";
}

.mdi-square-inc:before {
  content: "\F4CA";
}

.mdi-square-inc-cash:before {
  content: "\F4CB";
}

.mdi-square-medium:before {
  content: "\FA12";
}

.mdi-square-medium-outline:before {
  content: "\FA13";
}

.mdi-square-outline:before {
  content: "\F762";
}

.mdi-square-root:before {
  content: "\F783";
}

.mdi-square-root-box:before {
  content: "\F9A2";
}

.mdi-square-small:before {
  content: "\FA14";
}

.mdi-squeegee:before {
  content: "\FAE0";
}

.mdi-ssh:before {
  content: "\F8BF";
}

.mdi-stack-exchange:before {
  content: "\F60B";
}

.mdi-stack-overflow:before {
  content: "\F4CC";
}

.mdi-stadium:before {
  content: "\F71F";
}

.mdi-stairs:before {
  content: "\F4CD";
}

.mdi-standard-definition:before {
  content: "\F7EE";
}

.mdi-star:before {
  content: "\F4CE";
}

.mdi-star-box:before {
  content: "\FA72";
}

.mdi-star-box-outline:before {
  content: "\FA73";
}

.mdi-star-circle:before {
  content: "\F4CF";
}

.mdi-star-circle-outline:before {
  content: "\F9A3";
}

.mdi-star-face:before {
  content: "\F9A4";
}

.mdi-star-four-points:before {
  content: "\FAE1";
}

.mdi-star-four-points-outline:before {
  content: "\FAE2";
}

.mdi-star-half:before {
  content: "\F4D0";
}

.mdi-star-off:before {
  content: "\F4D1";
}

.mdi-star-outline:before {
  content: "\F4D2";
}

.mdi-star-three-points:before {
  content: "\FAE3";
}

.mdi-star-three-points-outline:before {
  content: "\FAE4";
}

.mdi-steam:before {
  content: "\F4D3";
}

.mdi-steam-box:before {
  content: "\F90C";
}

.mdi-steering:before {
  content: "\F4D4";
}

.mdi-steering-off:before {
  content: "\F90D";
}

.mdi-step-backward:before {
  content: "\F4D5";
}

.mdi-step-backward-2:before {
  content: "\F4D6";
}

.mdi-step-forward:before {
  content: "\F4D7";
}

.mdi-step-forward-2:before {
  content: "\F4D8";
}

.mdi-stethoscope:before {
  content: "\F4D9";
}

.mdi-sticker:before {
  content: "\F5D0";
}

.mdi-sticker-emoji:before {
  content: "\F784";
}

.mdi-stocking:before {
  content: "\F4DA";
}

.mdi-stop:before {
  content: "\F4DB";
}

.mdi-stop-circle:before {
  content: "\F666";
}

.mdi-stop-circle-outline:before {
  content: "\F667";
}

.mdi-store:before {
  content: "\F4DC";
}

.mdi-store-24-hour:before {
  content: "\F4DD";
}

.mdi-stove:before {
  content: "\F4DE";
}

.mdi-strava:before {
  content: "\FB25";
}

.mdi-subdirectory-arrow-left:before {
  content: "\F60C";
}

.mdi-subdirectory-arrow-right:before {
  content: "\F60D";
}

.mdi-subtitles:before {
  content: "\FA15";
}

.mdi-subtitles-outline:before {
  content: "\FA16";
}

.mdi-subway:before {
  content: "\F6AB";
}

.mdi-subway-variant:before {
  content: "\F4DF";
}

.mdi-summit:before {
  content: "\F785";
}

.mdi-sunglasses:before {
  content: "\F4E0";
}

.mdi-surround-sound:before {
  content: "\F5C5";
}

.mdi-surround-sound-2-0:before {
  content: "\F7EF";
}

.mdi-surround-sound-3-1:before {
  content: "\F7F0";
}

.mdi-surround-sound-5-1:before {
  content: "\F7F1";
}

.mdi-surround-sound-7-1:before {
  content: "\F7F2";
}

.mdi-svg:before {
  content: "\F720";
}

.mdi-swap-horizontal:before {
  content: "\F4E1";
}

.mdi-swap-horizontal-bold:before {
  content: "\FBA9";
}

.mdi-swap-horizontal-variant:before {
  content: "\F8C0";
}

.mdi-swap-vertical:before {
  content: "\F4E2";
}

.mdi-swap-vertical-bold:before {
  content: "\FBAA";
}

.mdi-swap-vertical-variant:before {
  content: "\F8C1";
}

.mdi-swim:before {
  content: "\F4E3";
}

.mdi-switch:before {
  content: "\F4E4";
}

.mdi-sword:before {
  content: "\F4E5";
}

.mdi-sword-cross:before {
  content: "\F786";
}

.mdi-symfony:before {
  content: "\FAE5";
}

.mdi-sync:before {
  content: "\F4E6";
}

.mdi-sync-alert:before {
  content: "\F4E7";
}

.mdi-sync-off:before {
  content: "\F4E8";
}

.mdi-tab:before {
  content: "\F4E9";
}

.mdi-tab-minus:before {
  content: "\FB26";
}

.mdi-tab-plus:before {
  content: "\F75B";
}

.mdi-tab-remove:before {
  content: "\FB27";
}

.mdi-tab-unselected:before {
  content: "\F4EA";
}

.mdi-table:before {
  content: "\F4EB";
}

.mdi-table-border:before {
  content: "\FA17";
}

.mdi-table-column:before {
  content: "\F834";
}

.mdi-table-column-plus-after:before {
  content: "\F4EC";
}

.mdi-table-column-plus-before:before {
  content: "\F4ED";
}

.mdi-table-column-remove:before {
  content: "\F4EE";
}

.mdi-table-column-width:before {
  content: "\F4EF";
}

.mdi-table-edit:before {
  content: "\F4F0";
}

.mdi-table-large:before {
  content: "\F4F1";
}

.mdi-table-merge-cells:before {
  content: "\F9A5";
}

.mdi-table-of-contents:before {
  content: "\F835";
}

.mdi-table-plus:before {
  content: "\FA74";
}

.mdi-table-remove:before {
  content: "\FA75";
}

.mdi-table-row:before {
  content: "\F836";
}

.mdi-table-row-height:before {
  content: "\F4F2";
}

.mdi-table-row-plus-after:before {
  content: "\F4F3";
}

.mdi-table-row-plus-before:before {
  content: "\F4F4";
}

.mdi-table-row-remove:before {
  content: "\F4F5";
}

.mdi-table-search:before {
  content: "\F90E";
}

.mdi-table-settings:before {
  content: "\F837";
}

.mdi-tablet:before {
  content: "\F4F6";
}

.mdi-tablet-android:before {
  content: "\F4F7";
}

.mdi-tablet-cellphone:before {
  content: "\F9A6";
}

.mdi-tablet-ipad:before {
  content: "\F4F8";
}

.mdi-taco:before {
  content: "\F761";
}

.mdi-tag:before {
  content: "\F4F9";
}

.mdi-tag-faces:before {
  content: "\F4FA";
}

.mdi-tag-heart:before {
  content: "\F68A";
}

.mdi-tag-heart-outline:before {
  content: "\FBAB";
}

.mdi-tag-minus:before {
  content: "\F90F";
}

.mdi-tag-multiple:before {
  content: "\F4FB";
}

.mdi-tag-outline:before {
  content: "\F4FC";
}

.mdi-tag-plus:before {
  content: "\F721";
}

.mdi-tag-remove:before {
  content: "\F722";
}

.mdi-tag-text-outline:before {
  content: "\F4FD";
}

.mdi-tape-measure:before {
  content: "\FB28";
}

.mdi-target:before {
  content: "\F4FE";
}

.mdi-target-account:before {
  content: "\FBAC";
}

.mdi-target-variant:before {
  content: "\FA76";
}

.mdi-taxi:before {
  content: "\F4FF";
}

.mdi-teach:before {
  content: "\F88F";
}

.mdi-teamviewer:before {
  content: "\F500";
}

.mdi-telegram:before {
  content: "\F501";
}

.mdi-telescope:before {
  content: "\FB29";
}

.mdi-television:before {
  content: "\F502";
}

.mdi-television-box:before {
  content: "\F838";
}

.mdi-television-classic:before {
  content: "\F7F3";
}

.mdi-television-classic-off:before {
  content: "\F839";
}

.mdi-television-guide:before {
  content: "\F503";
}

.mdi-television-off:before {
  content: "\F83A";
}

.mdi-temperature-celsius:before {
  content: "\F504";
}

.mdi-temperature-fahrenheit:before {
  content: "\F505";
}

.mdi-temperature-kelvin:before {
  content: "\F506";
}

.mdi-tennis:before {
  content: "\F507";
}

.mdi-tent:before {
  content: "\F508";
}

.mdi-terrain:before {
  content: "\F509";
}

.mdi-test-tube:before {
  content: "\F668";
}

.mdi-test-tube-empty:before {
  content: "\F910";
}

.mdi-test-tube-off:before {
  content: "\F911";
}

.mdi-text:before {
  content: "\F9A7";
}

.mdi-text-shadow:before {
  content: "\F669";
}

.mdi-text-short:before {
  content: "\F9A8";
}

.mdi-text-subject:before {
  content: "\F9A9";
}

.mdi-text-to-speech:before {
  content: "\F50A";
}

.mdi-text-to-speech-off:before {
  content: "\F50B";
}

.mdi-textbox:before {
  content: "\F60E";
}

.mdi-textbox-password:before {
  content: "\F7F4";
}

.mdi-texture:before {
  content: "\F50C";
}

.mdi-theater:before {
  content: "\F50D";
}

.mdi-theme-light-dark:before {
  content: "\F50E";
}

.mdi-thermometer:before {
  content: "\F50F";
}

.mdi-thermometer-lines:before {
  content: "\F510";
}

.mdi-thermostat:before {
  content: "\F393";
}

.mdi-thermostat-box:before {
  content: "\F890";
}

.mdi-thought-bubble:before {
  content: "\F7F5";
}

.mdi-thought-bubble-outline:before {
  content: "\F7F6";
}

.mdi-thumb-down:before {
  content: "\F511";
}

.mdi-thumb-down-outline:before {
  content: "\F512";
}

.mdi-thumb-up:before {
  content: "\F513";
}

.mdi-thumb-up-outline:before {
  content: "\F514";
}

.mdi-thumbs-up-down:before {
  content: "\F515";
}

.mdi-ticket:before {
  content: "\F516";
}

.mdi-ticket-account:before {
  content: "\F517";
}

.mdi-ticket-confirmation:before {
  content: "\F518";
}

.mdi-ticket-outline:before {
  content: "\F912";
}

.mdi-ticket-percent:before {
  content: "\F723";
}

.mdi-tie:before {
  content: "\F519";
}

.mdi-tilde:before {
  content: "\F724";
}

.mdi-timelapse:before {
  content: "\F51A";
}

.mdi-timeline:before {
  content: "\FBAD";
}

.mdi-timeline-outline:before {
  content: "\FBAE";
}

.mdi-timeline-text:before {
  content: "\FBAF";
}

.mdi-timeline-text-outline:before {
  content: "\FBB0";
}

.mdi-timer:before {
  content: "\F51B";
}

.mdi-timer-10:before {
  content: "\F51C";
}

.mdi-timer-3:before {
  content: "\F51D";
}

.mdi-timer-off:before {
  content: "\F51E";
}

.mdi-timer-sand:before {
  content: "\F51F";
}

.mdi-timer-sand-empty:before {
  content: "\F6AC";
}

.mdi-timer-sand-full:before {
  content: "\F78B";
}

.mdi-timetable:before {
  content: "\F520";
}

.mdi-toaster-oven:before {
  content: "\FCAF";
}

.mdi-toggle-switch:before {
  content: "\F521";
}

.mdi-toggle-switch-off:before {
  content: "\F522";
}

.mdi-toggle-switch-off-outline:before {
  content: "\FA18";
}

.mdi-toggle-switch-outline:before {
  content: "\FA19";
}

.mdi-toilet:before {
  content: "\F9AA";
}

.mdi-toolbox:before {
  content: "\F9AB";
}

.mdi-toolbox-outline:before {
  content: "\F9AC";
}

.mdi-tooltip:before {
  content: "\F523";
}

.mdi-tooltip-account:before {
  content: "\F00C";
}

.mdi-tooltip-edit:before {
  content: "\F524";
}

.mdi-tooltip-image:before {
  content: "\F525";
}

.mdi-tooltip-image-outline:before {
  content: "\FBB1";
}

.mdi-tooltip-outline:before {
  content: "\F526";
}

.mdi-tooltip-plus:before {
  content: "\FBB2";
}

.mdi-tooltip-plus-outline:before {
  content: "\F527";
}

.mdi-tooltip-text:before {
  content: "\F528";
}

.mdi-tooltip-text-outline:before {
  content: "\FBB3";
}

.mdi-tooth:before {
  content: "\F8C2";
}

.mdi-tooth-outline:before {
  content: "\F529";
}

.mdi-tor:before {
  content: "\F52A";
}

.mdi-tournament:before {
  content: "\F9AD";
}

.mdi-tower-beach:before {
  content: "\F680";
}

.mdi-tower-fire:before {
  content: "\F681";
}

.mdi-towing:before {
  content: "\F83B";
}

.mdi-track-light:before {
  content: "\F913";
}

.mdi-trackpad:before {
  content: "\F7F7";
}

.mdi-trackpad-lock:before {
  content: "\F932";
}

.mdi-tractor:before {
  content: "\F891";
}

.mdi-trademark:before {
  content: "\FA77";
}

.mdi-traffic-light:before {
  content: "\F52B";
}

.mdi-train:before {
  content: "\F52C";
}

.mdi-train-car:before {
  content: "\FBB4";
}

.mdi-train-variant:before {
  content: "\F8C3";
}

.mdi-tram:before {
  content: "\F52D";
}

.mdi-transcribe:before {
  content: "\F52E";
}

.mdi-transcribe-close:before {
  content: "\F52F";
}

.mdi-transfer:before {
  content: "\F530";
}

.mdi-transit-transfer:before {
  content: "\F6AD";
}

.mdi-transition:before {
  content: "\F914";
}

.mdi-transition-masked:before {
  content: "\F915";
}

.mdi-translate:before {
  content: "\F5CA";
}

.mdi-trash-can:before {
  content: "\FA78";
}

.mdi-trash-can-outline:before {
  content: "\FA79";
}

.mdi-treasure-chest:before {
  content: "\F725";
}

.mdi-tree:before {
  content: "\F531";
}

.mdi-trello:before {
  content: "\F532";
}

.mdi-trending-down:before {
  content: "\F533";
}

.mdi-trending-neutral:before {
  content: "\F534";
}

.mdi-trending-up:before {
  content: "\F535";
}

.mdi-triangle:before {
  content: "\F536";
}

.mdi-triangle-outline:before {
  content: "\F537";
}

.mdi-triforce:before {
  content: "\FBB5";
}

.mdi-trophy:before {
  content: "\F538";
}

.mdi-trophy-award:before {
  content: "\F539";
}

.mdi-trophy-outline:before {
  content: "\F53A";
}

.mdi-trophy-variant:before {
  content: "\F53B";
}

.mdi-trophy-variant-outline:before {
  content: "\F53C";
}

.mdi-truck:before {
  content: "\F53D";
}

.mdi-truck-check:before {
  content: "\FCB0";
}

.mdi-truck-delivery:before {
  content: "\F53E";
}

.mdi-truck-fast:before {
  content: "\F787";
}

.mdi-truck-trailer:before {
  content: "\F726";
}

.mdi-tshirt-crew:before {
  content: "\FA7A";
}

.mdi-tshirt-crew-outline:before {
  content: "\F53F";
}

.mdi-tshirt-v:before {
  content: "\FA7B";
}

.mdi-tshirt-v-outline:before {
  content: "\F540";
}

.mdi-tumble-dryer:before {
  content: "\F916";
}

.mdi-tumblr:before {
  content: "\F541";
}

.mdi-tumblr-box:before {
  content: "\F917";
}

.mdi-tumblr-reblog:before {
  content: "\F542";
}

.mdi-tune:before {
  content: "\F62E";
}

.mdi-tune-vertical:before {
  content: "\F66A";
}

.mdi-turnstile:before {
  content: "\FCB1";
}

.mdi-turnstile-outline:before {
  content: "\FCB2";
}

.mdi-turtle:before {
  content: "\FCB3";
}

.mdi-twitch:before {
  content: "\F543";
}

.mdi-twitter:before {
  content: "\F544";
}

.mdi-twitter-box:before {
  content: "\F545";
}

.mdi-twitter-circle:before {
  content: "\F546";
}

.mdi-twitter-retweet:before {
  content: "\F547";
}

.mdi-two-factor-authentication:before {
  content: "\F9AE";
}

.mdi-uber:before {
  content: "\F748";
}

.mdi-ubisoft:before {
  content: "\FBB6";
}

.mdi-ubuntu:before {
  content: "\F548";
}

.mdi-ultra-high-definition:before {
  content: "\F7F8";
}

.mdi-umbraco:before {
  content: "\F549";
}

.mdi-umbrella:before {
  content: "\F54A";
}

.mdi-umbrella-closed:before {
  content: "\F9AF";
}

.mdi-umbrella-outline:before {
  content: "\F54B";
}

.mdi-undo:before {
  content: "\F54C";
}

.mdi-undo-variant:before {
  content: "\F54D";
}

.mdi-unfold-less-horizontal:before {
  content: "\F54E";
}

.mdi-unfold-less-vertical:before {
  content: "\F75F";
}

.mdi-unfold-more-horizontal:before {
  content: "\F54F";
}

.mdi-unfold-more-vertical:before {
  content: "\F760";
}

.mdi-ungroup:before {
  content: "\F550";
}

.mdi-unity:before {
  content: "\F6AE";
}

.mdi-unreal:before {
  content: "\F9B0";
}

.mdi-untappd:before {
  content: "\F551";
}

.mdi-update:before {
  content: "\F6AF";
}

.mdi-upload:before {
  content: "\F552";
}

.mdi-upload-multiple:before {
  content: "\F83C";
}

.mdi-upload-network:before {
  content: "\F6F5";
}

.mdi-upload-network-outline:before {
  content: "\FCB4";
}

.mdi-usb:before {
  content: "\F553";
}

.mdi-van-passenger:before {
  content: "\F7F9";
}

.mdi-van-utility:before {
  content: "\F7FA";
}

.mdi-vanish:before {
  content: "\F7FB";
}

.mdi-variable:before {
  content: "\FAE6";
}

.mdi-vector-arrange-above:before {
  content: "\F554";
}

.mdi-vector-arrange-below:before {
  content: "\F555";
}

.mdi-vector-bezier:before {
  content: "\FAE7";
}

.mdi-vector-circle:before {
  content: "\F556";
}

.mdi-vector-circle-variant:before {
  content: "\F557";
}

.mdi-vector-combine:before {
  content: "\F558";
}

.mdi-vector-curve:before {
  content: "\F559";
}

.mdi-vector-difference:before {
  content: "\F55A";
}

.mdi-vector-difference-ab:before {
  content: "\F55B";
}

.mdi-vector-difference-ba:before {
  content: "\F55C";
}

.mdi-vector-ellipse:before {
  content: "\F892";
}

.mdi-vector-intersection:before {
  content: "\F55D";
}

.mdi-vector-line:before {
  content: "\F55E";
}

.mdi-vector-point:before {
  content: "\F55F";
}

.mdi-vector-polygon:before {
  content: "\F560";
}

.mdi-vector-polyline:before {
  content: "\F561";
}

.mdi-vector-radius:before {
  content: "\F749";
}

.mdi-vector-rectangle:before {
  content: "\F5C6";
}

.mdi-vector-selection:before {
  content: "\F562";
}

.mdi-vector-square:before {
  content: "\F001";
}

.mdi-vector-triangle:before {
  content: "\F563";
}

.mdi-vector-union:before {
  content: "\F564";
}

.mdi-venmo:before {
  content: "\F578";
}

.mdi-vhs:before {
  content: "\FA1A";
}

.mdi-vibrate:before {
  content: "\F566";
}

.mdi-vibrate-off:before {
  content: "\FCB5";
}

.mdi-video:before {
  content: "\F567";
}

.mdi-video-3d:before {
  content: "\F7FC";
}

.mdi-video-4k-box:before {
  content: "\F83D";
}

.mdi-video-account:before {
  content: "\F918";
}

.mdi-video-image:before {
  content: "\F919";
}

.mdi-video-input-antenna:before {
  content: "\F83E";
}

.mdi-video-input-component:before {
  content: "\F83F";
}

.mdi-video-input-hdmi:before {
  content: "\F840";
}

.mdi-video-input-svideo:before {
  content: "\F841";
}

.mdi-video-minus:before {
  content: "\F9B1";
}

.mdi-video-off:before {
  content: "\F568";
}

.mdi-video-off-outline:before {
  content: "\FBB7";
}

.mdi-video-outline:before {
  content: "\FBB8";
}

.mdi-video-plus:before {
  content: "\F9B2";
}

.mdi-video-stabilization:before {
  content: "\F91A";
}

.mdi-video-switch:before {
  content: "\F569";
}

.mdi-video-vintage:before {
  content: "\FA1B";
}

.mdi-view-agenda:before {
  content: "\F56A";
}

.mdi-view-array:before {
  content: "\F56B";
}

.mdi-view-carousel:before {
  content: "\F56C";
}

.mdi-view-column:before {
  content: "\F56D";
}

.mdi-view-dashboard:before {
  content: "\F56E";
}

.mdi-view-dashboard-outline:before {
  content: "\FA1C";
}

.mdi-view-dashboard-variant:before {
  content: "\F842";
}

.mdi-view-day:before {
  content: "\F56F";
}

.mdi-view-grid:before {
  content: "\F570";
}

.mdi-view-headline:before {
  content: "\F571";
}

.mdi-view-list:before {
  content: "\F572";
}

.mdi-view-module:before {
  content: "\F573";
}

.mdi-view-parallel:before {
  content: "\F727";
}

.mdi-view-quilt:before {
  content: "\F574";
}

.mdi-view-sequential:before {
  content: "\F728";
}

.mdi-view-split-horizontal:before {
  content: "\FBA7";
}

.mdi-view-split-vertical:before {
  content: "\FBA8";
}

.mdi-view-stream:before {
  content: "\F575";
}

.mdi-view-week:before {
  content: "\F576";
}

.mdi-vimeo:before {
  content: "\F577";
}

.mdi-violin:before {
  content: "\F60F";
}

.mdi-virtual-reality:before {
  content: "\F893";
}

.mdi-visual-studio:before {
  content: "\F610";
}

.mdi-visual-studio-code:before {
  content: "\FA1D";
}

.mdi-vk:before {
  content: "\F579";
}

.mdi-vk-box:before {
  content: "\F57A";
}

.mdi-vk-circle:before {
  content: "\F57B";
}

.mdi-vlc:before {
  content: "\F57C";
}

.mdi-voice:before {
  content: "\F5CB";
}

.mdi-voicemail:before {
  content: "\F57D";
}

.mdi-volleyball:before {
  content: "\F9B3";
}

.mdi-volume-high:before {
  content: "\F57E";
}

.mdi-volume-low:before {
  content: "\F57F";
}

.mdi-volume-medium:before {
  content: "\F580";
}

.mdi-volume-minus:before {
  content: "\F75D";
}

.mdi-volume-mute:before {
  content: "\F75E";
}

.mdi-volume-off:before {
  content: "\F581";
}

.mdi-volume-plus:before {
  content: "\F75C";
}

.mdi-vote:before {
  content: "\FA1E";
}

.mdi-vote-outline:before {
  content: "\FA1F";
}

.mdi-vpn:before {
  content: "\F582";
}

.mdi-vuejs:before {
  content: "\F843";
}

.mdi-walk:before {
  content: "\F583";
}

.mdi-wall:before {
  content: "\F7FD";
}

.mdi-wall-sconce:before {
  content: "\F91B";
}

.mdi-wall-sconce-flat:before {
  content: "\F91C";
}

.mdi-wall-sconce-variant:before {
  content: "\F91D";
}

.mdi-wallet:before {
  content: "\F584";
}

.mdi-wallet-giftcard:before {
  content: "\F585";
}

.mdi-wallet-membership:before {
  content: "\F586";
}

.mdi-wallet-outline:before {
  content: "\FBB9";
}

.mdi-wallet-travel:before {
  content: "\F587";
}

.mdi-wan:before {
  content: "\F588";
}

.mdi-washing-machine:before {
  content: "\F729";
}

.mdi-watch:before {
  content: "\F589";
}

.mdi-watch-export:before {
  content: "\F58A";
}

.mdi-watch-export-variant:before {
  content: "\F894";
}

.mdi-watch-import:before {
  content: "\F58B";
}

.mdi-watch-import-variant:before {
  content: "\F895";
}

.mdi-watch-variant:before {
  content: "\F896";
}

.mdi-watch-vibrate:before {
  content: "\F6B0";
}

.mdi-watch-vibrate-off:before {
  content: "\FCB6";
}

.mdi-water:before {
  content: "\F58C";
}

.mdi-water-off:before {
  content: "\F58D";
}

.mdi-water-percent:before {
  content: "\F58E";
}

.mdi-water-pump:before {
  content: "\F58F";
}

.mdi-watermark:before {
  content: "\F612";
}

.mdi-waves:before {
  content: "\F78C";
}

.mdi-waze:before {
  content: "\FBBA";
}

.mdi-weather-cloudy:before {
  content: "\F590";
}

.mdi-weather-fog:before {
  content: "\F591";
}

.mdi-weather-hail:before {
  content: "\F592";
}

.mdi-weather-hurricane:before {
  content: "\F897";
}

.mdi-weather-lightning:before {
  content: "\F593";
}

.mdi-weather-lightning-rainy:before {
  content: "\F67D";
}

.mdi-weather-night:before {
  content: "\F594";
}

.mdi-weather-partlycloudy:before {
  content: "\F595";
}

.mdi-weather-pouring:before {
  content: "\F596";
}

.mdi-weather-rainy:before {
  content: "\F597";
}

.mdi-weather-snowy:before {
  content: "\F598";
}

.mdi-weather-snowy-rainy:before {
  content: "\F67E";
}

.mdi-weather-sunny:before {
  content: "\F599";
}

.mdi-weather-sunset:before {
  content: "\F59A";
}

.mdi-weather-sunset-down:before {
  content: "\F59B";
}

.mdi-weather-sunset-up:before {
  content: "\F59C";
}

.mdi-weather-windy:before {
  content: "\F59D";
}

.mdi-weather-windy-variant:before {
  content: "\F59E";
}

.mdi-web:before {
  content: "\F59F";
}

.mdi-webcam:before {
  content: "\F5A0";
}

.mdi-webhook:before {
  content: "\F62F";
}

.mdi-webpack:before {
  content: "\F72A";
}

.mdi-wechat:before {
  content: "\F611";
}

.mdi-weight:before {
  content: "\F5A1";
}

.mdi-weight-kilogram:before {
  content: "\F5A2";
}

.mdi-weight-pound:before {
  content: "\F9B4";
}

.mdi-whatsapp:before {
  content: "\F5A3";
}

.mdi-wheelchair-accessibility:before {
  content: "\F5A4";
}

.mdi-whistle:before {
  content: "\F9B5";
}

.mdi-white-balance-auto:before {
  content: "\F5A5";
}

.mdi-white-balance-incandescent:before {
  content: "\F5A6";
}

.mdi-white-balance-iridescent:before {
  content: "\F5A7";
}

.mdi-white-balance-sunny:before {
  content: "\F5A8";
}

.mdi-widgets:before {
  content: "\F72B";
}

.mdi-wifi:before {
  content: "\F5A9";
}

.mdi-wifi-off:before {
  content: "\F5AA";
}

.mdi-wifi-strength-1:before {
  content: "\F91E";
}

.mdi-wifi-strength-1-alert:before {
  content: "\F91F";
}

.mdi-wifi-strength-1-lock:before {
  content: "\F920";
}

.mdi-wifi-strength-2:before {
  content: "\F921";
}

.mdi-wifi-strength-2-alert:before {
  content: "\F922";
}

.mdi-wifi-strength-2-lock:before {
  content: "\F923";
}

.mdi-wifi-strength-3:before {
  content: "\F924";
}

.mdi-wifi-strength-3-alert:before {
  content: "\F925";
}

.mdi-wifi-strength-3-lock:before {
  content: "\F926";
}

.mdi-wifi-strength-4:before {
  content: "\F927";
}

.mdi-wifi-strength-4-alert:before {
  content: "\F928";
}

.mdi-wifi-strength-4-lock:before {
  content: "\F929";
}

.mdi-wifi-strength-alert-outline:before {
  content: "\F92A";
}

.mdi-wifi-strength-lock-outline:before {
  content: "\F92B";
}

.mdi-wifi-strength-off:before {
  content: "\F92C";
}

.mdi-wifi-strength-off-outline:before {
  content: "\F92D";
}

.mdi-wifi-strength-outline:before {
  content: "\F92E";
}

.mdi-wii:before {
  content: "\F5AB";
}

.mdi-wiiu:before {
  content: "\F72C";
}

.mdi-wikipedia:before {
  content: "\F5AC";
}

.mdi-window-close:before {
  content: "\F5AD";
}

.mdi-window-closed:before {
  content: "\F5AE";
}

.mdi-window-maximize:before {
  content: "\F5AF";
}

.mdi-window-minimize:before {
  content: "\F5B0";
}

.mdi-window-open:before {
  content: "\F5B1";
}

.mdi-window-restore:before {
  content: "\F5B2";
}

.mdi-windows:before {
  content: "\F5B3";
}

.mdi-windows-classic:before {
  content: "\FA20";
}

.mdi-wiper:before {
  content: "\FAE8";
}

.mdi-wordpress:before {
  content: "\F5B4";
}

.mdi-worker:before {
  content: "\F5B5";
}

.mdi-wrap:before {
  content: "\F5B6";
}

.mdi-wrap-disabled:before {
  content: "\FBBB";
}

.mdi-wrench:before {
  content: "\F5B7";
}

.mdi-wrench-outline:before {
  content: "\FBBC";
}

.mdi-wunderlist:before {
  content: "\F5B8";
}

.mdi-xamarin:before {
  content: "\F844";
}

.mdi-xamarin-outline:before {
  content: "\F845";
}

.mdi-xaml:before {
  content: "\F673";
}

.mdi-xbox:before {
  content: "\F5B9";
}

.mdi-xbox-controller:before {
  content: "\F5BA";
}

.mdi-xbox-controller-battery-alert:before {
  content: "\F74A";
}

.mdi-xbox-controller-battery-charging:before {
  content: "\FA21";
}

.mdi-xbox-controller-battery-empty:before {
  content: "\F74B";
}

.mdi-xbox-controller-battery-full:before {
  content: "\F74C";
}

.mdi-xbox-controller-battery-low:before {
  content: "\F74D";
}

.mdi-xbox-controller-battery-medium:before {
  content: "\F74E";
}

.mdi-xbox-controller-battery-unknown:before {
  content: "\F74F";
}

.mdi-xbox-controller-off:before {
  content: "\F5BB";
}

.mdi-xda:before {
  content: "\F5BC";
}

.mdi-xing:before {
  content: "\F5BD";
}

.mdi-xing-box:before {
  content: "\F5BE";
}

.mdi-xing-circle:before {
  content: "\F5BF";
}

.mdi-xml:before {
  content: "\F5C0";
}

.mdi-xmpp:before {
  content: "\F7FE";
}

.mdi-yahoo:before {
  content: "\FB2A";
}

.mdi-yammer:before {
  content: "\F788";
}

.mdi-yeast:before {
  content: "\F5C1";
}

.mdi-yelp:before {
  content: "\F5C2";
}

.mdi-yin-yang:before {
  content: "\F67F";
}

.mdi-youtube:before {
  content: "\F5C3";
}

.mdi-youtube-creator-studio:before {
  content: "\F846";
}

.mdi-youtube-gaming:before {
  content: "\F847";
}

.mdi-youtube-tv:before {
  content: "\F448";
}

.mdi-z-wave:before {
  content: "\FAE9";
}

.mdi-zend:before {
  content: "\FAEA";
}

.mdi-zip-box:before {
  content: "\F5C4";
}

.mdi-zip-disk:before {
  content: "\FA22";
}

.mdi-zodiac-aquarius:before {
  content: "\FA7C";
}

.mdi-zodiac-aries:before {
  content: "\FA7D";
}

.mdi-zodiac-cancer:before {
  content: "\FA7E";
}

.mdi-zodiac-capricorn:before {
  content: "\FA7F";
}

.mdi-zodiac-gemini:before {
  content: "\FA80";
}

.mdi-zodiac-leo:before {
  content: "\FA81";
}

.mdi-zodiac-libra:before {
  content: "\FA82";
}

.mdi-zodiac-pisces:before {
  content: "\FA83";
}

.mdi-zodiac-sagittarius:before {
  content: "\FA84";
}

.mdi-zodiac-scorpio:before {
  content: "\FA85";
}

.mdi-zodiac-taurus:before {
  content: "\FA86";
}

.mdi-zodiac-virgo:before {
  content: "\FA87";
}

.mdi-blank:before {
  content: "\F68C";
  visibility: hidden;
}

.mdi-18px.mdi-set, .mdi-18px.mdi:before {
  font-size: 18px;
}

.mdi-24px.mdi-set, .mdi-24px.mdi:before {
  font-size: 24px;
}

.mdi-36px.mdi-set, .mdi-36px.mdi:before {
  font-size: 36px;
}

.mdi-48px.mdi-set, .mdi-48px.mdi:before {
  font-size: 48px;
}

.mdi-dark:before {
  color: rgba(0, 0, 0, 0.54);
}

.mdi-dark.mdi-inactive:before {
  color: rgba(0, 0, 0, 0.26);
}

.mdi-light:before {
  color: white;
}

.mdi-light.mdi-inactive:before {
  color: rgba(255, 255, 255, 0.3);
}

.mdi-rotate-45 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(45deg);
      transform: scaleX(-1) rotate(45deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: scaleY(-1) rotate(45deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}

.mdi-rotate-45:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mdi-rotate-90 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(90deg);
      transform: scaleX(-1) rotate(90deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: scaleY(-1) rotate(90deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}

.mdi-rotate-90:before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mdi-rotate-135 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(135deg);
      transform: scaleX(-1) rotate(135deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: scaleY(-1) rotate(135deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}

.mdi-rotate-135:before {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.mdi-rotate-180 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(180deg);
      transform: scaleX(-1) rotate(180deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: scaleY(-1) rotate(180deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}

.mdi-rotate-180:before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.mdi-rotate-225 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(225deg);
      transform: scaleX(-1) rotate(225deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(225deg);
      -ms-transform: rotate(225deg);
      transform: scaleY(-1) rotate(225deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}

.mdi-rotate-225:before {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

.mdi-rotate-270 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(270deg);
      transform: scaleX(-1) rotate(270deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(270deg);
      -ms-transform: rotate(270deg);
      transform: scaleY(-1) rotate(270deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}

.mdi-rotate-270:before {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.mdi-rotate-315 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(315deg);
      transform: scaleX(-1) rotate(315deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(315deg);
      -ms-transform: rotate(315deg);
      transform: scaleY(-1) rotate(315deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}

.mdi-rotate-315:before {
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
}

.mdi-flip-h:before {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  -webkit-filter: FlipH;
          filter: FlipH;
  -ms-filter: "FlipH";
}

.mdi-flip-v:before {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  -webkit-filter: FlipV;
          filter: FlipV;
  -ms-filter: "FlipV";
}

.mdi-spin:before {
  -webkit-animation: mdi-spin 2s infinite linear;
  animation: mdi-spin 2s infinite linear;
}

@-webkit-keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@font-face {
  font-family: 'themify';
  src: url("../fonts/themify.eot?-fvbane");
  src: url("../fonts/themify.eot?#iefix-fvbane") format("embedded-opentype"), url("../fonts/themify.woff?-fvbane") format("woff"), url("../fonts/themify.ttf?-fvbane") format("truetype"), url("../fonts/themify.svg?-fvbane#themify") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="ti-"], [class*=" ti-"] {
  font-family: 'themify';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ti-wand:before {
  content: "\e600";
}

.ti-volume:before {
  content: "\e601";
}

.ti-user:before {
  content: "\e602";
}

.ti-unlock:before {
  content: "\e603";
}

.ti-unlink:before {
  content: "\e604";
}

.ti-trash:before {
  content: "\e605";
}

.ti-thought:before {
  content: "\e606";
}

.ti-target:before {
  content: "\e607";
}

.ti-tag:before {
  content: "\e608";
}

.ti-tablet:before {
  content: "\e609";
}

.ti-star:before {
  content: "\e60a";
}

.ti-spray:before {
  content: "\e60b";
}

.ti-signal:before {
  content: "\e60c";
}

.ti-shopping-cart:before {
  content: "\e60d";
}

.ti-shopping-cart-full:before {
  content: "\e60e";
}

.ti-settings:before {
  content: "\e60f";
}

.ti-search:before {
  content: "\e610";
}

.ti-zoom-in:before {
  content: "\e611";
}

.ti-zoom-out:before {
  content: "\e612";
}

.ti-cut:before {
  content: "\e613";
}

.ti-ruler:before {
  content: "\e614";
}

.ti-ruler-pencil:before {
  content: "\e615";
}

.ti-ruler-alt:before {
  content: "\e616";
}

.ti-bookmark:before {
  content: "\e617";
}

.ti-bookmark-alt:before {
  content: "\e618";
}

.ti-reload:before {
  content: "\e619";
}

.ti-plus:before {
  content: "\e61a";
}

.ti-pin:before {
  content: "\e61b";
}

.ti-pencil:before {
  content: "\e61c";
}

.ti-pencil-alt:before {
  content: "\e61d";
}

.ti-paint-roller:before {
  content: "\e61e";
}

.ti-paint-bucket:before {
  content: "\e61f";
}

.ti-na:before {
  content: "\e620";
}

.ti-mobile:before {
  content: "\e621";
}

.ti-minus:before {
  content: "\e622";
}

.ti-medall:before {
  content: "\e623";
}

.ti-medall-alt:before {
  content: "\e624";
}

.ti-marker:before {
  content: "\e625";
}

.ti-marker-alt:before {
  content: "\e626";
}

.ti-arrow-up:before {
  content: "\e627";
}

.ti-arrow-right:before {
  content: "\e628";
}

.ti-arrow-left:before {
  content: "\e629";
}

.ti-arrow-down:before {
  content: "\e62a";
}

.ti-lock:before {
  content: "\e62b";
}

.ti-location-arrow:before {
  content: "\e62c";
}

.ti-link:before {
  content: "\e62d";
}

.ti-layout:before {
  content: "\e62e";
}

.ti-layers:before {
  content: "\e62f";
}

.ti-layers-alt:before {
  content: "\e630";
}

.ti-key:before {
  content: "\e631";
}

.ti-import:before {
  content: "\e632";
}

.ti-image:before {
  content: "\e633";
}

.ti-heart:before {
  content: "\e634";
}

.ti-heart-broken:before {
  content: "\e635";
}

.ti-hand-stop:before {
  content: "\e636";
}

.ti-hand-open:before {
  content: "\e637";
}

.ti-hand-drag:before {
  content: "\e638";
}

.ti-folder:before {
  content: "\e639";
}

.ti-flag:before {
  content: "\e63a";
}

.ti-flag-alt:before {
  content: "\e63b";
}

.ti-flag-alt-2:before {
  content: "\e63c";
}

.ti-eye:before {
  content: "\e63d";
}

.ti-export:before {
  content: "\e63e";
}

.ti-exchange-vertical:before {
  content: "\e63f";
}

.ti-desktop:before {
  content: "\e640";
}

.ti-cup:before {
  content: "\e641";
}

.ti-crown:before {
  content: "\e642";
}

.ti-comments:before {
  content: "\e643";
}

.ti-comment:before {
  content: "\e644";
}

.ti-comment-alt:before {
  content: "\e645";
}

.ti-close:before {
  content: "\e646";
}

.ti-clip:before {
  content: "\e647";
}

.ti-angle-up:before {
  content: "\e648";
}

.ti-angle-right:before {
  content: "\e649";
}

.ti-angle-left:before {
  content: "\e64a";
}

.ti-angle-down:before {
  content: "\e64b";
}

.ti-check:before {
  content: "\e64c";
}

.ti-check-box:before {
  content: "\e64d";
}

.ti-camera:before {
  content: "\e64e";
}

.ti-announcement:before {
  content: "\e64f";
}

.ti-brush:before {
  content: "\e650";
}

.ti-briefcase:before {
  content: "\e651";
}

.ti-bolt:before {
  content: "\e652";
}

.ti-bolt-alt:before {
  content: "\e653";
}

.ti-blackboard:before {
  content: "\e654";
}

.ti-bag:before {
  content: "\e655";
}

.ti-move:before {
  content: "\e656";
}

.ti-arrows-vertical:before {
  content: "\e657";
}

.ti-arrows-horizontal:before {
  content: "\e658";
}

.ti-fullscreen:before {
  content: "\e659";
}

.ti-arrow-top-right:before {
  content: "\e65a";
}

.ti-arrow-top-left:before {
  content: "\e65b";
}

.ti-arrow-circle-up:before {
  content: "\e65c";
}

.ti-arrow-circle-right:before {
  content: "\e65d";
}

.ti-arrow-circle-left:before {
  content: "\e65e";
}

.ti-arrow-circle-down:before {
  content: "\e65f";
}

.ti-angle-double-up:before {
  content: "\e660";
}

.ti-angle-double-right:before {
  content: "\e661";
}

.ti-angle-double-left:before {
  content: "\e662";
}

.ti-angle-double-down:before {
  content: "\e663";
}

.ti-zip:before {
  content: "\e664";
}

.ti-world:before {
  content: "\e665";
}

.ti-wheelchair:before {
  content: "\e666";
}

.ti-view-list:before {
  content: "\e667";
}

.ti-view-list-alt:before {
  content: "\e668";
}

.ti-view-grid:before {
  content: "\e669";
}

.ti-uppercase:before {
  content: "\e66a";
}

.ti-upload:before {
  content: "\e66b";
}

.ti-underline:before {
  content: "\e66c";
}

.ti-truck:before {
  content: "\e66d";
}

.ti-timer:before {
  content: "\e66e";
}

.ti-ticket:before {
  content: "\e66f";
}

.ti-thumb-up:before {
  content: "\e670";
}

.ti-thumb-down:before {
  content: "\e671";
}

.ti-text:before {
  content: "\e672";
}

.ti-stats-up:before {
  content: "\e673";
}

.ti-stats-down:before {
  content: "\e674";
}

.ti-split-v:before {
  content: "\e675";
}

.ti-split-h:before {
  content: "\e676";
}

.ti-smallcap:before {
  content: "\e677";
}

.ti-shine:before {
  content: "\e678";
}

.ti-shift-right:before {
  content: "\e679";
}

.ti-shift-left:before {
  content: "\e67a";
}

.ti-shield:before {
  content: "\e67b";
}

.ti-notepad:before {
  content: "\e67c";
}

.ti-server:before {
  content: "\e67d";
}

.ti-quote-right:before {
  content: "\e67e";
}

.ti-quote-left:before {
  content: "\e67f";
}

.ti-pulse:before {
  content: "\e680";
}

.ti-printer:before {
  content: "\e681";
}

.ti-power-off:before {
  content: "\e682";
}

.ti-plug:before {
  content: "\e683";
}

.ti-pie-chart:before {
  content: "\e684";
}

.ti-paragraph:before {
  content: "\e685";
}

.ti-panel:before {
  content: "\e686";
}

.ti-package:before {
  content: "\e687";
}

.ti-music:before {
  content: "\e688";
}

.ti-music-alt:before {
  content: "\e689";
}

.ti-mouse:before {
  content: "\e68a";
}

.ti-mouse-alt:before {
  content: "\e68b";
}

.ti-money:before {
  content: "\e68c";
}

.ti-microphone:before {
  content: "\e68d";
}

.ti-menu:before {
  content: "\e68e";
}

.ti-menu-alt:before {
  content: "\e68f";
}

.ti-map:before {
  content: "\e690";
}

.ti-map-alt:before {
  content: "\e691";
}

.ti-loop:before {
  content: "\e692";
}

.ti-location-pin:before {
  content: "\e693";
}

.ti-list:before {
  content: "\e694";
}

.ti-light-bulb:before {
  content: "\e695";
}

.ti-Italic:before {
  content: "\e696";
}

.ti-info:before {
  content: "\e697";
}

.ti-infinite:before {
  content: "\e698";
}

.ti-id-badge:before {
  content: "\e699";
}

.ti-hummer:before {
  content: "\e69a";
}

.ti-home:before {
  content: "\e69b";
}

.ti-help:before {
  content: "\e69c";
}

.ti-headphone:before {
  content: "\e69d";
}

.ti-harddrives:before {
  content: "\e69e";
}

.ti-harddrive:before {
  content: "\e69f";
}

.ti-gift:before {
  content: "\e6a0";
}

.ti-game:before {
  content: "\e6a1";
}

.ti-filter:before {
  content: "\e6a2";
}

.ti-files:before {
  content: "\e6a3";
}

.ti-file:before {
  content: "\e6a4";
}

.ti-eraser:before {
  content: "\e6a5";
}

.ti-envelope:before {
  content: "\e6a6";
}

.ti-download:before {
  content: "\e6a7";
}

.ti-direction:before {
  content: "\e6a8";
}

.ti-direction-alt:before {
  content: "\e6a9";
}

.ti-dashboard:before {
  content: "\e6aa";
}

.ti-control-stop:before {
  content: "\e6ab";
}

.ti-control-shuffle:before {
  content: "\e6ac";
}

.ti-control-play:before {
  content: "\e6ad";
}

.ti-control-pause:before {
  content: "\e6ae";
}

.ti-control-forward:before {
  content: "\e6af";
}

.ti-control-backward:before {
  content: "\e6b0";
}

.ti-cloud:before {
  content: "\e6b1";
}

.ti-cloud-up:before {
  content: "\e6b2";
}

.ti-cloud-down:before {
  content: "\e6b3";
}

.ti-clipboard:before {
  content: "\e6b4";
}

.ti-car:before {
  content: "\e6b5";
}

.ti-calendar:before {
  content: "\e6b6";
}

.ti-book:before {
  content: "\e6b7";
}

.ti-bell:before {
  content: "\e6b8";
}

.ti-basketball:before {
  content: "\e6b9";
}

.ti-bar-chart:before {
  content: "\e6ba";
}

.ti-bar-chart-alt:before {
  content: "\e6bb";
}

.ti-back-right:before {
  content: "\e6bc";
}

.ti-back-left:before {
  content: "\e6bd";
}

.ti-arrows-corner:before {
  content: "\e6be";
}

.ti-archive:before {
  content: "\e6bf";
}

.ti-anchor:before {
  content: "\e6c0";
}

.ti-align-right:before {
  content: "\e6c1";
}

.ti-align-left:before {
  content: "\e6c2";
}

.ti-align-justify:before {
  content: "\e6c3";
}

.ti-align-center:before {
  content: "\e6c4";
}

.ti-alert:before {
  content: "\e6c5";
}

.ti-alarm-clock:before {
  content: "\e6c6";
}

.ti-agenda:before {
  content: "\e6c7";
}

.ti-write:before {
  content: "\e6c8";
}

.ti-window:before {
  content: "\e6c9";
}

.ti-widgetized:before {
  content: "\e6ca";
}

.ti-widget:before {
  content: "\e6cb";
}

.ti-widget-alt:before {
  content: "\e6cc";
}

.ti-wallet:before {
  content: "\e6cd";
}

.ti-video-clapper:before {
  content: "\e6ce";
}

.ti-video-camera:before {
  content: "\e6cf";
}

.ti-vector:before {
  content: "\e6d0";
}

.ti-themify-logo:before {
  content: "\e6d1";
}

.ti-themify-favicon:before {
  content: "\e6d2";
}

.ti-themify-favicon-alt:before {
  content: "\e6d3";
}

.ti-support:before {
  content: "\e6d4";
}

.ti-stamp:before {
  content: "\e6d5";
}

.ti-split-v-alt:before {
  content: "\e6d6";
}

.ti-slice:before {
  content: "\e6d7";
}

.ti-shortcode:before {
  content: "\e6d8";
}

.ti-shift-right-alt:before {
  content: "\e6d9";
}

.ti-shift-left-alt:before {
  content: "\e6da";
}

.ti-ruler-alt-2:before {
  content: "\e6db";
}

.ti-receipt:before {
  content: "\e6dc";
}

.ti-pin2:before {
  content: "\e6dd";
}

.ti-pin-alt:before {
  content: "\e6de";
}

.ti-pencil-alt2:before {
  content: "\e6df";
}

.ti-palette:before {
  content: "\e6e0";
}

.ti-more:before {
  content: "\e6e1";
}

.ti-more-alt:before {
  content: "\e6e2";
}

.ti-microphone-alt:before {
  content: "\e6e3";
}

.ti-magnet:before {
  content: "\e6e4";
}

.ti-line-double:before {
  content: "\e6e5";
}

.ti-line-dotted:before {
  content: "\e6e6";
}

.ti-line-dashed:before {
  content: "\e6e7";
}

.ti-layout-width-full:before {
  content: "\e6e8";
}

.ti-layout-width-default:before {
  content: "\e6e9";
}

.ti-layout-width-default-alt:before {
  content: "\e6ea";
}

.ti-layout-tab:before {
  content: "\e6eb";
}

.ti-layout-tab-window:before {
  content: "\e6ec";
}

.ti-layout-tab-v:before {
  content: "\e6ed";
}

.ti-layout-tab-min:before {
  content: "\e6ee";
}

.ti-layout-slider:before {
  content: "\e6ef";
}

.ti-layout-slider-alt:before {
  content: "\e6f0";
}

.ti-layout-sidebar-right:before {
  content: "\e6f1";
}

.ti-layout-sidebar-none:before {
  content: "\e6f2";
}

.ti-layout-sidebar-left:before {
  content: "\e6f3";
}

.ti-layout-placeholder:before {
  content: "\e6f4";
}

.ti-layout-menu:before {
  content: "\e6f5";
}

.ti-layout-menu-v:before {
  content: "\e6f6";
}

.ti-layout-menu-separated:before {
  content: "\e6f7";
}

.ti-layout-menu-full:before {
  content: "\e6f8";
}

.ti-layout-media-right-alt:before {
  content: "\e6f9";
}

.ti-layout-media-right:before {
  content: "\e6fa";
}

.ti-layout-media-overlay:before {
  content: "\e6fb";
}

.ti-layout-media-overlay-alt:before {
  content: "\e6fc";
}

.ti-layout-media-overlay-alt-2:before {
  content: "\e6fd";
}

.ti-layout-media-left-alt:before {
  content: "\e6fe";
}

.ti-layout-media-left:before {
  content: "\e6ff";
}

.ti-layout-media-center-alt:before {
  content: "\e700";
}

.ti-layout-media-center:before {
  content: "\e701";
}

.ti-layout-list-thumb:before {
  content: "\e702";
}

.ti-layout-list-thumb-alt:before {
  content: "\e703";
}

.ti-layout-list-post:before {
  content: "\e704";
}

.ti-layout-list-large-image:before {
  content: "\e705";
}

.ti-layout-line-solid:before {
  content: "\e706";
}

.ti-layout-grid4:before {
  content: "\e707";
}

.ti-layout-grid3:before {
  content: "\e708";
}

.ti-layout-grid2:before {
  content: "\e709";
}

.ti-layout-grid2-thumb:before {
  content: "\e70a";
}

.ti-layout-cta-right:before {
  content: "\e70b";
}

.ti-layout-cta-left:before {
  content: "\e70c";
}

.ti-layout-cta-center:before {
  content: "\e70d";
}

.ti-layout-cta-btn-right:before {
  content: "\e70e";
}

.ti-layout-cta-btn-left:before {
  content: "\e70f";
}

.ti-layout-column4:before {
  content: "\e710";
}

.ti-layout-column3:before {
  content: "\e711";
}

.ti-layout-column2:before {
  content: "\e712";
}

.ti-layout-accordion-separated:before {
  content: "\e713";
}

.ti-layout-accordion-merged:before {
  content: "\e714";
}

.ti-layout-accordion-list:before {
  content: "\e715";
}

.ti-ink-pen:before {
  content: "\e716";
}

.ti-info-alt:before {
  content: "\e717";
}

.ti-help-alt:before {
  content: "\e718";
}

.ti-headphone-alt:before {
  content: "\e719";
}

.ti-hand-point-up:before {
  content: "\e71a";
}

.ti-hand-point-right:before {
  content: "\e71b";
}

.ti-hand-point-left:before {
  content: "\e71c";
}

.ti-hand-point-down:before {
  content: "\e71d";
}

.ti-gallery:before {
  content: "\e71e";
}

.ti-face-smile:before {
  content: "\e71f";
}

.ti-face-sad:before {
  content: "\e720";
}

.ti-credit-card:before {
  content: "\e721";
}

.ti-control-skip-forward:before {
  content: "\e722";
}

.ti-control-skip-backward:before {
  content: "\e723";
}

.ti-control-record:before {
  content: "\e724";
}

.ti-control-eject:before {
  content: "\e725";
}

.ti-comments-smiley:before {
  content: "\e726";
}

.ti-brush-alt:before {
  content: "\e727";
}

.ti-youtube:before {
  content: "\e728";
}

.ti-vimeo:before {
  content: "\e729";
}

.ti-twitter:before {
  content: "\e72a";
}

.ti-time:before {
  content: "\e72b";
}

.ti-tumblr:before {
  content: "\e72c";
}

.ti-skype:before {
  content: "\e72d";
}

.ti-share:before {
  content: "\e72e";
}

.ti-share-alt:before {
  content: "\e72f";
}

.ti-rocket:before {
  content: "\e730";
}

.ti-pinterest:before {
  content: "\e731";
}

.ti-new-window:before {
  content: "\e732";
}

.ti-microsoft:before {
  content: "\e733";
}

.ti-list-ol:before {
  content: "\e734";
}

.ti-linkedin:before {
  content: "\e735";
}

.ti-layout-sidebar-2:before {
  content: "\e736";
}

.ti-layout-grid4-alt:before {
  content: "\e737";
}

.ti-layout-grid3-alt:before {
  content: "\e738";
}

.ti-layout-grid2-alt:before {
  content: "\e739";
}

.ti-layout-column4-alt:before {
  content: "\e73a";
}

.ti-layout-column3-alt:before {
  content: "\e73b";
}

.ti-layout-column2-alt:before {
  content: "\e73c";
}

.ti-instagram:before {
  content: "\e73d";
}

.ti-google:before {
  content: "\e73e";
}

.ti-github:before {
  content: "\e73f";
}

.ti-flickr:before {
  content: "\e740";
}

.ti-facebook:before {
  content: "\e741";
}

.ti-dropbox:before {
  content: "\e742";
}

.ti-dribbble:before {
  content: "\e743";
}

.ti-apple:before {
  content: "\e744";
}

.ti-android:before {
  content: "\e745";
}

.ti-save:before {
  content: "\e746";
}

.ti-save-alt:before {
  content: "\e747";
}

.ti-yahoo:before {
  content: "\e748";
}

.ti-wordpress:before {
  content: "\e749";
}

.ti-vimeo-alt:before {
  content: "\e74a";
}

.ti-twitter-alt:before {
  content: "\e74b";
}

.ti-tumblr-alt:before {
  content: "\e74c";
}

.ti-trello:before {
  content: "\e74d";
}

.ti-stack-overflow:before {
  content: "\e74e";
}

.ti-soundcloud:before {
  content: "\e74f";
}

.ti-sharethis:before {
  content: "\e750";
}

.ti-sharethis-alt:before {
  content: "\e751";
}

.ti-reddit:before {
  content: "\e752";
}

.ti-pinterest-alt:before {
  content: "\e753";
}

.ti-microsoft-alt:before {
  content: "\e754";
}

.ti-linux:before {
  content: "\e755";
}

.ti-jsfiddle:before {
  content: "\e756";
}

.ti-joomla:before {
  content: "\e757";
}

.ti-html5:before {
  content: "\e758";
}

.ti-flickr-alt:before {
  content: "\e759";
}

.ti-email:before {
  content: "\e75a";
}

.ti-drupal:before {
  content: "\e75b";
}

.ti-dropbox-alt:before {
  content: "\e75c";
}

.ti-css3:before {
  content: "\e75d";
}

.ti-rss:before {
  content: "\e75e";
}

.ti-rss-alt:before {
  content: "\e75f";
}

@font-face {
  font-family: "dripicons-v2";
  src: url("../fonts/dripicons-v2.eot");
  src: url("../fonts/dripicons-v2.eot?#iefix") format("embedded-opentype"), url("../fonts/dripicons-v2.woff") format("woff"), url("../fonts/dripicons-v2.ttf") format("truetype"), url("../fonts/dripicons-v2.svg#dripicons-v2") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "dripicons-v2" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="dripicons-"]:before,
[class*=" dripicons-"]:before {
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dripicons-alarm:before {
  content: "\61";
}

.dripicons-align-center:before {
  content: "\62";
}

.dripicons-align-justify:before {
  content: "\63";
}

.dripicons-align-left:before {
  content: "\64";
}

.dripicons-align-right:before {
  content: "\65";
}

.dripicons-anchor:before {
  content: "\66";
}

.dripicons-archive:before {
  content: "\67";
}

.dripicons-arrow-down:before {
  content: "\68";
}

.dripicons-arrow-left:before {
  content: "\69";
}

.dripicons-arrow-right:before {
  content: "\6a";
}

.dripicons-arrow-thin-down:before {
  content: "\6b";
}

.dripicons-arrow-thin-left:before {
  content: "\6c";
}

.dripicons-arrow-thin-right:before {
  content: "\6d";
}

.dripicons-arrow-thin-up:before {
  content: "\6e";
}

.dripicons-arrow-up:before {
  content: "\6f";
}

.dripicons-article:before {
  content: "\70";
}

.dripicons-backspace:before {
  content: "\71";
}

.dripicons-basket:before {
  content: "\72";
}

.dripicons-basketball:before {
  content: "\73";
}

.dripicons-battery-empty:before {
  content: "\74";
}

.dripicons-battery-full:before {
  content: "\75";
}

.dripicons-battery-low:before {
  content: "\76";
}

.dripicons-battery-medium:before {
  content: "\77";
}

.dripicons-bell:before {
  content: "\78";
}

.dripicons-blog:before {
  content: "\79";
}

.dripicons-bluetooth:before {
  content: "\7a";
}

.dripicons-bold:before {
  content: "\41";
}

.dripicons-bookmark:before {
  content: "\42";
}

.dripicons-bookmarks:before {
  content: "\43";
}

.dripicons-box:before {
  content: "\44";
}

.dripicons-briefcase:before {
  content: "\45";
}

.dripicons-brightness-low:before {
  content: "\46";
}

.dripicons-brightness-max:before {
  content: "\47";
}

.dripicons-brightness-medium:before {
  content: "\48";
}

.dripicons-broadcast:before {
  content: "\49";
}

.dripicons-browser:before {
  content: "\4a";
}

.dripicons-browser-upload:before {
  content: "\4b";
}

.dripicons-brush:before {
  content: "\4c";
}

.dripicons-calendar:before {
  content: "\4d";
}

.dripicons-camcorder:before {
  content: "\4e";
}

.dripicons-camera:before {
  content: "\4f";
}

.dripicons-card:before {
  content: "\50";
}

.dripicons-cart:before {
  content: "\51";
}

.dripicons-checklist:before {
  content: "\52";
}

.dripicons-checkmark:before {
  content: "\53";
}

.dripicons-chevron-down:before {
  content: "\54";
}

.dripicons-chevron-left:before {
  content: "\55";
}

.dripicons-chevron-right:before {
  content: "\56";
}

.dripicons-chevron-up:before {
  content: "\57";
}

.dripicons-clipboard:before {
  content: "\58";
}

.dripicons-clock:before {
  content: "\59";
}

.dripicons-clockwise:before {
  content: "\5a";
}

.dripicons-cloud:before {
  content: "\30";
}

.dripicons-cloud-download:before {
  content: "\31";
}

.dripicons-cloud-upload:before {
  content: "\32";
}

.dripicons-code:before {
  content: "\33";
}

.dripicons-contract:before {
  content: "\34";
}

.dripicons-contract-2:before {
  content: "\35";
}

.dripicons-conversation:before {
  content: "\36";
}

.dripicons-copy:before {
  content: "\37";
}

.dripicons-crop:before {
  content: "\38";
}

.dripicons-cross:before {
  content: "\39";
}

.dripicons-crosshair:before {
  content: "\21";
}

.dripicons-cutlery:before {
  content: "\22";
}

.dripicons-device-desktop:before {
  content: "\23";
}

.dripicons-device-mobile:before {
  content: "\24";
}

.dripicons-device-tablet:before {
  content: "\25";
}

.dripicons-direction:before {
  content: "\26";
}

.dripicons-disc:before {
  content: "\27";
}

.dripicons-document:before {
  content: "\28";
}

.dripicons-document-delete:before {
  content: "\29";
}

.dripicons-document-edit:before {
  content: "\2a";
}

.dripicons-document-new:before {
  content: "\2b";
}

.dripicons-document-remove:before {
  content: "\2c";
}

.dripicons-dot:before {
  content: "\2d";
}

.dripicons-dots-2:before {
  content: "\2e";
}

.dripicons-dots-3:before {
  content: "\2f";
}

.dripicons-download:before {
  content: "\3a";
}

.dripicons-duplicate:before {
  content: "\3b";
}

.dripicons-enter:before {
  content: "\3c";
}

.dripicons-exit:before {
  content: "\3d";
}

.dripicons-expand:before {
  content: "\3e";
}

.dripicons-expand-2:before {
  content: "\3f";
}

.dripicons-experiment:before {
  content: "\40";
}

.dripicons-export:before {
  content: "\5b";
}

.dripicons-feed:before {
  content: "\5d";
}

.dripicons-flag:before {
  content: "\5e";
}

.dripicons-flashlight:before {
  content: "\5f";
}

.dripicons-folder:before {
  content: "\60";
}

.dripicons-folder-open:before {
  content: "\7b";
}

.dripicons-forward:before {
  content: "\7c";
}

.dripicons-gaming:before {
  content: "\7d";
}

.dripicons-gear:before {
  content: "\7e";
}

.dripicons-graduation:before {
  content: "\5c";
}

.dripicons-graph-bar:before {
  content: "\e000";
}

.dripicons-graph-line:before {
  content: "\e001";
}

.dripicons-graph-pie:before {
  content: "\e002";
}

.dripicons-headset:before {
  content: "\e003";
}

.dripicons-heart:before {
  content: "\e004";
}

.dripicons-help:before {
  content: "\e005";
}

.dripicons-home:before {
  content: "\e006";
}

.dripicons-hourglass:before {
  content: "\e007";
}

.dripicons-inbox:before {
  content: "\e008";
}

.dripicons-information:before {
  content: "\e009";
}

.dripicons-italic:before {
  content: "\e00a";
}

.dripicons-jewel:before {
  content: "\e00b";
}

.dripicons-lifting:before {
  content: "\e00c";
}

.dripicons-lightbulb:before {
  content: "\e00d";
}

.dripicons-link:before {
  content: "\e00e";
}

.dripicons-link-broken:before {
  content: "\e00f";
}

.dripicons-list:before {
  content: "\e010";
}

.dripicons-loading:before {
  content: "\e011";
}

.dripicons-location:before {
  content: "\e012";
}

.dripicons-lock:before {
  content: "\e013";
}

.dripicons-lock-open:before {
  content: "\e014";
}

.dripicons-mail:before {
  content: "\e015";
}

.dripicons-map:before {
  content: "\e016";
}

.dripicons-media-loop:before {
  content: "\e017";
}

.dripicons-media-next:before {
  content: "\e018";
}

.dripicons-media-pause:before {
  content: "\e019";
}

.dripicons-media-play:before {
  content: "\e01a";
}

.dripicons-media-previous:before {
  content: "\e01b";
}

.dripicons-media-record:before {
  content: "\e01c";
}

.dripicons-media-shuffle:before {
  content: "\e01d";
}

.dripicons-media-stop:before {
  content: "\e01e";
}

.dripicons-medical:before {
  content: "\e01f";
}

.dripicons-menu:before {
  content: "\e020";
}

.dripicons-message:before {
  content: "\e021";
}

.dripicons-meter:before {
  content: "\e022";
}

.dripicons-microphone:before {
  content: "\e023";
}

.dripicons-minus:before {
  content: "\e024";
}

.dripicons-monitor:before {
  content: "\e025";
}

.dripicons-move:before {
  content: "\e026";
}

.dripicons-music:before {
  content: "\e027";
}

.dripicons-network-1:before {
  content: "\e028";
}

.dripicons-network-2:before {
  content: "\e029";
}

.dripicons-network-3:before {
  content: "\e02a";
}

.dripicons-network-4:before {
  content: "\e02b";
}

.dripicons-network-5:before {
  content: "\e02c";
}

.dripicons-pamphlet:before {
  content: "\e02d";
}

.dripicons-paperclip:before {
  content: "\e02e";
}

.dripicons-pencil:before {
  content: "\e02f";
}

.dripicons-phone:before {
  content: "\e030";
}

.dripicons-photo:before {
  content: "\e031";
}

.dripicons-photo-group:before {
  content: "\e032";
}

.dripicons-pill:before {
  content: "\e033";
}

.dripicons-pin:before {
  content: "\e034";
}

.dripicons-plus:before {
  content: "\e035";
}

.dripicons-power:before {
  content: "\e036";
}

.dripicons-preview:before {
  content: "\e037";
}

.dripicons-print:before {
  content: "\e038";
}

.dripicons-pulse:before {
  content: "\e039";
}

.dripicons-question:before {
  content: "\e03a";
}

.dripicons-reply:before {
  content: "\e03b";
}

.dripicons-reply-all:before {
  content: "\e03c";
}

.dripicons-return:before {
  content: "\e03d";
}

.dripicons-retweet:before {
  content: "\e03e";
}

.dripicons-rocket:before {
  content: "\e03f";
}

.dripicons-scale:before {
  content: "\e040";
}

.dripicons-search:before {
  content: "\e041";
}

.dripicons-shopping-bag:before {
  content: "\e042";
}

.dripicons-skip:before {
  content: "\e043";
}

.dripicons-stack:before {
  content: "\e044";
}

.dripicons-star:before {
  content: "\e045";
}

.dripicons-stopwatch:before {
  content: "\e046";
}

.dripicons-store:before {
  content: "\e047";
}

.dripicons-suitcase:before {
  content: "\e048";
}

.dripicons-swap:before {
  content: "\e049";
}

.dripicons-tag:before {
  content: "\e04a";
}

.dripicons-tag-delete:before {
  content: "\e04b";
}

.dripicons-tags:before {
  content: "\e04c";
}

.dripicons-thumbs-down:before {
  content: "\e04d";
}

.dripicons-thumbs-up:before {
  content: "\e04e";
}

.dripicons-ticket:before {
  content: "\e04f";
}

.dripicons-time-reverse:before {
  content: "\e050";
}

.dripicons-to-do:before {
  content: "\e051";
}

.dripicons-toggles:before {
  content: "\e052";
}

.dripicons-trash:before {
  content: "\e053";
}

.dripicons-trophy:before {
  content: "\e054";
}

.dripicons-upload:before {
  content: "\e055";
}

.dripicons-user:before {
  content: "\e056";
}

.dripicons-user-group:before {
  content: "\e057";
}

.dripicons-user-id:before {
  content: "\e058";
}

.dripicons-vibrate:before {
  content: "\e059";
}

.dripicons-view-apps:before {
  content: "\e05a";
}

.dripicons-view-list:before {
  content: "\e05b";
}

.dripicons-view-list-large:before {
  content: "\e05c";
}

.dripicons-view-thumb:before {
  content: "\e05d";
}

.dripicons-volume-full:before {
  content: "\e05e";
}

.dripicons-volume-low:before {
  content: "\e05f";
}

.dripicons-volume-medium:before {
  content: "\e060";
}

.dripicons-volume-off:before {
  content: "\e061";
}

.dripicons-wallet:before {
  content: "\e062";
}

.dripicons-warning:before {
  content: "\e063";
}

.dripicons-web:before {
  content: "\e064";
}

.dripicons-weight:before {
  content: "\e065";
}

.dripicons-wifi:before {
  content: "\e066";
}

.dripicons-wrong:before {
  content: "\e067";
}

.dripicons-zoom-in:before {
  content: "\e068";
}

.dripicons-zoom-out:before {
  content: "\e069";
}

/* @FONT-FACE loads font into browser */
@font-face {
  font-family: 'typicons';
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/typicons.eot");
  src: url("../fonts/typicons.eot?#iefix") format("embedded-opentype"), url("../fonts/typicons.woff") format("woff"), url("../fonts/typicons.ttf") format("truetype"), url("../fonts/typicons.svg#typicons") format("svg");
}

/* :before psuedo-selector inserts and styles icon */
.typcn:before {
  font-family: 'typicons';
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  height: 1em;
  font-size: 1em;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

/* Code for individual icons */
.typcn-adjust-brightness:before {
  content: '\e000';
  /* '' */
}

.typcn-adjust-contrast:before {
  content: '\e001';
  /* '' */
}

.typcn-anchor-outline:before {
  content: '\e002';
  /* '' */
}

.typcn-anchor:before {
  content: '\e003';
  /* '' */
}

.typcn-archive:before {
  content: '\e004';
  /* '' */
}

.typcn-arrow-back-outline:before {
  content: '\e005';
  /* '' */
}

.typcn-arrow-back:before {
  content: '\e006';
  /* '' */
}

.typcn-arrow-down-outline:before {
  content: '\e007';
  /* '' */
}

.typcn-arrow-down-thick:before {
  content: '\e008';
  /* '' */
}

.typcn-arrow-down:before {
  content: '\e009';
  /* '' */
}

.typcn-arrow-forward-outline:before {
  content: '\e00a';
  /* '' */
}

.typcn-arrow-forward:before {
  content: '\e00b';
  /* '' */
}

.typcn-arrow-left-outline:before {
  content: '\e00c';
  /* '' */
}

.typcn-arrow-left-thick:before {
  content: '\e00d';
  /* '' */
}

.typcn-arrow-left:before {
  content: '\e00e';
  /* '' */
}

.typcn-arrow-loop-outline:before {
  content: '\e00f';
  /* '' */
}

.typcn-arrow-loop:before {
  content: '\e010';
  /* '' */
}

.typcn-arrow-maximise-outline:before {
  content: '\e011';
  /* '' */
}

.typcn-arrow-maximise:before {
  content: '\e012';
  /* '' */
}

.typcn-arrow-minimise-outline:before {
  content: '\e013';
  /* '' */
}

.typcn-arrow-minimise:before {
  content: '\e014';
  /* '' */
}

.typcn-arrow-move-outline:before {
  content: '\e015';
  /* '' */
}

.typcn-arrow-move:before {
  content: '\e016';
  /* '' */
}

.typcn-arrow-repeat-outline:before {
  content: '\e017';
  /* '' */
}

.typcn-arrow-repeat:before {
  content: '\e018';
  /* '' */
}

.typcn-arrow-right-outline:before {
  content: '\e019';
  /* '' */
}

.typcn-arrow-right-thick:before {
  content: '\e01a';
  /* '' */
}

.typcn-arrow-right:before {
  content: '\e01b';
  /* '' */
}

.typcn-arrow-shuffle:before {
  content: '\e01c';
  /* '' */
}

.typcn-arrow-sorted-down:before {
  content: '\e01d';
  /* '' */
}

.typcn-arrow-sorted-up:before {
  content: '\e01e';
  /* '' */
}

.typcn-arrow-sync-outline:before {
  content: '\e01f';
  /* '' */
}

.typcn-arrow-sync:before {
  content: '\e020';
  /* '' */
}

.typcn-arrow-unsorted:before {
  content: '\e021';
  /* '' */
}

.typcn-arrow-up-outline:before {
  content: '\e022';
  /* '' */
}

.typcn-arrow-up-thick:before {
  content: '\e023';
  /* '' */
}

.typcn-arrow-up:before {
  content: '\e024';
  /* '' */
}

.typcn-at:before {
  content: '\e025';
  /* '' */
}

.typcn-attachment-outline:before {
  content: '\e026';
  /* '' */
}

.typcn-attachment:before {
  content: '\e027';
  /* '' */
}

.typcn-backspace-outline:before {
  content: '\e028';
  /* '' */
}

.typcn-backspace:before {
  content: '\e029';
  /* '' */
}

.typcn-battery-charge:before {
  content: '\e02a';
  /* '' */
}

.typcn-battery-full:before {
  content: '\e02b';
  /* '' */
}

.typcn-battery-high:before {
  content: '\e02c';
  /* '' */
}

.typcn-battery-low:before {
  content: '\e02d';
  /* '' */
}

.typcn-battery-mid:before {
  content: '\e02e';
  /* '' */
}

.typcn-beaker:before {
  content: '\e02f';
  /* '' */
}

.typcn-beer:before {
  content: '\e030';
  /* '' */
}

.typcn-bell:before {
  content: '\e031';
  /* '' */
}

.typcn-book:before {
  content: '\e032';
  /* '' */
}

.typcn-bookmark:before {
  content: '\e033';
  /* '' */
}

.typcn-briefcase:before {
  content: '\e034';
  /* '' */
}

.typcn-brush:before {
  content: '\e035';
  /* '' */
}

.typcn-business-card:before {
  content: '\e036';
  /* '' */
}

.typcn-calculator:before {
  content: '\e037';
  /* '' */
}

.typcn-calendar-outline:before {
  content: '\e038';
  /* '' */
}

.typcn-calendar:before {
  content: '\e039';
  /* '' */
}

.typcn-camera-outline:before {
  content: '\e03a';
  /* '' */
}

.typcn-camera:before {
  content: '\e03b';
  /* '' */
}

.typcn-cancel-outline:before {
  content: '\e03c';
  /* '' */
}

.typcn-cancel:before {
  content: '\e03d';
  /* '' */
}

.typcn-chart-area-outline:before {
  content: '\e03e';
  /* '' */
}

.typcn-chart-area:before {
  content: '\e03f';
  /* '' */
}

.typcn-chart-bar-outline:before {
  content: '\e040';
  /* '' */
}

.typcn-chart-bar:before {
  content: '\e041';
  /* '' */
}

.typcn-chart-line-outline:before {
  content: '\e042';
  /* '' */
}

.typcn-chart-line:before {
  content: '\e043';
  /* '' */
}

.typcn-chart-pie-outline:before {
  content: '\e044';
  /* '' */
}

.typcn-chart-pie:before {
  content: '\e045';
  /* '' */
}

.typcn-chevron-left-outline:before {
  content: '\e046';
  /* '' */
}

.typcn-chevron-left:before {
  content: '\e047';
  /* '' */
}

.typcn-chevron-right-outline:before {
  content: '\e048';
  /* '' */
}

.typcn-chevron-right:before {
  content: '\e049';
  /* '' */
}

.typcn-clipboard:before {
  content: '\e04a';
  /* '' */
}

.typcn-cloud-storage:before {
  content: '\e04b';
  /* '' */
}

.typcn-cloud-storage-outline:before {
  content: '\e054';
  /* '' */
}

.typcn-code-outline:before {
  content: '\e04c';
  /* '' */
}

.typcn-code:before {
  content: '\e04d';
  /* '' */
}

.typcn-coffee:before {
  content: '\e04e';
  /* '' */
}

.typcn-cog-outline:before {
  content: '\e04f';
  /* '' */
}

.typcn-cog:before {
  content: '\e050';
  /* '' */
}

.typcn-compass:before {
  content: '\e051';
  /* '' */
}

.typcn-contacts:before {
  content: '\e052';
  /* '' */
}

.typcn-credit-card:before {
  content: '\e053';
  /* '' */
}

.typcn-css3:before {
  content: '\e055';
  /* '' */
}

.typcn-database:before {
  content: '\e056';
  /* '' */
}

.typcn-delete-outline:before {
  content: '\e057';
  /* '' */
}

.typcn-delete:before {
  content: '\e058';
  /* '' */
}

.typcn-device-desktop:before {
  content: '\e059';
  /* '' */
}

.typcn-device-laptop:before {
  content: '\e05a';
  /* '' */
}

.typcn-device-phone:before {
  content: '\e05b';
  /* '' */
}

.typcn-device-tablet:before {
  content: '\e05c';
  /* '' */
}

.typcn-directions:before {
  content: '\e05d';
  /* '' */
}

.typcn-divide-outline:before {
  content: '\e05e';
  /* '' */
}

.typcn-divide:before {
  content: '\e05f';
  /* '' */
}

.typcn-document-add:before {
  content: '\e060';
  /* '' */
}

.typcn-document-delete:before {
  content: '\e061';
  /* '' */
}

.typcn-document-text:before {
  content: '\e062';
  /* '' */
}

.typcn-document:before {
  content: '\e063';
  /* '' */
}

.typcn-download-outline:before {
  content: '\e064';
  /* '' */
}

.typcn-download:before {
  content: '\e065';
  /* '' */
}

.typcn-dropbox:before {
  content: '\e066';
  /* '' */
}

.typcn-edit:before {
  content: '\e067';
  /* '' */
}

.typcn-eject-outline:before {
  content: '\e068';
  /* '' */
}

.typcn-eject:before {
  content: '\e069';
  /* '' */
}

.typcn-equals-outline:before {
  content: '\e06a';
  /* '' */
}

.typcn-equals:before {
  content: '\e06b';
  /* '' */
}

.typcn-export-outline:before {
  content: '\e06c';
  /* '' */
}

.typcn-export:before {
  content: '\e06d';
  /* '' */
}

.typcn-eye-outline:before {
  content: '\e06e';
  /* '' */
}

.typcn-eye:before {
  content: '\e06f';
  /* '' */
}

.typcn-feather:before {
  content: '\e070';
  /* '' */
}

.typcn-film:before {
  content: '\e071';
  /* '' */
}

.typcn-filter:before {
  content: '\e072';
  /* '' */
}

.typcn-flag-outline:before {
  content: '\e073';
  /* '' */
}

.typcn-flag:before {
  content: '\e074';
  /* '' */
}

.typcn-flash-outline:before {
  content: '\e075';
  /* '' */
}

.typcn-flash:before {
  content: '\e076';
  /* '' */
}

.typcn-flow-children:before {
  content: '\e077';
  /* '' */
}

.typcn-flow-merge:before {
  content: '\e078';
  /* '' */
}

.typcn-flow-parallel:before {
  content: '\e079';
  /* '' */
}

.typcn-flow-switch:before {
  content: '\e07a';
  /* '' */
}

.typcn-folder-add:before {
  content: '\e07b';
  /* '' */
}

.typcn-folder-delete:before {
  content: '\e07c';
  /* '' */
}

.typcn-folder-open:before {
  content: '\e07d';
  /* '' */
}

.typcn-folder:before {
  content: '\e07e';
  /* '' */
}

.typcn-gift:before {
  content: '\e07f';
  /* '' */
}

.typcn-globe-outline:before {
  content: '\e080';
  /* '' */
}

.typcn-globe:before {
  content: '\e081';
  /* '' */
}

.typcn-group-outline:before {
  content: '\e082';
  /* '' */
}

.typcn-group:before {
  content: '\e083';
  /* '' */
}

.typcn-headphones:before {
  content: '\e084';
  /* '' */
}

.typcn-heart-full-outline:before {
  content: '\e085';
  /* '' */
}

.typcn-heart-half-outline:before {
  content: '\e086';
  /* '' */
}

.typcn-heart-outline:before {
  content: '\e087';
  /* '' */
}

.typcn-heart:before {
  content: '\e088';
  /* '' */
}

.typcn-home-outline:before {
  content: '\e089';
  /* '' */
}

.typcn-home:before {
  content: '\e08a';
  /* '' */
}

.typcn-html5:before {
  content: '\e08b';
  /* '' */
}

.typcn-image-outline:before {
  content: '\e08c';
  /* '' */
}

.typcn-image:before {
  content: '\e08d';
  /* '' */
}

.typcn-infinity-outline:before {
  content: '\e08e';
  /* '' */
}

.typcn-infinity:before {
  content: '\e08f';
  /* '' */
}

.typcn-info-large-outline:before {
  content: '\e090';
  /* '' */
}

.typcn-info-large:before {
  content: '\e091';
  /* '' */
}

.typcn-info-outline:before {
  content: '\e092';
  /* '' */
}

.typcn-info:before {
  content: '\e093';
  /* '' */
}

.typcn-input-checked-outline:before {
  content: '\e094';
  /* '' */
}

.typcn-input-checked:before {
  content: '\e095';
  /* '' */
}

.typcn-key-outline:before {
  content: '\e096';
  /* '' */
}

.typcn-key:before {
  content: '\e097';
  /* '' */
}

.typcn-keyboard:before {
  content: '\e098';
  /* '' */
}

.typcn-leaf:before {
  content: '\e099';
  /* '' */
}

.typcn-lightbulb:before {
  content: '\e09a';
  /* '' */
}

.typcn-link-outline:before {
  content: '\e09b';
  /* '' */
}

.typcn-link:before {
  content: '\e09c';
  /* '' */
}

.typcn-location-arrow-outline:before {
  content: '\e09d';
  /* '' */
}

.typcn-location-arrow:before {
  content: '\e09e';
  /* '' */
}

.typcn-location-outline:before {
  content: '\e09f';
  /* '' */
}

.typcn-location:before {
  content: '\e0a0';
  /* '' */
}

.typcn-lock-closed-outline:before {
  content: '\e0a1';
  /* '' */
}

.typcn-lock-closed:before {
  content: '\e0a2';
  /* '' */
}

.typcn-lock-open-outline:before {
  content: '\e0a3';
  /* '' */
}

.typcn-lock-open:before {
  content: '\e0a4';
  /* '' */
}

.typcn-mail:before {
  content: '\e0a5';
  /* '' */
}

.typcn-map:before {
  content: '\e0a6';
  /* '' */
}

.typcn-media-eject-outline:before {
  content: '\e0a7';
  /* '' */
}

.typcn-media-eject:before {
  content: '\e0a8';
  /* '' */
}

.typcn-media-fast-forward-outline:before {
  content: '\e0a9';
  /* '' */
}

.typcn-media-fast-forward:before {
  content: '\e0aa';
  /* '' */
}

.typcn-media-pause-outline:before {
  content: '\e0ab';
  /* '' */
}

.typcn-media-pause:before {
  content: '\e0ac';
  /* '' */
}

.typcn-media-play-outline:before {
  content: '\e0ad';
  /* '' */
}

.typcn-media-play-reverse-outline:before {
  content: '\e0ae';
  /* '' */
}

.typcn-media-play-reverse:before {
  content: '\e0af';
  /* '' */
}

.typcn-media-play:before {
  content: '\e0b0';
  /* '' */
}

.typcn-media-record-outline:before {
  content: '\e0b1';
  /* '' */
}

.typcn-media-record:before {
  content: '\e0b2';
  /* '' */
}

.typcn-media-rewind-outline:before {
  content: '\e0b3';
  /* '' */
}

.typcn-media-rewind:before {
  content: '\e0b4';
  /* '' */
}

.typcn-media-stop-outline:before {
  content: '\e0b5';
  /* '' */
}

.typcn-media-stop:before {
  content: '\e0b6';
  /* '' */
}

.typcn-message-typing:before {
  content: '\e0b7';
  /* '' */
}

.typcn-message:before {
  content: '\e0b8';
  /* '' */
}

.typcn-messages:before {
  content: '\e0b9';
  /* '' */
}

.typcn-microphone-outline:before {
  content: '\e0ba';
  /* '' */
}

.typcn-microphone:before {
  content: '\e0bb';
  /* '' */
}

.typcn-minus-outline:before {
  content: '\e0bc';
  /* '' */
}

.typcn-minus:before {
  content: '\e0bd';
  /* '' */
}

.typcn-mortar-board:before {
  content: '\e0be';
  /* '' */
}

.typcn-news:before {
  content: '\e0bf';
  /* '' */
}

.typcn-notes-outline:before {
  content: '\e0c0';
  /* '' */
}

.typcn-notes:before {
  content: '\e0c1';
  /* '' */
}

.typcn-pen:before {
  content: '\e0c2';
  /* '' */
}

.typcn-pencil:before {
  content: '\e0c3';
  /* '' */
}

.typcn-phone-outline:before {
  content: '\e0c4';
  /* '' */
}

.typcn-phone:before {
  content: '\e0c5';
  /* '' */
}

.typcn-pi-outline:before {
  content: '\e0c6';
  /* '' */
}

.typcn-pi:before {
  content: '\e0c7';
  /* '' */
}

.typcn-pin-outline:before {
  content: '\e0c8';
  /* '' */
}

.typcn-pin:before {
  content: '\e0c9';
  /* '' */
}

.typcn-pipette:before {
  content: '\e0ca';
  /* '' */
}

.typcn-plane-outline:before {
  content: '\e0cb';
  /* '' */
}

.typcn-plane:before {
  content: '\e0cc';
  /* '' */
}

.typcn-plug:before {
  content: '\e0cd';
  /* '' */
}

.typcn-plus-outline:before {
  content: '\e0ce';
  /* '' */
}

.typcn-plus:before {
  content: '\e0cf';
  /* '' */
}

.typcn-point-of-interest-outline:before {
  content: '\e0d0';
  /* '' */
}

.typcn-point-of-interest:before {
  content: '\e0d1';
  /* '' */
}

.typcn-power-outline:before {
  content: '\e0d2';
  /* '' */
}

.typcn-power:before {
  content: '\e0d3';
  /* '' */
}

.typcn-printer:before {
  content: '\e0d4';
  /* '' */
}

.typcn-puzzle-outline:before {
  content: '\e0d5';
  /* '' */
}

.typcn-puzzle:before {
  content: '\e0d6';
  /* '' */
}

.typcn-radar-outline:before {
  content: '\e0d7';
  /* '' */
}

.typcn-radar:before {
  content: '\e0d8';
  /* '' */
}

.typcn-refresh-outline:before {
  content: '\e0d9';
  /* '' */
}

.typcn-refresh:before {
  content: '\e0da';
  /* '' */
}

.typcn-rss-outline:before {
  content: '\e0db';
  /* '' */
}

.typcn-rss:before {
  content: '\e0dc';
  /* '' */
}

.typcn-scissors-outline:before {
  content: '\e0dd';
  /* '' */
}

.typcn-scissors:before {
  content: '\e0de';
  /* '' */
}

.typcn-shopping-bag:before {
  content: '\e0df';
  /* '' */
}

.typcn-shopping-cart:before {
  content: '\e0e0';
  /* '' */
}

.typcn-social-at-circular:before {
  content: '\e0e1';
  /* '' */
}

.typcn-social-dribbble-circular:before {
  content: '\e0e2';
  /* '' */
}

.typcn-social-dribbble:before {
  content: '\e0e3';
  /* '' */
}

.typcn-social-facebook-circular:before {
  content: '\e0e4';
  /* '' */
}

.typcn-social-facebook:before {
  content: '\e0e5';
  /* '' */
}

.typcn-social-flickr-circular:before {
  content: '\e0e6';
  /* '' */
}

.typcn-social-flickr:before {
  content: '\e0e7';
  /* '' */
}

.typcn-social-github-circular:before {
  content: '\e0e8';
  /* '' */
}

.typcn-social-github:before {
  content: '\e0e9';
  /* '' */
}

.typcn-social-google-plus-circular:before {
  content: '\e0ea';
  /* '' */
}

.typcn-social-google-plus:before {
  content: '\e0eb';
  /* '' */
}

.typcn-social-instagram-circular:before {
  content: '\e0ec';
  /* '' */
}

.typcn-social-instagram:before {
  content: '\e0ed';
  /* '' */
}

.typcn-social-last-fm-circular:before {
  content: '\e0ee';
  /* '' */
}

.typcn-social-last-fm:before {
  content: '\e0ef';
  /* '' */
}

.typcn-social-linkedin-circular:before {
  content: '\e0f0';
  /* '' */
}

.typcn-social-linkedin:before {
  content: '\e0f1';
  /* '' */
}

.typcn-social-pinterest-circular:before {
  content: '\e0f2';
  /* '' */
}

.typcn-social-pinterest:before {
  content: '\e0f3';
  /* '' */
}

.typcn-social-skype-outline:before {
  content: '\e0f4';
  /* '' */
}

.typcn-social-skype:before {
  content: '\e0f5';
  /* '' */
}

.typcn-social-tumbler-circular:before {
  content: '\e0f6';
  /* '' */
}

.typcn-social-tumbler:before {
  content: '\e0f7';
  /* '' */
}

.typcn-social-twitter-circular:before {
  content: '\e0f8';
  /* '' */
}

.typcn-social-twitter:before {
  content: '\e0f9';
  /* '' */
}

.typcn-social-vimeo-circular:before {
  content: '\e0fa';
  /* '' */
}

.typcn-social-vimeo:before {
  content: '\e0fb';
  /* '' */
}

.typcn-social-youtube-circular:before {
  content: '\e0fc';
  /* '' */
}

.typcn-social-youtube:before {
  content: '\e0fd';
  /* '' */
}

.typcn-sort-alphabetically-outline:before {
  content: '\e0fe';
  /* '' */
}

.typcn-sort-alphabetically:before {
  content: '\e0ff';
  /* '' */
}

.typcn-sort-numerically-outline:before {
  content: '\e100';
  /* '' */
}

.typcn-sort-numerically:before {
  content: '\e101';
  /* '' */
}

.typcn-spanner-outline:before {
  content: '\e102';
  /* '' */
}

.typcn-spanner:before {
  content: '\e103';
  /* '' */
}

.typcn-spiral:before {
  content: '\e104';
  /* '' */
}

.typcn-star-full-outline:before {
  content: '\e105';
  /* '' */
}

.typcn-star-half-outline:before {
  content: '\e106';
  /* '' */
}

.typcn-star-half:before {
  content: '\e107';
  /* '' */
}

.typcn-star-outline:before {
  content: '\e108';
  /* '' */
}

.typcn-star:before {
  content: '\e109';
  /* '' */
}

.typcn-starburst-outline:before {
  content: '\e10a';
  /* '' */
}

.typcn-starburst:before {
  content: '\e10b';
  /* '' */
}

.typcn-stopwatch:before {
  content: '\e10c';
  /* '' */
}

.typcn-support:before {
  content: '\e10d';
  /* '' */
}

.typcn-tabs-outline:before {
  content: '\e10e';
  /* '' */
}

.typcn-tag:before {
  content: '\e10f';
  /* '' */
}

.typcn-tags:before {
  content: '\e110';
  /* '' */
}

.typcn-th-large-outline:before {
  content: '\e111';
  /* '' */
}

.typcn-th-large:before {
  content: '\e112';
  /* '' */
}

.typcn-th-list-outline:before {
  content: '\e113';
  /* '' */
}

.typcn-th-list:before {
  content: '\e114';
  /* '' */
}

.typcn-th-menu-outline:before {
  content: '\e115';
  /* '' */
}

.typcn-th-menu:before {
  content: '\e116';
  /* '' */
}

.typcn-th-small-outline:before {
  content: '\e117';
  /* '' */
}

.typcn-th-small:before {
  content: '\e118';
  /* '' */
}

.typcn-thermometer:before {
  content: '\e119';
  /* '' */
}

.typcn-thumbs-down:before {
  content: '\e11a';
  /* '' */
}

.typcn-thumbs-ok:before {
  content: '\e11b';
  /* '' */
}

.typcn-thumbs-up:before {
  content: '\e11c';
  /* '' */
}

.typcn-tick-outline:before {
  content: '\e11d';
  /* '' */
}

.typcn-tick:before {
  content: '\e11e';
  /* '' */
}

.typcn-ticket:before {
  content: '\e11f';
  /* '' */
}

.typcn-time:before {
  content: '\e120';
  /* '' */
}

.typcn-times-outline:before {
  content: '\e121';
  /* '' */
}

.typcn-times:before {
  content: '\e122';
  /* '' */
}

.typcn-trash:before {
  content: '\e123';
  /* '' */
}

.typcn-tree:before {
  content: '\e124';
  /* '' */
}

.typcn-upload-outline:before {
  content: '\e125';
  /* '' */
}

.typcn-upload:before {
  content: '\e126';
  /* '' */
}

.typcn-user-add-outline:before {
  content: '\e127';
  /* '' */
}

.typcn-user-add:before {
  content: '\e128';
  /* '' */
}

.typcn-user-delete-outline:before {
  content: '\e129';
  /* '' */
}

.typcn-user-delete:before {
  content: '\e12a';
  /* '' */
}

.typcn-user-outline:before {
  content: '\e12b';
  /* '' */
}

.typcn-user:before {
  content: '\e12c';
  /* '' */
}

.typcn-vendor-android:before {
  content: '\e12d';
  /* '' */
}

.typcn-vendor-apple:before {
  content: '\e12e';
  /* '' */
}

.typcn-vendor-microsoft:before {
  content: '\e12f';
  /* '' */
}

.typcn-video-outline:before {
  content: '\e130';
  /* '' */
}

.typcn-video:before {
  content: '\e131';
  /* '' */
}

.typcn-volume-down:before {
  content: '\e132';
  /* '' */
}

.typcn-volume-mute:before {
  content: '\e133';
  /* '' */
}

.typcn-volume-up:before {
  content: '\e134';
  /* '' */
}

.typcn-volume:before {
  content: '\e135';
  /* '' */
}

.typcn-warning-outline:before {
  content: '\e136';
  /* '' */
}

.typcn-warning:before {
  content: '\e137';
  /* '' */
}

.typcn-watch:before {
  content: '\e138';
  /* '' */
}

.typcn-waves-outline:before {
  content: '\e139';
  /* '' */
}

.typcn-waves:before {
  content: '\e13a';
  /* '' */
}

.typcn-weather-cloudy:before {
  content: '\e13b';
  /* '' */
}

.typcn-weather-downpour:before {
  content: '\e13c';
  /* '' */
}

.typcn-weather-night:before {
  content: '\e13d';
  /* '' */
}

.typcn-weather-partly-sunny:before {
  content: '\e13e';
  /* '' */
}

.typcn-weather-shower:before {
  content: '\e13f';
  /* '' */
}

.typcn-weather-snow:before {
  content: '\e140';
  /* '' */
}

.typcn-weather-stormy:before {
  content: '\e141';
  /* '' */
}

.typcn-weather-sunny:before {
  content: '\e142';
  /* '' */
}

.typcn-weather-windy-cloudy:before {
  content: '\e143';
  /* '' */
}

.typcn-weather-windy:before {
  content: '\e144';
  /* '' */
}

.typcn-wi-fi-outline:before {
  content: '\e145';
  /* '' */
}

.typcn-wi-fi:before {
  content: '\e146';
  /* '' */
}

.typcn-wine:before {
  content: '\e147';
  /* '' */
}

.typcn-world-outline:before {
  content: '\e148';
  /* '' */
}

.typcn-world:before {
  content: '\e149';
  /* '' */
}

.typcn-zoom-in-outline:before {
  content: '\e14a';
  /* '' */
}

.typcn-zoom-in:before {
  content: '\e14b';
  /* '' */
}

.typcn-zoom-out-outline:before {
  content: '\e14c';
  /* '' */
}

.typcn-zoom-out:before {
  content: '\e14d';
  /* '' */
}

.typcn-zoom-outline:before {
  content: '\e14e';
  /* '' */
}

.typcn-zoom:before {
  content: '\e14f';
  /* '' */
}

.em, .em-svg {
  height: 1.5em;
  width: 1.5em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  vertical-align: middle;
}

.em---1, .em---1, .em-thumbsup {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f44d.png");
}

.em---1.em-svg, .em---1.em-svg, .em-thumbsup.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f44d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f44d.svg"), none;
}

.em--1, .em-thumbsdown {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f44e.png");
}

.em--1.em-svg, .em-thumbsdown.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f44e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f44e.svg"), none;
}

.em-100 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4af.png");
}

.em-100.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4af.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4af.svg"), none;
}

.em-1234 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f522.png");
}

.em-1234.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f522.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f522.svg"), none;
}

.em-8ball {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3b1.png");
}

.em-8ball.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3b1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3b1.svg"), none;
}

.em-a {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f170.png");
}

.em-a.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f170.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f170.svg"), none;
}

.em-ab {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f18e.png");
}

.em-ab.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f18e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f18e.svg"), none;
}

.em-abc {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f524.png");
}

.em-abc.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f524.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f524.svg"), none;
}

.em-abcd {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f521.png");
}

.em-abcd.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f521.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f521.svg"), none;
}

.em-accept {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f251.png");
}

.em-accept.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f251.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f251.svg"), none;
}

.em-admission_tickets {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f39f.png");
}

.em-admission_tickets.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f39f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f39f.svg"), none;
}

.em-adult {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9d1.png");
}

.em-adult.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9d1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9d1.svg"), none;
}

.em-aerial_tramway {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6a1.png");
}

.em-aerial_tramway.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6a1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a1.svg"), none;
}

.em-airplane {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2708.png");
}

.em-airplane.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2708.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2708.svg"), none;
}

.em-airplane_arriving {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6ec.png");
}

.em-airplane_arriving.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6ec.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6ec.svg"), none;
}

.em-airplane_departure {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6eb.png");
}

.em-airplane_departure.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6eb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6eb.svg"), none;
}

.em-alarm_clock {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/23f0.png");
}

.em-alarm_clock.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/23f0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/23f0.svg"), none;
}

.em-alembic {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2697.png");
}

.em-alembic.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2697.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2697.svg"), none;
}

.em-alien {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f47d.png");
}

.em-alien.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f47d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f47d.svg"), none;
}

.em-ambulance {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f691.png");
}

.em-ambulance.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f691.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f691.svg"), none;
}

.em-amphora {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3fa.png");
}

.em-amphora.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3fa.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3fa.svg"), none;
}

.em-anchor {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2693.png");
}

.em-anchor.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2693.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2693.svg"), none;
}

.em-angel {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f47c.png");
}

.em-angel.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f47c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f47c.svg"), none;
}

.em-anger {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4a2.png");
}

.em-anger.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4a2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4a2.svg"), none;
}

.em-angry {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f620.png");
}

.em-angry.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f620.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f620.svg"), none;
}

.em-anguished {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f627.png");
}

.em-anguished.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f627.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f627.svg"), none;
}

.em-ant {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f41c.png");
}

.em-ant.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f41c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f41c.svg"), none;
}

.em-apple {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f34e.png");
}

.em-apple.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f34e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f34e.svg"), none;
}

.em-aquarius {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2652.png");
}

.em-aquarius.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2652.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2652.svg"), none;
}

.em-aries {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2648.png");
}

.em-aries.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2648.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2648.svg"), none;
}

.em-arrow_backward {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/25c0.png");
}

.em-arrow_backward.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/25c0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/25c0.svg"), none;
}

.em-arrow_double_down {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/23ec.png");
}

.em-arrow_double_down.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/23ec.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/23ec.svg"), none;
}

.em-arrow_double_up {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/23eb.png");
}

.em-arrow_double_up.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/23eb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/23eb.svg"), none;
}

.em-arrow_down {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2b07.png");
}

.em-arrow_down.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2b07.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2b07.svg"), none;
}

.em-arrow_down_small {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f53d.png");
}

.em-arrow_down_small.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f53d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f53d.svg"), none;
}

.em-arrow_forward {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/25b6.png");
}

.em-arrow_forward.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/25b6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/25b6.svg"), none;
}

.em-arrow_heading_down {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2935.png");
}

.em-arrow_heading_down.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2935.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2935.svg"), none;
}

.em-arrow_heading_up {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2934.png");
}

.em-arrow_heading_up.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2934.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2934.svg"), none;
}

.em-arrow_left {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2b05.png");
}

.em-arrow_left.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2b05.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2b05.svg"), none;
}

.em-arrow_lower_left {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2199.png");
}

.em-arrow_lower_left.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2199.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2199.svg"), none;
}

.em-arrow_lower_right {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2198.png");
}

.em-arrow_lower_right.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2198.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2198.svg"), none;
}

.em-arrow_right {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/27a1.png");
}

.em-arrow_right.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/27a1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/27a1.svg"), none;
}

.em-arrow_right_hook {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/21aa.png");
}

.em-arrow_right_hook.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/21aa.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/21aa.svg"), none;
}

.em-arrow_up {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2b06.png");
}

.em-arrow_up.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2b06.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2b06.svg"), none;
}

.em-arrow_up_down {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2195.png");
}

.em-arrow_up_down.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2195.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2195.svg"), none;
}

.em-arrow_up_small {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f53c.png");
}

.em-arrow_up_small.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f53c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f53c.svg"), none;
}

.em-arrow_upper_left {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2196.png");
}

.em-arrow_upper_left.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2196.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2196.svg"), none;
}

.em-arrow_upper_right {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2197.png");
}

.em-arrow_upper_right.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2197.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2197.svg"), none;
}

.em-arrows_clockwise {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f503.png");
}

.em-arrows_clockwise.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f503.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f503.svg"), none;
}

.em-arrows_counterclockwise {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f504.png");
}

.em-arrows_counterclockwise.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f504.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f504.svg"), none;
}

.em-art {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3a8.png");
}

.em-art.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3a8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3a8.svg"), none;
}

.em-articulated_lorry {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f69b.png");
}

.em-articulated_lorry.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f69b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f69b.svg"), none;
}

.em-astonished {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f632.png");
}

.em-astonished.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f632.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f632.svg"), none;
}

.em-athletic_shoe {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f45f.png");
}

.em-athletic_shoe.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f45f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f45f.svg"), none;
}

.em-atm {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3e7.png");
}

.em-atm.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3e7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3e7.svg"), none;
}

.em-atom_symbol {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/269b.png");
}

.em-atom_symbol.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/269b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/269b.svg"), none;
}

.em-avocado {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f951.png");
}

.em-avocado.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f951.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f951.svg"), none;
}

.em-b {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f171.png");
}

.em-b.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f171.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f171.svg"), none;
}

.em-baby {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f476.png");
}

.em-baby.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f476.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f476.svg"), none;
}

.em-baby_bottle {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f37c.png");
}

.em-baby_bottle.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f37c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f37c.svg"), none;
}

.em-baby_chick {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f424.png");
}

.em-baby_chick.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f424.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f424.svg"), none;
}

.em-baby_symbol {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6bc.png");
}

.em-baby_symbol.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6bc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6bc.svg"), none;
}

.em-back {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f519.png");
}

.em-back.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f519.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f519.svg"), none;
}

.em-bacon {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f953.png");
}

.em-bacon.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f953.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f953.svg"), none;
}

.em-badminton_racquet_and_shuttlecock {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3f8.png");
}

.em-badminton_racquet_and_shuttlecock.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3f8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3f8.svg"), none;
}

.em-baggage_claim {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6c4.png");
}

.em-baggage_claim.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6c4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6c4.svg"), none;
}

.em-baguette_bread {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f956.png");
}

.em-baguette_bread.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f956.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f956.svg"), none;
}

.em-balloon {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f388.png");
}

.em-balloon.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f388.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f388.svg"), none;
}

.em-ballot_box_with_ballot {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f5f3.png");
}

.em-ballot_box_with_ballot.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f5f3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f5f3.svg"), none;
}

.em-ballot_box_with_check {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2611.png");
}

.em-ballot_box_with_check.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2611.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2611.svg"), none;
}

.em-bamboo {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f38d.png");
}

.em-bamboo.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f38d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f38d.svg"), none;
}

.em-banana {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f34c.png");
}

.em-banana.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f34c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f34c.svg"), none;
}

.em-bangbang {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/203c.png");
}

.em-bangbang.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/203c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/203c.svg"), none;
}

.em-bank {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3e6.png");
}

.em-bank.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3e6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3e6.svg"), none;
}

.em-bar_chart {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4ca.png");
}

.em-bar_chart.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4ca.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ca.svg"), none;
}

.em-barber {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f488.png");
}

.em-barber.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f488.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f488.svg"), none;
}

.em-barely_sunny, .em-sun_behind_cloud {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f325.png");
}

.em-barely_sunny.em-svg, .em-sun_behind_cloud.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f325.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f325.svg"), none;
}

.em-baseball {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26be.png");
}

.em-baseball.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26be.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26be.svg"), none;
}

.em-basketball {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3c0.png");
}

.em-basketball.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3c0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c0.svg"), none;
}

.em-bat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f987.png");
}

.em-bat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f987.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f987.svg"), none;
}

.em-bath {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6c0.png");
}

.em-bath.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6c0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6c0.svg"), none;
}

.em-bathtub {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6c1.png");
}

.em-bathtub.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6c1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6c1.svg"), none;
}

.em-battery {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f50b.png");
}

.em-battery.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f50b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f50b.svg"), none;
}

.em-beach_with_umbrella {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3d6.png");
}

.em-beach_with_umbrella.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3d6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3d6.svg"), none;
}

.em-bear {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f43b.png");
}

.em-bear.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f43b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f43b.svg"), none;
}

.em-bearded_person {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9d4.png");
}

.em-bearded_person.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9d4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9d4.svg"), none;
}

.em-bed {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6cf.png");
}

.em-bed.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6cf.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6cf.svg"), none;
}

.em-bee, .em-honeybee {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f41d.png");
}

.em-bee.em-svg, .em-honeybee.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f41d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f41d.svg"), none;
}

.em-beer {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f37a.png");
}

.em-beer.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f37a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f37a.svg"), none;
}

.em-beers {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f37b.png");
}

.em-beers.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f37b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f37b.svg"), none;
}

.em-beetle {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f41e.png");
}

.em-beetle.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f41e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f41e.svg"), none;
}

.em-beginner {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f530.png");
}

.em-beginner.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f530.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f530.svg"), none;
}

.em-bell {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f514.png");
}

.em-bell.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f514.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f514.svg"), none;
}

.em-bellhop_bell {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6ce.png");
}

.em-bellhop_bell.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6ce.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6ce.svg"), none;
}

.em-bento {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f371.png");
}

.em-bento.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f371.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f371.svg"), none;
}

.em-bicyclist {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6b4.png");
}

.em-bicyclist.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6b4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b4.svg"), none;
}

.em-bike {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6b2.png");
}

.em-bike.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6b2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b2.svg"), none;
}

.em-bikini {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f459.png");
}

.em-bikini.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f459.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f459.svg"), none;
}

.em-billed_cap {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9e2.png");
}

.em-billed_cap.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9e2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9e2.svg"), none;
}

.em-biohazard_sign {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2623.png");
}

.em-biohazard_sign.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2623.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2623.svg"), none;
}

.em-bird {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f426.png");
}

.em-bird.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f426.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f426.svg"), none;
}

.em-birthday {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f382.png");
}

.em-birthday.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f382.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f382.svg"), none;
}

.em-black_circle {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26ab.png");
}

.em-black_circle.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26ab.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26ab.svg"), none;
}

.em-black_circle_for_record {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/23fa.png");
}

.em-black_circle_for_record.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/23fa.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/23fa.svg"), none;
}

.em-black_heart {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f5a4.png");
}

.em-black_heart.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f5a4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f5a4.svg"), none;
}

.em-black_joker {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f0cf.png");
}

.em-black_joker.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f0cf.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f0cf.svg"), none;
}

.em-black_large_square {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2b1b.png");
}

.em-black_large_square.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2b1b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2b1b.svg"), none;
}

.em-black_left_pointing_double_triangle_with_vertical_bar {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/23ee.png");
}

.em-black_left_pointing_double_triangle_with_vertical_bar.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/23ee.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/23ee.svg"), none;
}

.em-black_medium_small_square {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/25fe.png");
}

.em-black_medium_small_square.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/25fe.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/25fe.svg"), none;
}

.em-black_medium_square {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/25fc.png");
}

.em-black_medium_square.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/25fc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/25fc.svg"), none;
}

.em-black_nib {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2712.png");
}

.em-black_nib.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2712.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2712.svg"), none;
}

.em-black_right_pointing_double_triangle_with_vertical_bar {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/23ed.png");
}

.em-black_right_pointing_double_triangle_with_vertical_bar.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/23ed.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/23ed.svg"), none;
}

.em-black_right_pointing_triangle_with_double_vertical_bar {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/23ef.png");
}

.em-black_right_pointing_triangle_with_double_vertical_bar.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/23ef.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/23ef.svg"), none;
}

.em-black_small_square {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/25aa.png");
}

.em-black_small_square.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/25aa.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/25aa.svg"), none;
}

.em-black_square_button {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f532.png");
}

.em-black_square_button.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f532.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f532.svg"), none;
}

.em-black_square_for_stop {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/23f9.png");
}

.em-black_square_for_stop.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/23f9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/23f9.svg"), none;
}

.em-blond-haired-man {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f471-200d-2642-fe0f.png");
}

.em-blond-haired-man.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f471-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f471-200d-2642-fe0f.svg"), none;
}

.em-blond-haired-woman {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f471-200d-2640-fe0f.png");
}

.em-blond-haired-woman.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f471-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f471-200d-2640-fe0f.svg"), none;
}

.em-blossom {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f33c.png");
}

.em-blossom.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f33c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f33c.svg"), none;
}

.em-blowfish {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f421.png");
}

.em-blowfish.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f421.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f421.svg"), none;
}

.em-blue_book {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4d8.png");
}

.em-blue_book.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4d8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4d8.svg"), none;
}

.em-blue_car {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f699.png");
}

.em-blue_car.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f699.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f699.svg"), none;
}

.em-blue_heart {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f499.png");
}

.em-blue_heart.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f499.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f499.svg"), none;
}

.em-blush {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f60a.png");
}

.em-blush.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f60a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f60a.svg"), none;
}

.em-boar {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f417.png");
}

.em-boar.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f417.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f417.svg"), none;
}

.em-boat, .em-sailboat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26f5.png");
}

.em-boat.em-svg, .em-sailboat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26f5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26f5.svg"), none;
}

.em-bomb {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4a3.png");
}

.em-bomb.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4a3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4a3.svg"), none;
}

.em-book, .em-open_book {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4d6.png");
}

.em-book.em-svg, .em-open_book.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4d6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4d6.svg"), none;
}

.em-bookmark {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f516.png");
}

.em-bookmark.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f516.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f516.svg"), none;
}

.em-bookmark_tabs {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4d1.png");
}

.em-bookmark_tabs.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4d1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4d1.svg"), none;
}

.em-books {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4da.png");
}

.em-books.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4da.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4da.svg"), none;
}

.em-boom, .em-collision {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4a5.png");
}

.em-boom.em-svg, .em-collision.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4a5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4a5.svg"), none;
}

.em-boot {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f462.png");
}

.em-boot.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f462.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f462.svg"), none;
}

.em-bouquet {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f490.png");
}

.em-bouquet.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f490.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f490.svg"), none;
}

.em-bow {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f647.png");
}

.em-bow.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f647.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f647.svg"), none;
}

.em-bow_and_arrow {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3f9.png");
}

.em-bow_and_arrow.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3f9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3f9.svg"), none;
}

.em-bowl_with_spoon {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f963.png");
}

.em-bowl_with_spoon.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f963.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f963.svg"), none;
}

.em-bowling {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3b3.png");
}

.em-bowling.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3b3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3b3.svg"), none;
}

.em-boxing_glove {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f94a.png");
}

.em-boxing_glove.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f94a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f94a.svg"), none;
}

.em-boy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f466.png");
}

.em-boy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f466.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f466.svg"), none;
}

.em-brain {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9e0.png");
}

.em-brain.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9e0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9e0.svg"), none;
}

.em-bread {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f35e.png");
}

.em-bread.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f35e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f35e.svg"), none;
}

.em-breast-feeding {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f931.png");
}

.em-breast-feeding.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f931.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f931.svg"), none;
}

.em-bride_with_veil {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f470.png");
}

.em-bride_with_veil.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f470.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f470.svg"), none;
}

.em-bridge_at_night {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f309.png");
}

.em-bridge_at_night.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f309.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f309.svg"), none;
}

.em-briefcase {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4bc.png");
}

.em-briefcase.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4bc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4bc.svg"), none;
}

.em-broccoli {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f966.png");
}

.em-broccoli.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f966.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f966.svg"), none;
}

.em-broken_heart {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f494.png");
}

.em-broken_heart.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f494.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f494.svg"), none;
}

.em-bug {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f41b.png");
}

.em-bug.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f41b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f41b.svg"), none;
}

.em-building_construction {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3d7.png");
}

.em-building_construction.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3d7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3d7.svg"), none;
}

.em-bulb {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4a1.png");
}

.em-bulb.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4a1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4a1.svg"), none;
}

.em-bullettrain_front {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f685.png");
}

.em-bullettrain_front.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f685.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f685.svg"), none;
}

.em-bullettrain_side {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f684.png");
}

.em-bullettrain_side.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f684.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f684.svg"), none;
}

.em-burrito {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f32f.png");
}

.em-burrito.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f32f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f32f.svg"), none;
}

.em-bus {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f68c.png");
}

.em-bus.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f68c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f68c.svg"), none;
}

.em-busstop {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f68f.png");
}

.em-busstop.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f68f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f68f.svg"), none;
}

.em-bust_in_silhouette {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f464.png");
}

.em-bust_in_silhouette.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f464.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f464.svg"), none;
}

.em-busts_in_silhouette {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f465.png");
}

.em-busts_in_silhouette.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f465.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f465.svg"), none;
}

.em-butterfly {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f98b.png");
}

.em-butterfly.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f98b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f98b.svg"), none;
}

.em-cactus {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f335.png");
}

.em-cactus.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f335.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f335.svg"), none;
}

.em-cake {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f370.png");
}

.em-cake.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f370.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f370.svg"), none;
}

.em-calendar {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4c6.png");
}

.em-calendar.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4c6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4c6.svg"), none;
}

.em-call_me_hand {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f919.png");
}

.em-call_me_hand.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f919.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f919.svg"), none;
}

.em-calling {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4f2.png");
}

.em-calling.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4f2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4f2.svg"), none;
}

.em-camel {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f42b.png");
}

.em-camel.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f42b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f42b.svg"), none;
}

.em-camera {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4f7.png");
}

.em-camera.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4f7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4f7.svg"), none;
}

.em-camera_with_flash {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4f8.png");
}

.em-camera_with_flash.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4f8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4f8.svg"), none;
}

.em-camping {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3d5.png");
}

.em-camping.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3d5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3d5.svg"), none;
}

.em-cancer {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/264b.png");
}

.em-cancer.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/264b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/264b.svg"), none;
}

.em-candle {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f56f.png");
}

.em-candle.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f56f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f56f.svg"), none;
}

.em-candy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f36c.png");
}

.em-candy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f36c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f36c.svg"), none;
}

.em-canned_food {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f96b.png");
}

.em-canned_food.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f96b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f96b.svg"), none;
}

.em-canoe {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6f6.png");
}

.em-canoe.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6f6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6f6.svg"), none;
}

.em-capital_abcd {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f520.png");
}

.em-capital_abcd.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f520.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f520.svg"), none;
}

.em-capricorn {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2651.png");
}

.em-capricorn.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2651.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2651.svg"), none;
}

.em-car, .em-red_car {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f697.png");
}

.em-car.em-svg, .em-red_car.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f697.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f697.svg"), none;
}

.em-card_file_box {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f5c3.png");
}

.em-card_file_box.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f5c3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f5c3.svg"), none;
}

.em-card_index {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4c7.png");
}

.em-card_index.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4c7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4c7.svg"), none;
}

.em-card_index_dividers {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f5c2.png");
}

.em-card_index_dividers.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f5c2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f5c2.svg"), none;
}

.em-carousel_horse {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3a0.png");
}

.em-carousel_horse.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3a0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3a0.svg"), none;
}

.em-carrot {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f955.png");
}

.em-carrot.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f955.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f955.svg"), none;
}

.em-cat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f431.png");
}

.em-cat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f431.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f431.svg"), none;
}

.em-cat2 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f408.png");
}

.em-cat2.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f408.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f408.svg"), none;
}

.em-cd {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4bf.png");
}

.em-cd.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4bf.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4bf.svg"), none;
}

.em-chains {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26d3.png");
}

.em-chains.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26d3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26d3.svg"), none;
}

.em-champagne {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f37e.png");
}

.em-champagne.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f37e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f37e.svg"), none;
}

.em-chart {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4b9.png");
}

.em-chart.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4b9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b9.svg"), none;
}

.em-chart_with_downwards_trend {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4c9.png");
}

.em-chart_with_downwards_trend.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4c9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4c9.svg"), none;
}

.em-chart_with_upwards_trend {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4c8.png");
}

.em-chart_with_upwards_trend.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4c8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4c8.svg"), none;
}

.em-checkered_flag {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3c1.png");
}

.em-checkered_flag.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3c1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c1.svg"), none;
}

.em-cheese_wedge {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9c0.png");
}

.em-cheese_wedge.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9c0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9c0.svg"), none;
}

.em-cherries {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f352.png");
}

.em-cherries.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f352.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f352.svg"), none;
}

.em-cherry_blossom {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f338.png");
}

.em-cherry_blossom.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f338.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f338.svg"), none;
}

.em-chestnut {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f330.png");
}

.em-chestnut.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f330.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f330.svg"), none;
}

.em-chicken {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f414.png");
}

.em-chicken.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f414.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f414.svg"), none;
}

.em-child {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9d2.png");
}

.em-child.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9d2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9d2.svg"), none;
}

.em-children_crossing {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6b8.png");
}

.em-children_crossing.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6b8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b8.svg"), none;
}

.em-chipmunk {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f43f.png");
}

.em-chipmunk.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f43f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f43f.svg"), none;
}

.em-chocolate_bar {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f36b.png");
}

.em-chocolate_bar.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f36b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f36b.svg"), none;
}

.em-chopsticks {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f962.png");
}

.em-chopsticks.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f962.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f962.svg"), none;
}

.em-christmas_tree {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f384.png");
}

.em-christmas_tree.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f384.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f384.svg"), none;
}

.em-church {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26ea.png");
}

.em-church.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26ea.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26ea.svg"), none;
}

.em-cinema {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3a6.png");
}

.em-cinema.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3a6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3a6.svg"), none;
}

.em-circus_tent {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3aa.png");
}

.em-circus_tent.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3aa.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3aa.svg"), none;
}

.em-city_sunrise {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f307.png");
}

.em-city_sunrise.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f307.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f307.svg"), none;
}

.em-city_sunset {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f306.png");
}

.em-city_sunset.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f306.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f306.svg"), none;
}

.em-cityscape {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3d9.png");
}

.em-cityscape.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3d9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3d9.svg"), none;
}

.em-cl {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f191.png");
}

.em-cl.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f191.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f191.svg"), none;
}

.em-clap {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f44f.png");
}

.em-clap.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f44f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f44f.svg"), none;
}

.em-clapper {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3ac.png");
}

.em-clapper.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3ac.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ac.svg"), none;
}

.em-classical_building {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3db.png");
}

.em-classical_building.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3db.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3db.svg"), none;
}

.em-clinking_glasses {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f942.png");
}

.em-clinking_glasses.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f942.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f942.svg"), none;
}

.em-clipboard {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4cb.png");
}

.em-clipboard.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4cb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4cb.svg"), none;
}

.em-clock1 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f550.png");
}

.em-clock1.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f550.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f550.svg"), none;
}

.em-clock10 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f559.png");
}

.em-clock10.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f559.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f559.svg"), none;
}

.em-clock1030 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f565.png");
}

.em-clock1030.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f565.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f565.svg"), none;
}

.em-clock11 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f55a.png");
}

.em-clock11.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f55a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f55a.svg"), none;
}

.em-clock1130 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f566.png");
}

.em-clock1130.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f566.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f566.svg"), none;
}

.em-clock12 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f55b.png");
}

.em-clock12.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f55b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f55b.svg"), none;
}

.em-clock1230 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f567.png");
}

.em-clock1230.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f567.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f567.svg"), none;
}

.em-clock130 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f55c.png");
}

.em-clock130.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f55c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f55c.svg"), none;
}

.em-clock2 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f551.png");
}

.em-clock2.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f551.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f551.svg"), none;
}

.em-clock230 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f55d.png");
}

.em-clock230.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f55d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f55d.svg"), none;
}

.em-clock3 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f552.png");
}

.em-clock3.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f552.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f552.svg"), none;
}

.em-clock330 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f55e.png");
}

.em-clock330.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f55e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f55e.svg"), none;
}

.em-clock4 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f553.png");
}

.em-clock4.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f553.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f553.svg"), none;
}

.em-clock430 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f55f.png");
}

.em-clock430.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f55f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f55f.svg"), none;
}

.em-clock5 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f554.png");
}

.em-clock5.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f554.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f554.svg"), none;
}

.em-clock530 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f560.png");
}

.em-clock530.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f560.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f560.svg"), none;
}

.em-clock6 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f555.png");
}

.em-clock6.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f555.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f555.svg"), none;
}

.em-clock630 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f561.png");
}

.em-clock630.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f561.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f561.svg"), none;
}

.em-clock7 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f556.png");
}

.em-clock7.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f556.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f556.svg"), none;
}

.em-clock730 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f562.png");
}

.em-clock730.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f562.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f562.svg"), none;
}

.em-clock8 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f557.png");
}

.em-clock8.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f557.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f557.svg"), none;
}

.em-clock830 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f563.png");
}

.em-clock830.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f563.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f563.svg"), none;
}

.em-clock9 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f558.png");
}

.em-clock9.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f558.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f558.svg"), none;
}

.em-clock930 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f564.png");
}

.em-clock930.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f564.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f564.svg"), none;
}

.em-closed_book {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4d5.png");
}

.em-closed_book.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4d5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4d5.svg"), none;
}

.em-closed_lock_with_key {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f510.png");
}

.em-closed_lock_with_key.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f510.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f510.svg"), none;
}

.em-closed_umbrella {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f302.png");
}

.em-closed_umbrella.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f302.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f302.svg"), none;
}

.em-cloud {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2601.png");
}

.em-cloud.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2601.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2601.svg"), none;
}

.em-clown_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f921.png");
}

.em-clown_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f921.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f921.svg"), none;
}

.em-clubs {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2663.png");
}

.em-clubs.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2663.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2663.svg"), none;
}

.em-cn, .em-flag-cn {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1f3.png");
}

.em-cn.em-svg, .em-flag-cn.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1f3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1f3.svg"), none;
}

.em-coat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9e5.png");
}

.em-coat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9e5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9e5.svg"), none;
}

.em-cocktail {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f378.png");
}

.em-cocktail.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f378.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f378.svg"), none;
}

.em-coconut {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f965.png");
}

.em-coconut.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f965.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f965.svg"), none;
}

.em-coffee {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2615.png");
}

.em-coffee.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2615.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2615.svg"), none;
}

.em-coffin {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26b0.png");
}

.em-coffin.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26b0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26b0.svg"), none;
}

.em-cold_sweat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f630.png");
}

.em-cold_sweat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f630.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f630.svg"), none;
}

.em-comet {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2604.png");
}

.em-comet.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2604.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2604.svg"), none;
}

.em-compression {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f5dc.png");
}

.em-compression.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f5dc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f5dc.svg"), none;
}

.em-computer {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4bb.png");
}

.em-computer.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4bb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4bb.svg"), none;
}

.em-confetti_ball {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f38a.png");
}

.em-confetti_ball.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f38a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f38a.svg"), none;
}

.em-confounded {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f616.png");
}

.em-confounded.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f616.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f616.svg"), none;
}

.em-confused {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f615.png");
}

.em-confused.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f615.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f615.svg"), none;
}

.em-congratulations {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/3297.png");
}

.em-congratulations.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/3297.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/3297.svg"), none;
}

.em-construction {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6a7.png");
}

.em-construction.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6a7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a7.svg"), none;
}

.em-construction_worker {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f477.png");
}

.em-construction_worker.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f477.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f477.svg"), none;
}

.em-control_knobs {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f39b.png");
}

.em-control_knobs.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f39b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f39b.svg"), none;
}

.em-convenience_store {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3ea.png");
}

.em-convenience_store.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3ea.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ea.svg"), none;
}

.em-cookie {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f36a.png");
}

.em-cookie.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f36a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f36a.svg"), none;
}

.em-cool {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f192.png");
}

.em-cool.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f192.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f192.svg"), none;
}

.em-cop {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f46e.png");
}

.em-cop.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f46e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f46e.svg"), none;
}

.em-copyright {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/a9.png");
}

.em-copyright.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/a9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/a9.svg"), none;
}

.em-corn {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f33d.png");
}

.em-corn.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f33d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f33d.svg"), none;
}

.em-couch_and_lamp {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6cb.png");
}

.em-couch_and_lamp.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6cb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6cb.svg"), none;
}

.em-couple, .em-man_and_woman_holding_hands {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f46b.png");
}

.em-couple.em-svg, .em-man_and_woman_holding_hands.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f46b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f46b.svg"), none;
}

.em-couple_with_heart {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f491.png");
}

.em-couple_with_heart.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f491.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f491.svg"), none;
}

.em-couplekiss {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f48f.png");
}

.em-couplekiss.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f48f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f48f.svg"), none;
}

.em-cow {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f42e.png");
}

.em-cow.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f42e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f42e.svg"), none;
}

.em-cow2 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f404.png");
}

.em-cow2.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f404.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f404.svg"), none;
}

.em-crab {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f980.png");
}

.em-crab.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f980.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f980.svg"), none;
}

.em-credit_card {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4b3.png");
}

.em-credit_card.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4b3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b3.svg"), none;
}

.em-crescent_moon {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f319.png");
}

.em-crescent_moon.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f319.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f319.svg"), none;
}

.em-cricket {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f997.png");
}

.em-cricket.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f997.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f997.svg"), none;
}

.em-cricket_bat_and_ball {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3cf.png");
}

.em-cricket_bat_and_ball.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3cf.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3cf.svg"), none;
}

.em-crocodile {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f40a.png");
}

.em-crocodile.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f40a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f40a.svg"), none;
}

.em-croissant {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f950.png");
}

.em-croissant.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f950.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f950.svg"), none;
}

.em-crossed_fingers, .em-hand_with_index_and_middle_fingers_crossed {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f91e.png");
}

.em-crossed_fingers.em-svg, .em-hand_with_index_and_middle_fingers_crossed.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f91e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f91e.svg"), none;
}

.em-crossed_flags {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f38c.png");
}

.em-crossed_flags.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f38c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f38c.svg"), none;
}

.em-crossed_swords {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2694.png");
}

.em-crossed_swords.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2694.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2694.svg"), none;
}

.em-crown {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f451.png");
}

.em-crown.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f451.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f451.svg"), none;
}

.em-cry {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f622.png");
}

.em-cry.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f622.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f622.svg"), none;
}

.em-crying_cat_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f63f.png");
}

.em-crying_cat_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f63f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f63f.svg"), none;
}

.em-crystal_ball {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f52e.png");
}

.em-crystal_ball.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f52e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f52e.svg"), none;
}

.em-cucumber {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f952.png");
}

.em-cucumber.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f952.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f952.svg"), none;
}

.em-cup_with_straw {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f964.png");
}

.em-cup_with_straw.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f964.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f964.svg"), none;
}

.em-cupid {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f498.png");
}

.em-cupid.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f498.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f498.svg"), none;
}

.em-curling_stone {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f94c.png");
}

.em-curling_stone.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f94c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f94c.svg"), none;
}

.em-curly_loop {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/27b0.png");
}

.em-curly_loop.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/27b0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/27b0.svg"), none;
}

.em-currency_exchange {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4b1.png");
}

.em-currency_exchange.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4b1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b1.svg"), none;
}

.em-curry {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f35b.png");
}

.em-curry.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f35b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f35b.svg"), none;
}

.em-custard {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f36e.png");
}

.em-custard.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f36e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f36e.svg"), none;
}

.em-customs {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6c3.png");
}

.em-customs.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6c3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6c3.svg"), none;
}

.em-cut_of_meat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f969.png");
}

.em-cut_of_meat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f969.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f969.svg"), none;
}

.em-cyclone {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f300.png");
}

.em-cyclone.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f300.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f300.svg"), none;
}

.em-dagger_knife {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f5e1.png");
}

.em-dagger_knife.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f5e1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f5e1.svg"), none;
}

.em-dancer {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f483.png");
}

.em-dancer.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f483.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f483.svg"), none;
}

.em-dancers {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f46f.png");
}

.em-dancers.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f46f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f46f.svg"), none;
}

.em-dango {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f361.png");
}

.em-dango.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f361.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f361.svg"), none;
}

.em-dark_sunglasses {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f576.png");
}

.em-dark_sunglasses.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f576.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f576.svg"), none;
}

.em-dart {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3af.png");
}

.em-dart.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3af.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3af.svg"), none;
}

.em-dash {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4a8.png");
}

.em-dash.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4a8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4a8.svg"), none;
}

.em-date {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4c5.png");
}

.em-date.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4c5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4c5.svg"), none;
}

.em-de, .em-flag-de {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e9-1f1ea.png");
}

.em-de.em-svg, .em-flag-de.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e9-1f1ea.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e9-1f1ea.svg"), none;
}

.em-deciduous_tree {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f333.png");
}

.em-deciduous_tree.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f333.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f333.svg"), none;
}

.em-deer {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f98c.png");
}

.em-deer.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f98c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f98c.svg"), none;
}

.em-department_store {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3ec.png");
}

.em-department_store.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3ec.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ec.svg"), none;
}

.em-derelict_house_building {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3da.png");
}

.em-derelict_house_building.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3da.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3da.svg"), none;
}

.em-desert {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3dc.png");
}

.em-desert.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3dc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3dc.svg"), none;
}

.em-desert_island {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3dd.png");
}

.em-desert_island.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3dd.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3dd.svg"), none;
}

.em-desktop_computer {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f5a5.png");
}

.em-desktop_computer.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f5a5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f5a5.svg"), none;
}

.em-diamond_shape_with_a_dot_inside {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4a0.png");
}

.em-diamond_shape_with_a_dot_inside.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4a0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4a0.svg"), none;
}

.em-diamonds {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2666.png");
}

.em-diamonds.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2666.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2666.svg"), none;
}

.em-disappointed {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f61e.png");
}

.em-disappointed.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f61e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f61e.svg"), none;
}

.em-disappointed_relieved {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f625.png");
}

.em-disappointed_relieved.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f625.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f625.svg"), none;
}

.em-dizzy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4ab.png");
}

.em-dizzy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4ab.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ab.svg"), none;
}

.em-dizzy_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f635.png");
}

.em-dizzy_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f635.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f635.svg"), none;
}

.em-do_not_litter {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6af.png");
}

.em-do_not_litter.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6af.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6af.svg"), none;
}

.em-dog {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f436.png");
}

.em-dog.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f436.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f436.svg"), none;
}

.em-dog2 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f415.png");
}

.em-dog2.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f415.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f415.svg"), none;
}

.em-dollar {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4b5.png");
}

.em-dollar.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4b5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b5.svg"), none;
}

.em-dolls {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f38e.png");
}

.em-dolls.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f38e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f38e.svg"), none;
}

.em-dolphin, .em-flipper {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f42c.png");
}

.em-dolphin.em-svg, .em-flipper.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f42c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f42c.svg"), none;
}

.em-door {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6aa.png");
}

.em-door.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6aa.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6aa.svg"), none;
}

.em-double_vertical_bar {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/23f8.png");
}

.em-double_vertical_bar.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/23f8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/23f8.svg"), none;
}

.em-doughnut {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f369.png");
}

.em-doughnut.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f369.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f369.svg"), none;
}

.em-dove_of_peace {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f54a.png");
}

.em-dove_of_peace.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f54a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f54a.svg"), none;
}

.em-dragon {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f409.png");
}

.em-dragon.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f409.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f409.svg"), none;
}

.em-dragon_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f432.png");
}

.em-dragon_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f432.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f432.svg"), none;
}

.em-dress {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f457.png");
}

.em-dress.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f457.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f457.svg"), none;
}

.em-dromedary_camel {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f42a.png");
}

.em-dromedary_camel.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f42a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f42a.svg"), none;
}

.em-drooling_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f924.png");
}

.em-drooling_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f924.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f924.svg"), none;
}

.em-droplet {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4a7.png");
}

.em-droplet.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4a7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4a7.svg"), none;
}

.em-drum_with_drumsticks {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f941.png");
}

.em-drum_with_drumsticks.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f941.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f941.svg"), none;
}

.em-duck {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f986.png");
}

.em-duck.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f986.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f986.svg"), none;
}

.em-dumpling {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f95f.png");
}

.em-dumpling.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f95f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f95f.svg"), none;
}

.em-dvd {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4c0.png");
}

.em-dvd.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4c0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4c0.svg"), none;
}

.em-e-mail {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4e7.png");
}

.em-e-mail.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4e7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4e7.svg"), none;
}

.em-eagle {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f985.png");
}

.em-eagle.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f985.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f985.svg"), none;
}

.em-ear {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f442.png");
}

.em-ear.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f442.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f442.svg"), none;
}

.em-ear_of_rice {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f33e.png");
}

.em-ear_of_rice.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f33e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f33e.svg"), none;
}

.em-earth_africa {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f30d.png");
}

.em-earth_africa.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f30d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f30d.svg"), none;
}

.em-earth_americas {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f30e.png");
}

.em-earth_americas.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f30e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f30e.svg"), none;
}

.em-earth_asia {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f30f.png");
}

.em-earth_asia.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f30f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f30f.svg"), none;
}

.em-egg {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f95a.png");
}

.em-egg.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f95a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f95a.svg"), none;
}

.em-eggplant {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f346.png");
}

.em-eggplant.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f346.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f346.svg"), none;
}

.em-eight {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/38-20e3.png");
}

.em-eight.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/38-20e3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/38-20e3.svg"), none;
}

.em-eight_pointed_black_star {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2734.png");
}

.em-eight_pointed_black_star.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2734.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2734.svg"), none;
}

.em-eight_spoked_asterisk {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2733.png");
}

.em-eight_spoked_asterisk.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2733.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2733.svg"), none;
}

.em-eject {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/23cf.png");
}

.em-eject.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/23cf.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/23cf.svg"), none;
}

.em-electric_plug {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f50c.png");
}

.em-electric_plug.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f50c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f50c.svg"), none;
}

.em-elephant {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f418.png");
}

.em-elephant.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f418.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f418.svg"), none;
}

.em-elf {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9dd.png");
}

.em-elf.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9dd.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9dd.svg"), none;
}

.em-email, .em-envelope {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2709.png");
}

.em-email.em-svg, .em-envelope.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2709.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2709.svg"), none;
}

.em-end {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f51a.png");
}

.em-end.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f51a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f51a.svg"), none;
}

.em-envelope_with_arrow {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4e9.png");
}

.em-envelope_with_arrow.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4e9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4e9.svg"), none;
}

.em-es, .em-flag-es {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1f8.png");
}

.em-es.em-svg, .em-flag-es.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1f8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ea-1f1f8.svg"), none;
}

.em-euro {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4b6.png");
}

.em-euro.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4b6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b6.svg"), none;
}

.em-european_castle {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3f0.png");
}

.em-european_castle.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3f0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3f0.svg"), none;
}

.em-european_post_office {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3e4.png");
}

.em-european_post_office.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3e4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3e4.svg"), none;
}

.em-evergreen_tree {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f332.png");
}

.em-evergreen_tree.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f332.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f332.svg"), none;
}

.em-exclamation, .em-heavy_exclamation_mark {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2757.png");
}

.em-exclamation.em-svg, .em-heavy_exclamation_mark.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2757.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2757.svg"), none;
}

.em-exploding_head, .em-shocked_face_with_exploding_head {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f92f.png");
}

.em-exploding_head.em-svg, .em-shocked_face_with_exploding_head.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f92f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f92f.svg"), none;
}

.em-expressionless {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f611.png");
}

.em-expressionless.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f611.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f611.svg"), none;
}

.em-eye {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f441.png");
}

.em-eye.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f441.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f441.svg"), none;
}

.em-eyeglasses {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f453.png");
}

.em-eyeglasses.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f453.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f453.svg"), none;
}

.em-eyes {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f440.png");
}

.em-eyes.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f440.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f440.svg"), none;
}

.em-face_palm {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f926.png");
}

.em-face_palm.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f926.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f926.svg"), none;
}

.em-face_vomiting, .em-face_with_open_mouth_vomiting {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f92e.png");
}

.em-face_vomiting.em-svg, .em-face_with_open_mouth_vomiting.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f92e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f92e.svg"), none;
}

.em-face_with_cowboy_hat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f920.png");
}

.em-face_with_cowboy_hat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f920.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f920.svg"), none;
}

.em-face_with_hand_over_mouth, .em-smiling_face_with_smiling_eyes_and_hand_covering_mouth {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f92d.png");
}

.em-face_with_hand_over_mouth.em-svg, .em-smiling_face_with_smiling_eyes_and_hand_covering_mouth.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f92d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f92d.svg"), none;
}

.em-face_with_head_bandage {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f915.png");
}

.em-face_with_head_bandage.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f915.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f915.svg"), none;
}

.em-face_with_monocle {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9d0.png");
}

.em-face_with_monocle.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9d0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9d0.svg"), none;
}

.em-face_with_raised_eyebrow, .em-face_with_one_eyebrow_raised {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f928.png");
}

.em-face_with_raised_eyebrow.em-svg, .em-face_with_one_eyebrow_raised.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f928.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f928.svg"), none;
}

.em-face_with_rolling_eyes {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f644.png");
}

.em-face_with_rolling_eyes.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f644.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f644.svg"), none;
}

.em-face_with_symbols_on_mouth, .em-serious_face_with_symbols_covering_mouth {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f92c.png");
}

.em-face_with_symbols_on_mouth.em-svg, .em-serious_face_with_symbols_covering_mouth.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f92c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f92c.svg"), none;
}

.em-face_with_thermometer {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f912.png");
}

.em-face_with_thermometer.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f912.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f912.svg"), none;
}

.em-facepunch, .em-punch {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f44a.png");
}

.em-facepunch.em-svg, .em-punch.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f44a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f44a.svg"), none;
}

.em-factory {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3ed.png");
}

.em-factory.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3ed.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ed.svg"), none;
}

.em-fairy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9da.png");
}

.em-fairy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9da.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9da.svg"), none;
}

.em-fallen_leaf {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f342.png");
}

.em-fallen_leaf.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f342.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f342.svg"), none;
}

.em-family, .em-man-woman-boy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f46a.png");
}

.em-family.em-svg, .em-man-woman-boy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f46a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f46a.svg"), none;
}

.em-fast_forward {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/23e9.png");
}

.em-fast_forward.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/23e9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/23e9.svg"), none;
}

.em-fax {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4e0.png");
}

.em-fax.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4e0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4e0.svg"), none;
}

.em-fearful {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f628.png");
}

.em-fearful.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f628.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f628.svg"), none;
}

.em-feet, .em-paw_prints {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f43e.png");
}

.em-feet.em-svg, .em-paw_prints.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f43e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f43e.svg"), none;
}

.em-female-artist {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f3a8.png");
}

.em-female-artist.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f3a8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f3a8.svg"), none;
}

.em-female-astronaut {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f680.png");
}

.em-female-astronaut.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f680.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f680.svg"), none;
}

.em-female-construction-worker {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f477-200d-2640-fe0f.png");
}

.em-female-construction-worker.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f477-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f477-200d-2640-fe0f.svg"), none;
}

.em-female-cook {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f373.png");
}

.em-female-cook.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f373.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f373.svg"), none;
}

.em-female-detective {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f575-fe0f-200d-2640-fe0f.png");
}

.em-female-detective.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f575-fe0f-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f575-fe0f-200d-2640-fe0f.svg"), none;
}

.em-female-doctor {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-2695-fe0f.png");
}

.em-female-doctor.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-2695-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-2695-fe0f.svg"), none;
}

.em-female-factory-worker {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f3ed.png");
}

.em-female-factory-worker.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f3ed.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f3ed.svg"), none;
}

.em-female-farmer {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f33e.png");
}

.em-female-farmer.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f33e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f33e.svg"), none;
}

.em-female-firefighter {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f692.png");
}

.em-female-firefighter.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f692.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f692.svg"), none;
}

.em-female-guard {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f482-200d-2640-fe0f.png");
}

.em-female-guard.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f482-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f482-200d-2640-fe0f.svg"), none;
}

.em-female-judge {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-2696-fe0f.png");
}

.em-female-judge.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-2696-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-2696-fe0f.svg"), none;
}

.em-female-mechanic {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f527.png");
}

.em-female-mechanic.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f527.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f527.svg"), none;
}

.em-female-office-worker {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f4bc.png");
}

.em-female-office-worker.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f4bc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f4bc.svg"), none;
}

.em-female-pilot {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-2708-fe0f.png");
}

.em-female-pilot.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-2708-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-2708-fe0f.svg"), none;
}

.em-female-police-officer {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f46e-200d-2640-fe0f.png");
}

.em-female-police-officer.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f46e-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f46e-200d-2640-fe0f.svg"), none;
}

.em-female-scientist {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f52c.png");
}

.em-female-scientist.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f52c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f52c.svg"), none;
}

.em-female-singer {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f3a4.png");
}

.em-female-singer.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f3a4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f3a4.svg"), none;
}

.em-female-student {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f393.png");
}

.em-female-student.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f393.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f393.svg"), none;
}

.em-female-teacher {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f3eb.png");
}

.em-female-teacher.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f3eb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f3eb.svg"), none;
}

.em-female-technologist {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f4bb.png");
}

.em-female-technologist.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f4bb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f4bb.svg"), none;
}

.em-female_elf {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9dd-200d-2640-fe0f.png");
}

.em-female_elf.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9dd-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9dd-200d-2640-fe0f.svg"), none;
}

.em-female_fairy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9da-200d-2640-fe0f.png");
}

.em-female_fairy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9da-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9da-200d-2640-fe0f.svg"), none;
}

.em-female_genie {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9de-200d-2640-fe0f.png");
}

.em-female_genie.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9de-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9de-200d-2640-fe0f.svg"), none;
}

.em-female_mage {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9d9-200d-2640-fe0f.png");
}

.em-female_mage.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9d9-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9d9-200d-2640-fe0f.svg"), none;
}

.em-female_sign {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2640.png");
}

.em-female_sign.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2640.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2640.svg"), none;
}

.em-female_vampire {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9db-200d-2640-fe0f.png");
}

.em-female_vampire.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9db-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9db-200d-2640-fe0f.svg"), none;
}

.em-female_zombie {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9df-200d-2640-fe0f.png");
}

.em-female_zombie.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9df-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9df-200d-2640-fe0f.svg"), none;
}

.em-fencer {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f93a.png");
}

.em-fencer.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f93a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f93a.svg"), none;
}

.em-ferris_wheel {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3a1.png");
}

.em-ferris_wheel.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3a1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3a1.svg"), none;
}

.em-ferry {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26f4.png");
}

.em-ferry.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26f4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26f4.svg"), none;
}

.em-field_hockey_stick_and_ball {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3d1.png");
}

.em-field_hockey_stick_and_ball.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3d1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3d1.svg"), none;
}

.em-file_cabinet {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f5c4.png");
}

.em-file_cabinet.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f5c4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f5c4.svg"), none;
}

.em-file_folder {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4c1.png");
}

.em-file_folder.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4c1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4c1.svg"), none;
}

.em-film_frames {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f39e.png");
}

.em-film_frames.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f39e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f39e.svg"), none;
}

.em-film_projector {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4fd.png");
}

.em-film_projector.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4fd.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4fd.svg"), none;
}

.em-fire {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f525.png");
}

.em-fire.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f525.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f525.svg"), none;
}

.em-fire_engine {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f692.png");
}

.em-fire_engine.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f692.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f692.svg"), none;
}

.em-fireworks {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f386.png");
}

.em-fireworks.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f386.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f386.svg"), none;
}

.em-first_place_medal {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f947.png");
}

.em-first_place_medal.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f947.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f947.svg"), none;
}

.em-first_quarter_moon {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f313.png");
}

.em-first_quarter_moon.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f313.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f313.svg"), none;
}

.em-first_quarter_moon_with_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f31b.png");
}

.em-first_quarter_moon_with_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f31b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f31b.svg"), none;
}

.em-fish {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f41f.png");
}

.em-fish.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f41f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f41f.svg"), none;
}

.em-fish_cake {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f365.png");
}

.em-fish_cake.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f365.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f365.svg"), none;
}

.em-fishing_pole_and_fish {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3a3.png");
}

.em-fishing_pole_and_fish.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3a3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3a3.svg"), none;
}

.em-fist {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/270a.png");
}

.em-fist.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/270a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/270a.svg"), none;
}

.em-five {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/35-20e3.png");
}

.em-five.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/35-20e3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/35-20e3.svg"), none;
}

.em-flag-ac {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1e8.png");
}

.em-flag-ac.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1e8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1e8.svg"), none;
}

.em-flag-ad {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1e9.png");
}

.em-flag-ad.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1e9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1e9.svg"), none;
}

.em-flag-ae {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1ea.png");
}

.em-flag-ae.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1ea.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1ea.svg"), none;
}

.em-flag-af {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1eb.png");
}

.em-flag-af.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1eb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1eb.svg"), none;
}

.em-flag-ag {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1ec.png");
}

.em-flag-ag.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1ec.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1ec.svg"), none;
}

.em-flag-ai {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1ee.png");
}

.em-flag-ai.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1ee.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1ee.svg"), none;
}

.em-flag-al {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1f1.png");
}

.em-flag-al.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1f1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1f1.svg"), none;
}

.em-flag-am {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1f2.png");
}

.em-flag-am.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1f2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1f2.svg"), none;
}

.em-flag-ao {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1f4.png");
}

.em-flag-ao.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1f4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1f4.svg"), none;
}

.em-flag-aq {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1f6.png");
}

.em-flag-aq.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1f6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1f6.svg"), none;
}

.em-flag-ar {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1f7.png");
}

.em-flag-ar.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1f7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1f7.svg"), none;
}

.em-flag-as {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1f8.png");
}

.em-flag-as.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1f8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1f8.svg"), none;
}

.em-flag-at {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1f9.png");
}

.em-flag-at.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1f9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1f9.svg"), none;
}

.em-flag-au {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1fa.png");
}

.em-flag-au.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1fa.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1fa.svg"), none;
}

.em-flag-aw {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1fc.png");
}

.em-flag-aw.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1fc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1fc.svg"), none;
}

.em-flag-ax {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1fd.png");
}

.em-flag-ax.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1fd.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1fd.svg"), none;
}

.em-flag-az {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1ff.png");
}

.em-flag-az.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e6-1f1ff.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e6-1f1ff.svg"), none;
}

.em-flag-ba {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1e6.png");
}

.em-flag-ba.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1e6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1e6.svg"), none;
}

.em-flag-bb {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1e7.png");
}

.em-flag-bb.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1e7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1e7.svg"), none;
}

.em-flag-bd {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1e9.png");
}

.em-flag-bd.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1e9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1e9.svg"), none;
}

.em-flag-be {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1ea.png");
}

.em-flag-be.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1ea.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1ea.svg"), none;
}

.em-flag-bf {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1eb.png");
}

.em-flag-bf.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1eb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1eb.svg"), none;
}

.em-flag-bg {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1ec.png");
}

.em-flag-bg.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1ec.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1ec.svg"), none;
}

.em-flag-bh {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1ed.png");
}

.em-flag-bh.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1ed.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1ed.svg"), none;
}

.em-flag-bi {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1ee.png");
}

.em-flag-bi.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1ee.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1ee.svg"), none;
}

.em-flag-bj {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1ef.png");
}

.em-flag-bj.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1ef.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1ef.svg"), none;
}

.em-flag-bl {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1f1.png");
}

.em-flag-bl.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1f1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1f1.svg"), none;
}

.em-flag-bm {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1f2.png");
}

.em-flag-bm.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1f2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1f2.svg"), none;
}

.em-flag-bn {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1f3.png");
}

.em-flag-bn.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1f3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1f3.svg"), none;
}

.em-flag-bo {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1f4.png");
}

.em-flag-bo.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1f4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1f4.svg"), none;
}

.em-flag-bq {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1f6.png");
}

.em-flag-bq.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1f6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1f6.svg"), none;
}

.em-flag-br {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1f7.png");
}

.em-flag-br.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1f7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1f7.svg"), none;
}

.em-flag-bs {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1f8.png");
}

.em-flag-bs.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1f8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1f8.svg"), none;
}

.em-flag-bt {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1f9.png");
}

.em-flag-bt.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1f9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1f9.svg"), none;
}

.em-flag-bv {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1fb.png");
}

.em-flag-bv.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1fb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1fb.svg"), none;
}

.em-flag-bw {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1fc.png");
}

.em-flag-bw.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1fc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1fc.svg"), none;
}

.em-flag-by {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1fe.png");
}

.em-flag-by.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1fe.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1fe.svg"), none;
}

.em-flag-bz {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1ff.png");
}

.em-flag-bz.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e7-1f1ff.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e7-1f1ff.svg"), none;
}

.em-flag-ca {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1e6.png");
}

.em-flag-ca.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1e6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1e6.svg"), none;
}

.em-flag-cc {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1e8.png");
}

.em-flag-cc.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1e8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1e8.svg"), none;
}

.em-flag-cd {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1e9.png");
}

.em-flag-cd.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1e9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1e9.svg"), none;
}

.em-flag-cf {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1eb.png");
}

.em-flag-cf.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1eb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1eb.svg"), none;
}

.em-flag-cg {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1ec.png");
}

.em-flag-cg.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1ec.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1ec.svg"), none;
}

.em-flag-ch {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1ed.png");
}

.em-flag-ch.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1ed.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1ed.svg"), none;
}

.em-flag-ci {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1ee.png");
}

.em-flag-ci.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1ee.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1ee.svg"), none;
}

.em-flag-ck {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1f0.png");
}

.em-flag-ck.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1f0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1f0.svg"), none;
}

.em-flag-cl {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1f1.png");
}

.em-flag-cl.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1f1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1f1.svg"), none;
}

.em-flag-cm {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1f2.png");
}

.em-flag-cm.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1f2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1f2.svg"), none;
}

.em-flag-co {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1f4.png");
}

.em-flag-co.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1f4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1f4.svg"), none;
}

.em-flag-cp {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1f5.png");
}

.em-flag-cp.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1f5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1f5.svg"), none;
}

.em-flag-cr {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1f7.png");
}

.em-flag-cr.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1f7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1f7.svg"), none;
}

.em-flag-cu {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1fa.png");
}

.em-flag-cu.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1fa.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1fa.svg"), none;
}

.em-flag-cv {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1fb.png");
}

.em-flag-cv.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1fb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1fb.svg"), none;
}

.em-flag-cw {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1fc.png");
}

.em-flag-cw.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1fc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1fc.svg"), none;
}

.em-flag-cx {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1fd.png");
}

.em-flag-cx.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1fd.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1fd.svg"), none;
}

.em-flag-cy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1fe.png");
}

.em-flag-cy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1fe.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1fe.svg"), none;
}

.em-flag-cz {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1ff.png");
}

.em-flag-cz.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e8-1f1ff.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e8-1f1ff.svg"), none;
}

.em-flag-dg {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e9-1f1ec.png");
}

.em-flag-dg.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e9-1f1ec.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e9-1f1ec.svg"), none;
}

.em-flag-dj {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e9-1f1ef.png");
}

.em-flag-dj.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e9-1f1ef.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e9-1f1ef.svg"), none;
}

.em-flag-dk {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e9-1f1f0.png");
}

.em-flag-dk.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e9-1f1f0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e9-1f1f0.svg"), none;
}

.em-flag-dm {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e9-1f1f2.png");
}

.em-flag-dm.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e9-1f1f2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e9-1f1f2.svg"), none;
}

.em-flag-do {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e9-1f1f4.png");
}

.em-flag-do.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e9-1f1f4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e9-1f1f4.svg"), none;
}

.em-flag-dz {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1e9-1f1ff.png");
}

.em-flag-dz.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1e9-1f1ff.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1e9-1f1ff.svg"), none;
}

.em-flag-ea {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1e6.png");
}

.em-flag-ea.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1e6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ea-1f1e6.svg"), none;
}

.em-flag-ec {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1e8.png");
}

.em-flag-ec.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1e8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ea-1f1e8.svg"), none;
}

.em-flag-ee {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1ea.png");
}

.em-flag-ee.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1ea.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ea-1f1ea.svg"), none;
}

.em-flag-eg {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1ec.png");
}

.em-flag-eg.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1ec.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ea-1f1ec.svg"), none;
}

.em-flag-eh {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1ed.png");
}

.em-flag-eh.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1ed.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ea-1f1ed.svg"), none;
}

.em-flag-england {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3f4-e0067-e0062-e0065-e006e-e0067-e007f.png");
}

.em-flag-england.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3f4-e0067-e0062-e0065-e006e-e0067-e007f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3f4-e0067-e0062-e0065-e006e-e0067-e007f.svg"), none;
}

.em-flag-er {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1f7.png");
}

.em-flag-er.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1f7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ea-1f1f7.svg"), none;
}

.em-flag-et {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1f9.png");
}

.em-flag-et.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1f9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ea-1f1f9.svg"), none;
}

.em-flag-eu {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1fa.png");
}

.em-flag-eu.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ea-1f1fa.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ea-1f1fa.svg"), none;
}

.em-flag-fi {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1eb-1f1ee.png");
}

.em-flag-fi.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1eb-1f1ee.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1eb-1f1ee.svg"), none;
}

.em-flag-fj {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1eb-1f1ef.png");
}

.em-flag-fj.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1eb-1f1ef.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1eb-1f1ef.svg"), none;
}

.em-flag-fk {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1eb-1f1f0.png");
}

.em-flag-fk.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1eb-1f1f0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1eb-1f1f0.svg"), none;
}

.em-flag-fm {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1eb-1f1f2.png");
}

.em-flag-fm.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1eb-1f1f2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1eb-1f1f2.svg"), none;
}

.em-flag-fo {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1eb-1f1f4.png");
}

.em-flag-fo.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1eb-1f1f4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1eb-1f1f4.svg"), none;
}

.em-flag-ga {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1e6.png");
}

.em-flag-ga.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1e6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1e6.svg"), none;
}

.em-flag-gd {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1e9.png");
}

.em-flag-gd.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1e9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1e9.svg"), none;
}

.em-flag-ge {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1ea.png");
}

.em-flag-ge.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1ea.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1ea.svg"), none;
}

.em-flag-gf {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1eb.png");
}

.em-flag-gf.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1eb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1eb.svg"), none;
}

.em-flag-gg {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1ec.png");
}

.em-flag-gg.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1ec.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1ec.svg"), none;
}

.em-flag-gh {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1ed.png");
}

.em-flag-gh.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1ed.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1ed.svg"), none;
}

.em-flag-gi {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1ee.png");
}

.em-flag-gi.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1ee.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1ee.svg"), none;
}

.em-flag-gl {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1f1.png");
}

.em-flag-gl.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1f1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1f1.svg"), none;
}

.em-flag-gm {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1f2.png");
}

.em-flag-gm.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1f2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1f2.svg"), none;
}

.em-flag-gn {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1f3.png");
}

.em-flag-gn.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1f3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1f3.svg"), none;
}

.em-flag-gp {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1f5.png");
}

.em-flag-gp.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1f5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1f5.svg"), none;
}

.em-flag-gq {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1f6.png");
}

.em-flag-gq.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1f6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1f6.svg"), none;
}

.em-flag-gr {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1f7.png");
}

.em-flag-gr.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1f7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1f7.svg"), none;
}

.em-flag-gs {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1f8.png");
}

.em-flag-gs.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1f8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1f8.svg"), none;
}

.em-flag-gt {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1f9.png");
}

.em-flag-gt.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1f9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1f9.svg"), none;
}

.em-flag-gu {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1fa.png");
}

.em-flag-gu.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1fa.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1fa.svg"), none;
}

.em-flag-gw {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1fc.png");
}

.em-flag-gw.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1fc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1fc.svg"), none;
}

.em-flag-gy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1fe.png");
}

.em-flag-gy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1fe.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1fe.svg"), none;
}

.em-flag-hk {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ed-1f1f0.png");
}

.em-flag-hk.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ed-1f1f0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ed-1f1f0.svg"), none;
}

.em-flag-hm {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ed-1f1f2.png");
}

.em-flag-hm.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ed-1f1f2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ed-1f1f2.svg"), none;
}

.em-flag-hn {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ed-1f1f3.png");
}

.em-flag-hn.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ed-1f1f3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ed-1f1f3.svg"), none;
}

.em-flag-hr {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ed-1f1f7.png");
}

.em-flag-hr.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ed-1f1f7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ed-1f1f7.svg"), none;
}

.em-flag-ht {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ed-1f1f9.png");
}

.em-flag-ht.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ed-1f1f9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ed-1f1f9.svg"), none;
}

.em-flag-hu {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ed-1f1fa.png");
}

.em-flag-hu.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ed-1f1fa.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ed-1f1fa.svg"), none;
}

.em-flag-ic {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1e8.png");
}

.em-flag-ic.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1e8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ee-1f1e8.svg"), none;
}

.em-flag-id {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1e9.png");
}

.em-flag-id.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1e9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ee-1f1e9.svg"), none;
}

.em-flag-ie {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1ea.png");
}

.em-flag-ie.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1ea.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ee-1f1ea.svg"), none;
}

.em-flag-il {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1f1.png");
}

.em-flag-il.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1f1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ee-1f1f1.svg"), none;
}

.em-flag-im {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1f2.png");
}

.em-flag-im.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1f2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ee-1f1f2.svg"), none;
}

.em-flag-in {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1f3.png");
}

.em-flag-in.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1f3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ee-1f1f3.svg"), none;
}

.em-flag-io {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1f4.png");
}

.em-flag-io.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1f4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ee-1f1f4.svg"), none;
}

.em-flag-iq {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1f6.png");
}

.em-flag-iq.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1f6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ee-1f1f6.svg"), none;
}

.em-flag-ir {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1f7.png");
}

.em-flag-ir.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1f7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ee-1f1f7.svg"), none;
}

.em-flag-is {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1f8.png");
}

.em-flag-is.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1f8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ee-1f1f8.svg"), none;
}

.em-flag-je {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ef-1f1ea.png");
}

.em-flag-je.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ef-1f1ea.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ef-1f1ea.svg"), none;
}

.em-flag-jm {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ef-1f1f2.png");
}

.em-flag-jm.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ef-1f1f2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ef-1f1f2.svg"), none;
}

.em-flag-jo {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ef-1f1f4.png");
}

.em-flag-jo.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ef-1f1f4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ef-1f1f4.svg"), none;
}

.em-flag-ke {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1ea.png");
}

.em-flag-ke.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1ea.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f0-1f1ea.svg"), none;
}

.em-flag-kg {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1ec.png");
}

.em-flag-kg.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1ec.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f0-1f1ec.svg"), none;
}

.em-flag-kh {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1ed.png");
}

.em-flag-kh.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1ed.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f0-1f1ed.svg"), none;
}

.em-flag-ki {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1ee.png");
}

.em-flag-ki.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1ee.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f0-1f1ee.svg"), none;
}

.em-flag-km {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1f2.png");
}

.em-flag-km.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1f2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f0-1f1f2.svg"), none;
}

.em-flag-kn {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1f3.png");
}

.em-flag-kn.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1f3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f0-1f1f3.svg"), none;
}

.em-flag-kp {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1f5.png");
}

.em-flag-kp.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1f5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f0-1f1f5.svg"), none;
}

.em-flag-kw {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1fc.png");
}

.em-flag-kw.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1fc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f0-1f1fc.svg"), none;
}

.em-flag-ky {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1fe.png");
}

.em-flag-ky.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1fe.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f0-1f1fe.svg"), none;
}

.em-flag-kz {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1ff.png");
}

.em-flag-kz.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1ff.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f0-1f1ff.svg"), none;
}

.em-flag-la {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1e6.png");
}

.em-flag-la.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1e6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f1-1f1e6.svg"), none;
}

.em-flag-lb {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1e7.png");
}

.em-flag-lb.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1e7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f1-1f1e7.svg"), none;
}

.em-flag-lc {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1e8.png");
}

.em-flag-lc.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1e8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f1-1f1e8.svg"), none;
}

.em-flag-li {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1ee.png");
}

.em-flag-li.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1ee.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f1-1f1ee.svg"), none;
}

.em-flag-lk {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1f0.png");
}

.em-flag-lk.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1f0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f1-1f1f0.svg"), none;
}

.em-flag-lr {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1f7.png");
}

.em-flag-lr.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1f7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f1-1f1f7.svg"), none;
}

.em-flag-ls {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1f8.png");
}

.em-flag-ls.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1f8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f1-1f1f8.svg"), none;
}

.em-flag-lt {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1f9.png");
}

.em-flag-lt.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1f9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f1-1f1f9.svg"), none;
}

.em-flag-lu {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1fa.png");
}

.em-flag-lu.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1fa.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f1-1f1fa.svg"), none;
}

.em-flag-lv {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1fb.png");
}

.em-flag-lv.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1fb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f1-1f1fb.svg"), none;
}

.em-flag-ly {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1fe.png");
}

.em-flag-ly.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f1-1f1fe.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f1-1f1fe.svg"), none;
}

.em-flag-ma {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1e6.png");
}

.em-flag-ma.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1e6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1e6.svg"), none;
}

.em-flag-mc {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1e8.png");
}

.em-flag-mc.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1e8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1e8.svg"), none;
}

.em-flag-md {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1e9.png");
}

.em-flag-md.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1e9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1e9.svg"), none;
}

.em-flag-me {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1ea.png");
}

.em-flag-me.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1ea.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1ea.svg"), none;
}

.em-flag-mf {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1eb.png");
}

.em-flag-mf.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1eb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1eb.svg"), none;
}

.em-flag-mg {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1ec.png");
}

.em-flag-mg.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1ec.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1ec.svg"), none;
}

.em-flag-mh {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1ed.png");
}

.em-flag-mh.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1ed.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1ed.svg"), none;
}

.em-flag-mk {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f0.png");
}

.em-flag-mk.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f0.svg"), none;
}

.em-flag-ml {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f1.png");
}

.em-flag-ml.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f1.svg"), none;
}

.em-flag-mm {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f2.png");
}

.em-flag-mm.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f2.svg"), none;
}

.em-flag-mn {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f3.png");
}

.em-flag-mn.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f3.svg"), none;
}

.em-flag-mo {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f4.png");
}

.em-flag-mo.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f4.svg"), none;
}

.em-flag-mp {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f5.png");
}

.em-flag-mp.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f5.svg"), none;
}

.em-flag-mq {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f6.png");
}

.em-flag-mq.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f6.svg"), none;
}

.em-flag-mr {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f7.png");
}

.em-flag-mr.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f7.svg"), none;
}

.em-flag-ms {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f8.png");
}

.em-flag-ms.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f8.svg"), none;
}

.em-flag-mt {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f9.png");
}

.em-flag-mt.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1f9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1f9.svg"), none;
}

.em-flag-mu {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1fa.png");
}

.em-flag-mu.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1fa.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1fa.svg"), none;
}

.em-flag-mv {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1fb.png");
}

.em-flag-mv.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1fb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1fb.svg"), none;
}

.em-flag-mw {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1fc.png");
}

.em-flag-mw.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1fc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1fc.svg"), none;
}

.em-flag-mx {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1fd.png");
}

.em-flag-mx.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1fd.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1fd.svg"), none;
}

.em-flag-my {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1fe.png");
}

.em-flag-my.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1fe.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1fe.svg"), none;
}

.em-flag-mz {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1ff.png");
}

.em-flag-mz.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f2-1f1ff.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f2-1f1ff.svg"), none;
}

.em-flag-na {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1e6.png");
}

.em-flag-na.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1e6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f3-1f1e6.svg"), none;
}

.em-flag-nc {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1e8.png");
}

.em-flag-nc.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1e8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f3-1f1e8.svg"), none;
}

.em-flag-ne {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1ea.png");
}

.em-flag-ne.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1ea.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f3-1f1ea.svg"), none;
}

.em-flag-nf {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1eb.png");
}

.em-flag-nf.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1eb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f3-1f1eb.svg"), none;
}

.em-flag-ng {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1ec.png");
}

.em-flag-ng.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1ec.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f3-1f1ec.svg"), none;
}

.em-flag-ni {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1ee.png");
}

.em-flag-ni.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1ee.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f3-1f1ee.svg"), none;
}

.em-flag-nl {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1f1.png");
}

.em-flag-nl.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1f1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f3-1f1f1.svg"), none;
}

.em-flag-no {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1f4.png");
}

.em-flag-no.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1f4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f3-1f1f4.svg"), none;
}

.em-flag-np {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1f5.png");
}

.em-flag-np.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1f5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f3-1f1f5.svg"), none;
}

.em-flag-nr {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1f7.png");
}

.em-flag-nr.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1f7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f3-1f1f7.svg"), none;
}

.em-flag-nu {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1fa.png");
}

.em-flag-nu.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1fa.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f3-1f1fa.svg"), none;
}

.em-flag-nz {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1ff.png");
}

.em-flag-nz.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f3-1f1ff.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f3-1f1ff.svg"), none;
}

.em-flag-om {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f4-1f1f2.png");
}

.em-flag-om.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f4-1f1f2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f4-1f1f2.svg"), none;
}

.em-flag-pa {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1e6.png");
}

.em-flag-pa.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1e6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1e6.svg"), none;
}

.em-flag-pe {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1ea.png");
}

.em-flag-pe.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1ea.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1ea.svg"), none;
}

.em-flag-pf {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1eb.png");
}

.em-flag-pf.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1eb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1eb.svg"), none;
}

.em-flag-pg {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1ec.png");
}

.em-flag-pg.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1ec.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1ec.svg"), none;
}

.em-flag-ph {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1ed.png");
}

.em-flag-ph.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1ed.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1ed.svg"), none;
}

.em-flag-pk {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1f0.png");
}

.em-flag-pk.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1f0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1f0.svg"), none;
}

.em-flag-pl {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1f1.png");
}

.em-flag-pl.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1f1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1f1.svg"), none;
}

.em-flag-pm {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1f2.png");
}

.em-flag-pm.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1f2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1f2.svg"), none;
}

.em-flag-pn {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1f3.png");
}

.em-flag-pn.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1f3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1f3.svg"), none;
}

.em-flag-pr {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1f7.png");
}

.em-flag-pr.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1f7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1f7.svg"), none;
}

.em-flag-ps {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1f8.png");
}

.em-flag-ps.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1f8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1f8.svg"), none;
}

.em-flag-pt {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1f9.png");
}

.em-flag-pt.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1f9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1f9.svg"), none;
}

.em-flag-pw {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1fc.png");
}

.em-flag-pw.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1fc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1fc.svg"), none;
}

.em-flag-py {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1fe.png");
}

.em-flag-py.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f5-1f1fe.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f5-1f1fe.svg"), none;
}

.em-flag-qa {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f6-1f1e6.png");
}

.em-flag-qa.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f6-1f1e6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f6-1f1e6.svg"), none;
}

.em-flag-re {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f7-1f1ea.png");
}

.em-flag-re.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f7-1f1ea.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f7-1f1ea.svg"), none;
}

.em-flag-ro {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f7-1f1f4.png");
}

.em-flag-ro.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f7-1f1f4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f7-1f1f4.svg"), none;
}

.em-flag-rs {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f7-1f1f8.png");
}

.em-flag-rs.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f7-1f1f8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f7-1f1f8.svg"), none;
}

.em-flag-rw {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f7-1f1fc.png");
}

.em-flag-rw.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f7-1f1fc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f7-1f1fc.svg"), none;
}

.em-flag-sa {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1e6.png");
}

.em-flag-sa.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1e6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1e6.svg"), none;
}

.em-flag-sb {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1e7.png");
}

.em-flag-sb.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1e7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1e7.svg"), none;
}

.em-flag-sc {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1e8.png");
}

.em-flag-sc.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1e8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1e8.svg"), none;
}

.em-flag-scotland {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3f4-e0067-e0062-e0073-e0063-e0074-e007f.png");
}

.em-flag-scotland.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3f4-e0067-e0062-e0073-e0063-e0074-e007f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3f4-e0067-e0062-e0073-e0063-e0074-e007f.svg"), none;
}

.em-flag-sd {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1e9.png");
}

.em-flag-sd.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1e9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1e9.svg"), none;
}

.em-flag-se {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1ea.png");
}

.em-flag-se.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1ea.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1ea.svg"), none;
}

.em-flag-sg {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1ec.png");
}

.em-flag-sg.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1ec.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1ec.svg"), none;
}

.em-flag-sh {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1ed.png");
}

.em-flag-sh.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1ed.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1ed.svg"), none;
}

.em-flag-si {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1ee.png");
}

.em-flag-si.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1ee.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1ee.svg"), none;
}

.em-flag-sj {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1ef.png");
}

.em-flag-sj.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1ef.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1ef.svg"), none;
}

.em-flag-sk {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1f0.png");
}

.em-flag-sk.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1f0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1f0.svg"), none;
}

.em-flag-sl {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1f1.png");
}

.em-flag-sl.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1f1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1f1.svg"), none;
}

.em-flag-sm {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1f2.png");
}

.em-flag-sm.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1f2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1f2.svg"), none;
}

.em-flag-sn {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1f3.png");
}

.em-flag-sn.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1f3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1f3.svg"), none;
}

.em-flag-so {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1f4.png");
}

.em-flag-so.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1f4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1f4.svg"), none;
}

.em-flag-sr {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1f7.png");
}

.em-flag-sr.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1f7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1f7.svg"), none;
}

.em-flag-ss {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1f8.png");
}

.em-flag-ss.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1f8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1f8.svg"), none;
}

.em-flag-st {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1f9.png");
}

.em-flag-st.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1f9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1f9.svg"), none;
}

.em-flag-sv {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1fb.png");
}

.em-flag-sv.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1fb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1fb.svg"), none;
}

.em-flag-sx {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1fd.png");
}

.em-flag-sx.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1fd.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1fd.svg"), none;
}

.em-flag-sy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1fe.png");
}

.em-flag-sy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1fe.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1fe.svg"), none;
}

.em-flag-sz {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1ff.png");
}

.em-flag-sz.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f8-1f1ff.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f8-1f1ff.svg"), none;
}

.em-flag-ta {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1e6.png");
}

.em-flag-ta.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1e6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1e6.svg"), none;
}

.em-flag-tc {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1e8.png");
}

.em-flag-tc.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1e8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1e8.svg"), none;
}

.em-flag-td {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1e9.png");
}

.em-flag-td.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1e9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1e9.svg"), none;
}

.em-flag-tf {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1eb.png");
}

.em-flag-tf.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1eb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1eb.svg"), none;
}

.em-flag-tg {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1ec.png");
}

.em-flag-tg.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1ec.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1ec.svg"), none;
}

.em-flag-th {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1ed.png");
}

.em-flag-th.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1ed.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1ed.svg"), none;
}

.em-flag-tj {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1ef.png");
}

.em-flag-tj.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1ef.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1ef.svg"), none;
}

.em-flag-tk {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1f0.png");
}

.em-flag-tk.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1f0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1f0.svg"), none;
}

.em-flag-tl {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1f1.png");
}

.em-flag-tl.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1f1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1f1.svg"), none;
}

.em-flag-tm {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1f2.png");
}

.em-flag-tm.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1f2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1f2.svg"), none;
}

.em-flag-tn {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1f3.png");
}

.em-flag-tn.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1f3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1f3.svg"), none;
}

.em-flag-to {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1f4.png");
}

.em-flag-to.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1f4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1f4.svg"), none;
}

.em-flag-tr {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1f7.png");
}

.em-flag-tr.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1f7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1f7.svg"), none;
}

.em-flag-tt {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1f9.png");
}

.em-flag-tt.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1f9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1f9.svg"), none;
}

.em-flag-tv {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1fb.png");
}

.em-flag-tv.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1fb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1fb.svg"), none;
}

.em-flag-tw {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1fc.png");
}

.em-flag-tw.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1fc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1fc.svg"), none;
}

.em-flag-tz {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1ff.png");
}

.em-flag-tz.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f9-1f1ff.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f9-1f1ff.svg"), none;
}

.em-flag-ua {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1fa-1f1e6.png");
}

.em-flag-ua.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1fa-1f1e6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fa-1f1e6.svg"), none;
}

.em-flag-ug {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1fa-1f1ec.png");
}

.em-flag-ug.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1fa-1f1ec.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fa-1f1ec.svg"), none;
}

.em-flag-um {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1fa-1f1f2.png");
}

.em-flag-um.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1fa-1f1f2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fa-1f1f2.svg"), none;
}

.em-flag-un {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1fa-1f1f3.png");
}

.em-flag-un.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1fa-1f1f3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fa-1f1f3.svg"), none;
}

.em-flag-uy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1fa-1f1fe.png");
}

.em-flag-uy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1fa-1f1fe.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fa-1f1fe.svg"), none;
}

.em-flag-uz {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1fa-1f1ff.png");
}

.em-flag-uz.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1fa-1f1ff.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fa-1f1ff.svg"), none;
}

.em-flag-va {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1fb-1f1e6.png");
}

.em-flag-va.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1fb-1f1e6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fb-1f1e6.svg"), none;
}

.em-flag-vc {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1fb-1f1e8.png");
}

.em-flag-vc.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1fb-1f1e8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fb-1f1e8.svg"), none;
}

.em-flag-ve {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1fb-1f1ea.png");
}

.em-flag-ve.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1fb-1f1ea.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fb-1f1ea.svg"), none;
}

.em-flag-vg {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1fb-1f1ec.png");
}

.em-flag-vg.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1fb-1f1ec.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fb-1f1ec.svg"), none;
}

.em-flag-vi {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1fb-1f1ee.png");
}

.em-flag-vi.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1fb-1f1ee.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fb-1f1ee.svg"), none;
}

.em-flag-vn {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1fb-1f1f3.png");
}

.em-flag-vn.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1fb-1f1f3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fb-1f1f3.svg"), none;
}

.em-flag-vu {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1fb-1f1fa.png");
}

.em-flag-vu.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1fb-1f1fa.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fb-1f1fa.svg"), none;
}

.em-flag-wales {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3f4-e0067-e0062-e0077-e006c-e0073-e007f.png");
}

.em-flag-wales.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3f4-e0067-e0062-e0077-e006c-e0073-e007f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3f4-e0067-e0062-e0077-e006c-e0073-e007f.svg"), none;
}

.em-flag-wf {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1fc-1f1eb.png");
}

.em-flag-wf.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1fc-1f1eb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fc-1f1eb.svg"), none;
}

.em-flag-ws {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1fc-1f1f8.png");
}

.em-flag-ws.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1fc-1f1f8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fc-1f1f8.svg"), none;
}

.em-flag-xk {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1fd-1f1f0.png");
}

.em-flag-xk.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1fd-1f1f0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fd-1f1f0.svg"), none;
}

.em-flag-ye {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1fe-1f1ea.png");
}

.em-flag-ye.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1fe-1f1ea.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fe-1f1ea.svg"), none;
}

.em-flag-yt {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1fe-1f1f9.png");
}

.em-flag-yt.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1fe-1f1f9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fe-1f1f9.svg"), none;
}

.em-flag-za {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ff-1f1e6.png");
}

.em-flag-za.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ff-1f1e6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ff-1f1e6.svg"), none;
}

.em-flag-zm {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ff-1f1f2.png");
}

.em-flag-zm.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ff-1f1f2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ff-1f1f2.svg"), none;
}

.em-flag-zw {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ff-1f1fc.png");
}

.em-flag-zw.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ff-1f1fc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ff-1f1fc.svg"), none;
}

.em-flags {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f38f.png");
}

.em-flags.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f38f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f38f.svg"), none;
}

.em-flashlight {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f526.png");
}

.em-flashlight.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f526.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f526.svg"), none;
}

.em-fleur_de_lis {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/269c.png");
}

.em-fleur_de_lis.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/269c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/269c.svg"), none;
}

.em-floppy_disk {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4be.png");
}

.em-floppy_disk.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4be.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4be.svg"), none;
}

.em-flower_playing_cards {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3b4.png");
}

.em-flower_playing_cards.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3b4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3b4.svg"), none;
}

.em-flushed {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f633.png");
}

.em-flushed.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f633.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f633.svg"), none;
}

.em-flying_saucer {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6f8.png");
}

.em-flying_saucer.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6f8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6f8.svg"), none;
}

.em-fog {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f32b.png");
}

.em-fog.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f32b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f32b.svg"), none;
}

.em-foggy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f301.png");
}

.em-foggy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f301.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f301.svg"), none;
}

.em-football {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3c8.png");
}

.em-football.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3c8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c8.svg"), none;
}

.em-footprints {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f463.png");
}

.em-footprints.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f463.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f463.svg"), none;
}

.em-fork_and_knife {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f374.png");
}

.em-fork_and_knife.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f374.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f374.svg"), none;
}

.em-fortune_cookie {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f960.png");
}

.em-fortune_cookie.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f960.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f960.svg"), none;
}

.em-fountain {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26f2.png");
}

.em-fountain.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26f2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26f2.svg"), none;
}

.em-four {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/34-20e3.png");
}

.em-four.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/34-20e3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/34-20e3.svg"), none;
}

.em-four_leaf_clover {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f340.png");
}

.em-four_leaf_clover.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f340.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f340.svg"), none;
}

.em-fox_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f98a.png");
}

.em-fox_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f98a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f98a.svg"), none;
}

.em-fr, .em-flag-fr {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1eb-1f1f7.png");
}

.em-fr.em-svg, .em-flag-fr.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1eb-1f1f7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1eb-1f1f7.svg"), none;
}

.em-frame_with_picture {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f5bc.png");
}

.em-frame_with_picture.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f5bc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f5bc.svg"), none;
}

.em-free {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f193.png");
}

.em-free.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f193.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f193.svg"), none;
}

.em-fried_egg, .em-cooking {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f373.png");
}

.em-fried_egg.em-svg, .em-cooking.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f373.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f373.svg"), none;
}

.em-fried_shrimp {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f364.png");
}

.em-fried_shrimp.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f364.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f364.svg"), none;
}

.em-fries {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f35f.png");
}

.em-fries.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f35f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f35f.svg"), none;
}

.em-frog {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f438.png");
}

.em-frog.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f438.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f438.svg"), none;
}

.em-frowning {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f626.png");
}

.em-frowning.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f626.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f626.svg"), none;
}

.em-fuelpump {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26fd.png");
}

.em-fuelpump.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26fd.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26fd.svg"), none;
}

.em-full_moon {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f315.png");
}

.em-full_moon.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f315.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f315.svg"), none;
}

.em-full_moon_with_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f31d.png");
}

.em-full_moon_with_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f31d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f31d.svg"), none;
}

.em-funeral_urn {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26b1.png");
}

.em-funeral_urn.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26b1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26b1.svg"), none;
}

.em-game_die {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3b2.png");
}

.em-game_die.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3b2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3b2.svg"), none;
}

.em-gb, .em-uk, .em-flag-gb {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1e7.png");
}

.em-gb.em-svg, .em-uk.em-svg, .em-flag-gb.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ec-1f1e7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ec-1f1e7.svg"), none;
}

.em-gear {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2699.png");
}

.em-gear.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2699.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2699.svg"), none;
}

.em-gem {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f48e.png");
}

.em-gem.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f48e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f48e.svg"), none;
}

.em-gemini {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/264a.png");
}

.em-gemini.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/264a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/264a.svg"), none;
}

.em-genie {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9de.png");
}

.em-genie.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9de.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9de.svg"), none;
}

.em-ghost {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f47b.png");
}

.em-ghost.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f47b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f47b.svg"), none;
}

.em-gift {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f381.png");
}

.em-gift.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f381.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f381.svg"), none;
}

.em-gift_heart {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f49d.png");
}

.em-gift_heart.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f49d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f49d.svg"), none;
}

.em-giraffe_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f992.png");
}

.em-giraffe_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f992.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f992.svg"), none;
}

.em-girl {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f467.png");
}

.em-girl.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f467.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f467.svg"), none;
}

.em-glass_of_milk {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f95b.png");
}

.em-glass_of_milk.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f95b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f95b.svg"), none;
}

.em-globe_with_meridians {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f310.png");
}

.em-globe_with_meridians.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f310.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f310.svg"), none;
}

.em-gloves {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9e4.png");
}

.em-gloves.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9e4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9e4.svg"), none;
}

.em-goal_net {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f945.png");
}

.em-goal_net.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f945.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f945.svg"), none;
}

.em-goat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f410.png");
}

.em-goat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f410.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f410.svg"), none;
}

.em-golf {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26f3.png");
}

.em-golf.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26f3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26f3.svg"), none;
}

.em-golfer {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3cc.png");
}

.em-golfer.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3cc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3cc.svg"), none;
}

.em-gorilla {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f98d.png");
}

.em-gorilla.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f98d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f98d.svg"), none;
}

.em-grapes {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f347.png");
}

.em-grapes.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f347.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f347.svg"), none;
}

.em-green_apple {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f34f.png");
}

.em-green_apple.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f34f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f34f.svg"), none;
}

.em-green_book {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4d7.png");
}

.em-green_book.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4d7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4d7.svg"), none;
}

.em-green_heart {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f49a.png");
}

.em-green_heart.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f49a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f49a.svg"), none;
}

.em-green_salad {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f957.png");
}

.em-green_salad.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f957.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f957.svg"), none;
}

.em-grey_exclamation {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2755.png");
}

.em-grey_exclamation.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2755.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2755.svg"), none;
}

.em-grey_question {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2754.png");
}

.em-grey_question.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2754.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2754.svg"), none;
}

.em-grimacing {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f62c.png");
}

.em-grimacing.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f62c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f62c.svg"), none;
}

.em-grin {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f601.png");
}

.em-grin.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f601.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f601.svg"), none;
}

.em-grinning {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f600.png");
}

.em-grinning.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f600.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f600.svg"), none;
}

.em-guardsman {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f482.png");
}

.em-guardsman.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f482.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f482.svg"), none;
}

.em-guitar {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3b8.png");
}

.em-guitar.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3b8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3b8.svg"), none;
}

.em-gun {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f52b.png");
}

.em-gun.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f52b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f52b.svg"), none;
}

.em-haircut {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f487.png");
}

.em-haircut.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f487.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f487.svg"), none;
}

.em-hamburger {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f354.png");
}

.em-hamburger.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f354.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f354.svg"), none;
}

.em-hammer {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f528.png");
}

.em-hammer.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f528.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f528.svg"), none;
}

.em-hammer_and_pick {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2692.png");
}

.em-hammer_and_pick.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2692.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2692.svg"), none;
}

.em-hammer_and_wrench {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6e0.png");
}

.em-hammer_and_wrench.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6e0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6e0.svg"), none;
}

.em-hamster {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f439.png");
}

.em-hamster.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f439.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f439.svg"), none;
}

.em-hand, .em-raised_hand {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/270b.png");
}

.em-hand.em-svg, .em-raised_hand.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/270b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/270b.svg"), none;
}

.em-handbag {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f45c.png");
}

.em-handbag.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f45c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f45c.svg"), none;
}

.em-handball {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f93e.png");
}

.em-handball.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f93e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f93e.svg"), none;
}

.em-handshake {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f91d.png");
}

.em-handshake.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f91d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f91d.svg"), none;
}

.em-hankey, .em-poop, .em-shit {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4a9.png");
}

.em-hankey.em-svg, .em-poop.em-svg, .em-shit.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4a9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4a9.svg"), none;
}

.em-hash {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/23-20e3.png");
}

.em-hash.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/23-20e3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/23-20e3.svg"), none;
}

.em-hatched_chick {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f425.png");
}

.em-hatched_chick.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f425.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f425.svg"), none;
}

.em-hatching_chick {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f423.png");
}

.em-hatching_chick.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f423.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f423.svg"), none;
}

.em-headphones {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3a7.png");
}

.em-headphones.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3a7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3a7.svg"), none;
}

.em-hear_no_evil {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f649.png");
}

.em-hear_no_evil.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f649.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f649.svg"), none;
}

.em-heart {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2764.png");
}

.em-heart.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2764.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2764.svg"), none;
}

.em-heart_decoration {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f49f.png");
}

.em-heart_decoration.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f49f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f49f.svg"), none;
}

.em-heart_eyes {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f60d.png");
}

.em-heart_eyes.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f60d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f60d.svg"), none;
}

.em-heart_eyes_cat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f63b.png");
}

.em-heart_eyes_cat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f63b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f63b.svg"), none;
}

.em-heartbeat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f493.png");
}

.em-heartbeat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f493.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f493.svg"), none;
}

.em-heartpulse {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f497.png");
}

.em-heartpulse.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f497.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f497.svg"), none;
}

.em-hearts {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2665.png");
}

.em-hearts.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2665.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2665.svg"), none;
}

.em-heavy_check_mark {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2714.png");
}

.em-heavy_check_mark.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2714.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2714.svg"), none;
}

.em-heavy_division_sign {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2797.png");
}

.em-heavy_division_sign.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2797.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2797.svg"), none;
}

.em-heavy_dollar_sign {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4b2.png");
}

.em-heavy_dollar_sign.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4b2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b2.svg"), none;
}

.em-heavy_heart_exclamation_mark_ornament {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2763.png");
}

.em-heavy_heart_exclamation_mark_ornament.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2763.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2763.svg"), none;
}

.em-heavy_minus_sign {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2796.png");
}

.em-heavy_minus_sign.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2796.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2796.svg"), none;
}

.em-heavy_multiplication_x {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2716.png");
}

.em-heavy_multiplication_x.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2716.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2716.svg"), none;
}

.em-heavy_plus_sign {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2795.png");
}

.em-heavy_plus_sign.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2795.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2795.svg"), none;
}

.em-hedgehog {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f994.png");
}

.em-hedgehog.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f994.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f994.svg"), none;
}

.em-helicopter {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f681.png");
}

.em-helicopter.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f681.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f681.svg"), none;
}

.em-helmet_with_white_cross {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26d1.png");
}

.em-helmet_with_white_cross.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26d1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26d1.svg"), none;
}

.em-herb {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f33f.png");
}

.em-herb.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f33f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f33f.svg"), none;
}

.em-hibiscus {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f33a.png");
}

.em-hibiscus.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f33a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f33a.svg"), none;
}

.em-high_brightness {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f506.png");
}

.em-high_brightness.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f506.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f506.svg"), none;
}

.em-high_heel {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f460.png");
}

.em-high_heel.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f460.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f460.svg"), none;
}

.em-hocho, .em-knife {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f52a.png");
}

.em-hocho.em-svg, .em-knife.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f52a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f52a.svg"), none;
}

.em-hole {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f573.png");
}

.em-hole.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f573.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f573.svg"), none;
}

.em-honey_pot {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f36f.png");
}

.em-honey_pot.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f36f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f36f.svg"), none;
}

.em-horse {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f434.png");
}

.em-horse.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f434.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f434.svg"), none;
}

.em-horse_racing {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3c7.png");
}

.em-horse_racing.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3c7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c7.svg"), none;
}

.em-hospital {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3e5.png");
}

.em-hospital.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3e5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3e5.svg"), none;
}

.em-hot_pepper {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f336.png");
}

.em-hot_pepper.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f336.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f336.svg"), none;
}

.em-hotdog {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f32d.png");
}

.em-hotdog.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f32d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f32d.svg"), none;
}

.em-hotel {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3e8.png");
}

.em-hotel.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3e8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3e8.svg"), none;
}

.em-hotsprings {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2668.png");
}

.em-hotsprings.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2668.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2668.svg"), none;
}

.em-hourglass {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/231b.png");
}

.em-hourglass.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/231b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/231b.svg"), none;
}

.em-hourglass_flowing_sand {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/23f3.png");
}

.em-hourglass_flowing_sand.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/23f3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/23f3.svg"), none;
}

.em-house {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3e0.png");
}

.em-house.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3e0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3e0.svg"), none;
}

.em-house_buildings {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3d8.png");
}

.em-house_buildings.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3d8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3d8.svg"), none;
}

.em-house_with_garden {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3e1.png");
}

.em-house_with_garden.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3e1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3e1.svg"), none;
}

.em-hugging_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f917.png");
}

.em-hugging_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f917.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f917.svg"), none;
}

.em-hushed {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f62f.png");
}

.em-hushed.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f62f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f62f.svg"), none;
}

.em-i_love_you_hand_sign {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f91f.png");
}

.em-i_love_you_hand_sign.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f91f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f91f.svg"), none;
}

.em-ice_cream {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f368.png");
}

.em-ice_cream.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f368.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f368.svg"), none;
}

.em-ice_hockey_stick_and_puck {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3d2.png");
}

.em-ice_hockey_stick_and_puck.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3d2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3d2.svg"), none;
}

.em-ice_skate {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26f8.png");
}

.em-ice_skate.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26f8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26f8.svg"), none;
}

.em-icecream {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f366.png");
}

.em-icecream.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f366.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f366.svg"), none;
}

.em-id {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f194.png");
}

.em-id.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f194.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f194.svg"), none;
}

.em-ideograph_advantage {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f250.png");
}

.em-ideograph_advantage.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f250.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f250.svg"), none;
}

.em-imp {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f47f.png");
}

.em-imp.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f47f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f47f.svg"), none;
}

.em-inbox_tray {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4e5.png");
}

.em-inbox_tray.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4e5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4e5.svg"), none;
}

.em-incoming_envelope {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4e8.png");
}

.em-incoming_envelope.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4e8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4e8.svg"), none;
}

.em-information_desk_person {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f481.png");
}

.em-information_desk_person.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f481.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f481.svg"), none;
}

.em-information_source {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2139.png");
}

.em-information_source.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2139.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2139.svg"), none;
}

.em-innocent {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f607.png");
}

.em-innocent.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f607.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f607.svg"), none;
}

.em-interrobang {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2049.png");
}

.em-interrobang.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2049.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2049.svg"), none;
}

.em-iphone {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4f1.png");
}

.em-iphone.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4f1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4f1.svg"), none;
}

.em-it, .em-flag-it {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1f9.png");
}

.em-it.em-svg, .em-flag-it.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ee-1f1f9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ee-1f1f9.svg"), none;
}

.em-izakaya_lantern, .em-lantern {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3ee.png");
}

.em-izakaya_lantern.em-svg, .em-lantern.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3ee.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ee.svg"), none;
}

.em-jack_o_lantern {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f383.png");
}

.em-jack_o_lantern.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f383.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f383.svg"), none;
}

.em-japan {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f5fe.png");
}

.em-japan.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f5fe.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f5fe.svg"), none;
}

.em-japanese_castle {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3ef.png");
}

.em-japanese_castle.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3ef.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ef.svg"), none;
}

.em-japanese_goblin {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f47a.png");
}

.em-japanese_goblin.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f47a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f47a.svg"), none;
}

.em-japanese_ogre {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f479.png");
}

.em-japanese_ogre.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f479.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f479.svg"), none;
}

.em-jeans {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f456.png");
}

.em-jeans.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f456.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f456.svg"), none;
}

.em-joy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f602.png");
}

.em-joy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f602.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f602.svg"), none;
}

.em-joy_cat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f639.png");
}

.em-joy_cat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f639.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f639.svg"), none;
}

.em-joystick {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f579.png");
}

.em-joystick.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f579.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f579.svg"), none;
}

.em-jp, .em-flag-jp {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1ef-1f1f5.png");
}

.em-jp.em-svg, .em-flag-jp.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1ef-1f1f5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1ef-1f1f5.svg"), none;
}

.em-juggling {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f939.png");
}

.em-juggling.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f939.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f939.svg"), none;
}

.em-kaaba {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f54b.png");
}

.em-kaaba.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f54b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f54b.svg"), none;
}

.em-key {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f511.png");
}

.em-key.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f511.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f511.svg"), none;
}

.em-keyboard {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2328.png");
}

.em-keyboard.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2328.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2328.svg"), none;
}

.em-keycap_star {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2a-20e3.png");
}

.em-keycap_star.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2a-20e3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2a-20e3.svg"), none;
}

.em-keycap_ten {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f51f.png");
}

.em-keycap_ten.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f51f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f51f.svg"), none;
}

.em-kimono {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f458.png");
}

.em-kimono.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f458.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f458.svg"), none;
}

.em-kiss {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f48b.png");
}

.em-kiss.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f48b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f48b.svg"), none;
}

.em-kissing {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f617.png");
}

.em-kissing.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f617.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f617.svg"), none;
}

.em-kissing_cat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f63d.png");
}

.em-kissing_cat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f63d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f63d.svg"), none;
}

.em-kissing_closed_eyes {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f61a.png");
}

.em-kissing_closed_eyes.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f61a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f61a.svg"), none;
}

.em-kissing_heart {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f618.png");
}

.em-kissing_heart.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f618.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f618.svg"), none;
}

.em-kissing_smiling_eyes {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f619.png");
}

.em-kissing_smiling_eyes.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f619.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f619.svg"), none;
}

.em-kiwifruit {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f95d.png");
}

.em-kiwifruit.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f95d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f95d.svg"), none;
}

.em-knife_fork_plate {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f37d.png");
}

.em-knife_fork_plate.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f37d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f37d.svg"), none;
}

.em-koala {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f428.png");
}

.em-koala.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f428.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f428.svg"), none;
}

.em-koko {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f201.png");
}

.em-koko.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f201.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f201.svg"), none;
}

.em-kr, .em-flag-kr {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1f7.png");
}

.em-kr.em-svg, .em-flag-kr.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f0-1f1f7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f0-1f1f7.svg"), none;
}

.em-label {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3f7.png");
}

.em-label.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3f7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3f7.svg"), none;
}

.em-large_blue_circle {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f535.png");
}

.em-large_blue_circle.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f535.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f535.svg"), none;
}

.em-large_blue_diamond {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f537.png");
}

.em-large_blue_diamond.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f537.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f537.svg"), none;
}

.em-large_orange_diamond {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f536.png");
}

.em-large_orange_diamond.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f536.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f536.svg"), none;
}

.em-last_quarter_moon {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f317.png");
}

.em-last_quarter_moon.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f317.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f317.svg"), none;
}

.em-last_quarter_moon_with_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f31c.png");
}

.em-last_quarter_moon_with_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f31c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f31c.svg"), none;
}

.em-latin_cross {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/271d.png");
}

.em-latin_cross.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/271d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/271d.svg"), none;
}

.em-laughing, .em-satisfied {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f606.png");
}

.em-laughing.em-svg, .em-satisfied.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f606.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f606.svg"), none;
}

.em-leaves {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f343.png");
}

.em-leaves.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f343.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f343.svg"), none;
}

.em-ledger {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4d2.png");
}

.em-ledger.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4d2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4d2.svg"), none;
}

.em-left-facing_fist {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f91b.png");
}

.em-left-facing_fist.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f91b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f91b.svg"), none;
}

.em-left_luggage {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6c5.png");
}

.em-left_luggage.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6c5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6c5.svg"), none;
}

.em-left_right_arrow {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2194.png");
}

.em-left_right_arrow.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2194.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2194.svg"), none;
}

.em-left_speech_bubble {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f5e8.png");
}

.em-left_speech_bubble.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f5e8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f5e8.svg"), none;
}

.em-leftwards_arrow_with_hook {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/21a9.png");
}

.em-leftwards_arrow_with_hook.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/21a9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/21a9.svg"), none;
}

.em-lemon {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f34b.png");
}

.em-lemon.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f34b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f34b.svg"), none;
}

.em-leo {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/264c.png");
}

.em-leo.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/264c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/264c.svg"), none;
}

.em-leopard {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f406.png");
}

.em-leopard.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f406.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f406.svg"), none;
}

.em-level_slider {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f39a.png");
}

.em-level_slider.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f39a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f39a.svg"), none;
}

.em-libra {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/264e.png");
}

.em-libra.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/264e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/264e.svg"), none;
}

.em-light_rail {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f688.png");
}

.em-light_rail.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f688.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f688.svg"), none;
}

.em-lightning, .em-lightning_cloud {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f329.png");
}

.em-lightning.em-svg, .em-lightning_cloud.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f329.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f329.svg"), none;
}

.em-link {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f517.png");
}

.em-link.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f517.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f517.svg"), none;
}

.em-linked_paperclips {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f587.png");
}

.em-linked_paperclips.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f587.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f587.svg"), none;
}

.em-lion_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f981.png");
}

.em-lion_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f981.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f981.svg"), none;
}

.em-lips {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f444.png");
}

.em-lips.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f444.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f444.svg"), none;
}

.em-lipstick {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f484.png");
}

.em-lipstick.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f484.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f484.svg"), none;
}

.em-lizard {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f98e.png");
}

.em-lizard.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f98e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f98e.svg"), none;
}

.em-lock {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f512.png");
}

.em-lock.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f512.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f512.svg"), none;
}

.em-lock_with_ink_pen {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f50f.png");
}

.em-lock_with_ink_pen.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f50f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f50f.svg"), none;
}

.em-lollipop {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f36d.png");
}

.em-lollipop.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f36d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f36d.svg"), none;
}

.em-loop {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/27bf.png");
}

.em-loop.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/27bf.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/27bf.svg"), none;
}

.em-loud_sound {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f50a.png");
}

.em-loud_sound.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f50a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f50a.svg"), none;
}

.em-loudspeaker {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4e2.png");
}

.em-loudspeaker.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4e2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4e2.svg"), none;
}

.em-love_hotel {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3e9.png");
}

.em-love_hotel.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3e9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3e9.svg"), none;
}

.em-love_letter {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f48c.png");
}

.em-love_letter.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f48c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f48c.svg"), none;
}

.em-low_brightness {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f505.png");
}

.em-low_brightness.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f505.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f505.svg"), none;
}

.em-lower_left_ballpoint_pen {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f58a.png");
}

.em-lower_left_ballpoint_pen.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f58a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f58a.svg"), none;
}

.em-lower_left_crayon {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f58d.png");
}

.em-lower_left_crayon.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f58d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f58d.svg"), none;
}

.em-lower_left_fountain_pen {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f58b.png");
}

.em-lower_left_fountain_pen.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f58b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f58b.svg"), none;
}

.em-lower_left_paintbrush {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f58c.png");
}

.em-lower_left_paintbrush.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f58c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f58c.svg"), none;
}

.em-lying_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f925.png");
}

.em-lying_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f925.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f925.svg"), none;
}

.em-m {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/24c2.png");
}

.em-m.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/24c2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/24c2.svg"), none;
}

.em-mag {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f50d.png");
}

.em-mag.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f50d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f50d.svg"), none;
}

.em-mag_right {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f50e.png");
}

.em-mag_right.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f50e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f50e.svg"), none;
}

.em-mage {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9d9.png");
}

.em-mage.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9d9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9d9.svg"), none;
}

.em-mahjong {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f004.png");
}

.em-mahjong.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f004.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f004.svg"), none;
}

.em-mailbox {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4eb.png");
}

.em-mailbox.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4eb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4eb.svg"), none;
}

.em-mailbox_closed {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4ea.png");
}

.em-mailbox_closed.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4ea.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ea.svg"), none;
}

.em-mailbox_with_mail {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4ec.png");
}

.em-mailbox_with_mail.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4ec.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ec.svg"), none;
}

.em-mailbox_with_no_mail {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4ed.png");
}

.em-mailbox_with_no_mail.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4ed.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ed.svg"), none;
}

.em-male-artist {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f3a8.png");
}

.em-male-artist.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f3a8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f3a8.svg"), none;
}

.em-male-astronaut {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f680.png");
}

.em-male-astronaut.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f680.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f680.svg"), none;
}

.em-male-construction-worker {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f477-200d-2642-fe0f.png");
}

.em-male-construction-worker.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f477-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f477-200d-2642-fe0f.svg"), none;
}

.em-male-cook {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f373.png");
}

.em-male-cook.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f373.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f373.svg"), none;
}

.em-male-detective {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f575-fe0f-200d-2642-fe0f.png");
}

.em-male-detective.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f575-fe0f-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f575-fe0f-200d-2642-fe0f.svg"), none;
}

.em-male-doctor {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-2695-fe0f.png");
}

.em-male-doctor.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-2695-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-2695-fe0f.svg"), none;
}

.em-male-factory-worker {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f3ed.png");
}

.em-male-factory-worker.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f3ed.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f3ed.svg"), none;
}

.em-male-farmer {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f33e.png");
}

.em-male-farmer.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f33e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f33e.svg"), none;
}

.em-male-firefighter {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f692.png");
}

.em-male-firefighter.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f692.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f692.svg"), none;
}

.em-male-guard {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f482-200d-2642-fe0f.png");
}

.em-male-guard.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f482-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f482-200d-2642-fe0f.svg"), none;
}

.em-male-judge {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-2696-fe0f.png");
}

.em-male-judge.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-2696-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-2696-fe0f.svg"), none;
}

.em-male-mechanic {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f527.png");
}

.em-male-mechanic.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f527.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f527.svg"), none;
}

.em-male-office-worker {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f4bc.png");
}

.em-male-office-worker.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f4bc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f4bc.svg"), none;
}

.em-male-pilot {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-2708-fe0f.png");
}

.em-male-pilot.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-2708-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-2708-fe0f.svg"), none;
}

.em-male-police-officer {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f46e-200d-2642-fe0f.png");
}

.em-male-police-officer.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f46e-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f46e-200d-2642-fe0f.svg"), none;
}

.em-male-scientist {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f52c.png");
}

.em-male-scientist.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f52c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f52c.svg"), none;
}

.em-male-singer {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f3a4.png");
}

.em-male-singer.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f3a4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f3a4.svg"), none;
}

.em-male-student {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f393.png");
}

.em-male-student.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f393.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f393.svg"), none;
}

.em-male-teacher {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f3eb.png");
}

.em-male-teacher.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f3eb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f3eb.svg"), none;
}

.em-male-technologist {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f4bb.png");
}

.em-male-technologist.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f4bb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f4bb.svg"), none;
}

.em-male_elf {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9dd-200d-2642-fe0f.png");
}

.em-male_elf.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9dd-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9dd-200d-2642-fe0f.svg"), none;
}

.em-male_fairy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9da-200d-2642-fe0f.png");
}

.em-male_fairy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9da-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9da-200d-2642-fe0f.svg"), none;
}

.em-male_genie {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9de-200d-2642-fe0f.png");
}

.em-male_genie.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9de-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9de-200d-2642-fe0f.svg"), none;
}

.em-male_mage {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9d9-200d-2642-fe0f.png");
}

.em-male_mage.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9d9-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9d9-200d-2642-fe0f.svg"), none;
}

.em-male_sign {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2642.png");
}

.em-male_sign.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2642.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2642.svg"), none;
}

.em-male_vampire {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9db-200d-2642-fe0f.png");
}

.em-male_vampire.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9db-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9db-200d-2642-fe0f.svg"), none;
}

.em-male_zombie {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9df-200d-2642-fe0f.png");
}

.em-male_zombie.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9df-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9df-200d-2642-fe0f.svg"), none;
}

.em-man {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468.png");
}

.em-man.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468.svg"), none;
}

.em-man-biking {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6b4-200d-2642-fe0f.png");
}

.em-man-biking.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6b4-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b4-200d-2642-fe0f.svg"), none;
}

.em-man-bouncing-ball {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26f9-fe0f-200d-2642-fe0f.png");
}

.em-man-bouncing-ball.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26f9-fe0f-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26f9-fe0f-200d-2642-fe0f.svg"), none;
}

.em-man-bowing {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f647-200d-2642-fe0f.png");
}

.em-man-bowing.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f647-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f647-200d-2642-fe0f.svg"), none;
}

.em-man-boy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f466.png");
}

.em-man-boy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f466.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f466.svg"), none;
}

.em-man-boy-boy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f466-200d-1f466.png");
}

.em-man-boy-boy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f466-200d-1f466.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f466-200d-1f466.svg"), none;
}

.em-man-cartwheeling {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f938-200d-2642-fe0f.png");
}

.em-man-cartwheeling.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f938-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f938-200d-2642-fe0f.svg"), none;
}

.em-man-facepalming {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f926-200d-2642-fe0f.png");
}

.em-man-facepalming.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f926-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f926-200d-2642-fe0f.svg"), none;
}

.em-man-frowning {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f64d-200d-2642-fe0f.png");
}

.em-man-frowning.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f64d-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f64d-200d-2642-fe0f.svg"), none;
}

.em-man-gesturing-no {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f645-200d-2642-fe0f.png");
}

.em-man-gesturing-no.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f645-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f645-200d-2642-fe0f.svg"), none;
}

.em-man-gesturing-ok {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f646-200d-2642-fe0f.png");
}

.em-man-gesturing-ok.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f646-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f646-200d-2642-fe0f.svg"), none;
}

.em-man-getting-haircut {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f487-200d-2642-fe0f.png");
}

.em-man-getting-haircut.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f487-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f487-200d-2642-fe0f.svg"), none;
}

.em-man-getting-massage {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f486-200d-2642-fe0f.png");
}

.em-man-getting-massage.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f486-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f486-200d-2642-fe0f.svg"), none;
}

.em-man-girl {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f467.png");
}

.em-man-girl.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f467.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f467.svg"), none;
}

.em-man-girl-boy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f467-200d-1f466.png");
}

.em-man-girl-boy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f467-200d-1f466.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f467-200d-1f466.svg"), none;
}

.em-man-girl-girl {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f467-200d-1f467.png");
}

.em-man-girl-girl.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f467-200d-1f467.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f467-200d-1f467.svg"), none;
}

.em-man-golfing {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3cc-fe0f-200d-2642-fe0f.png");
}

.em-man-golfing.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3cc-fe0f-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3cc-fe0f-200d-2642-fe0f.svg"), none;
}

.em-man-heart-man {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-2764-fe0f-200d-1f468.png");
}

.em-man-heart-man.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-2764-fe0f-200d-1f468.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-2764-fe0f-200d-1f468.svg"), none;
}

.em-man-juggling {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f939-200d-2642-fe0f.png");
}

.em-man-juggling.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f939-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f939-200d-2642-fe0f.svg"), none;
}

.em-man-kiss-man {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-2764-fe0f-200d-1f48b-200d-1f468.png");
}

.em-man-kiss-man.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-2764-fe0f-200d-1f48b-200d-1f468.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-2764-fe0f-200d-1f48b-200d-1f468.svg"), none;
}

.em-man-lifting-weights {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3cb-fe0f-200d-2642-fe0f.png");
}

.em-man-lifting-weights.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3cb-fe0f-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3cb-fe0f-200d-2642-fe0f.svg"), none;
}

.em-man-man-boy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f468-200d-1f466.png");
}

.em-man-man-boy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f468-200d-1f466.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f468-200d-1f466.svg"), none;
}

.em-man-man-boy-boy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f468-200d-1f466-200d-1f466.png");
}

.em-man-man-boy-boy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f468-200d-1f466-200d-1f466.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f468-200d-1f466-200d-1f466.svg"), none;
}

.em-man-man-girl {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f468-200d-1f467.png");
}

.em-man-man-girl.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f468-200d-1f467.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f468-200d-1f467.svg"), none;
}

.em-man-man-girl-boy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f468-200d-1f467-200d-1f466.png");
}

.em-man-man-girl-boy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f468-200d-1f467-200d-1f466.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f468-200d-1f467-200d-1f466.svg"), none;
}

.em-man-man-girl-girl {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f468-200d-1f467-200d-1f467.png");
}

.em-man-man-girl-girl.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f468-200d-1f467-200d-1f467.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f468-200d-1f467-200d-1f467.svg"), none;
}

.em-man-mountain-biking {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6b5-200d-2642-fe0f.png");
}

.em-man-mountain-biking.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6b5-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b5-200d-2642-fe0f.svg"), none;
}

.em-man-playing-handball {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f93e-200d-2642-fe0f.png");
}

.em-man-playing-handball.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f93e-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f93e-200d-2642-fe0f.svg"), none;
}

.em-man-playing-water-polo {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f93d-200d-2642-fe0f.png");
}

.em-man-playing-water-polo.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f93d-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f93d-200d-2642-fe0f.svg"), none;
}

.em-man-pouting {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f64e-200d-2642-fe0f.png");
}

.em-man-pouting.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f64e-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f64e-200d-2642-fe0f.svg"), none;
}

.em-man-raising-hand {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f64b-200d-2642-fe0f.png");
}

.em-man-raising-hand.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f64b-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f64b-200d-2642-fe0f.svg"), none;
}

.em-man-rowing-boat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6a3-200d-2642-fe0f.png");
}

.em-man-rowing-boat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6a3-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a3-200d-2642-fe0f.svg"), none;
}

.em-man-running {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3c3-200d-2642-fe0f.png");
}

.em-man-running.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3c3-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c3-200d-2642-fe0f.svg"), none;
}

.em-man-shrugging {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f937-200d-2642-fe0f.png");
}

.em-man-shrugging.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f937-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f937-200d-2642-fe0f.svg"), none;
}

.em-man-surfing {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3c4-200d-2642-fe0f.png");
}

.em-man-surfing.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3c4-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c4-200d-2642-fe0f.svg"), none;
}

.em-man-swimming {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3ca-200d-2642-fe0f.png");
}

.em-man-swimming.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3ca-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ca-200d-2642-fe0f.svg"), none;
}

.em-man-tipping-hand {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f481-200d-2642-fe0f.png");
}

.em-man-tipping-hand.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f481-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f481-200d-2642-fe0f.svg"), none;
}

.em-man-walking {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6b6-200d-2642-fe0f.png");
}

.em-man-walking.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6b6-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b6-200d-2642-fe0f.svg"), none;
}

.em-man-wearing-turban {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f473-200d-2642-fe0f.png");
}

.em-man-wearing-turban.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f473-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f473-200d-2642-fe0f.svg"), none;
}

.em-man-with-bunny-ears-partying {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f46f-200d-2642-fe0f.png");
}

.em-man-with-bunny-ears-partying.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f46f-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f46f-200d-2642-fe0f.svg"), none;
}

.em-man-woman-boy, .em-family {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f469-200d-1f466.png");
}

.em-man-woman-boy.em-svg, .em-family.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f469-200d-1f466.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f469-200d-1f466.svg"), none;
}

.em-man-woman-boy-boy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f469-200d-1f466-200d-1f466.png");
}

.em-man-woman-boy-boy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f469-200d-1f466-200d-1f466.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f469-200d-1f466-200d-1f466.svg"), none;
}

.em-man-woman-girl {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f469-200d-1f467.png");
}

.em-man-woman-girl.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f469-200d-1f467.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f469-200d-1f467.svg"), none;
}

.em-man-woman-girl-boy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f469-200d-1f467-200d-1f466.png");
}

.em-man-woman-girl-boy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f469-200d-1f467-200d-1f466.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f469-200d-1f467-200d-1f466.svg"), none;
}

.em-man-woman-girl-girl {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f469-200d-1f467-200d-1f467.png");
}

.em-man-woman-girl-girl.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f468-200d-1f469-200d-1f467-200d-1f467.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f468-200d-1f469-200d-1f467-200d-1f467.svg"), none;
}

.em-man-wrestling {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f93c-200d-2642-fe0f.png");
}

.em-man-wrestling.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f93c-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f93c-200d-2642-fe0f.svg"), none;
}

.em-man_climbing {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9d7-200d-2642-fe0f.png");
}

.em-man_climbing.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9d7-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9d7-200d-2642-fe0f.svg"), none;
}

.em-man_dancing {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f57a.png");
}

.em-man_dancing.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f57a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f57a.svg"), none;
}

.em-man_in_business_suit_levitating {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f574.png");
}

.em-man_in_business_suit_levitating.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f574.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f574.svg"), none;
}

.em-man_in_lotus_position {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9d8-200d-2642-fe0f.png");
}

.em-man_in_lotus_position.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9d8-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9d8-200d-2642-fe0f.svg"), none;
}

.em-man_in_steamy_room {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9d6-200d-2642-fe0f.png");
}

.em-man_in_steamy_room.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9d6-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9d6-200d-2642-fe0f.svg"), none;
}

.em-man_in_tuxedo {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f935.png");
}

.em-man_in_tuxedo.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f935.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f935.svg"), none;
}

.em-man_with_gua_pi_mao {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f472.png");
}

.em-man_with_gua_pi_mao.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f472.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f472.svg"), none;
}

.em-man_with_turban {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f473.png");
}

.em-man_with_turban.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f473.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f473.svg"), none;
}

.em-mans_shoe, .em-shoe {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f45e.png");
}

.em-mans_shoe.em-svg, .em-shoe.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f45e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f45e.svg"), none;
}

.em-mantelpiece_clock {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f570.png");
}

.em-mantelpiece_clock.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f570.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f570.svg"), none;
}

.em-maple_leaf {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f341.png");
}

.em-maple_leaf.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f341.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f341.svg"), none;
}

.em-martial_arts_uniform {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f94b.png");
}

.em-martial_arts_uniform.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f94b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f94b.svg"), none;
}

.em-mask {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f637.png");
}

.em-mask.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f637.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f637.svg"), none;
}

.em-massage {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f486.png");
}

.em-massage.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f486.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f486.svg"), none;
}

.em-meat_on_bone {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f356.png");
}

.em-meat_on_bone.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f356.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f356.svg"), none;
}

.em-medal {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f396.png");
}

.em-medal.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f396.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f396.svg"), none;
}

.em-medical_symbol, .em-staff_of_aesculapius {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2695.png");
}

.em-medical_symbol.em-svg, .em-staff_of_aesculapius.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2695.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2695.svg"), none;
}

.em-mega {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4e3.png");
}

.em-mega.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4e3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4e3.svg"), none;
}

.em-melon {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f348.png");
}

.em-melon.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f348.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f348.svg"), none;
}

.em-memo, .em-pencil {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4dd.png");
}

.em-memo.em-svg, .em-pencil.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4dd.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4dd.svg"), none;
}

.em-menorah_with_nine_branches {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f54e.png");
}

.em-menorah_with_nine_branches.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f54e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f54e.svg"), none;
}

.em-mens {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6b9.png");
}

.em-mens.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6b9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b9.svg"), none;
}

.em-mermaid {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9dc-200d-2640-fe0f.png");
}

.em-mermaid.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9dc-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9dc-200d-2640-fe0f.svg"), none;
}

.em-merman {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9dc-200d-2642-fe0f.png");
}

.em-merman.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9dc-200d-2642-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9dc-200d-2642-fe0f.svg"), none;
}

.em-merperson {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9dc.png");
}

.em-merperson.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9dc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9dc.svg"), none;
}

.em-metro {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f687.png");
}

.em-metro.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f687.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f687.svg"), none;
}

.em-microphone {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3a4.png");
}

.em-microphone.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3a4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3a4.svg"), none;
}

.em-microscope {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f52c.png");
}

.em-microscope.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f52c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f52c.svg"), none;
}

.em-middle_finger, .em-reversed_hand_with_middle_finger_extended {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f595.png");
}

.em-middle_finger.em-svg, .em-reversed_hand_with_middle_finger_extended.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f595.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f595.svg"), none;
}

.em-milky_way {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f30c.png");
}

.em-milky_way.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f30c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f30c.svg"), none;
}

.em-minibus {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f690.png");
}

.em-minibus.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f690.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f690.svg"), none;
}

.em-minidisc {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4bd.png");
}

.em-minidisc.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4bd.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4bd.svg"), none;
}

.em-mobile_phone_off {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4f4.png");
}

.em-mobile_phone_off.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4f4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4f4.svg"), none;
}

.em-money_mouth_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f911.png");
}

.em-money_mouth_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f911.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f911.svg"), none;
}

.em-money_with_wings {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4b8.png");
}

.em-money_with_wings.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4b8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b8.svg"), none;
}

.em-moneybag {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4b0.png");
}

.em-moneybag.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4b0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b0.svg"), none;
}

.em-monkey {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f412.png");
}

.em-monkey.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f412.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f412.svg"), none;
}

.em-monkey_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f435.png");
}

.em-monkey_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f435.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f435.svg"), none;
}

.em-monorail {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f69d.png");
}

.em-monorail.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f69d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f69d.svg"), none;
}

.em-moon, .em-waxing_gibbous_moon {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f314.png");
}

.em-moon.em-svg, .em-waxing_gibbous_moon.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f314.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f314.svg"), none;
}

.em-mortar_board {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f393.png");
}

.em-mortar_board.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f393.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f393.svg"), none;
}

.em-mosque {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f54c.png");
}

.em-mosque.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f54c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f54c.svg"), none;
}

.em-mostly_sunny, .em-sun_small_cloud {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f324.png");
}

.em-mostly_sunny.em-svg, .em-sun_small_cloud.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f324.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f324.svg"), none;
}

.em-motor_boat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6e5.png");
}

.em-motor_boat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6e5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6e5.svg"), none;
}

.em-motor_scooter {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6f5.png");
}

.em-motor_scooter.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6f5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6f5.svg"), none;
}

.em-motorway {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6e3.png");
}

.em-motorway.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6e3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6e3.svg"), none;
}

.em-mount_fuji {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f5fb.png");
}

.em-mount_fuji.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f5fb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f5fb.svg"), none;
}

.em-mountain {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26f0.png");
}

.em-mountain.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26f0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26f0.svg"), none;
}

.em-mountain_bicyclist {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6b5.png");
}

.em-mountain_bicyclist.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6b5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b5.svg"), none;
}

.em-mountain_cableway {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6a0.png");
}

.em-mountain_cableway.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6a0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a0.svg"), none;
}

.em-mountain_railway {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f69e.png");
}

.em-mountain_railway.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f69e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f69e.svg"), none;
}

.em-mouse {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f42d.png");
}

.em-mouse.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f42d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f42d.svg"), none;
}

.em-mouse2 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f401.png");
}

.em-mouse2.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f401.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f401.svg"), none;
}

.em-movie_camera {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3a5.png");
}

.em-movie_camera.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3a5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3a5.svg"), none;
}

.em-moyai {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f5ff.png");
}

.em-moyai.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f5ff.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f5ff.svg"), none;
}

.em-mrs_claus, .em-mother_christmas {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f936.png");
}

.em-mrs_claus.em-svg, .em-mother_christmas.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f936.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f936.svg"), none;
}

.em-muscle {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4aa.png");
}

.em-muscle.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4aa.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4aa.svg"), none;
}

.em-mushroom {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f344.png");
}

.em-mushroom.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f344.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f344.svg"), none;
}

.em-musical_keyboard {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3b9.png");
}

.em-musical_keyboard.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3b9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3b9.svg"), none;
}

.em-musical_note {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3b5.png");
}

.em-musical_note.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3b5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3b5.svg"), none;
}

.em-musical_score {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3bc.png");
}

.em-musical_score.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3bc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3bc.svg"), none;
}

.em-mute {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f507.png");
}

.em-mute.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f507.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f507.svg"), none;
}

.em-nail_care {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f485.png");
}

.em-nail_care.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f485.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f485.svg"), none;
}

.em-name_badge {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4db.png");
}

.em-name_badge.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4db.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4db.svg"), none;
}

.em-national_park {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3de.png");
}

.em-national_park.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3de.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3de.svg"), none;
}

.em-nauseated_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f922.png");
}

.em-nauseated_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f922.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f922.svg"), none;
}

.em-necktie {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f454.png");
}

.em-necktie.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f454.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f454.svg"), none;
}

.em-negative_squared_cross_mark {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/274e.png");
}

.em-negative_squared_cross_mark.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/274e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/274e.svg"), none;
}

.em-nerd_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f913.png");
}

.em-nerd_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f913.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f913.svg"), none;
}

.em-neutral_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f610.png");
}

.em-neutral_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f610.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f610.svg"), none;
}

.em-new {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f195.png");
}

.em-new.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f195.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f195.svg"), none;
}

.em-new_moon {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f311.png");
}

.em-new_moon.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f311.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f311.svg"), none;
}

.em-new_moon_with_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f31a.png");
}

.em-new_moon_with_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f31a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f31a.svg"), none;
}

.em-newspaper {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4f0.png");
}

.em-newspaper.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4f0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4f0.svg"), none;
}

.em-ng {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f196.png");
}

.em-ng.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f196.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f196.svg"), none;
}

.em-night_with_stars {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f303.png");
}

.em-night_with_stars.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f303.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f303.svg"), none;
}

.em-nine {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/39-20e3.png");
}

.em-nine.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/39-20e3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/39-20e3.svg"), none;
}

.em-no_bell {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f515.png");
}

.em-no_bell.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f515.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f515.svg"), none;
}

.em-no_bicycles {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6b3.png");
}

.em-no_bicycles.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6b3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b3.svg"), none;
}

.em-no_entry {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26d4.png");
}

.em-no_entry.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26d4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26d4.svg"), none;
}

.em-no_entry_sign {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6ab.png");
}

.em-no_entry_sign.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6ab.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6ab.svg"), none;
}

.em-no_good {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f645.png");
}

.em-no_good.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f645.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f645.svg"), none;
}

.em-no_mobile_phones {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4f5.png");
}

.em-no_mobile_phones.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4f5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4f5.svg"), none;
}

.em-no_mouth {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f636.png");
}

.em-no_mouth.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f636.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f636.svg"), none;
}

.em-no_pedestrians {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6b7.png");
}

.em-no_pedestrians.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6b7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b7.svg"), none;
}

.em-no_smoking {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6ad.png");
}

.em-no_smoking.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6ad.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6ad.svg"), none;
}

.em-non-potable_water {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6b1.png");
}

.em-non-potable_water.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6b1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b1.svg"), none;
}

.em-nose {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f443.png");
}

.em-nose.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f443.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f443.svg"), none;
}

.em-notebook {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4d3.png");
}

.em-notebook.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4d3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4d3.svg"), none;
}

.em-notebook_with_decorative_cover {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4d4.png");
}

.em-notebook_with_decorative_cover.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4d4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4d4.svg"), none;
}

.em-notes {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3b6.png");
}

.em-notes.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3b6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3b6.svg"), none;
}

.em-nut_and_bolt {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f529.png");
}

.em-nut_and_bolt.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f529.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f529.svg"), none;
}

.em-o {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2b55.png");
}

.em-o.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2b55.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2b55.svg"), none;
}

.em-o2 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f17e.png");
}

.em-o2.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f17e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f17e.svg"), none;
}

.em-ocean {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f30a.png");
}

.em-ocean.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f30a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f30a.svg"), none;
}

.em-octagonal_sign {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6d1.png");
}

.em-octagonal_sign.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6d1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6d1.svg"), none;
}

.em-octopus {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f419.png");
}

.em-octopus.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f419.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f419.svg"), none;
}

.em-oden {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f362.png");
}

.em-oden.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f362.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f362.svg"), none;
}

.em-office {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3e2.png");
}

.em-office.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3e2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3e2.svg"), none;
}

.em-oil_drum {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6e2.png");
}

.em-oil_drum.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6e2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6e2.svg"), none;
}

.em-ok {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f197.png");
}

.em-ok.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f197.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f197.svg"), none;
}

.em-ok_hand {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f44c.png");
}

.em-ok_hand.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f44c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f44c.svg"), none;
}

.em-ok_woman {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f646.png");
}

.em-ok_woman.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f646.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f646.svg"), none;
}

.em-old_key {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f5dd.png");
}

.em-old_key.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f5dd.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f5dd.svg"), none;
}

.em-older_adult {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9d3.png");
}

.em-older_adult.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9d3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9d3.svg"), none;
}

.em-older_man {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f474.png");
}

.em-older_man.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f474.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f474.svg"), none;
}

.em-older_woman {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f475.png");
}

.em-older_woman.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f475.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f475.svg"), none;
}

.em-om_symbol {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f549.png");
}

.em-om_symbol.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f549.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f549.svg"), none;
}

.em-on {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f51b.png");
}

.em-on.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f51b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f51b.svg"), none;
}

.em-oncoming_automobile {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f698.png");
}

.em-oncoming_automobile.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f698.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f698.svg"), none;
}

.em-oncoming_bus {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f68d.png");
}

.em-oncoming_bus.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f68d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f68d.svg"), none;
}

.em-oncoming_police_car {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f694.png");
}

.em-oncoming_police_car.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f694.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f694.svg"), none;
}

.em-oncoming_taxi {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f696.png");
}

.em-oncoming_taxi.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f696.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f696.svg"), none;
}

.em-one {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/31-20e3.png");
}

.em-one.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/31-20e3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/31-20e3.svg"), none;
}

.em-open_file_folder {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4c2.png");
}

.em-open_file_folder.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4c2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4c2.svg"), none;
}

.em-open_hands {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f450.png");
}

.em-open_hands.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f450.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f450.svg"), none;
}

.em-open_mouth {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f62e.png");
}

.em-open_mouth.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f62e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f62e.svg"), none;
}

.em-ophiuchus {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26ce.png");
}

.em-ophiuchus.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26ce.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26ce.svg"), none;
}

.em-orange_book {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4d9.png");
}

.em-orange_book.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4d9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4d9.svg"), none;
}

.em-orange_heart {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9e1.png");
}

.em-orange_heart.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9e1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9e1.svg"), none;
}

.em-orthodox_cross {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2626.png");
}

.em-orthodox_cross.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2626.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2626.svg"), none;
}

.em-outbox_tray {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4e4.png");
}

.em-outbox_tray.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4e4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4e4.svg"), none;
}

.em-owl {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f989.png");
}

.em-owl.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f989.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f989.svg"), none;
}

.em-ox {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f402.png");
}

.em-ox.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f402.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f402.svg"), none;
}

.em-package {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4e6.png");
}

.em-package.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4e6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4e6.svg"), none;
}

.em-page_facing_up {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4c4.png");
}

.em-page_facing_up.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4c4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4c4.svg"), none;
}

.em-page_with_curl {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4c3.png");
}

.em-page_with_curl.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4c3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4c3.svg"), none;
}

.em-pager {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4df.png");
}

.em-pager.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4df.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4df.svg"), none;
}

.em-palm_tree {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f334.png");
}

.em-palm_tree.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f334.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f334.svg"), none;
}

.em-palms_up_together {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f932.png");
}

.em-palms_up_together.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f932.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f932.svg"), none;
}

.em-pancakes {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f95e.png");
}

.em-pancakes.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f95e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f95e.svg"), none;
}

.em-panda_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f43c.png");
}

.em-panda_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f43c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f43c.svg"), none;
}

.em-paperclip {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4ce.png");
}

.em-paperclip.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4ce.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ce.svg"), none;
}

.em-parking {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f17f.png");
}

.em-parking.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f17f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f17f.svg"), none;
}

.em-part_alternation_mark {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/303d.png");
}

.em-part_alternation_mark.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/303d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/303d.svg"), none;
}

.em-partly_sunny {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26c5.png");
}

.em-partly_sunny.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26c5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26c5.svg"), none;
}

.em-partly_sunny_rain, .em-sun_behind_rain_cloud {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f326.png");
}

.em-partly_sunny_rain.em-svg, .em-sun_behind_rain_cloud.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f326.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f326.svg"), none;
}

.em-passenger_ship {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6f3.png");
}

.em-passenger_ship.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6f3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6f3.svg"), none;
}

.em-passport_control {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6c2.png");
}

.em-passport_control.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6c2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6c2.svg"), none;
}

.em-peace_symbol {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/262e.png");
}

.em-peace_symbol.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/262e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/262e.svg"), none;
}

.em-peach {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f351.png");
}

.em-peach.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f351.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f351.svg"), none;
}

.em-peanuts {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f95c.png");
}

.em-peanuts.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f95c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f95c.svg"), none;
}

.em-pear {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f350.png");
}

.em-pear.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f350.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f350.svg"), none;
}

.em-pencil2 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/270f.png");
}

.em-pencil2.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/270f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/270f.svg"), none;
}

.em-penguin {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f427.png");
}

.em-penguin.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f427.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f427.svg"), none;
}

.em-pensive {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f614.png");
}

.em-pensive.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f614.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f614.svg"), none;
}

.em-performing_arts {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3ad.png");
}

.em-performing_arts.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3ad.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ad.svg"), none;
}

.em-persevere {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f623.png");
}

.em-persevere.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f623.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f623.svg"), none;
}

.em-person_climbing {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9d7.png");
}

.em-person_climbing.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9d7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9d7.svg"), none;
}

.em-person_doing_cartwheel {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f938.png");
}

.em-person_doing_cartwheel.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f938.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f938.svg"), none;
}

.em-person_frowning {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f64d.png");
}

.em-person_frowning.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f64d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f64d.svg"), none;
}

.em-person_in_lotus_position {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9d8.png");
}

.em-person_in_lotus_position.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9d8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9d8.svg"), none;
}

.em-person_in_steamy_room {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9d6.png");
}

.em-person_in_steamy_room.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9d6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9d6.svg"), none;
}

.em-person_with_ball {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26f9.png");
}

.em-person_with_ball.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26f9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26f9.svg"), none;
}

.em-person_with_blond_hair {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f471.png");
}

.em-person_with_blond_hair.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f471.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f471.svg"), none;
}

.em-person_with_headscarf {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9d5.png");
}

.em-person_with_headscarf.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9d5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9d5.svg"), none;
}

.em-person_with_pouting_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f64e.png");
}

.em-person_with_pouting_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f64e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f64e.svg"), none;
}

.em-phone, .em-telephone {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/260e.png");
}

.em-phone.em-svg, .em-telephone.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/260e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/260e.svg"), none;
}

.em-pick {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26cf.png");
}

.em-pick.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26cf.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26cf.svg"), none;
}

.em-pie {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f967.png");
}

.em-pie.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f967.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f967.svg"), none;
}

.em-pig {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f437.png");
}

.em-pig.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f437.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f437.svg"), none;
}

.em-pig2 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f416.png");
}

.em-pig2.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f416.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f416.svg"), none;
}

.em-pig_nose {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f43d.png");
}

.em-pig_nose.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f43d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f43d.svg"), none;
}

.em-pill {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f48a.png");
}

.em-pill.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f48a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f48a.svg"), none;
}

.em-pineapple {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f34d.png");
}

.em-pineapple.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f34d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f34d.svg"), none;
}

.em-pisces {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2653.png");
}

.em-pisces.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2653.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2653.svg"), none;
}

.em-pizza {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f355.png");
}

.em-pizza.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f355.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f355.svg"), none;
}

.em-place_of_worship {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6d0.png");
}

.em-place_of_worship.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6d0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6d0.svg"), none;
}

.em-point_down {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f447.png");
}

.em-point_down.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f447.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f447.svg"), none;
}

.em-point_left {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f448.png");
}

.em-point_left.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f448.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f448.svg"), none;
}

.em-point_right {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f449.png");
}

.em-point_right.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f449.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f449.svg"), none;
}

.em-point_up {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/261d.png");
}

.em-point_up.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/261d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/261d.svg"), none;
}

.em-point_up_2 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f446.png");
}

.em-point_up_2.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f446.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f446.svg"), none;
}

.em-police_car {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f693.png");
}

.em-police_car.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f693.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f693.svg"), none;
}

.em-poodle {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f429.png");
}

.em-poodle.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f429.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f429.svg"), none;
}

.em-popcorn {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f37f.png");
}

.em-popcorn.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f37f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f37f.svg"), none;
}

.em-post_office {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3e3.png");
}

.em-post_office.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3e3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3e3.svg"), none;
}

.em-postal_horn {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4ef.png");
}

.em-postal_horn.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4ef.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ef.svg"), none;
}

.em-postbox {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4ee.png");
}

.em-postbox.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4ee.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ee.svg"), none;
}

.em-potable_water {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6b0.png");
}

.em-potable_water.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6b0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b0.svg"), none;
}

.em-potato {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f954.png");
}

.em-potato.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f954.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f954.svg"), none;
}

.em-pouch {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f45d.png");
}

.em-pouch.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f45d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f45d.svg"), none;
}

.em-poultry_leg {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f357.png");
}

.em-poultry_leg.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f357.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f357.svg"), none;
}

.em-pound {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4b7.png");
}

.em-pound.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4b7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b7.svg"), none;
}

.em-pouting_cat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f63e.png");
}

.em-pouting_cat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f63e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f63e.svg"), none;
}

.em-pray {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f64f.png");
}

.em-pray.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f64f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f64f.svg"), none;
}

.em-prayer_beads {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4ff.png");
}

.em-prayer_beads.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4ff.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ff.svg"), none;
}

.em-pregnant_woman {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f930.png");
}

.em-pregnant_woman.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f930.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f930.svg"), none;
}

.em-pretzel {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f968.png");
}

.em-pretzel.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f968.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f968.svg"), none;
}

.em-prince {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f934.png");
}

.em-prince.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f934.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f934.svg"), none;
}

.em-princess {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f478.png");
}

.em-princess.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f478.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f478.svg"), none;
}

.em-printer {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f5a8.png");
}

.em-printer.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f5a8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f5a8.svg"), none;
}

.em-purple_heart {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f49c.png");
}

.em-purple_heart.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f49c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f49c.svg"), none;
}

.em-purse {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f45b.png");
}

.em-purse.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f45b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f45b.svg"), none;
}

.em-pushpin {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4cc.png");
}

.em-pushpin.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4cc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4cc.svg"), none;
}

.em-put_litter_in_its_place {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6ae.png");
}

.em-put_litter_in_its_place.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6ae.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6ae.svg"), none;
}

.em-question {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2753.png");
}

.em-question.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2753.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2753.svg"), none;
}

.em-rabbit {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f430.png");
}

.em-rabbit.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f430.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f430.svg"), none;
}

.em-rabbit2 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f407.png");
}

.em-rabbit2.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f407.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f407.svg"), none;
}

.em-racehorse {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f40e.png");
}

.em-racehorse.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f40e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f40e.svg"), none;
}

.em-racing_car {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3ce.png");
}

.em-racing_car.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3ce.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ce.svg"), none;
}

.em-racing_motorcycle {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3cd.png");
}

.em-racing_motorcycle.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3cd.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3cd.svg"), none;
}

.em-radio {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4fb.png");
}

.em-radio.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4fb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4fb.svg"), none;
}

.em-radio_button {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f518.png");
}

.em-radio_button.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f518.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f518.svg"), none;
}

.em-radioactive_sign {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2622.png");
}

.em-radioactive_sign.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2622.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2622.svg"), none;
}

.em-rage {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f621.png");
}

.em-rage.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f621.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f621.svg"), none;
}

.em-railway_car {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f683.png");
}

.em-railway_car.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f683.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f683.svg"), none;
}

.em-railway_track {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6e4.png");
}

.em-railway_track.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6e4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6e4.svg"), none;
}

.em-rain_cloud {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f327.png");
}

.em-rain_cloud.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f327.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f327.svg"), none;
}

.em-rainbow {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f308.png");
}

.em-rainbow.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f308.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f308.svg"), none;
}

.em-rainbow-flag {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3f3-fe0f-200d-1f308.png");
}

.em-rainbow-flag.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3f3-fe0f-200d-1f308.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3f3-fe0f-200d-1f308.svg"), none;
}

.em-raised_back_of_hand {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f91a.png");
}

.em-raised_back_of_hand.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f91a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f91a.svg"), none;
}

.em-raised_hand_with_fingers_splayed {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f590.png");
}

.em-raised_hand_with_fingers_splayed.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f590.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f590.svg"), none;
}

.em-raised_hands {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f64c.png");
}

.em-raised_hands.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f64c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f64c.svg"), none;
}

.em-raising_hand {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f64b.png");
}

.em-raising_hand.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f64b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f64b.svg"), none;
}

.em-ram {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f40f.png");
}

.em-ram.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f40f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f40f.svg"), none;
}

.em-ramen {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f35c.png");
}

.em-ramen.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f35c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f35c.svg"), none;
}

.em-rat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f400.png");
}

.em-rat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f400.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f400.svg"), none;
}

.em-recycle {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/267b.png");
}

.em-recycle.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/267b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/267b.svg"), none;
}

.em-red_circle {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f534.png");
}

.em-red_circle.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f534.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f534.svg"), none;
}

.em-registered {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/ae.png");
}

.em-registered.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/ae.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/ae.svg"), none;
}

.em-relaxed {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/263a.png");
}

.em-relaxed.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/263a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/263a.svg"), none;
}

.em-relieved {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f60c.png");
}

.em-relieved.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f60c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f60c.svg"), none;
}

.em-reminder_ribbon {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f397.png");
}

.em-reminder_ribbon.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f397.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f397.svg"), none;
}

.em-repeat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f501.png");
}

.em-repeat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f501.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f501.svg"), none;
}

.em-repeat_one {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f502.png");
}

.em-repeat_one.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f502.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f502.svg"), none;
}

.em-restroom {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6bb.png");
}

.em-restroom.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6bb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6bb.svg"), none;
}

.em-revolving_hearts {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f49e.png");
}

.em-revolving_hearts.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f49e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f49e.svg"), none;
}

.em-rewind {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/23ea.png");
}

.em-rewind.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/23ea.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/23ea.svg"), none;
}

.em-rhinoceros {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f98f.png");
}

.em-rhinoceros.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f98f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f98f.svg"), none;
}

.em-ribbon {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f380.png");
}

.em-ribbon.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f380.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f380.svg"), none;
}

.em-rice {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f35a.png");
}

.em-rice.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f35a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f35a.svg"), none;
}

.em-rice_ball {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f359.png");
}

.em-rice_ball.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f359.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f359.svg"), none;
}

.em-rice_cracker {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f358.png");
}

.em-rice_cracker.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f358.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f358.svg"), none;
}

.em-rice_scene {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f391.png");
}

.em-rice_scene.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f391.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f391.svg"), none;
}

.em-right-facing_fist {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f91c.png");
}

.em-right-facing_fist.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f91c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f91c.svg"), none;
}

.em-right_anger_bubble {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f5ef.png");
}

.em-right_anger_bubble.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f5ef.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f5ef.svg"), none;
}

.em-ring {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f48d.png");
}

.em-ring.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f48d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f48d.svg"), none;
}

.em-robot_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f916.png");
}

.em-robot_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f916.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f916.svg"), none;
}

.em-rocket {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f680.png");
}

.em-rocket.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f680.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f680.svg"), none;
}

.em-rolled_up_newspaper {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f5de.png");
}

.em-rolled_up_newspaper.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f5de.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f5de.svg"), none;
}

.em-roller_coaster {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3a2.png");
}

.em-roller_coaster.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3a2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3a2.svg"), none;
}

.em-rolling_on_the_floor_laughing {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f923.png");
}

.em-rolling_on_the_floor_laughing.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f923.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f923.svg"), none;
}

.em-rooster {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f413.png");
}

.em-rooster.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f413.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f413.svg"), none;
}

.em-rose {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f339.png");
}

.em-rose.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f339.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f339.svg"), none;
}

.em-rosette {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3f5.png");
}

.em-rosette.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3f5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3f5.svg"), none;
}

.em-rotating_light {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6a8.png");
}

.em-rotating_light.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6a8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a8.svg"), none;
}

.em-round_pushpin {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4cd.png");
}

.em-round_pushpin.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4cd.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4cd.svg"), none;
}

.em-rowboat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6a3.png");
}

.em-rowboat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6a3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a3.svg"), none;
}

.em-ru, .em-flag-ru {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1f7-1f1fa.png");
}

.em-ru.em-svg, .em-flag-ru.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1f7-1f1fa.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1f7-1f1fa.svg"), none;
}

.em-rugby_football {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3c9.png");
}

.em-rugby_football.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3c9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c9.svg"), none;
}

.em-runner, .em-running {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3c3.png");
}

.em-runner.em-svg, .em-running.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3c3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c3.svg"), none;
}

.em-running_shirt_with_sash {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3bd.png");
}

.em-running_shirt_with_sash.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3bd.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3bd.svg"), none;
}

.em-sa {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f202.png");
}

.em-sa.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f202.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f202.svg"), none;
}

.em-sagittarius {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2650.png");
}

.em-sagittarius.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2650.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2650.svg"), none;
}

.em-sake {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f376.png");
}

.em-sake.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f376.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f376.svg"), none;
}

.em-sandal {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f461.png");
}

.em-sandal.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f461.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f461.svg"), none;
}

.em-sandwich {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f96a.png");
}

.em-sandwich.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f96a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f96a.svg"), none;
}

.em-santa {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f385.png");
}

.em-santa.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f385.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f385.svg"), none;
}

.em-satellite {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6f0.png");
}

.em-satellite.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6f0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6f0.svg"), none;
}

.em-satellite_antenna {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4e1.png");
}

.em-satellite_antenna.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4e1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4e1.svg"), none;
}

.em-sauropod {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f995.png");
}

.em-sauropod.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f995.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f995.svg"), none;
}

.em-saxophone {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3b7.png");
}

.em-saxophone.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3b7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3b7.svg"), none;
}

.em-scales {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2696.png");
}

.em-scales.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2696.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2696.svg"), none;
}

.em-scarf {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9e3.png");
}

.em-scarf.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9e3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9e3.svg"), none;
}

.em-school {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3eb.png");
}

.em-school.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3eb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3eb.svg"), none;
}

.em-school_satchel {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f392.png");
}

.em-school_satchel.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f392.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f392.svg"), none;
}

.em-scissors {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2702.png");
}

.em-scissors.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2702.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2702.svg"), none;
}

.em-scooter {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6f4.png");
}

.em-scooter.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6f4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6f4.svg"), none;
}

.em-scorpion {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f982.png");
}

.em-scorpion.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f982.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f982.svg"), none;
}

.em-scorpius {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/264f.png");
}

.em-scorpius.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/264f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/264f.svg"), none;
}

.em-scream {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f631.png");
}

.em-scream.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f631.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f631.svg"), none;
}

.em-scream_cat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f640.png");
}

.em-scream_cat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f640.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f640.svg"), none;
}

.em-scroll {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4dc.png");
}

.em-scroll.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4dc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4dc.svg"), none;
}

.em-seat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4ba.png");
}

.em-seat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4ba.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ba.svg"), none;
}

.em-second_place_medal {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f948.png");
}

.em-second_place_medal.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f948.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f948.svg"), none;
}

.em-secret {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/3299.png");
}

.em-secret.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/3299.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/3299.svg"), none;
}

.em-see_no_evil {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f648.png");
}

.em-see_no_evil.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f648.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f648.svg"), none;
}

.em-seedling {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f331.png");
}

.em-seedling.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f331.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f331.svg"), none;
}

.em-selfie {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f933.png");
}

.em-selfie.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f933.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f933.svg"), none;
}

.em-seven {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/37-20e3.png");
}

.em-seven.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/37-20e3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/37-20e3.svg"), none;
}

.em-shallow_pan_of_food {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f958.png");
}

.em-shallow_pan_of_food.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f958.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f958.svg"), none;
}

.em-shamrock {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2618.png");
}

.em-shamrock.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2618.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2618.svg"), none;
}

.em-shark {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f988.png");
}

.em-shark.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f988.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f988.svg"), none;
}

.em-shaved_ice {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f367.png");
}

.em-shaved_ice.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f367.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f367.svg"), none;
}

.em-sheep {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f411.png");
}

.em-sheep.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f411.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f411.svg"), none;
}

.em-shell {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f41a.png");
}

.em-shell.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f41a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f41a.svg"), none;
}

.em-shield {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6e1.png");
}

.em-shield.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6e1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6e1.svg"), none;
}

.em-shinto_shrine {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26e9.png");
}

.em-shinto_shrine.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26e9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26e9.svg"), none;
}

.em-ship {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6a2.png");
}

.em-ship.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6a2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a2.svg"), none;
}

.em-shirt, .em-tshirt {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f455.png");
}

.em-shirt.em-svg, .em-tshirt.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f455.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f455.svg"), none;
}

.em-shopping_bags {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6cd.png");
}

.em-shopping_bags.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6cd.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6cd.svg"), none;
}

.em-shopping_trolley {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6d2.png");
}

.em-shopping_trolley.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6d2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6d2.svg"), none;
}

.em-shower {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6bf.png");
}

.em-shower.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6bf.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6bf.svg"), none;
}

.em-shrimp {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f990.png");
}

.em-shrimp.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f990.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f990.svg"), none;
}

.em-shrug {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f937.png");
}

.em-shrug.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f937.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f937.svg"), none;
}

.em-shushing_face, .em-face_with_finger_covering_closed_lips {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f92b.png");
}

.em-shushing_face.em-svg, .em-face_with_finger_covering_closed_lips.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f92b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f92b.svg"), none;
}

.em-signal_strength {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4f6.png");
}

.em-signal_strength.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4f6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4f6.svg"), none;
}

.em-six {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/36-20e3.png");
}

.em-six.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/36-20e3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/36-20e3.svg"), none;
}

.em-six_pointed_star {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f52f.png");
}

.em-six_pointed_star.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f52f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f52f.svg"), none;
}

.em-ski {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3bf.png");
}

.em-ski.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3bf.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3bf.svg"), none;
}

.em-skier {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26f7.png");
}

.em-skier.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26f7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26f7.svg"), none;
}

.em-skin-tone-2 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3fb.png");
}

.em-skin-tone-2.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3fb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3fb.svg"), none;
}

.em-skin-tone-3 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3fc.png");
}

.em-skin-tone-3.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3fc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3fc.svg"), none;
}

.em-skin-tone-4 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3fd.png");
}

.em-skin-tone-4.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3fd.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3fd.svg"), none;
}

.em-skin-tone-5 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3fe.png");
}

.em-skin-tone-5.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3fe.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3fe.svg"), none;
}

.em-skin-tone-6 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3ff.png");
}

.em-skin-tone-6.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3ff.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ff.svg"), none;
}

.em-skull {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f480.png");
}

.em-skull.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f480.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f480.svg"), none;
}

.em-skull_and_crossbones {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2620.png");
}

.em-skull_and_crossbones.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2620.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2620.svg"), none;
}

.em-sled {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6f7.png");
}

.em-sled.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6f7.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6f7.svg"), none;
}

.em-sleeping {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f634.png");
}

.em-sleeping.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f634.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f634.svg"), none;
}

.em-sleeping_accommodation {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6cc.png");
}

.em-sleeping_accommodation.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6cc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6cc.svg"), none;
}

.em-sleepy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f62a.png");
}

.em-sleepy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f62a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f62a.svg"), none;
}

.em-sleuth_or_spy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f575.png");
}

.em-sleuth_or_spy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f575.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f575.svg"), none;
}

.em-slightly_frowning_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f641.png");
}

.em-slightly_frowning_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f641.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f641.svg"), none;
}

.em-slightly_smiling_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f642.png");
}

.em-slightly_smiling_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f642.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f642.svg"), none;
}

.em-slot_machine {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3b0.png");
}

.em-slot_machine.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3b0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3b0.svg"), none;
}

.em-small_airplane {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6e9.png");
}

.em-small_airplane.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6e9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6e9.svg"), none;
}

.em-small_blue_diamond {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f539.png");
}

.em-small_blue_diamond.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f539.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f539.svg"), none;
}

.em-small_orange_diamond {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f538.png");
}

.em-small_orange_diamond.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f538.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f538.svg"), none;
}

.em-small_red_triangle {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f53a.png");
}

.em-small_red_triangle.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f53a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f53a.svg"), none;
}

.em-small_red_triangle_down {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f53b.png");
}

.em-small_red_triangle_down.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f53b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f53b.svg"), none;
}

.em-smile {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f604.png");
}

.em-smile.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f604.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f604.svg"), none;
}

.em-smile_cat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f638.png");
}

.em-smile_cat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f638.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f638.svg"), none;
}

.em-smiley {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f603.png");
}

.em-smiley.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f603.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f603.svg"), none;
}

.em-smiley_cat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f63a.png");
}

.em-smiley_cat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f63a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f63a.svg"), none;
}

.em-smiling_imp {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f608.png");
}

.em-smiling_imp.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f608.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f608.svg"), none;
}

.em-smirk {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f60f.png");
}

.em-smirk.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f60f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f60f.svg"), none;
}

.em-smirk_cat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f63c.png");
}

.em-smirk_cat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f63c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f63c.svg"), none;
}

.em-smoking {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6ac.png");
}

.em-smoking.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6ac.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6ac.svg"), none;
}

.em-snail {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f40c.png");
}

.em-snail.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f40c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f40c.svg"), none;
}

.em-snake {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f40d.png");
}

.em-snake.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f40d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f40d.svg"), none;
}

.em-sneezing_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f927.png");
}

.em-sneezing_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f927.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f927.svg"), none;
}

.em-snow_capped_mountain {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3d4.png");
}

.em-snow_capped_mountain.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3d4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3d4.svg"), none;
}

.em-snow_cloud {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f328.png");
}

.em-snow_cloud.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f328.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f328.svg"), none;
}

.em-snowboarder {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3c2.png");
}

.em-snowboarder.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3c2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c2.svg"), none;
}

.em-snowflake {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2744.png");
}

.em-snowflake.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2744.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2744.svg"), none;
}

.em-snowman {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2603.png");
}

.em-snowman.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2603.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2603.svg"), none;
}

.em-snowman_without_snow {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26c4.png");
}

.em-snowman_without_snow.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26c4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26c4.svg"), none;
}

.em-sob {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f62d.png");
}

.em-sob.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f62d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f62d.svg"), none;
}

.em-soccer {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26bd.png");
}

.em-soccer.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26bd.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26bd.svg"), none;
}

.em-socks {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9e6.png");
}

.em-socks.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9e6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9e6.svg"), none;
}

.em-soon {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f51c.png");
}

.em-soon.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f51c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f51c.svg"), none;
}

.em-sos {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f198.png");
}

.em-sos.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f198.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f198.svg"), none;
}

.em-sound {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f509.png");
}

.em-sound.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f509.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f509.svg"), none;
}

.em-space_invader {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f47e.png");
}

.em-space_invader.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f47e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f47e.svg"), none;
}

.em-spades {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2660.png");
}

.em-spades.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2660.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2660.svg"), none;
}

.em-spaghetti {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f35d.png");
}

.em-spaghetti.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f35d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f35d.svg"), none;
}

.em-sparkle {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2747.png");
}

.em-sparkle.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2747.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2747.svg"), none;
}

.em-sparkler {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f387.png");
}

.em-sparkler.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f387.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f387.svg"), none;
}

.em-sparkles {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2728.png");
}

.em-sparkles.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2728.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2728.svg"), none;
}

.em-sparkling_heart {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f496.png");
}

.em-sparkling_heart.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f496.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f496.svg"), none;
}

.em-speak_no_evil {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f64a.png");
}

.em-speak_no_evil.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f64a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f64a.svg"), none;
}

.em-speaker {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f508.png");
}

.em-speaker.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f508.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f508.svg"), none;
}

.em-speaking_head_in_silhouette {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f5e3.png");
}

.em-speaking_head_in_silhouette.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f5e3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f5e3.svg"), none;
}

.em-speech_balloon {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4ac.png");
}

.em-speech_balloon.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4ac.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ac.svg"), none;
}

.em-speedboat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6a4.png");
}

.em-speedboat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6a4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a4.svg"), none;
}

.em-spider {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f577.png");
}

.em-spider.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f577.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f577.svg"), none;
}

.em-spider_web {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f578.png");
}

.em-spider_web.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f578.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f578.svg"), none;
}

.em-spiral_calendar_pad {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f5d3.png");
}

.em-spiral_calendar_pad.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f5d3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f5d3.svg"), none;
}

.em-spiral_note_pad {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f5d2.png");
}

.em-spiral_note_pad.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f5d2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f5d2.svg"), none;
}

.em-spock-hand {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f596.png");
}

.em-spock-hand.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f596.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f596.svg"), none;
}

.em-spoon {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f944.png");
}

.em-spoon.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f944.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f944.svg"), none;
}

.em-sports_medal {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3c5.png");
}

.em-sports_medal.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3c5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c5.svg"), none;
}

.em-squid {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f991.png");
}

.em-squid.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f991.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f991.svg"), none;
}

.em-stadium {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3df.png");
}

.em-stadium.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3df.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3df.svg"), none;
}

.em-star {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2b50.png");
}

.em-star.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2b50.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2b50.svg"), none;
}

.em-star-struck, .em-grinning_face_with_star_eyes {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f929.png");
}

.em-star-struck.em-svg, .em-grinning_face_with_star_eyes.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f929.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f929.svg"), none;
}

.em-star2 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f31f.png");
}

.em-star2.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f31f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f31f.svg"), none;
}

.em-star_and_crescent {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/262a.png");
}

.em-star_and_crescent.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/262a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/262a.svg"), none;
}

.em-star_of_david {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2721.png");
}

.em-star_of_david.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2721.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2721.svg"), none;
}

.em-stars {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f320.png");
}

.em-stars.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f320.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f320.svg"), none;
}

.em-station {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f689.png");
}

.em-station.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f689.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f689.svg"), none;
}

.em-statue_of_liberty {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f5fd.png");
}

.em-statue_of_liberty.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f5fd.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f5fd.svg"), none;
}

.em-steam_locomotive {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f682.png");
}

.em-steam_locomotive.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f682.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f682.svg"), none;
}

.em-stew {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f372.png");
}

.em-stew.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f372.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f372.svg"), none;
}

.em-stopwatch {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/23f1.png");
}

.em-stopwatch.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/23f1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/23f1.svg"), none;
}

.em-straight_ruler {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4cf.png");
}

.em-straight_ruler.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4cf.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4cf.svg"), none;
}

.em-strawberry {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f353.png");
}

.em-strawberry.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f353.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f353.svg"), none;
}

.em-stuck_out_tongue {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f61b.png");
}

.em-stuck_out_tongue.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f61b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f61b.svg"), none;
}

.em-stuck_out_tongue_closed_eyes {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f61d.png");
}

.em-stuck_out_tongue_closed_eyes.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f61d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f61d.svg"), none;
}

.em-stuck_out_tongue_winking_eye {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f61c.png");
}

.em-stuck_out_tongue_winking_eye.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f61c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f61c.svg"), none;
}

.em-studio_microphone {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f399.png");
}

.em-studio_microphone.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f399.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f399.svg"), none;
}

.em-stuffed_flatbread {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f959.png");
}

.em-stuffed_flatbread.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f959.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f959.svg"), none;
}

.em-sun_with_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f31e.png");
}

.em-sun_with_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f31e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f31e.svg"), none;
}

.em-sunflower {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f33b.png");
}

.em-sunflower.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f33b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f33b.svg"), none;
}

.em-sunglasses {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f60e.png");
}

.em-sunglasses.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f60e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f60e.svg"), none;
}

.em-sunny {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2600.png");
}

.em-sunny.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2600.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2600.svg"), none;
}

.em-sunrise {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f305.png");
}

.em-sunrise.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f305.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f305.svg"), none;
}

.em-sunrise_over_mountains {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f304.png");
}

.em-sunrise_over_mountains.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f304.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f304.svg"), none;
}

.em-surfer {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3c4.png");
}

.em-surfer.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3c4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c4.svg"), none;
}

.em-sushi {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f363.png");
}

.em-sushi.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f363.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f363.svg"), none;
}

.em-suspension_railway {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f69f.png");
}

.em-suspension_railway.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f69f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f69f.svg"), none;
}

.em-sweat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f613.png");
}

.em-sweat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f613.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f613.svg"), none;
}

.em-sweat_drops {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4a6.png");
}

.em-sweat_drops.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4a6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4a6.svg"), none;
}

.em-sweat_smile {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f605.png");
}

.em-sweat_smile.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f605.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f605.svg"), none;
}

.em-sweet_potato {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f360.png");
}

.em-sweet_potato.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f360.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f360.svg"), none;
}

.em-swimmer {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3ca.png");
}

.em-swimmer.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3ca.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ca.svg"), none;
}

.em-symbols {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f523.png");
}

.em-symbols.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f523.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f523.svg"), none;
}

.em-synagogue {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f54d.png");
}

.em-synagogue.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f54d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f54d.svg"), none;
}

.em-syringe {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f489.png");
}

.em-syringe.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f489.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f489.svg"), none;
}

.em-t-rex {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f996.png");
}

.em-t-rex.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f996.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f996.svg"), none;
}

.em-table_tennis_paddle_and_ball {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3d3.png");
}

.em-table_tennis_paddle_and_ball.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3d3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3d3.svg"), none;
}

.em-taco {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f32e.png");
}

.em-taco.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f32e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f32e.svg"), none;
}

.em-tada {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f389.png");
}

.em-tada.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f389.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f389.svg"), none;
}

.em-takeout_box {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f961.png");
}

.em-takeout_box.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f961.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f961.svg"), none;
}

.em-tanabata_tree {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f38b.png");
}

.em-tanabata_tree.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f38b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f38b.svg"), none;
}

.em-tangerine {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f34a.png");
}

.em-tangerine.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f34a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f34a.svg"), none;
}

.em-taurus {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2649.png");
}

.em-taurus.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2649.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2649.svg"), none;
}

.em-taxi {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f695.png");
}

.em-taxi.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f695.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f695.svg"), none;
}

.em-tea {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f375.png");
}

.em-tea.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f375.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f375.svg"), none;
}

.em-telephone_receiver {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4de.png");
}

.em-telephone_receiver.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4de.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4de.svg"), none;
}

.em-telescope {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f52d.png");
}

.em-telescope.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f52d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f52d.svg"), none;
}

.em-tennis {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3be.png");
}

.em-tennis.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3be.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3be.svg"), none;
}

.em-tent {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26fa.png");
}

.em-tent.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26fa.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26fa.svg"), none;
}

.em-the_horns, .em-sign_of_the_horns {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f918.png");
}

.em-the_horns.em-svg, .em-sign_of_the_horns.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f918.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f918.svg"), none;
}

.em-thermometer {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f321.png");
}

.em-thermometer.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f321.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f321.svg"), none;
}

.em-thinking_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f914.png");
}

.em-thinking_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f914.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f914.svg"), none;
}

.em-third_place_medal {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f949.png");
}

.em-third_place_medal.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f949.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f949.svg"), none;
}

.em-thought_balloon {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4ad.png");
}

.em-thought_balloon.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4ad.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ad.svg"), none;
}

.em-three {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/33-20e3.png");
}

.em-three.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/33-20e3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/33-20e3.svg"), none;
}

.em-three_button_mouse {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f5b1.png");
}

.em-three_button_mouse.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f5b1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f5b1.svg"), none;
}

.em-thunder_cloud_and_rain {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26c8.png");
}

.em-thunder_cloud_and_rain.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26c8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26c8.svg"), none;
}

.em-ticket {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3ab.png");
}

.em-ticket.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3ab.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ab.svg"), none;
}

.em-tiger {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f42f.png");
}

.em-tiger.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f42f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f42f.svg"), none;
}

.em-tiger2 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f405.png");
}

.em-tiger2.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f405.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f405.svg"), none;
}

.em-timer_clock {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/23f2.png");
}

.em-timer_clock.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/23f2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/23f2.svg"), none;
}

.em-tired_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f62b.png");
}

.em-tired_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f62b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f62b.svg"), none;
}

.em-tm {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2122.png");
}

.em-tm.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2122.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2122.svg"), none;
}

.em-toilet {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6bd.png");
}

.em-toilet.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6bd.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6bd.svg"), none;
}

.em-tokyo_tower {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f5fc.png");
}

.em-tokyo_tower.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f5fc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f5fc.svg"), none;
}

.em-tomato {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f345.png");
}

.em-tomato.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f345.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f345.svg"), none;
}

.em-tongue {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f445.png");
}

.em-tongue.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f445.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f445.svg"), none;
}

.em-top {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f51d.png");
}

.em-top.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f51d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f51d.svg"), none;
}

.em-tophat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3a9.png");
}

.em-tophat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3a9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3a9.svg"), none;
}

.em-tornado, .em-tornado_cloud {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f32a.png");
}

.em-tornado.em-svg, .em-tornado_cloud.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f32a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f32a.svg"), none;
}

.em-trackball {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f5b2.png");
}

.em-trackball.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f5b2.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f5b2.svg"), none;
}

.em-tractor {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f69c.png");
}

.em-tractor.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f69c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f69c.svg"), none;
}

.em-traffic_light {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6a5.png");
}

.em-traffic_light.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6a5.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a5.svg"), none;
}

.em-train {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f68b.png");
}

.em-train.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f68b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f68b.svg"), none;
}

.em-train2 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f686.png");
}

.em-train2.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f686.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f686.svg"), none;
}

.em-tram {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f68a.png");
}

.em-tram.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f68a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f68a.svg"), none;
}

.em-triangular_flag_on_post {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6a9.png");
}

.em-triangular_flag_on_post.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6a9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a9.svg"), none;
}

.em-triangular_ruler {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4d0.png");
}

.em-triangular_ruler.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4d0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4d0.svg"), none;
}

.em-trident {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f531.png");
}

.em-trident.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f531.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f531.svg"), none;
}

.em-triumph {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f624.png");
}

.em-triumph.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f624.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f624.svg"), none;
}

.em-trolleybus {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f68e.png");
}

.em-trolleybus.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f68e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f68e.svg"), none;
}

.em-trophy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3c6.png");
}

.em-trophy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3c6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c6.svg"), none;
}

.em-tropical_drink {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f379.png");
}

.em-tropical_drink.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f379.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f379.svg"), none;
}

.em-tropical_fish {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f420.png");
}

.em-tropical_fish.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f420.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f420.svg"), none;
}

.em-truck {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f69a.png");
}

.em-truck.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f69a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f69a.svg"), none;
}

.em-trumpet {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3ba.png");
}

.em-trumpet.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3ba.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ba.svg"), none;
}

.em-tulip {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f337.png");
}

.em-tulip.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f337.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f337.svg"), none;
}

.em-tumbler_glass {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f943.png");
}

.em-tumbler_glass.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f943.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f943.svg"), none;
}

.em-turkey {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f983.png");
}

.em-turkey.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f983.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f983.svg"), none;
}

.em-turtle {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f422.png");
}

.em-turtle.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f422.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f422.svg"), none;
}

.em-tv {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4fa.png");
}

.em-tv.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4fa.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4fa.svg"), none;
}

.em-twisted_rightwards_arrows {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f500.png");
}

.em-twisted_rightwards_arrows.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f500.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f500.svg"), none;
}

.em-two {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/32-20e3.png");
}

.em-two.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/32-20e3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/32-20e3.svg"), none;
}

.em-two_hearts {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f495.png");
}

.em-two_hearts.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f495.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f495.svg"), none;
}

.em-two_men_holding_hands {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f46c.png");
}

.em-two_men_holding_hands.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f46c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f46c.svg"), none;
}

.em-two_women_holding_hands {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f46d.png");
}

.em-two_women_holding_hands.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f46d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f46d.svg"), none;
}

.em-u5272 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f239.png");
}

.em-u5272.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f239.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f239.svg"), none;
}

.em-u5408 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f234.png");
}

.em-u5408.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f234.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f234.svg"), none;
}

.em-u55b6 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f23a.png");
}

.em-u55b6.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f23a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f23a.svg"), none;
}

.em-u6307 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f22f.png");
}

.em-u6307.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f22f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f22f.svg"), none;
}

.em-u6708 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f237.png");
}

.em-u6708.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f237.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f237.svg"), none;
}

.em-u6709 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f236.png");
}

.em-u6709.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f236.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f236.svg"), none;
}

.em-u6e80 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f235.png");
}

.em-u6e80.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f235.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f235.svg"), none;
}

.em-u7121 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f21a.png");
}

.em-u7121.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f21a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f21a.svg"), none;
}

.em-u7533 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f238.png");
}

.em-u7533.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f238.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f238.svg"), none;
}

.em-u7981 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f232.png");
}

.em-u7981.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f232.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f232.svg"), none;
}

.em-u7a7a {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f233.png");
}

.em-u7a7a.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f233.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f233.svg"), none;
}

.em-umbrella {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2602.png");
}

.em-umbrella.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2602.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2602.svg"), none;
}

.em-umbrella_on_ground {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26f1.png");
}

.em-umbrella_on_ground.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26f1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26f1.svg"), none;
}

.em-umbrella_with_rain_drops {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2614.png");
}

.em-umbrella_with_rain_drops.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2614.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2614.svg"), none;
}

.em-unamused {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f612.png");
}

.em-unamused.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f612.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f612.svg"), none;
}

.em-underage {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f51e.png");
}

.em-underage.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f51e.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f51e.svg"), none;
}

.em-unicorn_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f984.png");
}

.em-unicorn_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f984.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f984.svg"), none;
}

.em-unlock {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f513.png");
}

.em-unlock.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f513.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f513.svg"), none;
}

.em-up {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f199.png");
}

.em-up.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f199.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f199.svg"), none;
}

.em-upside_down_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f643.png");
}

.em-upside_down_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f643.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f643.svg"), none;
}

.em-us, .em-flag-us {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f1fa-1f1f8.png");
}

.em-us.em-svg, .em-flag-us.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f1fa-1f1f8.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f1fa-1f1f8.svg"), none;
}

.em-v {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/270c.png");
}

.em-v.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/270c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/270c.svg"), none;
}

.em-vampire {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9db.png");
}

.em-vampire.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9db.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9db.svg"), none;
}

.em-vertical_traffic_light {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6a6.png");
}

.em-vertical_traffic_light.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6a6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a6.svg"), none;
}

.em-vhs {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4fc.png");
}

.em-vhs.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4fc.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4fc.svg"), none;
}

.em-vibration_mode {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4f3.png");
}

.em-vibration_mode.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4f3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4f3.svg"), none;
}

.em-video_camera {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4f9.png");
}

.em-video_camera.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4f9.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4f9.svg"), none;
}

.em-video_game {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3ae.png");
}

.em-video_game.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3ae.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ae.svg"), none;
}

.em-violin {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3bb.png");
}

.em-violin.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3bb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3bb.svg"), none;
}

.em-virgo {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/264d.png");
}

.em-virgo.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/264d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/264d.svg"), none;
}

.em-volcano {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f30b.png");
}

.em-volcano.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f30b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f30b.svg"), none;
}

.em-volleyball {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3d0.png");
}

.em-volleyball.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3d0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3d0.svg"), none;
}

.em-vs {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f19a.png");
}

.em-vs.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f19a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f19a.svg"), none;
}

.em-walking {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6b6.png");
}

.em-walking.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6b6.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b6.svg"), none;
}

.em-waning_crescent_moon {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f318.png");
}

.em-waning_crescent_moon.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f318.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f318.svg"), none;
}

.em-waning_gibbous_moon {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f316.png");
}

.em-waning_gibbous_moon.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f316.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f316.svg"), none;
}

.em-warning {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26a0.png");
}

.em-warning.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26a0.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26a0.svg"), none;
}

.em-wastebasket {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f5d1.png");
}

.em-wastebasket.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f5d1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f5d1.svg"), none;
}

.em-watch {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/231a.png");
}

.em-watch.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/231a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/231a.svg"), none;
}

.em-water_buffalo {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f403.png");
}

.em-water_buffalo.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f403.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f403.svg"), none;
}

.em-water_polo {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f93d.png");
}

.em-water_polo.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f93d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f93d.svg"), none;
}

.em-watermelon {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f349.png");
}

.em-watermelon.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f349.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f349.svg"), none;
}

.em-wave {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f44b.png");
}

.em-wave.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f44b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f44b.svg"), none;
}

.em-waving_black_flag {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3f4.png");
}

.em-waving_black_flag.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3f4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3f4.svg"), none;
}

.em-waving_white_flag {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3f3.png");
}

.em-waving_white_flag.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3f3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3f3.svg"), none;
}

.em-wavy_dash {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/3030.png");
}

.em-wavy_dash.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/3030.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/3030.svg"), none;
}

.em-waxing_crescent_moon {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f312.png");
}

.em-waxing_crescent_moon.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f312.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f312.svg"), none;
}

.em-wc {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6be.png");
}

.em-wc.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6be.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6be.svg"), none;
}

.em-weary {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f629.png");
}

.em-weary.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f629.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f629.svg"), none;
}

.em-wedding {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f492.png");
}

.em-wedding.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f492.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f492.svg"), none;
}

.em-weight_lifter {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3cb.png");
}

.em-weight_lifter.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3cb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3cb.svg"), none;
}

.em-whale {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f433.png");
}

.em-whale.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f433.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f433.svg"), none;
}

.em-whale2 {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f40b.png");
}

.em-whale2.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f40b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f40b.svg"), none;
}

.em-wheel_of_dharma {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2638.png");
}

.em-wheel_of_dharma.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2638.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2638.svg"), none;
}

.em-wheelchair {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/267f.png");
}

.em-wheelchair.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/267f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/267f.svg"), none;
}

.em-white_check_mark {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2705.png");
}

.em-white_check_mark.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2705.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2705.svg"), none;
}

.em-white_circle {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26aa.png");
}

.em-white_circle.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26aa.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26aa.svg"), none;
}

.em-white_flower {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4ae.png");
}

.em-white_flower.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4ae.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4ae.svg"), none;
}

.em-white_frowning_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2639.png");
}

.em-white_frowning_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2639.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2639.svg"), none;
}

.em-white_large_square {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/2b1c.png");
}

.em-white_large_square.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/2b1c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/2b1c.svg"), none;
}

.em-white_medium_small_square {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/25fd.png");
}

.em-white_medium_small_square.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/25fd.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/25fd.svg"), none;
}

.em-white_medium_square {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/25fb.png");
}

.em-white_medium_square.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/25fb.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/25fb.svg"), none;
}

.em-white_small_square {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/25ab.png");
}

.em-white_small_square.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/25ab.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/25ab.svg"), none;
}

.em-white_square_button {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f533.png");
}

.em-white_square_button.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f533.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f533.svg"), none;
}

.em-wilted_flower {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f940.png");
}

.em-wilted_flower.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f940.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f940.svg"), none;
}

.em-wind_blowing_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f32c.png");
}

.em-wind_blowing_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f32c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f32c.svg"), none;
}

.em-wind_chime {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f390.png");
}

.em-wind_chime.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f390.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f390.svg"), none;
}

.em-wine_glass {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f377.png");
}

.em-wine_glass.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f377.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f377.svg"), none;
}

.em-wink {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f609.png");
}

.em-wink.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f609.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f609.svg"), none;
}

.em-wolf {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f43a.png");
}

.em-wolf.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f43a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f43a.svg"), none;
}

.em-woman {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469.png");
}

.em-woman.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469.svg"), none;
}

.em-woman-biking {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6b4-200d-2640-fe0f.png");
}

.em-woman-biking.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6b4-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b4-200d-2640-fe0f.svg"), none;
}

.em-woman-bouncing-ball {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26f9-fe0f-200d-2640-fe0f.png");
}

.em-woman-bouncing-ball.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26f9-fe0f-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26f9-fe0f-200d-2640-fe0f.svg"), none;
}

.em-woman-bowing {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f647-200d-2640-fe0f.png");
}

.em-woman-bowing.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f647-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f647-200d-2640-fe0f.svg"), none;
}

.em-woman-boy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f466.png");
}

.em-woman-boy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f466.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f466.svg"), none;
}

.em-woman-boy-boy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f466-200d-1f466.png");
}

.em-woman-boy-boy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f466-200d-1f466.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f466-200d-1f466.svg"), none;
}

.em-woman-cartwheeling {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f938-200d-2640-fe0f.png");
}

.em-woman-cartwheeling.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f938-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f938-200d-2640-fe0f.svg"), none;
}

.em-woman-facepalming {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f926-200d-2640-fe0f.png");
}

.em-woman-facepalming.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f926-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f926-200d-2640-fe0f.svg"), none;
}

.em-woman-frowning {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f64d-200d-2640-fe0f.png");
}

.em-woman-frowning.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f64d-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f64d-200d-2640-fe0f.svg"), none;
}

.em-woman-gesturing-no {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f645-200d-2640-fe0f.png");
}

.em-woman-gesturing-no.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f645-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f645-200d-2640-fe0f.svg"), none;
}

.em-woman-gesturing-ok {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f646-200d-2640-fe0f.png");
}

.em-woman-gesturing-ok.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f646-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f646-200d-2640-fe0f.svg"), none;
}

.em-woman-getting-haircut {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f487-200d-2640-fe0f.png");
}

.em-woman-getting-haircut.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f487-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f487-200d-2640-fe0f.svg"), none;
}

.em-woman-getting-massage {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f486-200d-2640-fe0f.png");
}

.em-woman-getting-massage.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f486-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f486-200d-2640-fe0f.svg"), none;
}

.em-woman-girl {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f467.png");
}

.em-woman-girl.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f467.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f467.svg"), none;
}

.em-woman-girl-boy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f467-200d-1f466.png");
}

.em-woman-girl-boy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f467-200d-1f466.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f467-200d-1f466.svg"), none;
}

.em-woman-girl-girl {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f467-200d-1f467.png");
}

.em-woman-girl-girl.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f467-200d-1f467.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f467-200d-1f467.svg"), none;
}

.em-woman-golfing {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3cc-fe0f-200d-2640-fe0f.png");
}

.em-woman-golfing.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3cc-fe0f-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3cc-fe0f-200d-2640-fe0f.svg"), none;
}

.em-woman-heart-man {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-2764-fe0f-200d-1f468.png");
}

.em-woman-heart-man.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-2764-fe0f-200d-1f468.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-2764-fe0f-200d-1f468.svg"), none;
}

.em-woman-heart-woman {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-2764-fe0f-200d-1f469.png");
}

.em-woman-heart-woman.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-2764-fe0f-200d-1f469.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-2764-fe0f-200d-1f469.svg"), none;
}

.em-woman-juggling {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f939-200d-2640-fe0f.png");
}

.em-woman-juggling.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f939-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f939-200d-2640-fe0f.svg"), none;
}

.em-woman-kiss-man {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-2764-fe0f-200d-1f48b-200d-1f468.png");
}

.em-woman-kiss-man.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-2764-fe0f-200d-1f48b-200d-1f468.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-2764-fe0f-200d-1f48b-200d-1f468.svg"), none;
}

.em-woman-kiss-woman {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-2764-fe0f-200d-1f48b-200d-1f469.png");
}

.em-woman-kiss-woman.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-2764-fe0f-200d-1f48b-200d-1f469.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-2764-fe0f-200d-1f48b-200d-1f469.svg"), none;
}

.em-woman-lifting-weights {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3cb-fe0f-200d-2640-fe0f.png");
}

.em-woman-lifting-weights.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3cb-fe0f-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3cb-fe0f-200d-2640-fe0f.svg"), none;
}

.em-woman-mountain-biking {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6b5-200d-2640-fe0f.png");
}

.em-woman-mountain-biking.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6b5-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b5-200d-2640-fe0f.svg"), none;
}

.em-woman-playing-handball {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f93e-200d-2640-fe0f.png");
}

.em-woman-playing-handball.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f93e-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f93e-200d-2640-fe0f.svg"), none;
}

.em-woman-playing-water-polo {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f93d-200d-2640-fe0f.png");
}

.em-woman-playing-water-polo.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f93d-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f93d-200d-2640-fe0f.svg"), none;
}

.em-woman-pouting {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f64e-200d-2640-fe0f.png");
}

.em-woman-pouting.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f64e-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f64e-200d-2640-fe0f.svg"), none;
}

.em-woman-raising-hand {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f64b-200d-2640-fe0f.png");
}

.em-woman-raising-hand.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f64b-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f64b-200d-2640-fe0f.svg"), none;
}

.em-woman-rowing-boat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6a3-200d-2640-fe0f.png");
}

.em-woman-rowing-boat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6a3-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6a3-200d-2640-fe0f.svg"), none;
}

.em-woman-running {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3c3-200d-2640-fe0f.png");
}

.em-woman-running.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3c3-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c3-200d-2640-fe0f.svg"), none;
}

.em-woman-shrugging {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f937-200d-2640-fe0f.png");
}

.em-woman-shrugging.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f937-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f937-200d-2640-fe0f.svg"), none;
}

.em-woman-surfing {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3c4-200d-2640-fe0f.png");
}

.em-woman-surfing.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3c4-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3c4-200d-2640-fe0f.svg"), none;
}

.em-woman-swimming {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f3ca-200d-2640-fe0f.png");
}

.em-woman-swimming.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f3ca-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f3ca-200d-2640-fe0f.svg"), none;
}

.em-woman-tipping-hand {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f481-200d-2640-fe0f.png");
}

.em-woman-tipping-hand.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f481-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f481-200d-2640-fe0f.svg"), none;
}

.em-woman-walking {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6b6-200d-2640-fe0f.png");
}

.em-woman-walking.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6b6-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6b6-200d-2640-fe0f.svg"), none;
}

.em-woman-wearing-turban {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f473-200d-2640-fe0f.png");
}

.em-woman-wearing-turban.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f473-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f473-200d-2640-fe0f.svg"), none;
}

.em-woman-with-bunny-ears-partying {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f46f-200d-2640-fe0f.png");
}

.em-woman-with-bunny-ears-partying.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f46f-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f46f-200d-2640-fe0f.svg"), none;
}

.em-woman-woman-boy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f469-200d-1f466.png");
}

.em-woman-woman-boy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f469-200d-1f466.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f469-200d-1f466.svg"), none;
}

.em-woman-woman-boy-boy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f469-200d-1f466-200d-1f466.png");
}

.em-woman-woman-boy-boy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f469-200d-1f466-200d-1f466.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f469-200d-1f466-200d-1f466.svg"), none;
}

.em-woman-woman-girl {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f469-200d-1f467.png");
}

.em-woman-woman-girl.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f469-200d-1f467.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f469-200d-1f467.svg"), none;
}

.em-woman-woman-girl-boy {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f469-200d-1f467-200d-1f466.png");
}

.em-woman-woman-girl-boy.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f469-200d-1f467-200d-1f466.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f469-200d-1f467-200d-1f466.svg"), none;
}

.em-woman-woman-girl-girl {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f469-200d-1f467-200d-1f467.png");
}

.em-woman-woman-girl-girl.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f469-200d-1f469-200d-1f467-200d-1f467.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f469-200d-1f469-200d-1f467-200d-1f467.svg"), none;
}

.em-woman-wrestling {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f93c-200d-2640-fe0f.png");
}

.em-woman-wrestling.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f93c-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f93c-200d-2640-fe0f.svg"), none;
}

.em-woman_climbing {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9d7-200d-2640-fe0f.png");
}

.em-woman_climbing.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9d7-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9d7-200d-2640-fe0f.svg"), none;
}

.em-woman_in_lotus_position {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9d8-200d-2640-fe0f.png");
}

.em-woman_in_lotus_position.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9d8-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9d8-200d-2640-fe0f.svg"), none;
}

.em-woman_in_steamy_room {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9d6-200d-2640-fe0f.png");
}

.em-woman_in_steamy_room.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9d6-200d-2640-fe0f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9d6-200d-2640-fe0f.svg"), none;
}

.em-womans_clothes {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f45a.png");
}

.em-womans_clothes.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f45a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f45a.svg"), none;
}

.em-womans_hat {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f452.png");
}

.em-womans_hat.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f452.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f452.svg"), none;
}

.em-womens {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f6ba.png");
}

.em-womens.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f6ba.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f6ba.svg"), none;
}

.em-world_map {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f5fa.png");
}

.em-world_map.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f5fa.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f5fa.svg"), none;
}

.em-worried {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f61f.png");
}

.em-worried.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f61f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f61f.svg"), none;
}

.em-wrench {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f527.png");
}

.em-wrench.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f527.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f527.svg"), none;
}

.em-wrestlers {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f93c.png");
}

.em-wrestlers.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f93c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f93c.svg"), none;
}

.em-writing_hand {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/270d.png");
}

.em-writing_hand.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/270d.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/270d.svg"), none;
}

.em-x {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/274c.png");
}

.em-x.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/274c.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/274c.svg"), none;
}

.em-yellow_heart {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f49b.png");
}

.em-yellow_heart.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f49b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f49b.svg"), none;
}

.em-yen {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4b4.png");
}

.em-yen.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4b4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4b4.svg"), none;
}

.em-yin_yang {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/262f.png");
}

.em-yin_yang.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/262f.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/262f.svg"), none;
}

.em-yum {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f60b.png");
}

.em-yum.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f60b.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f60b.svg"), none;
}

.em-zany_face, .em-grinning_face_with_one_large_and_one_small_eye {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f92a.png");
}

.em-zany_face.em-svg, .em-grinning_face_with_one_large_and_one_small_eye.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f92a.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f92a.svg"), none;
}

.em-zap {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/26a1.png");
}

.em-zap.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/26a1.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/26a1.svg"), none;
}

.em-zebra_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f993.png");
}

.em-zebra_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f993.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f993.svg"), none;
}

.em-zero {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/30-20e3.png");
}

.em-zero.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/30-20e3.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/30-20e3.svg"), none;
}

.em-zipper_mouth_face {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f910.png");
}

.em-zipper_mouth_face.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f910.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f910.svg"), none;
}

.em-zombie {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f9df.png");
}

.em-zombie.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f9df.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f9df.svg"), none;
}

.em-zzz {
  background-image: url("https://twemoji.maxcdn.com/2/72x72/1f4a4.png");
}

.em-zzz.em-svg {
  background: url("https://twemoji.maxcdn.com/2/72x72/1f4a4.png");
  background-image: url("https://twemoji.maxcdn.com/2/svg/1f4a4.svg"), none;
}
/*# sourceMappingURL=icons.css.map */